



























































































































































































































































































































































import { ADDRESS } from "@/setting";
import axios from "axios";
import { Component, Vue, Watch } from "vue-property-decorator";
import { ConfirmAction } from "@/decorators";
@Component({
  props:{
    status:String,
    budgetYear:Number,
    active_i:Number,
    active_j:Number,
    active_k:Number,
    activeItem:Object,
    budgetitems:Array
  },
  components: {},
})
export default class Form extends Vue {
  status!:string
  budgetYear!:number
  master:Array<any> = []
  types:Array<any> = []
  budgetitems!:Array<any>
  activeItem:any = {
    months:[0,0,0,0,0,0,0,0,0,0,0,0],
    monthsFlag:[false,false,false,false,false,false,false,false,false,false,false,false],
    budgetOverlap:[{
      year:0,
      budgets:[0,0,0,0,0,0,0,0,0,0,0,0]
    }]
  }
  active_i!:number
  active_j!:number
  active_k!:number
  masterbyName:Array<string> = []
  budgetOperationTypes:Array<any> = []
  budgetItemCategoryTypes:Array<any> = []
  responsibles:Array<any> = []

  get categories(){
    if(this.activeItem.type == "งบอุดหนุน"){
      return [
        'การจ้างผู้ช่วยปฎิบัติงาน ฯ',
        'ค่าใช้จ่ายในการเดินทางต่างประเทศ',
        'ค่าใช้จ่ายการเดินทางในประเทศ',
        'ค่าเช่าเครื่องถ่ายเอกสาร',
        'การจัดซื้อวัสดุสำนักงาน',
        'ค่าเบี้ยประชุม / ค่าตอบแทน',
        'ค่าจัดประชุมคณะกรรมการต่าง ๆ',
        'จ้างที่ปรึกษา',
        'การปฏิบัติงานนอกเวลา',
        'อื่นๆ'
      ]
    } else {
      return [
        'งบลงทุน',
        'งบลงทุนภายในรายการ'
      ]
    }
  }

  @ConfirmAction(`ยืนยันลบงบกันเหลื่อม?`)
  deleteBudgetOverlap(i:number){
    this.activeItem.budgetOverlap.splice(i, 1)
  }
  pushBudgetOverlap() {
    let budgetOverlap: Array<any> = this.activeItem.budgetOverlap;
    if (!Array.isArray(budgetOverlap)) budgetOverlap = [];
    budgetOverlap.push({
      year:0,
      budgets:[0,0,0,0,0,0,0,0,0,0,0,0]
    })
    this.$set(this.activeItem, "budgetOverlap", budgetOverlap);
  }
  mounted(){
    this.fetchBudgetOperation()
    this.fetchBudgetItemType()
    this.fetchbudgetItemCategory()
    this.fetchMaster()
  }

  fetchBudgetOperation(){
    axios.get(`${ADDRESS}/setting/get-by-name/ประเภทแหล่งเงินงบประมาณในหน้าแผน`).then((response) => {
      console.log('budgetOperationTypes',response)
      let data = response.data
      let member = []
      try {
        member = data.doc.member
      } catch (error) { }
      this.budgetOperationTypes = member
    });
    console.log('budgetOperationTypes',this.budgetOperationTypes)
  }
  fetchbudgetItemCategory(){
    axios.get(`${ADDRESS}/setting/get-by-name/ประเภทค่าใช้จ่ายในหน้าแผน`).then((response) => {
      // console.log('budgetItemCategoryTypes',response)
      let data = response.data
      let member = []
      try {
        member = data.doc.member
      } catch (error) { }
      this.budgetItemCategoryTypes = member
    });
    console.log('budgetItemCategoryTypes',this.budgetItemCategoryTypes)
  }
  fetchMaster(){
    this.master = []
    let query = {
      search:"WHERE category = 'master,ERP'",
    }
    axios.post(`${ADDRESS}/settings/paginate`,query).then((response) => {
      console.log(response)
      this.master = response.data.result;
    });
  }
  fetchBudgetItemType(){
    axios.get(`${ADDRESS}/setting/get-by-name/ประเภทงบประมาณในคำขอ`).then((response) => {
      console.log(response)
      let data = response.data
      let member = []
      try {
        member = data.doc.member
      } catch (error) { }
      this.types = member
    });
  }
  // @Watch('doc.typeCode') 
  updateTypeCode(){
    try {
      let combined = this.activeItem.sourceCombined
      combined = combined.split(",")
      let sourceName = combined[0]
      let type = combined[1]
      let typeCode = combined[2]
      this.activeItem.sourceName = sourceName
      this.activeItem.type = type
      this.activeItem.typeCode = typeCode
    } catch (error) {
      console.log(error)
    }
  }
  // @Watch('doc.type') 
  // updateTypes(){
  //   let found = this.typeCodes.find(item=>item.name==this.doc.type)
  //   this.doc.typeCode = found.name
  // }
  clearActive(){
    this.$emit("update_i", -1)
    this.$emit("update_j", -1)
    this.$emit("update_k", -1)
  }

  updateItem(){
    // let item = JSON.parse(JSON.stringify(this.activeItem))
    // if(this.active_i!=-1&&this.active_j==-1&&this.active_k==-1){
    //   // this.budgetitems[this.active_i] = this.activeItem
    //   this.$emit("updateBudgetItems1", item)
    // } else if (this.active_i!=-1&&this.active_j!=-1&&this.active_k==-1) {
    //   // this.budgetitems[this.active_i].children[this.active_j] = this.activeItem
    //   this.$emit("updateBudgetItems2", item)
    // } else if (this.active_i!=-1&&this.active_j!=-1&&this.active_k!=-1) {
    //   // this.budgetitems[this.active_i].children[this.active_j].children[this.active_k] = this.activeItem
    //   this.$emit("updateBudgetItems3", item)
    // }
    // this.$emit("updateBudgetItems1", item)
    // this.$emit("update_i", -1)
    // this.$emit("update_j", -1)
    // this.$emit("update_k", -1)
    // this.clearFlag()
    this.$emit("completed")
  }

  fetchMasterbyName(){
    axios.get(`${ADDRESS}/setting/get-by-name/${this.activeItem.expenseType}`).then((response) => {
      // console.log(response)
      this.masterbyName = response.data.doc.member;
      try { 
        let array = this.activeItem.items
        array = []
        this.masterbyName.forEach((el:any,i:Number) => {
          // console.log('el',el)
          array.push({name:el.name,amount:el.amount})
          this.$set(this.activeItem,"value", array.map((a:any)=>a.amount).reduce((a:number,b:number)=>a+b,0))
          this.$set(this.activeItem,"items", array)
        })
      } catch (error) {
        
      }
    });
  }

  clearFlag(){

    try {
      let sum = 0
      for (let i = 0; i < 12; i++) {
        if(this.activeItem.monthsFlag[i]!==true){
          this.activeItem.months[i] = 0
        }
      }
    } catch (error) {
      
    }
  }

  checkAll(ev:any){
    let isChecked = ev.target.checked
    if(isChecked){
      // console.log("isChecked", isChecked)
      this.$set(this.activeItem,"monthsFlag", [true,true,true,true,true,true,true,true,true,true,true,true])
      for(const [i,checked] of this.activeItem.monthsFlag.entries()){
        if(checked) this.activeItem.months[i] = 0
        // console.log(i,checked,this.activeItem.months[i])
      }
    } else {
    // console.log("isChecked", isChecked)
      this.$set(this.activeItem,"monthsFlag", [false,false,false,false,false,false,false,false,false,false,false,false])
      for(const [i,checked] of this.activeItem.monthsFlag.entries()){
        if(!checked) this.activeItem.months[i] = 0
        // console.log(i,checked,this.activeItem.months[i])
      }
    }
    // console.log("this.activeItem",this.activeItem.monthsFlag)
  }


  optionsAll:Array<any> = []
  fetchOptionsAll(search:string, loading:boolean) {
    axios.post(`${ADDRESS}/users/paginate`, {
      // search:{
      //   displayName:{
      //     contains:search,
      //   },
      //   NOT:{
      //     OR:[
      //       { displayName:{ startsWith:"งาน" } },
      //       { displayName:{ startsWith:"ฝ่าย" } },
      //       { displayName:{ startsWith:"สำนัก" } },
      //       { displayName:{ startsWith:"กอง" } },
      //     ]
      //   }
      // },
      search:{
        firstName: {
          contains: search
        },
      },
      limit: 10,
      skip: 0,
    })
    .then((response) => {
      // console.log('this.optionsAll',response)
      this.optionsAll = response.data.docs.map((el:any)=>el.firstName)
    })
    .catch((error) => {
      console.log(error)
    })
  }

}
