import {  RouteConfig } from 'vue-router'

import AssignmentMain from '../../views/assignment/Main.vue'
import AssignmentList from '../../views/assignment/List.vue'
import AssignmentForm from '../../views/assignment/Form.vue'
import AssignmentReplyMain from '../../views/assignmentReply/Main.vue'
import AssignmentReplyList from '../../views/assignmentReply/List.vue'
import AssignmentReplyForm from '../../views/assignmentReply/Form.vue'
import AssignmentHistoryMain from '../../views/assignmentHistory/Main.vue'
import AssignmentHistoryList from '../../views/assignmentHistory/List.vue'
import Attendant from '../../views/attendant/Attendant.vue'
const routes: Array<RouteConfig> = [
  {
    path: 'assignment',
    component: AssignmentMain,
    meta:{
      "ac": "WFH,r"
    },  
    children: [
      {
        path: '',
        name: 'Assignment List',
        component: AssignmentList,
        meta:{
          "ac": "WFH,r"
        },  
      },
      {
        path: 'form',
        name: 'Assignment Form',
        component: AssignmentForm,
        meta:{
          "ac": "WFH,r"
        },  
      },
      {
        path: 'edit/:id',
        name: 'Assignment Edit',
        component: AssignmentForm,
        meta:{
          "ac": "WFH,r"
        },  
      },
      {
        path: 'detail/:id',
        name: 'Assignment Detail',
        component: AssignmentForm,
        meta:{
          "ac": "WFH,r"
        },  
      },
    ]
  },
  {
    path: 'attendant',
    name: 'Attendant',
    component: Attendant,
    meta:{
      "ac": "WFH,r"
    }, 
  },
  {
    path: 'assignment-reply',
    component: AssignmentReplyMain,
    meta:{
      "ac": "WFH,r"
    },  
    children: [
      {
        path: '',
        name: 'Assignment Reply List',
        component: AssignmentReplyList,
        meta:{
          "ac": "WFH,r"
        },  
      },
      {
        path: 'form',
        name: 'Assignment Reply Form',
        component: AssignmentReplyForm,
        meta:{
          "ac": "WFH,r"
        },  
      },
      {
        path: 'edit/:id',
        name: 'Assignment Reply Edit',
        component: AssignmentReplyForm,
        meta:{
          "ac": "WFH,r"
        },  
      },      
      {
        path: 'detail/:id',
        name: 'Assignment Reply Detail',
        component: AssignmentReplyForm,
        meta:{
          "ac": "WFH,r"
        },  
      },
    ]
  },
  {
    path: 'assignment-history',
    component: AssignmentHistoryMain,
    meta:{
      "ac": "WFH,r"
    },  
    children: [
      {
        path: '',
        name: 'Assignment History List',
        component: AssignmentHistoryList,
        meta:{
          "ac": "WFH,r"
        },
      },
    ]
  },
]
export default routes