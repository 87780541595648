










import { Component, Vue } from "vue-property-decorator";
import axios from "axios";
import { ADDRESS, APIKEY } from "@/setting";
import { DateTime } from "luxon";
@Component({
  components: {},
})
export default class App extends Vue {
  pdfBLOB: Blob = new Blob();
  today = new Date()
  attendants:Array<any> = []
  beforeMount() {
    axios.interceptors.request.use((config)=> {
      const token = APIKEY;
      config!.headers!.apikey = token;
      config!.headers!.token = token;
      // config!.headers!.authorization = `Bearer ${this.$store.state.jwt}`;

      return config;
    });
  }

  checkin(){
    let prep = {
      staff:this.$store.state.userId,
      inTime: new Date()
    }
    axios.post(`${ADDRESS}/attendant/create`,prep).then(response=>{
      // console.log("checkin",response.data)
    })
  }
  fetchAttendant(){
    let dt = DateTime.fromJSDate(this.today)
    let dto = dt.toObject()
    let day = dto.day
    let month = dto.month
    let year = dto.year
    // console.log(dt)
    let query = {
            search:{
        staff:this.$store.state.userId,
        inTime:{
          gte: dt.startOf('day').toISO(),
          lt:  dt.endOf('day').toISO()
        }
      },
      skip:0,
      limit:999
    }
    axios.post(`${ADDRESS}/attendants/paginate`,query).then(response=>{
      this.attendants = response.data.docs
      let length = this.attendants.length
      if(length==0){
        console.log("วันนี้ผู้ใช้ ยังไม่ได้เช็คอิน")
        this.checkin()
      } else {
        console.log("วันนี้ผู้ใช้ เช็คอินแล้ว")
      }
      this.$router.push('/portal/flashnews')
    })
  }
  mounted() {
    axios.get(`${ADDRESS}/setting/get-by-name/ปีงบประมาณ`).then((response) => {
      let data = response.data;
      let member = [];
      // console.log(data)
      try {
        member = data.doc.member.map((el: any) => {
          let number = -1;
          try {
            number = parseInt(el.name);
          } catch (error) {}
          return {
            ...el,
            number,
          };
        });
      } catch (error) {
        console.log("budgetYear", error)
      }
      this.$store.commit("setBudgetYear", member);
    });
    this.fetchSector();
    this.getERPPending()
    this.getAdjustPending()
  }
  
  getERPPending(){
    axios.get(`${ADDRESS}/erp/status/pending`).then((response) => {
      // console.log("response pending", response.data)
      this.$store.commit("setERPNotification", response.data.count)
    });
  }
  getAdjustPending(){
    axios.get(`${ADDRESS}/budgetAdjustment/status`).then((response) => {
      // console.log("response pending", response.data)
      this.$store.commit("setAdjustNotification", response.data.count)
    });
  }
  getBudgetYear(){

    axios.get(`${ADDRESS}/setting-by-name/ปีงบประมาณ`).then((response) => {
      this.$store.commit("setBudgetYear", response.data.result.member)
    });
  }

  fetchSector() {
    return new Promise((resolve, reject) => {
      axios.get(`${ADDRESS}/sectors/list`).then((response) => {
        this.$store.commit("setSectors", response.data.docs);
      });
    });
  }
}
