


























































































































































































import { Component, Vue } from 'vue-property-decorator';
import axios from "axios"
import { ADDRESS } from "@/setting";

@Component({
  components: {
  },
})
export default class DocumentList extends Vue {
  page: number = 1;
  pages: number = 0;
  show: number = 200;
  total: number = 0;
  docs:Array<any> = []
  filter1:any = {}
  sort:any = {
  }
  address = ADDRESS

}
