import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userId: "",
    username: "",
    fullName: "",
    token: "",
    jwt: "",
    position: "",
    roles: [],
    sector1: {},
    sector2: {},
    sector3: {},
    sector4: {},
    budgetYears: [],
    sectors: [],
    telephone: "",
    erpNotification: 0,
    adjustNotification: 0,
    accesses: [],
  },
  getters: {
    isAdmin(state) {
      //@ts-ignore
      let isAdmin = state.roles.includes("ผู้ดูแลระบบ")
      return isAdmin
    },
    hasEditRights:(state) => (ac:any) => {
      try {
        let accesses = state.accesses as Array<string>
        let roles = state.roles as Array<string>
        let editRights:string = ac.ac.replace(",r",",w")
        // console.log("has edit rights", ac, editRights)
        let hasRights = accesses.includes(editRights)
        let isAdmin = roles.includes("ผู้ดูแลระบบ")
        return hasRights||isAdmin
      } catch (error) {
        return true
      }
    },
    hasDeleteRights:(state) => (ac:any) => {
      try {
        let accesses = state.accesses as Array<string>
        let roles = state.roles as Array<string>
        let deleteRights:string = ac.ac.replace(",r",",d")
        // console.log("has delete rights", ac, deleteRights)
        let hasRights = accesses.includes(deleteRights)
        let isAdmin = roles.includes("ผู้ดูแลระบบ")
        return hasRights||isAdmin
      } catch (error) {
        console.log(error)
        return true
      }
    }
  },
  mutations: {
    setUser(state, data) {
      console.log("setting user", data)
      state.username = data.username
      state.fullName = `${data.prefix}${data.firstName} ${data.lastName}`
      state.userId = data.id
      state.token = data.token
      state.jwt = data.jwt
      state.roles = data.roles
      state.telephone = data.extensionNumber
      state.accesses = data.accesses
      state.position = data.position
      state.sector1 = data.sector1
      state.sector2 = data.sector2
      state.sector3 = data.sector3
      state.sector4 = data.sector4
    },
    setSectors(state, data) {
      state.sectors = data
    },
    logout(state, data) {

    },
    setBudgetYear(state, data) {
      state.budgetYears = data
    },
    setERPNotification(state, data) {
      state.erpNotification = data
    },
    setAdjustNotification(state, data) {
      state.adjustNotification = data
    }
  },
  actions: {
  },
  modules: {
  },
  plugins: [createPersistedState(
    {
      key: "SAT211",
      paths: ["username", "fullName", "userId", "token", "jwt", "roles", "telephone", "position", "sector1", "sector2", "sector3", "sector4", "sector5", "accesses","erpNotification","adjustNotification"]
    }
  )]
})
