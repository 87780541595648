



















































import { Component, Vue } from 'vue-property-decorator';
import axios from "axios";
import { ADDRESS } from "@/setting";
@Component({
  components: {
  },
})
export default class Form extends Vue {
  doc: any = {};
  person:any = {}
  pushNgarn(index:number) {
    if(!Array.isArray(this.doc.kong[index].ngarn)) this.doc.kong[index].ngarn = []
    this.doc.kong[index].ngarn.push({
      name: ""
    })
  }
  pushKong() {
    console.log("ok", !Array.isArray(this.doc.kong))
    if(!Array.isArray(this.doc.kong)) this.doc.kong = []
    this.doc.kong.push({
      name: "",
      ngarn: [{
        name: ""
      }]
    })
  }
  fetchPerson(){
    axios.get(`${ADDRESS}/person/search-by-sector/${this.doc.department}`).then(response=>{
      if(response.data[0]) this.person = response.data[0]
    })
  }
  submitForm() {
    axios.post(`${ADDRESS}/sector/create`,this.doc)
    .then((response) => {
      console.log(response)
      alert("ดำเนินการบันทึกสำเร็จแล้ว")
      this.$router.push("/portal/sector")
    })
  }
  fetchData() {
    axios.get(`${ADDRESS}/sector/${this.$route.params.id}`).then((response) => {
      console.log(response)
      if(response.data.status === "success") {
        let data = response.data.doc;
        this.doc = data;
        this.doc.department = this.doc.name
        this.fetchPerson()
      } else {
        console.log(`พบปัญหาในการดึงข้อมูลจากแม่ข่าย , ${response.data.error}`)
      }
    });
  }
  mounted() {
    if (this.$route.params.id != undefined) this.fetchData()
  }
}
