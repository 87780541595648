
































import { Component, Vue } from "vue-property-decorator";
import axios from "axios";
import { ADDRESS } from "@/setting";
import draggable from "vuedraggable";
import { isString } from "lodash";
@Component({
  props:{
    value:Array,
    name:String,
    link:String,
    disabled:Boolean,
    limit:Number,
    type!:String
  },
  components: {
    draggable,
  },
})
export default class BudgetComponentLink extends Vue {
  name!:string
  link!:string
  value!:Array<string>
  val:Array<string> = []

  mounted(){
    this.val = this.value??[]
  }
  pushItem(){
    if(isString(this.val)) this.val = [this.val]
    else if (!Array.isArray(this.val)) this.val = []
    console.log(this.val.length)
    this.val.push("")
    this.emitUp()
  }
  deleteItem(i:number){
    let c = confirm("ยืนยันลบรายการหรือไม่?")
    if(c){
      this.val.splice(i,1)
      this.emitUp()
    }
  }

  emitUp(){
    this.$emit("input", this.val)
  }
}
