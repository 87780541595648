




















import { Component, Vue, Watch } from "vue-property-decorator";
import axios from "axios";
import { ADDRESS } from "@/setting";
import { ConfirmAction } from "@/decorators";
@Component({
  props:{
    item:Object,
    isWithdrawn:Boolean
  },
  components: {},
  directives:{
    clickOutside:{
      bind(el, binding, vnode) {
        let clickOutsideEvent = function (event:any) {
          if (!(el === event.target || el.contains(event.target))) {
            // console.dir(binding)
            //@ts-ignore
            binding.value()
            // vnode.context[binding.expression](event);
          }
        };
        document.body.addEventListener("click", clickOutsideEvent);
      },
    }
  }
})
export default class PlanTableTools extends Vue {
  item!:any
  isWithdrawn!:boolean

  hideTools(){
    this.item.edit = false
  }
  pushItem(){
    this.$emit("pushItem",{id:this.item.id, level:1})
  }
  pushChild(i:number){
    this.$emit("pushItem",{id:this.item.id, level:2})
  }
  pushDetail(i:number,j:number){
    this.$emit("pushItem",{id:this.item.id, level:3})
  }
  openModalItem(id:string){
    console.log('openModal',id)
    this.$emit("openModal",id)
  }

  @ConfirmAction("ยืนยันลบรายการ?")
  deleteItem(id:string){
    axios.delete(`${ADDRESS}/budgetitem/delete/${id}`).then(response=>{
      // console.log(response.data)
      this.$emit("change")
    })
  }
}
