











































import { Component, Vue } from "vue-property-decorator";
import axios from "axios";
import { ADDRESS } from "@/setting";
import { DateTime } from "luxon"
import { DocumentExcel } from "@/document/xlsx"

@Component({
  components: {},
})
export default class B27 extends Vue {
  docs:Array<any> = []
  fromDate: Date = DateTime.now().startOf("month").toJSDate();
  toDate: Date = DateTime.now().endOf("month").toJSDate();
  filter:any = {}
  
  resolveDate(dt:string){
    let result = new Date (dt).toTime()
    if(result=="Invalid DateTime")
      return "-"
    else return result + " น."
  }

  fetchData() {
    let query = {
      fromDateISO:this.fromDate,
      toDateISO:this.toDate,
      position:this.filter.sector
    }
    console.log('query',query)
    axios.post(`${ADDRESS}/report/B27`,query).then((response) => {
      console.log('report/B27',response)
      this.docs = response.data.result;
    });
  }
  async saveAsExcel() {
    let docExcel = new DocumentExcel()
    docExcel.createB27(this.docs)
  }
  mounted() {
    this.fetchData()
  }
}
