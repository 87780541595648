import {  RouteConfig } from 'vue-router'

import SettingMain from '../../views/setting/Main.vue'
import SettingForm from '../../views/setting/Form.vue'
import SettingList from '../../views/setting/List.vue'
const routes: Array<RouteConfig> = [
  {
    path: 'setting',
    component: SettingMain,
    meta:{
      "ac": "Setting,r"
    },
    children: [
      {
        path: '',
        name: 'Setting',
        component: SettingList,
        meta:{
          "ac": "Setting,r"
        },
      },
      {
        path: 'form',
        name: 'Setting Form',
        component: SettingForm,
        meta:{
          "ac": "Setting,r"
        },
      },
      {
        path: 'edit/:id',
        name: 'Setting Edit',
        component: SettingForm,
        meta:{
          "ac": "Setting,r"
        },
      }
    ]
  },
]
export default routes