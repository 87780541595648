
























































































import { Component, Vue, Watch } from "vue-property-decorator";
import axios from "axios";
import { ADDRESS } from "@/setting";
import { Store } from "vuex";
@Component({
  components: {},
  props:{
    id:String
  }
})
export default class ModalInfo extends Vue {
  operatestatus:string = ""
  doc:any={}
  id!:string 
  mounted() {
    console.log('id',this.id)
    this.fetchData()
  }
  submitModal() {
    let prep = {
      ...this.doc
    }
    axios.patch(`${ADDRESS}/budget/${this.id}`, prep)
      .then((response) => {
        console.log(response)
      })
  }
  fetchData() {
    axios.get(`${ADDRESS}/budget/get/${this.id}`)
      .then((response) => {
        console.log(response)
        this.doc = response.data.result
      })
  }
}
