




import { Component, Vue } from "vue-property-decorator";
import sparkline from "@fnando/sparkline";

@Component({
  components: {
  },
})
export default class Main extends Vue {
}
