























































































































































import { Component, Vue } from "vue-property-decorator";
import axios from "axios";
import { ADDRESS } from "@/setting";
import ExcelJS from "exceljs"
import { saveAs } from 'file-saver';
// import contenteditable from "vue-contenteditable";
@Component({
  components: {
    // contenteditable
  },
  props:{
    modelValue:Object,
    "update:modelValue":Function
  }
})
export default class Petition extends Vue {
  budgetYear:number = 0
  doc:any = {}
  data:any = {}
  modelValue!:any
  budget:number = 0
  emitUp(){
    this.$emit("update:modelValue", this.doc);
    this.$emit("change", this.doc);
  }
  exportExcel() {
    console.log("generateExcel");
    console.log('doc',this.doc)
    let workbook = new ExcelJS.Workbook()
    let worksheet = workbook.addWorksheet('ฎีกา')
    //headerหน่วยงาน / ส่วนราชการเจ้าของงบประมาณ  
    worksheet.getCell("A1").alignment = { vertical: 'middle' }
    worksheet.getCell("A1").value = `แผนการเบิกจ่ายเงินงบประมาณงบเงินอุดหนุน  ประเภทเงินอุดหนุนทั่วไป พ.ศ.  ${this.budgetYear}`
    worksheet.mergeCells('A1:M1')
    //
    worksheet.getCell("A2").alignment = { vertical: 'middle' }
    worksheet.getCell("A2").value = `หน่วยงาน/ส่วนราชการเจ้าของงบประมาณ`
    worksheet.mergeCells('A2:C2')
    worksheet.getCell("D2").alignment = { vertical: 'middle' }
    worksheet.getCell("D2").value = `${this.doc.sector}`
    worksheet.mergeCells('D2:F2')
    worksheet.getCell("G2").alignment = { vertical: 'middle' }
    worksheet.getCell("G2").value = `องค์กรที่ได้รับเงินอุดหนุน`
    worksheet.mergeCells('G2:H2')
    worksheet.getCell("I2").alignment = { vertical: 'middle' }
    worksheet.getCell("I2").value = `${this.doc.organization}`
    worksheet.mergeCells('I2:K2')
    //
    worksheet.getCell("A3").alignment = { vertical: 'middle' }
    worksheet.getCell("A3").value = `รหัสหน่วยงาน`
    worksheet.getCell("B3").alignment = { vertical: 'middle', horizontal: 'center' }
    worksheet.getCell("B3").value = `${this.doc.sectorCode}`
    worksheet.getCell("C3").alignment = { vertical: 'middle' }
    worksheet.getCell("C3").value = `ลักษณะงาน`
    worksheet.getCell("D3").alignment = { vertical: 'middle', horizontal: 'center' }
    worksheet.getCell("D3").value = `${this.doc.nature}`
    worksheet.getCell("E3").alignment = { vertical: 'middle' }
    worksheet.getCell("E3").value = `ประเภทผลผลิต/ โครงการ`
    worksheet.mergeCells('E3:F3')
    worksheet.getCell("G3").alignment = { vertical: 'middle', horizontal: 'center' }
    worksheet.getCell("G3").value = `${this.doc.type}`
    worksheet.getCell("H3").alignment = { vertical: 'middle', horizontal: 'right' }
    worksheet.getCell("H3").value = `บัญชี`
    worksheet.getCell("I3").alignment = { vertical: 'middle', horizontal: 'center' }
    worksheet.getCell("I3").value = `${this.doc.account}`
    worksheet.mergeCells('I3:J3')
    //
    worksheet.getCell("A4").alignment = { vertical: 'middle' }
    worksheet.getCell("A4").value = `แหล่งงบประมาณ`
    worksheet.getCell("B4").alignment = { vertical: 'middle' }
    worksheet.getCell("B4").value = `${this.doc.plan}`
    worksheet.mergeCells('B4:M4')
    //
    worksheet.getCell("A5").alignment = { vertical: 'middle' }
    worksheet.getCell("A5").value = `ผลผลิต/โครงการ`
    worksheet.getCell("B5").alignment = { vertical: 'middle' }
    worksheet.getCell("B5").value = `${this.doc.productItem}`
    worksheet.getCell("C5").alignment = { vertical: 'middle' }
    worksheet.getCell("C5").value = `${this.doc.product}`
    worksheet.mergeCells('C5:M5')
    //
    worksheet.getCell("A6").alignment = { vertical: 'middle' }
    worksheet.getCell("A6").value = `กิจกรรมที่ 1`
    worksheet.getCell("B6").alignment = { vertical: 'middle' }
    worksheet.getCell("B6").value = `${this.doc.strategy}`
    worksheet.mergeCells('B6:H6')
    worksheet.getCell("I6").alignment = { vertical: 'middle', horizontal: 'center' }
    worksheet.getCell("I6").value = `รหัสงบประมาณ`
    worksheet.getCell("J6").alignment = { vertical: 'middle' }
    worksheet.getCell("J6").value = `${this.doc.budgetCode}`
    worksheet.getCell("L6").alignment = { vertical: 'middle', horizontal: 'center' }
    worksheet.getCell("L6").value = `รหัสผลผลิต`
    worksheet.getCell("M6").alignment = { vertical: 'middle', horizontal: 'center' }
    worksheet.getCell("M6").value = `${this.doc.productCode}`
    //
    worksheet.getCell("A7").alignment = { vertical: 'middle' }
    worksheet.getCell("A7").value = `รายการที่ 101/01`
    worksheet.getCell("B7").alignment = { vertical: 'middle' }
    worksheet.getCell("B7").value = `${this.doc.item}`
    worksheet.mergeCells('B7:H7')
    worksheet.getCell("I7").alignment = { vertical: 'middle', horizontal: 'center' }
    worksheet.getCell("I7").value = `รหัสกิจกรรมหลัก`
    worksheet.getCell("J7").alignment = { vertical: 'middle' }
    worksheet.getCell("J7").value = `${this.doc.itemCode}`
    worksheet.getCell("L7").alignment = { vertical: 'middle', horizontal: 'center' }
    worksheet.getCell("L7").value = `รหัสบัญชีย่อย`
    worksheet.getCell("M7").alignment = { vertical: 'middle', horizontal: 'center' }
    worksheet.getCell("M7").value = `${this.doc.accountCode}`
    //
    worksheet.addRow([""])
    worksheet.getCell("A9").alignment = { vertical: 'middle', horizontal: 'center' }
    worksheet.getCell("A9").value = `วงเงินงบประมาณ`
    worksheet.mergeCells('A9:A10')
    worksheet.getCell("B9").alignment = { vertical: 'middle', horizontal: 'center' }
    worksheet.getCell("B9").value = `ไตรมาสที่ 1`
    worksheet.mergeCells('B9:D9')
    worksheet.getCell("E9").alignment = { vertical: 'middle', horizontal: 'center' }
    worksheet.getCell("E9").value = `ไตรมาสที่ 2`
    worksheet.mergeCells('E9:G9')
    worksheet.getCell("H9").alignment = { vertical: 'middle', horizontal: 'center' }
    worksheet.getCell("H9").value = `ไตรมาสที่ 3`
    worksheet.mergeCells('H9:J9')
    worksheet.getCell("K9").alignment = { vertical: 'middle', horizontal: 'center' }
    worksheet.getCell("K9").value = `ไตรมาสที่ 4`
    worksheet.mergeCells('K9:M9')
    worksheet.getCell("B10").alignment = { vertical: 'middle', horizontal: 'center' }
    worksheet.getCell("B10").value = `ตุลาคม ${this.budgetYear-1}`
    worksheet.getCell("C10").alignment = { vertical: 'middle', horizontal: 'center' }
    worksheet.getCell("C10").value = `พฤศจิกายน ${this.budgetYear-1}`
    worksheet.getCell("D10").alignment = { vertical: 'middle', horizontal: 'center' }
    worksheet.getCell("D10").value = `ธันวาคม ${this.budgetYear-1}`
    worksheet.getCell("E10").alignment = { vertical: 'middle', horizontal: 'center' }
    worksheet.getCell("E10").value = `มกราคม ${this.budgetYear}`
    worksheet.getCell("F10").alignment = { vertical: 'middle', horizontal: 'center' }
    worksheet.getCell("F10").value = `กุมภาพันธ์ ${this.budgetYear}`
    worksheet.getCell("G10").alignment = { vertical: 'middle', horizontal: 'center' }
    worksheet.getCell("G10").value = `มีนาคม ${this.budgetYear}`
    worksheet.getCell("H10").alignment = { vertical: 'middle', horizontal: 'center' }
    worksheet.getCell("H10").value = `เมษายน ${this.budgetYear}`
    worksheet.getCell("I10").alignment = { vertical: 'middle', horizontal: 'center' }
    worksheet.getCell("I10").value = `พฤษภาคม ${this.budgetYear}`
    worksheet.getCell("J10").alignment = { vertical: 'middle', horizontal: 'center' }
    worksheet.getCell("J10").value = `มิถุนายน ${this.budgetYear}`
    worksheet.getCell("K10").alignment = { vertical: 'middle', horizontal: 'center' }
    worksheet.getCell("K10").value = `กรกฎาคม ${this.budgetYear}`
    worksheet.getCell("L10").alignment = { vertical: 'middle', horizontal: 'center' }
    worksheet.getCell("L10").value = `สิงหาคม ${this.budgetYear}`
    worksheet.getCell("M10").alignment = { vertical: 'middle', horizontal: 'center' }
    worksheet.getCell("M10").value = `กันยายน ${this.budgetYear}`
    //width
    worksheet.getColumn("A").width = 18
    worksheet.getColumn("B").width = 16
    worksheet.getColumn("C").width = 16
    worksheet.getColumn("D").width = 16
    worksheet.getColumn("E").width = 16
    worksheet.getColumn("F").width = 16
    worksheet.getColumn("G").width = 16
    worksheet.getColumn("H").width = 16
    worksheet.getColumn("I").width = 16
    worksheet.getColumn("J").width = 16
    worksheet.getColumn("K").width = 16
    worksheet.getColumn("L").width = 16
    worksheet.getColumn("M").width = 16
    worksheet.addRow([this.data.data.budget[0].formatFull(),"","","","","","","","","","","", ""])
    
    worksheet.addRow([""])
    let lastRow2 = worksheet.rowCount
    worksheet.addRow([`หมายเหตุ`,(".").repeat(400),"","","","","","","","","","", ""])
    worksheet.addRow(["",(".").repeat(400),"","","","","","","","","","", ""])
    worksheet.mergeCells(lastRow2+1,2,lastRow2+1,13)
    worksheet.mergeCells(lastRow2+2,2,lastRow2+2,13) 
    worksheet.addRow([])
    worksheet.addRow([])
    worksheet.addRow([])
    worksheet.addRow([])
    worksheet.addRow(["","","","","","","","","", `ลงชื่อ`+(".").repeat(60),"","",""])
    worksheet.addRow(["","","","","","","","","", "(ชาญยุทธ คำหวาน)","","",""]) 
    worksheet.addRow(["","","","","","","","","", "หัวหน้างานพัฒนาและประสานแผน","","",""]) 
    worksheet.mergeCells(lastRow2+7,10,lastRow2+7,12)
    worksheet.mergeCells(lastRow2+8,10,lastRow2+8,12)
    worksheet.mergeCells(lastRow2+9,10,lastRow2+9,12)

    worksheet.eachRow({ includeEmpty: true }, (row, rn) => {
      if (rn > 8 && rn <12) {
        row.eachCell({ includeEmpty: true }, (cell, cn) => {
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
          };
        })
      }
      if (rn > 18 && rn < 22 ) {
        row.eachCell({ includeEmpty: true }, (cell, cn) => {
          cell.alignment = {
            horizontal: 'center'
          }
        })
      }
    })
    workbook.xlsx.writeBuffer().then(function (data: any) {
      var blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, "ฎีกา.xlsx");
    });
  }
  fetchData() {
    return new Promise((resolve,reject)=>{
      axios
        .get(`${ADDRESS}/budget/get/${this.$route.params.id}`)
        .then((response) => {
          console.log('response',response.data);
          if (response.data.status === "success") {
            let data = response.data.doc;
            this.data = data; 
            this.budget = this.data.data.budget[0]
            resolve('ok data')
          } else {
            reject()
            console.log(`พบปัญหาในการดึงข้อมูลจากแม่ข่าย , ${response.data.error}`);
          }
      });
    })
  }
  fetchItem() {
    return new Promise((resolve,reject)=>{
      resolve("mocking done")
      // axios
      //   .get(`${ADDRESS}/budget/get/${this.$route.params.id}`)
      //   .then((response) => {
      //     console.log('response',response.data);
      //     if (response.data.status === "success") {
      //       let data = response.data.doc;
      //       this.data = data;
      //       // this.$set(this, "information", this.doc.data)
      //       resolve('ok data')
      //     } else {
      //       reject()
      //       console.log(`พบปัญหาในการดึงข้อมูลจากแม่ข่าย , ${response.data.error}`);
      //     }
      // });
    })
  }
  mounted() {
    this.budgetYear = parseInt(this.$route.query.budgetYear as string)
    this.fetchData()
    this.doc = this.modelValue??{}
  }
}
