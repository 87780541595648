import {  RouteConfig } from 'vue-router'

import UACMain from '../../views/uac/Main.vue'
import UACForm from '../../views/uac/Form.vue'
import UACList from '../../views/uac/List.vue'
const routes: Array<RouteConfig> = [
  {
    path: 'uac',
    component: UACMain,
    meta:{
      "ac": "UAC,r"
    },
    children: [
      {
        path: '',
        name: 'UACList',
        component: UACList,
        meta:{
          "ac": "UAC,r"
        },
      },
      {
        path: 'form',
        name: 'UACForm',
        component: UACForm,
        meta:{
          "ac": "UAC,r"
        },
      },
      {
        path: 'edit/:id',
        name: 'UACEdit',
        component: UACForm,
        meta:{
          "ac": "UAC,r"
        },
      },
      {
        path: 'detail/:id',
        name: 'UACDetail',
        component: UACForm,
        meta:{
          "ac": "UAC,r"
        },
      }
    ]
  },
]
export default routes