





























































































import { ADDRESS } from "@/setting";
import store from "@/store";
import axios from "axios";
import { forEach } from "lodash";
import { Component, Vue } from "vue-property-decorator";
import { Store } from "vuex";
@Component({
  components: {
  },
})
export default class List extends Vue {
  store = store
  docs:Array<any> = []
  sectors:Array<any> = []
  limit: number = 15;
  show: number = 20;
  page: number = 1;
  pages: number = 0;
  total: number = 0;
  filter1: any = {}
  filter2: any = {}
  filter3: any ={}
  types:Array<any> = []
  checked:Array<string> = []
  search:string = ""
  address = ADDRESS;
  admin:boolean = false
  doc: any = {
    category:"ปรับตามระยะ",
    name:"",
    project_id:""
  };
  checkAdjustPlan() {    
    let suffix = prompt("กรุณาระบุชื่อการปรับตามระยะ", "ปรับตามระยะ");
    console.log(suffix)
    this.checked.forEach((el:any) => {
      let found = this.docs.find(doc=>doc.id==el)
      this.doc.name = found.name
      this.doc.project_id = found.id
      let prep = {
        budget_id : found.id,
        adjustment : this.doc 
      }
      axios.post(`${this.address}/process/budget-open-adjusting`,prep).then((res)=>{
        console.log('res',res)
        this.fetchData()
      }).catch((err)=>{
        console.log(err);
      });
    })
    
  }
  checkedAll(ev:any){
    let target = ev.target.checked
    if(target==true) {
      this.checked = this.docs.map(item=>item.id)
      // console.log(this.checked)
    } else {
      this.checked = []
    }
  }
  resolveSector(_id:string):string{
    try {
      let foundName = this.sectors.find(item=>item.id==_id)
      return foundName.name
    } catch (error) {
      return "-"
    }
  }
  resolveSectorFhai(_id:string):string{
    try {
      let code = this.$store.state.sectors.find((item:any)=>item.id==_id).code
      let foundName = this.$store.state.sectors.filter((item:any)=>item.category=="ฝ่าย"||item.category=="สำนักงาน").find((item:any)=>item.code==code)
      return foundName.name
    } catch (error) {
      return ""
    }
  }
  fetchSector(){
    return new Promise((resolve,reject)=>{
      axios.get(`${ADDRESS}/sectors/list`).then((response) => {
        this.sectors = response.data.docs;
        resolve('ok sector')
      });
    })
  }
  fetchBudgetType(){
    let promise = new Promise((resolve,reject)=>{
    axios
      .get(`${ADDRESS}/setting/get-by-name/แหล่งงบประมาณในคำขอ`)
      .then((response) => {
        console.log('แหล่งงบประมาณในคำขอ',response.data);
        if (response.data.status === "success") {
          let data = response.data.doc;
          this.types = data.member;
          if (this.$route.params.id != undefined) {
            this.fetchData();
          }
          resolve('ok budget type')
        } else {
          reject(response.data.error)
          console.log(`พบปัญหาในการดึงข้อมูลจากแม่ข่าย , ${response.data.error}`);
        }
      });
    })
    return promise
  }
  clearSearch(){
    this.filter1 = {}
    this.filter2 = {}
    this.filter3 = {}
    this.search =  ""
    this.fetchData()
  }
  fetchData(){
    this.docs = []
    let search:Array<string> = []
    let searchQuery = ""
    if(this.filter1 !== 0) search.push(`budgetYear = ${this.filter1}`)
    if(this.filter2 !== "") search.push(`sector ='${this.filter2}'`)
    if(search.length > 0) searchQuery = `WHERE ${search.join(" AND ")}`
    let query = {
      skip: this.limit*(this.page-1),
      limit: this.limit,
      sort: [
        { budgetYear: "desc" },
        { createdAt: "desc" }
      ],
      isFollow: true,
      search:{
        ...this.filter1,
        ...this.filter2,
        ...this.filter3,
      }
    }
    if(this.search!=""){
      query.search.OR = [
        {code: {contains: this.search}},
        {name: {contains: this.search}}
      ]
    }
    axios.post(`${ADDRESS}/budgets/paginate`,query).then((response) => {
      console.log(response)
      this.docs = response.data.docs;
      this.total = response.data.total;
      this.pages = Math.ceil(response.data.total / this.limit);
    });
  }
  deleteItem(item: any) {
    var c = confirm(`คุณแน่ใจหรือว่าต้องการลบรายการนี้`);
    if (c) {
      axios.delete(`${ADDRESS}/budget/delete/${item.id}`).then((response) => {
        console.log(response)
        this.fetchData();
      });
    }
  }
  mounted() {
    this.filter1.budgetYear = (new Date().getFullYear() + 543) + ((new Date().getMonth()+1)>10?1:0)
    // this.filter2 = {sector:this.store.state.sector3}
    this.fetchData()
    this.fetchSector()
    this.fetchBudgetType()
  }
}
