









































import { Component, Vue } from "vue-property-decorator";
import axios from "axios";
import { ADDRESS } from "@/setting";
import draggable from "vuedraggable";
import { isString } from "lodash";
@Component({
  props:{
    value:Array,
    link:String,
    disabled:Boolean,
    limit:Number,
    type:String,
    label:String,
  },
  components: {
    draggable,
  },
})
export default class BudgetComponentLink extends Vue {
  link!:string
  value!:Array<string>
  val:Array<any> = []
  label!:string


  mounted(){
    this.val = this.value??[]
  }
  pushItem(){
    if(isString(this.val)) this.val = [this.val]
    else if (!Array.isArray(this.val)) this.val = []
    console.log(this.val.length)
    this.val.push({name:"", sector:"", tel:"", email:"", ngarn:""})
    this.emitUp()
  }
  deleteItem(i:number){
    let c = confirm("ยืนยันลบรายการหรือไม่?")
    if(c){
      this.val.splice(i,1)
      this.emitUp()
    }
  }

  emitUp(){
    this.$emit("input", this.val)
  }

  options:Array<any> = []
  fetchOptions(search:string, loading:boolean) {
    axios.post(`${ADDRESS}/activedirectories/paginate`, {
      search:{
        displayName:{
          contains:search,
        },
        NOT:{
          OR:[
            { displayName:{ startsWith:"งาน" } },
            { displayName:{ startsWith:"กอง" } },
            { displayName:{ startsWith:"ฝ่าย" } },
            { displayName:{ startsWith:"สำนัก" } },
            { displayName:{ startsWith:"FN_" } },
          ]
        }
      },
      limit: 10,
      skip: 0,
    })
    .then((response) => {
      this.options = response.data.docs.map((el:any)=>el.displayName)
    })
    .catch((error) => {
      console.log(error)
    })
  }
  // fetchOptions(search:string, loading:boolean) {
  //   axios.post(`${ADDRESS}/activedirectories/paginate`, {
  //     search:{
  //       displayName:{
  //         contains:search,
  //       },
  //       NOT:{
  //         OR:[
  //           { displayName:{ startsWith:"งาน" } },
  //           { displayName:{ startsWith:"กอง" } },
  //           { displayName:{ startsWith:"ฝ่าย" } },
  //           { displayName:{ startsWith:"สำนัก" } },
  //           { displayName:{ startsWith:"FN_" } },
  //         ]
  //       }
  //     },
  //     limit: 10,
  //     skip: 0,
  //   })
  //   .then((response) => {
  //     this.options = response.data.docs.map((el:any)=>el.displayName)
  //   })
  //   .catch((error) => {
  //     console.log(error)
  //   })
  // }
  optionsFhai:Array<any> = []
  fetchOptionsFhai(search:string, loading:boolean) {
    axios.post(`${ADDRESS}/activedirectories/paginate`, {
      search:{
        displayName:{
          contains:search,
        },
        AND:{
          OR:[
            // { displayName:{ startsWith:"งาน" } },
            // { displayName:{ startsWith:"กอง" } },
            { displayName:{ startsWith:"ฝ่าย" } },
            { displayName:{ startsWith:"สำนัก" } },
          ]
        }
      },
      limit: 10,
      skip: 0,
    })
    .then((response) => {
      this.optionsFhai = response.data.docs.map((el:any)=>el.displayName)
    })
    .catch((error) => {
      console.log(error)
    })
  }
  optionsKong:Array<any> = []
  fetchOptionsKong(search:string, loading:boolean) {
    axios.post(`${ADDRESS}/activedirectories/paginate`, {
      search:{
        displayName:{
          contains:search,
        },
        AND:{
          OR:[
            { displayName:{ startsWith:"กอง" } },
          ]
        }
      },
      limit: 10,
      skip: 0,
    })
    .then((response) => {
      this.optionsKong = response.data.docs.map((el:any)=>el.displayName)
    })
    .catch((error) => {
      console.log(error)
    })
  }
  optionsNgarn:Array<any> = []
  fetchOptionsNgarn(search:string, loading:boolean) {
    axios.post(`${ADDRESS}/activedirectories/paginate`, {
      search:{
        displayName:{
          contains:search,
        },
        AND:{
          OR:[
            { displayName:{ startsWith:"งาน" } },
          ]
        }
      },
      limit: 10,
      skip: 0,
    })
    .then((response) => {
      this.optionsNgarn = response.data.docs.map((el:any)=>el.displayName)
    })
    .catch((error) => {
      console.log(error)
    })
  }
  optionsAll:Array<any> = []
  fetchOptionsAll(search:string, loading:boolean) {
    axios.post(`${ADDRESS}/activedirectories/paginate`, {
      search:{
        displayName:{
          contains:search,
        },
        AND:{
          OR:[
            { displayName:{ startsWith:"งาน" } },
            { displayName:{ startsWith:"ฝ่าย" } },
            { displayName:{ startsWith:"สำนัก" } },
            { displayName:{ startsWith:"กอง" } },
          ]
        }
      },
      limit: 10,
      skip: 0,
    })
    .then((response) => {
      this.optionsAll = response.data.docs.map((el:any)=>el.displayName)
    })
    .catch((error) => {
      console.log(error)
    })
  }
}
