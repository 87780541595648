





















































































import { Component, Vue, Watch } from "vue-property-decorator";
import axios from "axios";
import { ADDRESS } from "@/setting";
import draggable from "vuedraggable";
import Link from "./components/Link.vue"
import InputText from "./components/InputText.vue"
import InputNumber from "./components/InputNumber.vue"
import TextArray from "./components/TextArray.vue"
import ResponsibleArray from "./components/ResponsibleArray.vue"
import Attachment from "./components/Attachment.vue"
import { DateTime } from "luxon";
import DocumentA from "@/document/pdf";
import { DocumentDocx } from "@/document/docx";
import { DocumentExcel } from "@/document/xlsx";
import JMBudgetYear from "../../components/JMBudgetYear.vue";
@Component({
  components: {
    draggable,
    Link,
    InputText,
    InputNumber,
    Attachment,
    TextArray,
    ResponsibleArray
  },
})
export default class Form extends Vue {
  doc: any = {
    data:{}
  };
  counter: number = 0;
  types:Array<any> = []
  typeCodes:Array<any> = []
  type:any = {}
  sources:Array<any> = []
  budgetYears:Array<any> = []
  information:any = {}
  forms:Array<any> = [];
  dataLoaded:boolean = false;
  formLoaded:boolean = false;
  address = ADDRESS;
  budgetYear = (new Date().getFullYear() + 543)+(new Date().getMonth()>10?1:0);
  sectors:Array<any> = []
  indicators:Array<any> = []

  get Year () {
    let today = DateTime.now()
    let month = today.get('month')
    let result = 0
    // if(month >= 9 ) {

    // } else {

    // }
    return month
  }
  generatePetition() {  
    console.log("generateExcel");
    let docExcel = new DocumentExcel();
    docExcel.createPT(this.doc.budgetYear);
  }
  
  @Watch("doc.data.code",{deep:true})
  checkDuplicate(){
    let self = this
    let budgetYear = this.doc.budgetYear??new Date().getFullYear()+543
    this.doc.code = this.doc.data.code
    console.log(this.$route.params.id)
    if(this.$route.params.id==undefined||true){
      let code = this.doc.code
      let length = 0
      try {
        console.log(code)
        length = code.length
      } catch (error) {
        console.log(error)
      }
      if(length>4){
        let prep = {
          code: this.doc.code,
          budgetYear: this.doc.budgetYear,
          id: this.doc.id,
        }
        axios.post(`${ADDRESS}/budget/check-duplicate/`,prep).then((response) => {
          console.log(response.data, prep)
          // console.log(this.doc.data.code)
          if(response.data.status=="found"){
            this.doc.data.projectCode = ""
            self.$set(this.doc.data,"code", "")
            self.$forceUpdate()
            self.counter++
            console.log("ในปีงบประมาณ " + budgetYear + " มีรหัสโครงการนี้แล้ว! กรุณาใส่รหัสโครงการใหม่อีกครั้ง")
            console.log(budgetYear)
            alert("ในปีงบประมาณ " + budgetYear + " มีรหัสโครงการนี้แล้ว! กรุณาใส่รหัสโครงการใหม่อีกครั้ง")
          } else {

          }
          // if(response.data.docs.length>0){
          //   console.log("รหัสโครงการซ้ำ")
          // }
        });
      }
    }
  }
  get isDetail (){
    if(this.$route.path.search(`/detail/`)!=-1) return true
    else return false
  }
  checkIfPlanCreated(){
    if(this.$route.params.id==undefined){
      
      axios.post(`${ADDRESS}/budget/create`, {...this.doc, name:this.doc.data.name}).then((response) => {
        this.$router.push(`/portal/budget/plan/${response.data.id}`)
      });
    } else {
       axios
        .patch(`${ADDRESS}/budget/update/${this.$route.params.id}`, {...this.doc, name:this.doc.data.name})
        .then((response) => {
      this.$router.push(`/portal/budget/plan/${this.$route.params.id}`)
        });
    }
  }
  updateSectorCode(){
    console.log("ok")
    try {
      
      this.$set(this.doc, "sectorCode", this.sectors.find(item=>item.id==this.doc.sector).code)
      this.$set(this.doc, "budgetLongCode", this.sectors.find(item=>item.id==this.doc.sector).longCode)
      // + this.sectors.find(item=>item.id==this.doc.sector).nameEN )
    } catch (error) {
      
    }
  }
  @Watch("information")
  updateDoc(){
    this.doc = {
      ...this.doc,
      data:this.information
    }
  }
  fetchSector(){
    return new Promise((resolve,reject)=>{
      axios.get(`${ADDRESS}/sectors/list`).then((response) => {
        // console.table(response)
        this.sectors = response.data.docs;
        resolve('ok sector')
      });
    })
  }

  pushMember() {
    let member: Array<any> = this.doc.member;
    if (!Array.isArray(member)) member = [];
    member.push({
      name: "",
      text1: "",
    });
    this.$set(this.doc, "member", member);
  }
  @Watch('doc.typeCode') 
  updateTypeCode(){
    let found = this.types.find(item=>item.text1==this.doc.typeCode)
    this.doc.type = found.name
  }
  @Watch('doc.type') 
  updateTypes(){
    let found = this.typeCodes.find(item=>item.name==this.doc.type)
    this.doc.typeCode = found.name
  }

  submitForm() {
    console.log('this.doc',this.doc)
    if (this.$route.params.id == undefined) {
      let prep = this.doc
      delete prep.commonText
      console.log(prep)
      axios.post(`${ADDRESS}/budget/create`, {
        ...this.doc,
        name:this.doc.data.name,
        code: this.doc.data.code,
        revision:1
        }).then((response) => {
        console.log(response);
        alert("ดำเนินการบันทึกสำเร็จแล้ว");
        this.$router.push("/portal/budget")
      });
    } else {
      axios
        .patch(`${ADDRESS}/budget/update/${this.$route.params.id}`, {
          ...this.doc, 
          name:this.doc.data.name,
          projectCode: this.doc.data.projectCode,
        })
        .then((response) => {
          console.log(response);
          alert("ดำเนินการแก้ไขสำเร็จแล้ว");
          this.$router.push("/portal/budget")
        });
    }
  }
  fetchData() {
    return new Promise((resolve,reject)=>{
      axios
        .get(`${ADDRESS}/budget/get/${this.$route.params.id}`)
        .then((response) => {
          console.log(response.data);
          if (response.data.status === "success") {
            let data = response.data.doc;
            this.doc = data;
            this.dataLoaded = true
            this.$set(this, "information", this.doc.data)
            resolve('ok data')
          } else {
            reject()
            console.log(`พบปัญหาในการดึงข้อมูลจากแม่ข่าย , ${response.data.error}`);
          }
      });
    })
  }
  fetchIndicator() {
    let promise = new Promise((resolve,reject)=>{
    let query = {
      skip: 0,
      limit: 999999
    }
    axios
      .post(`${ADDRESS}/indicators/paginate`, query)
      .then((response) => {
        console.log(response.data);
        if (response.data.status === "success") {
          let data = response.data.doc;
          this.$set(this, "indicators", data)
          resolve('ok indicator')
        } else {
          reject(response.data.error)
          console.log(`พบปัญหาในการดึงข้อมูลจากแม่ข่าย , ${response.data.error}`);
        }
      });
    })
    return promise
  }
  fetchFormRender(){
    return new Promise((resolve,reject)=>{
      axios
        .get(`${ADDRESS}/setting/get-by-name/คำของบประมาณ`)
        .then((response) => {
          console.log(response.data);
          if (response.data.status === "success") {
            let data = response.data.doc;
            this.forms = data.member;
            this.formLoaded = true
            resolve('ok form')
          } else {
            reject(response.data.error)
            console.log(`พบปัญหาในการดึงข้อมูลจากแม่ข่าย , ${response.data.error}`);
          }
        });
    })
  }

  fetchBudgetYear(){
    console.log('ปีงบประมาณ')
    let promise = new Promise((resolve,reject)=>{
    axios
      .get(`${ADDRESS}/setting/get-by-name/ปีงบประมาณ`)
      .then((response) => {
        console.log(response.data);
        if (response.data.status === "success") {
          let data = response.data.doc;
          this.budgetYears = data.member;
          if (this.$route.params.id != undefined) {
            this.fetchData();
          }
          resolve('ok budget year')
        } else {
          reject(response.data.error)
          console.log(`พบปัญหาในการดึงข้อมูลจากแม่ข่าย , ${response.data.error}`);
        }
      });
    })
    return promise
  }

  fetchBudgetSource(){
    let promise = new Promise((resolve,reject)=>{
    axios
      .get(`${ADDRESS}/setting/get-by-name/แหล่งงบประมาณในคำขอ`)
      .then((response) => {
        console.log(response.data);
        if (response.data.status === "success") {
          let data = response.data.doc;
          this.sources = data.member;
          if (this.$route.params.id != undefined) {
            this.fetchData();
          }
          resolve('ok budget source')
        } else {
          reject(response.data.error)
          console.log(`พบปัญหาในการดึงข้อมูลจากแม่ข่าย , ${response.data.error}`);
        }
      });
    })
    return promise
  }

  fetchType(){
    let promise = new Promise((resolve,reject)=>{
    axios
      .get(`${ADDRESS}/setting/get-by-name/ประเภทงบในคำขอ`)
      .then((response) => {
        console.log(response.data);
        if (response.data.status === "success") {
          let data = response.data.doc;
          this.types = data.member;
          if (this.$route.params.id != undefined) {
            this.fetchData();
          }
          resolve('ok budget type')
        } else {
          reject(response.data.error)
          console.log(`พบปัญหาในการดึงข้อมูลจากแม่ข่าย , ${response.data.error}`);
        }
      });
    })
    return promise
  }
  get currentBudgetYear(){
  let year = new Date().getFullYear()+543
  let month = new Date().getMonth() + 1
    if(month >= 10) year += 1
    return year
}

  pushItems(item:any) {
    let items: Array<any> = this.doc.data[item];
    if (!Array.isArray(items)) items = [];
    items.push({
      name: "",
    });
    this.$set(this.doc.data, item, items);
  }

  mounted() {
    let status = ""
    let main = async () => {
      status += await this.fetchFormRender()
      if(this.$route.params.id != undefined){
        status += await this.fetchData()
      } else {
        this.dataLoaded = true
      }
      status += await this.fetchIndicator()
      status += await this.fetchBudgetSource()
      status += await this.fetchBudgetYear()
      status += await this.fetchSector()
      status += await this.fetchType()
      status += await this.fetchIndicatorsByBudget()
      console.log(status)
    }
    main()
  }
  async generateReportPDF() {
    let items = await this.getBudgetItems()
    console.log(items)
    console.log("generatePDF");
    const documentA = new DocumentA();
    documentA.create11(this.doc, 2565,items,this.indicators);
    documentA.save(`reportA11.pdf`);
  }
  async generateReportWord() {
    let items = await this.getBudgetItems()
    console.log('items',items)
    console.log("generateDocx");
    const doc = new DocumentDocx();
    await doc.create01(this.doc, 2565,items,this.indicators);
    doc.save("Report_A11.docx");
  }
  budgetitems:Array<any> = []
  getBudgetItems():Promise<any> {
    return new Promise((resolve,reject)=>{
      let query = {
        budget_id:this.$route.params.id,
      }
      axios
        .post(`${ADDRESS}/budgetitem/get-items`, query)
        .then((response) => {
          if (response.data.status === "success") {
            let data = response.data.results;
            console.log('budget items', response)
            this.budgetitems = data.map((el:any)=>{
              return {
                ...el,
                edit:false
              }
            });
            resolve(this.budgetitems)
          } else {
            console.log(`พบปัญหาในการดึงข้อมูลจากแม่ข่าย , ${response.data.error}`);
          }
      });
    })
  }
  fetchIndicatorsByBudget(){
    let query = {
      skip:0,
      limit: 5000
    }
    axios.post(`${ADDRESS}/indicators/paginate`,query).then(response => {
      this.indicators = response.data.docs;
    })
  }
}
