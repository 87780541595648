


































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import axios from "axios";
import { ADDRESS } from "@/setting";
import draggable from "vuedraggable";
import { ConfirmAction } from "@/decorators";
@Component({
  components: {
    draggable,
  },
})
export default class Form extends Vue {
  types:Array<any> = []
  indicators: Array<any> =[]
  strategies:Array<any> = []
  sectors: Array<any> = [];
  doc: any = {
    mission:[
      {name:""}
    ],
    objective:[
      {name:""}
    ]
  };
  forms:Array<any> = [];
  address = ADDRESS;
  strategy:Array<any> = [];
  // showAlert(){
  //   alert("อยู่ในขั้นตอนพัฒนาระบบ")
  // }
  pushTopic() {
    let topic: Array<any> = this.doc.topic;
    if (!Array.isArray(topic)) topic = [];
    topic.push({
      name: "",
      children:[{
        children:[{
        
          children:[{
          
            children:[{
            
            }],
            indicators:[{}]
          }],
          indicators:[{}]
        }],
        indicators:[{}]
      }],
      indicators:[{}]
    });
    this.$set(this.doc, "topic", topic);
  }
  pushGoal(i:number,j:number) {
    let topic: Array<any> = this.doc.topic;
    if (!Array.isArray(topic[i].children[j].indicators)) topic[i].children[j].indicators = [];
    topic[i].children[j].indicators.push({ indicator:"", target: ""})
    this.$set(this.doc, "topic", topic);
  }
  pushGoalGroup(i:number,j:number) {
    let topic: Array<any> = this.doc.topic;
    if (!Array.isArray(topic[i].children)) topic[i].children = [];
    topic[i].children.push({      
      name: "",
      children:[{
        children:[{
        
          children:[{
          
            children:[{
            
            }],
            indicators:[{}]
          }],
          indicators:[{}]
        }],
        indicators:[{}]
      }],
      indicators:[{}]})
    this.$set(this.doc, "topic", topic);
  }
  pushProduct(i:number,j:number,k:number) {
    let topic: Array<any> = this.doc.topic;
    if (!Array.isArray(topic[i].children[j].children[k].indicators)) topic[i].children[j].children[k].indicators = [];
    topic[i].children[j].children[k].indicators.push({ indicator:"", target: ""})
    this.$set(this.doc, "topic", topic);
  }
  pushProductGroup(i:number,j:number,k:number) {
    let topic: Array<any> = this.doc.topic;
    if (!Array.isArray(topic[i].children[j].children)) topic[i].children[j].children = [];
    topic[i].children[j].children.push({       
      name: "",
      children:[{
        children:[{
        
          children:[{
          
            children:[{
            
            }],
            indicators:[{}]
          }],
          indicators:[{}]
        }],
        indicators:[{}]
      }],
      indicators:[{}] })
    this.$set(this.doc, "topic", topic);
  }
  pushStrategyGroup(i:number,j:number,k:number) {
    let topic: Array<any> = this.doc.topic;
    if (!Array.isArray(topic[i].children[j].children[k].children)) topic[i].children[j].children[k].children = [];
    topic[i].children[j].children[k].children.push({
      name: "",
      children:[{
        children:[{
        
          children:[{
          
            children:[{
            
            }],
            indicators:[{}]
          }],
          indicators:[{}]
        }],
        indicators:[{}]
      }],
      indicators:[{}]})
    this.$set(this.doc, "topic", topic);
  }
  pushStrategy(i:number,j:number,k:number,z:number) {
    let topic: Array<any> = this.doc.topic;
    if (!Array.isArray(topic[i].children[j].children[k].children[z].indicators)) topic[i].children[j].children[k].children[z].indicators = [];
    topic[i].children[j].children[k].children[z].indicators.push({ indicator:"", target: ""})
    this.$set(this.doc, "topic", topic);
  }
  pushPlan(i:number,j:number,k:number,z:number) {
    let topic: Array<any> = this.doc.topic;
    if (!Array.isArray(topic[i].children[j].children[k].children[z].children)) topic[i].children[j].children[k].children[z].children = [];
    topic[i].children[j].children[k].children[z].children.push({name:""})
    this.$set(this.doc, "topic", topic);
  }
  pushMission() {
    let mission: Array<any> = this.doc.mission;
    if (!Array.isArray(mission)) mission = [];
    mission.push({ 
      name:""
      })
    this.$set(this.doc, "mission", mission);
  }
  pushObj() {
    let objective: Array<any> = this.doc.objective;
    if (!Array.isArray(objective)) objective = [];
    objective.push({ 
      name:""
      })
    this.$set(this.doc, "objective", objective);
  }
  submitForm() {
    let prep = this.doc
    if (this.$route.params.id == undefined) {
      axios.post(`${ADDRESS}/strategy/create`, prep)
      .then((response) => {
        console.log(response)
        alert("ดำเนินการบันทึกสำเร็จแล้ว")
        this.$router.push("/portal/strategy")
      })
    } else {
      axios.patch(`${ADDRESS}/strategy/update/${this.doc.id}`,prep)
      .then((response) => {
        console.log(response)
        alert("ดำเนินการแก้ไขสำเร็จแล้ว")
        this.$router.push("/portal/strategy")
      })
    }
  }
  fetchData() {
    axios
      .get(`${ADDRESS}/strategy/get/${this.$route.params.id}`)
      .then((response) => {
        console.log(response.data);
        if (response.data.status === "success") {
          let data = response.data.doc;
          this.doc = data;
          this.getIndicator()
        } else {
          console.log(`พบปัญหาในการดึงข้อมูลจากแม่ข่าย , ${response.data.error}`);
        }
      });
  }
  fetchSector() {
    axios.get(`${ADDRESS}/sectors/list`).then((response) => {
      this.sectors = response.data.docs;
      if (this.$route.params.id != undefined) this.fetchData();
    });
  }
getIndicator() {
  let query = {
    search: {
      budgetYear : this.doc.budgetYear,
    },
    limit:9999,
    offset:0,
    sort: {
      name: "asc"
    }
  }
  console.log("query", query)
  axios.post(`${ADDRESS}/indicators/paginate`,query).then((response) => {
    console.log('indicators',response)
    let data = response.data.docs;
    this.indicators = data.map((o:any)=>{
      return {
        _id:o.id,
        name:o.name,
        intro:o.intro,
        code:o.code
      }
    })
  });
  } 
  fetchStrategy(){
    let promise = new Promise((resolve,reject)=>{
    axios
      .get(`${ADDRESS}/setting/get-by-name/ระดับผังยุทธศาสตร์`)
      .then((response) => {
        console.log(response.data);
        if (response.data.status === "success") {
          let data = response.data.doc;
          this.strategy = data.member;
        } else {
          reject(response.data.error)
          console.log(`พบปัญหาในการดึงข้อมูลจากแม่ข่าย , ${response.data.error}`);
        }
      });
    })
    return promise
  }
  mounted() {
    this.fetchStrategyLevel()
    if(this.$route.params.id != undefined) this.fetchData()
    this.fetchSector();
    this.fetchStrategy();
  }

  fetchStrategyLevel(){
    console.log('ปีงบประมาณ')
    let promise = new Promise((resolve,reject)=>{
    axios
      .get(`${ADDRESS}/setting/get-by-name/ระดับผังยุทธศาสตร์`)
      .then((response) => {
        console.log(response.data);
        if (response.data.status === "success") {
          let data = response.data.doc;
          this.strategies = data.member;
          if (this.$route.params.id != undefined) {
            this.fetchData();
          }
          resolve('ok budget year')
        } else {
          reject(response.data.error)
          console.log(`พบปัญหาในการดึงข้อมูลจากแม่ข่าย , ${response.data.error}`);
        }
      });
    })
    return promise
  }
  @ConfirmAction("ยืนยันลบพันธกิจ?")
  deleteMission(index:number){
    this.doc.mission.splice(index, 1)
  }
  @ConfirmAction("ยืนยันลบวัตถุประสงค์?")
  deleteObjective(d:number){
    this.doc.objective.splice(d, 1)
  }
}
