import ExcelJS from "exceljs"
import { saveAs } from 'file-saver';

export class DocumentExcel {
  workbook = new ExcelJS.Workbook()
  worksheet = this.workbook.addWorksheet('Report')
  async createB03(docs: Array<any>, budgetYear: number) {

    this.worksheet.addRow([`รายงานงบประมาณ กกท.หัวข้อ "งบอุดหนุน" ประจำปีงบประมาณ พ.ศ. ${budgetYear ?? new Date().getFullYear() + 543}`])
    this.worksheet.mergeCells('A1:F1')
    this.worksheet.getCell("A2").value = "ฝ่าย/สำนัก"
    this.worksheet.getCell("B2").value = "ลำดับ"
    this.worksheet.getCell("C2").value = "รายการที่"
    this.worksheet.getCell("D2").value = "การดำเนินงานหลัก"
    this.worksheet.getCell("E2").value = "งบประมาณ"
    this.worksheet.getCell("F2").value = "หมายเหตุ"
    this.worksheet.getColumn("A").width = 20
    this.worksheet.getColumn("C").width = 40
    this.worksheet.getColumn("D").width = 40
    this.worksheet.getCell("A1").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("A2").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("B2").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("C2").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("D2").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("E2").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("F2").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("A2").style = { fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FF3399FF' } } }
    this.worksheet.getCell("B2").style = { fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FF3399FF' } } }
    this.worksheet.getCell("C2").style = { fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FF3399FF' } } }
    this.worksheet.getCell("D2").style = { fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FF3399FF' } } }
    this.worksheet.getCell("E2").style = { fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FF3399FF' } } }
    this.worksheet.getCell("F2").style = { fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FF3399FF' } } }
    docs.forEach((doc, i) => {
      let l1ProjectName = ""
      try { l1ProjectName = (doc.pj[0].projectCode ?? "") + (doc.pj[0].name ?? "") } catch (error) { }
      doc.project.forEach((pj: any, j: number) => {
        pj.items.forEach((item: any, k: number) => {
          let sectorName = doc.sector
          let projectName = (pj.projectCode ?? "") + (pj.name ?? "")
          this.worksheet.addRow([j == 0 ? sectorName : "", k == 0 ? j + 1 : "", k == 0 ? projectName : "", k + 1 + ". " + item.subject, item.sumMonths, ""])
        });
      });
    })
    console.log(docs)
    this.workbook.xlsx.writeBuffer().then(function (data: any) {
      var blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, "ReportB03.xlsx");
    });
  }
  async createB04(docs: any, budgetYear: number) {
    this.worksheet.addRow([`รายงานจำนวนผู้ช่วยปฏิบัติงาน กกท. ประจำปีงบประมาณ พ.ศ. ${budgetYear ?? new Date().getFullYear() + 543}`])
    this.worksheet.mergeCells('A1:K1')
    this.worksheet.getCell("A2").value = "ลำดับ"
    this.worksheet.getCell("B2").value = "ฝ่าย/สำนัก"
    this.worksheet.getCell("C2").value = "รายการที่"
    this.worksheet.getCell("D2").value = "ปริญญาโท"
    this.worksheet.getCell("E2").value = "ปริญญาตรี"
    this.worksheet.getCell("F2").value = "ปริญญาตรี (เชี่ยวชาญ)"
    this.worksheet.getCell("G2").value = "ปวส."
    this.worksheet.getCell("H2").value = "ปวช."
    this.worksheet.getCell("I2").value = "ม.6"
    this.worksheet.getCell("J2").value = "ม.3"
    this.worksheet.getCell("K2").value = "หมายเหตุ"
    this.worksheet.getCell("A1").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getColumn("B").width = 70
    this.worksheet.getColumn("C").width = 70
    console.log(docs)
    docs.forEach((doc: any, i: number) => {
      this.worksheet.addRow([i + 1, doc.sector ?? "", doc.name ?? "", ...doc.qty, ""]);
    })
    this.worksheet.addRow(['', '', 'รวมจำนวนผู้ช่วยปฏิบัติงาน'
      , docs.map((doc: any) => doc.qty[0]).reduce((a: number, b: number) => a + b, 0)
      , docs.map((doc: any) => doc.qty[1]).reduce((a: number, b: number) => a + b, 0)
      , docs.map((doc: any) => doc.qty[2]).reduce((a: number, b: number) => a + b, 0)
      , docs.map((doc: any) => doc.qty[3]).reduce((a: number, b: number) => a + b, 0)
      , docs.map((doc: any) => doc.qty[4]).reduce((a: number, b: number) => a + b, 0)
      , docs.map((doc: any) => doc.qty[5]).reduce((a: number, b: number) => a + b, 0)
      , docs.map((doc: any) => doc.qty[6]).reduce((a: number, b: number) => a + b, 0)
      , ""])
    this.worksheet.eachRow((row, rn) => {
      row.eachCell((cell, cn) => {
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' }
        };
      })
    })
    this.workbook.xlsx.writeBuffer().then(function (data: any) {
      var blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, "ReportB04.xlsx");
    });
  }
  async createB05(docs: any, budgetYear: number) {
    this.worksheet.getCell("A1").value = `สรุปแผนการปฏิบัติงานและแผนการใช้จ่ายงบประมาณ ประจำปีงบประมาณ พ.ศ. ${budgetYear ?? new Date().getFullYear() + 543}`
    this.worksheet.getCell("A2").value = "ผลผลิต/กิจกรรม/กิจกรรมย่อย/รายการ"
    this.worksheet.getCell("A1").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.mergeCells('A1:R1')
    this.worksheet.mergeCells('A2:A3')
    this.worksheet.getCell("B2").value = "ไตรมาส 1"
    this.worksheet.getCell("F2").value = "ไตรมาส 2"
    this.worksheet.getCell("J2").value = "ไตรมาส 3"
    this.worksheet.getCell("N2").value = "ไตรมาส 4"
    this.worksheet.mergeCells('B2:E2')
    this.worksheet.mergeCells('F2:I2')
    this.worksheet.mergeCells('J2:M2')
    this.worksheet.mergeCells('N2:Q2')
    this.worksheet.getCell("B3").value = "ต.ค."
    this.worksheet.getCell("C3").value = "พ.ย."
    this.worksheet.getCell("D3").value = "ธ.ค."
    this.worksheet.getCell("E3").value = "รวม"
    this.worksheet.getCell("F3").value = "ม.ค."
    this.worksheet.getCell("G3").value = "ก.พ."
    this.worksheet.getCell("H3").value = "มี.ค."
    this.worksheet.getCell("I3").value = "รวม"
    this.worksheet.getCell("J3").value = "เม.ย."
    this.worksheet.getCell("K3").value = "พ.ค."
    this.worksheet.getCell("L3").value = "มิ.ย."
    this.worksheet.getCell("M3").value = "รวม"
    this.worksheet.getCell("N3").value = "ก.ค."
    this.worksheet.getCell("O3").value = "ส.ค."
    this.worksheet.getCell("P3").value = "ก.ย."
    this.worksheet.getCell("Q3").value = "รวม"
    this.worksheet.getCell("R3").value = "รวมทั้งสิ้น"
    this.worksheet.getColumn("A").width = 80
    this.worksheet.getColumn("B").width = 12
    this.worksheet.getColumn("C").width = 12
    this.worksheet.getColumn("D").width = 12
    this.worksheet.getColumn("E").width = 12
    this.worksheet.getColumn("F").width = 12
    this.worksheet.getColumn("G").width = 12
    this.worksheet.getColumn("H").width = 12
    this.worksheet.getColumn("I").width = 12
    this.worksheet.getColumn("J").width = 12
    this.worksheet.getColumn("K").width = 12
    this.worksheet.getColumn("L").width = 12
    this.worksheet.getColumn("M").width = 12
    this.worksheet.getColumn("N").width = 12
    this.worksheet.getColumn("O").width = 12
    this.worksheet.getColumn("P").width = 12
    this.worksheet.getColumn("Q").width = 12
    this.worksheet.getColumn("R").width = 12
    // console.log(docs)

    docs.forEach((doc: any, i: number) => {
      this.worksheet.addRow([doc.name, ...doc.budget, doc.sumBudget]);
      // 99ccff
      this.worksheet.getRow(4 + i).eachCell((cell, cn) => {
        let color = "FFFFFFFF"
        if (doc.level == 1) color = 'FF3399FF'
        else if (doc.level == 2) color = 'FFCCE6FF'
        else if (doc.level == 3) color = 'FFFFFFFF'
        cell.style = { fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: color } } }
      })
    })

    this.workbook.xlsx.writeBuffer().then(function (data: any) {
      var blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, "ReportB05.xlsx");
    });
  }
  async createB14(docs: any, budgetYear: number) {
    this.worksheet.getCell("A1").value = `สรุปแผนการปฏิบัติงานและแผนการใช้จ่ายงบประมาณ ประจำปีงบประมาณ พ.ศ. ${budgetYear ?? new Date().getFullYear() + 543}`
    this.worksheet.getCell("A2").value = "ผลผลิต/กิจกรรม/กิจกรรมย่อย/รายการ"
    this.worksheet.getCell("B2").value = "สถานะ"
    this.worksheet.getCell("C2").value = "ผลการเบิก/จ่าย"
    this.worksheet.getCell("A1").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.mergeCells('A1:R1')
    this.worksheet.mergeCells('A2:A3')
    this.worksheet.mergeCells('B2:B3')
    this.worksheet.mergeCells('C2:C3')
    this.worksheet.getCell("D2").value = "ไตรมาส 1"
    this.worksheet.getCell("H2").value = "ไตรมาส 2"
    this.worksheet.getCell("L2").value = "ไตรมาส 3"
    this.worksheet.getCell("P2").value = "ไตรมาส 4"
    this.worksheet.mergeCells('D2:G2')
    this.worksheet.mergeCells('H2:K2')
    this.worksheet.mergeCells('L2:O2')
    this.worksheet.mergeCells('P2:R2')
    this.worksheet.getCell("D3").value = "ต.ค."
    this.worksheet.getCell("E3").value = "พ.ย."
    this.worksheet.getCell("F3").value = "ธ.ค."
    this.worksheet.getCell("G3").value = "รวม"
    this.worksheet.getCell("H3").value = "ม.ค."
    this.worksheet.getCell("I3").value = "ก.พ."
    this.worksheet.getCell("J3").value = "มี.ค."
    this.worksheet.getCell("K3").value = "รวม"
    this.worksheet.getCell("L3").value = "เม.ย."
    this.worksheet.getCell("M3").value = "พ.ค."
    this.worksheet.getCell("N3").value = "มิ.ย."
    this.worksheet.getCell("O3").value = "รวม"
    this.worksheet.getCell("P3").value = "ก.ค."
    this.worksheet.getCell("Q3").value = "ส.ค."
    this.worksheet.getCell("R3").value = "ก.ย."
    this.worksheet.getCell("S3").value = "รวม"
    this.worksheet.getCell("T3").value = "รวมทั้งสิ้น"
    this.worksheet.getColumn("A").width = 80
    this.worksheet.getColumn("B").width = 20
    this.worksheet.getColumn("C").width = 20
    this.worksheet.getColumn("D").width = 12
    this.worksheet.getColumn("E").width = 12
    this.worksheet.getColumn("F").width = 12
    this.worksheet.getColumn("G").width = 12
    this.worksheet.getColumn("H").width = 12
    this.worksheet.getColumn("I").width = 12
    this.worksheet.getColumn("J").width = 12
    this.worksheet.getColumn("K").width = 12
    this.worksheet.getColumn("L").width = 12
    this.worksheet.getColumn("M").width = 12
    this.worksheet.getColumn("N").width = 12
    this.worksheet.getColumn("O").width = 12
    this.worksheet.getColumn("P").width = 12
    this.worksheet.getColumn("Q").width = 12
    this.worksheet.getColumn("R").width = 12
    this.worksheet.getColumn("S").width = 12
    this.worksheet.getColumn("T").width = 12
    // console.log(docs)

    docs.forEach((doc: any, i: number) => {
      this.worksheet.addRow([doc.name, (doc.status ?? "?"), doc.progress, ...doc.budget, doc.sumBudget]);
      // 99ccff
      this.worksheet.getRow(4 + i).eachCell((cell, cn) => {
        let color = "FFFFFFFF"
        if (doc.level == 1) color = 'FF3399FF'
        else if (doc.level == 2) color = 'FFCCE6FF'
        else if (doc.level == 3) color = 'FFFFFFFF'
        cell.style = { fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: color } } }
      })
    })

    this.workbook.xlsx.writeBuffer().then(function (data: any) {
      var blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, "ReportB14.xlsx");
    });
  }
  async createB15(docs: any, budgetYear: number) {
    this.worksheet.getCell("A1").value = `สรุปแผนการปฏิบัติงานและแผนการใช้จ่ายงบประมาณ ประจำปีงบประมาณ พ.ศ. ${budgetYear ?? new Date().getFullYear() + 543}`
    this.worksheet.getCell("A2").value = "ผลผลิต/กิจกรรม/กิจกรรมย่อย/รายการ"
    this.worksheet.getCell("B2").value = "สถานะ"
    this.worksheet.getCell("C2").value = "ผลการเบิก/จ่าย"
    this.worksheet.getCell("D2").value = "ไตรมาส 1"
    this.worksheet.getCell("E2").value = "ไตรมาส 2"
    this.worksheet.getCell("F2").value = "ไตรมาส 3"
    this.worksheet.getCell("G2").value = "ไตรมาส 4"
    this.worksheet.getCell("H2").value = "รวมทั้งสิ้น"
    this.worksheet.getCell("A1").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.mergeCells('A1:H1')
    this.worksheet.mergeCells('A2:A3')
    this.worksheet.mergeCells('B2:B3')
    this.worksheet.mergeCells('C2:C3')
    this.worksheet.mergeCells('D2:D3')
    this.worksheet.mergeCells('E2:E3')
    this.worksheet.mergeCells('F2:F3')
    this.worksheet.mergeCells('G2:G3')
    this.worksheet.mergeCells('H2:H3')
    this.worksheet.getColumn("A").width = 80
    this.worksheet.getColumn("B").width = 20
    this.worksheet.getColumn("C").width = 20
    this.worksheet.getColumn("D").width = 12
    this.worksheet.getColumn("E").width = 12
    this.worksheet.getColumn("F").width = 12
    this.worksheet.getColumn("G").width = 12
    this.worksheet.getColumn("H").width = 12
    // console.log(docs)

    docs.forEach((doc: any, i: number) => {
      this.worksheet.addRow([doc.name, (doc.status ?? "?"), doc.progress, ...doc.quarter, doc.sumWithdrawn]);
      // 99ccff
      this.worksheet.getRow(4 + i).eachCell((cell, cn) => {
        let color = "FFFFFFFF"
        if (doc.level == 1) color = 'FF3399FF'
        else if (doc.level == 2) color = 'FFCCE6FF'
        else if (doc.level == 3) color = 'FFFFFFFF'
        cell.style = { fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: color } } }
      })
    })

    this.workbook.xlsx.writeBuffer().then(function (data: any) {
      var blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, "ReportB15.xlsx");
    });
  }
  async createB16(docs: Array<any>, budgetYear: number) {

    this.worksheet.addRow([`รายงานงบประมาณ กกท.หัวข้อ "งบอุดหนุน" ประจำปีงบประมาณ พ.ศ. ${budgetYear ?? new Date().getFullYear() + 543}`])
    this.worksheet.mergeCells('A1:F1')
    this.worksheet.getCell("A2").value = "ฝ่าย/สำนัก"
    this.worksheet.getCell("B2").value = "ลำดับ"
    this.worksheet.getCell("C2").value = "รายการที่"
    this.worksheet.getCell("D2").value = "การดำเนินงานหลัก"
    this.worksheet.getCell("E2").value = "งบประมาณ"
    this.worksheet.getCell("F2").value = "หมายเหตุ"
    this.worksheet.getColumn("A").width = 20
    this.worksheet.getColumn("C").width = 40
    this.worksheet.getColumn("D").width = 40
    this.worksheet.getCell("A1").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("A2").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("B2").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("C2").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("D2").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("E2").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("F2").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("A2").style = { fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FF3399FF' } } }
    this.worksheet.getCell("B2").style = { fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FF3399FF' } } }
    this.worksheet.getCell("C2").style = { fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FF3399FF' } } }
    this.worksheet.getCell("D2").style = { fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FF3399FF' } } }
    this.worksheet.getCell("E2").style = { fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FF3399FF' } } }
    this.worksheet.getCell("F2").style = { fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FF3399FF' } } }
    docs.forEach((doc, i) => {
      let l1ProjectName = ""
      try { l1ProjectName = (doc.pj[0].projectCode ?? "") + (doc.pj[0].name ?? "") } catch (error) { }
      doc.project.forEach((pj: any, j: number) => {
        pj.items.forEach((item: any, k: number) => {
          let sectorName = doc.sector
          let projectName = (pj.projectCode ?? "") + (pj.name ?? "")
          this.worksheet.addRow([j == 0 ? sectorName : "", k == 0 ? j + 1 : "", k == 0 ? projectName : "", k + 1 + ". " + item.subject, item.sumMonths, ""])
        });
      });
    })
    console.log(docs)
    this.workbook.xlsx.writeBuffer().then(function (data: any) {
      var blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, "ReportB16.xlsx");
    });
  }
  async createB17(docs: Array<any>, budgetYear: number) {
    this.worksheet.addRow(['รายการที่', 'ฝ่าย/สำนัก', 'สถานะ', 'ผลการดำเนินงาน', 'งบประมาณ', `ผลการเบิกจ่ายรายไตรมาส ${budgetYear}`, '', '', , '', '', '', , '', '', '', '', '', '', '', '', 'การเบิกจ่าย', 'เงินเหลือจ่าย', '	เงินคงเหลือ']).alignment = { vertical: 'middle', horizontal: 'center' };
    this.worksheet.addRow(['', '', '', '', '', 'ไตรมาส 1', '', '', '', 'ไตรมาส 2', '', '', '', 'ไตรมาส 3', '', '', '', 'ไตรมาส 4']).alignment = { vertical: 'middle', horizontal: 'center' };
    this.worksheet.addRow(['', '', '', '', '', 'ต.ค.', 'พ.ย.', 'ธ.ค.', 'รวม', 'ม.ค.', 'ก.พ.	', 'มี.ค.', 'รวม', 'เม.ย.', 'พ.ค.', 'มิ.ย.', 'รวม	', 'ก.ค.', 'ส.ค.', 'ก.ย.', 'รวม']).alignment = { vertical: 'middle', horizontal: 'center' };
    this.worksheet.mergeCells('F1:U1')
    this.worksheet.mergeCells('F2:I2')
    this.worksheet.mergeCells('J2:M2')
    this.worksheet.mergeCells('N2:Q2')
    this.worksheet.mergeCells('R2:U2')
    this.worksheet.mergeCells('A1:A3')
    this.worksheet.mergeCells('B1:B3')
    this.worksheet.mergeCells('C1:C3')
    this.worksheet.mergeCells('D1:D3')
    this.worksheet.mergeCells('E1:E3')
    this.worksheet.mergeCells('V1:V3')
    this.worksheet.mergeCells('W1:W3')
    this.worksheet.mergeCells('X1:X3')
    this.worksheet.getColumn("A").width = 100
    this.worksheet.getColumn("B").width = 20
    this.worksheet.getColumn("D").width = 20
    this.worksheet.getColumn("V").width = 10
    this.worksheet.getColumn("W").width = 10
    this.worksheet.getColumn("X").width = 10

    console.log(docs)
    docs.forEach((doc, i) => {
      this.worksheet.addRow([doc.projectCode + doc.projectsName, doc.sector, doc.status, doc.percentage, doc.budget, ...doc.months, doc.withdrawn, doc.surplus, doc.remaining])
    })
    this.worksheet.addRow(['', '', '', 'รวมงบประมาณ',
      docs.map(doc => doc.budget).reduce((a, b) => a + b, 0),
      docs.map(doc => doc.months[0]).reduce((a, b) => a + b, 0),
      docs.map(doc => doc.months[1]).reduce((a, b) => a + b, 0),
      docs.map(doc => doc.months[2]).reduce((a, b) => a + b, 0),
      docs.map(doc => doc.months[3]).reduce((a, b) => a + b, 0),
      docs.map(doc => doc.months[4]).reduce((a, b) => a + b, 0),
      docs.map(doc => doc.months[5]).reduce((a, b) => a + b, 0),
      docs.map(doc => doc.months[6]).reduce((a, b) => a + b, 0),
      docs.map(doc => doc.months[7]).reduce((a, b) => a + b, 0),
      docs.map(doc => doc.months[8]).reduce((a, b) => a + b, 0),
      docs.map(doc => doc.months[9]).reduce((a, b) => a + b, 0),
      docs.map(doc => doc.months[10]).reduce((a, b) => a + b, 0),
      docs.map(doc => doc.months[11]).reduce((a, b) => a + b, 0),
      docs.map(doc => doc.months[12]).reduce((a, b) => a + b, 0),
      docs.map(doc => doc.months[13]).reduce((a, b) => a + b, 0),
      docs.map(doc => doc.months[14]).reduce((a, b) => a + b, 0),
      docs.map(doc => doc.months[15]).reduce((a, b) => a + b, 0),
      docs.map(doc => doc.withdrawn).reduce((a, b) => a + b, 0),
      docs.map(doc => doc.surplus).reduce((a, b) => a + b, 0),
      docs.map(doc => doc.remaining).reduce((a, b) => a + b, 0),
    ])

    this.workbook.xlsx.writeBuffer().then(function (data: any) {
      var blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, "ReportB17.xlsx");
    });
  }
  async createB19(docs: Array<any>) {
    this.worksheet.getColumn("A").width = 100
    this.worksheet.getColumn("C").width = 30
    this.worksheet.getColumn("D").width = 10


    docs.forEach((doc, i) => {
      this.worksheet.addRow([doc.name]);
      this.worksheet.addRow([doc.intro]);
      this.worksheet.addRow(['ชื่อรายการ', 'ลำดับ', 'การดำเนินงาน', 'งบประมาณ']).alignment = { vertical: 'middle', horizontal: 'center' };
      doc.projects.forEach((project: any) => {
        this.worksheet.addRow(["รายการที่ " + (project.code ?? "") + (project.name ?? ""), 1, project.items[0].subject, project.items[0].budget]);
        project.items.filter((item: any, ii: number) => ii != 0).forEach((item: any, ii: number) => {
          this.worksheet.addRow(["", ii + 2, item.subject, item.budget])
        })
      })
      this.worksheet.addRow([""])
      this.worksheet.addRow([""])
    })

    this.workbook.xlsx.writeBuffer().then(function (data: any) {
      var blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, "ReportB19.xlsx");
    });
  }
  async createB24(docs: Array<any>, budgetYear: number) {
    this.worksheet.addRow(['รายการที่', 'ฝ่าย/สำนัก', 'สถานะ', 'ผลการดำเนินงาน', 'งบประมาณ', `ผลการเบิกจ่ายรายไตรมาส ${budgetYear}`, '', '', , '', '', '', , '', '', '', '', '', '', '', '', 'การเบิกจ่าย', 'เงินเหลือจ่าย', '	เงินคงเหลือ']).alignment = { vertical: 'middle', horizontal: 'center' };
    this.worksheet.addRow(['', '', '', '', '', 'ไตรมาส 1', '', '', '', 'ไตรมาส 2', '', '', '', 'ไตรมาส 3', '', '', '', 'ไตรมาส 4']).alignment = { vertical: 'middle', horizontal: 'center' };
    this.worksheet.addRow(['', '', '', '', '', 'ต.ค.', 'พ.ย.', 'ธ.ค.', 'รวม', 'ม.ค.', 'ก.พ.	', 'มี.ค.', 'รวม', 'เม.ย.', 'พ.ค.', 'มิ.ย.', 'รวม	', 'ก.ค.', 'ส.ค.', 'ก.ย.', 'รวม']).alignment = { vertical: 'middle', horizontal: 'center' };
    this.worksheet.mergeCells('F1:U1')
    this.worksheet.mergeCells('F2:I2')
    this.worksheet.mergeCells('J2:M2')
    this.worksheet.mergeCells('N2:Q2')
    this.worksheet.mergeCells('R2:U2')
    this.worksheet.mergeCells('A1:A3')
    this.worksheet.mergeCells('B1:B3')
    this.worksheet.mergeCells('C1:C3')
    this.worksheet.mergeCells('D1:D3')
    this.worksheet.mergeCells('E1:E3')
    this.worksheet.mergeCells('V1:V3')
    this.worksheet.mergeCells('W1:W3')
    this.worksheet.mergeCells('X1:X3')
    this.worksheet.getColumn("A").width = 100
    this.worksheet.getColumn("B").width = 20
    this.worksheet.getColumn("D").width = 20
    this.worksheet.getColumn("V").width = 10
    this.worksheet.getColumn("W").width = 10
    this.worksheet.getColumn("X").width = 10

    console.log(docs)
    docs.forEach((doc, i) => {
      this.worksheet.addRow([doc.projectCode + doc.projectsName, doc.sector, doc.status, doc.percentage, doc.budget, ...doc.months, doc.withdrawn, doc.surplus, doc.remaining])
    })
    this.worksheet.addRow(['', '', '', 'รวมงบประมาณ',
      docs.map(doc => doc.budget).reduce((a, b) => a + b, 0),
      docs.map(doc => doc.months[0]).reduce((a, b) => a + b, 0),
      docs.map(doc => doc.months[1]).reduce((a, b) => a + b, 0),
      docs.map(doc => doc.months[2]).reduce((a, b) => a + b, 0),
      docs.map(doc => doc.months[3]).reduce((a, b) => a + b, 0),
      docs.map(doc => doc.months[4]).reduce((a, b) => a + b, 0),
      docs.map(doc => doc.months[5]).reduce((a, b) => a + b, 0),
      docs.map(doc => doc.months[6]).reduce((a, b) => a + b, 0),
      docs.map(doc => doc.months[7]).reduce((a, b) => a + b, 0),
      docs.map(doc => doc.months[8]).reduce((a, b) => a + b, 0),
      docs.map(doc => doc.months[9]).reduce((a, b) => a + b, 0),
      docs.map(doc => doc.months[10]).reduce((a, b) => a + b, 0),
      docs.map(doc => doc.months[11]).reduce((a, b) => a + b, 0),
      docs.map(doc => doc.months[12]).reduce((a, b) => a + b, 0),
      docs.map(doc => doc.months[13]).reduce((a, b) => a + b, 0),
      docs.map(doc => doc.months[14]).reduce((a, b) => a + b, 0),
      docs.map(doc => doc.months[15]).reduce((a, b) => a + b, 0),
      docs.map(doc => doc.withdrawn).reduce((a, b) => a + b, 0),
      docs.map(doc => doc.surplus).reduce((a, b) => a + b, 0),
      docs.map(doc => doc.remaining).reduce((a, b) => a + b, 0),
    ])

    this.workbook.xlsx.writeBuffer().then(function (data: any) {
      var blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, "ReportB24.xlsx");
    });
  }
  async createB27(docs: Array<any>) {
    this.worksheet.getCell("A1").value = "#"
    this.worksheet.getCell("B1").value = "วันที่"
    this.worksheet.getCell("C1").value = "ชื่อ-สกุล"
    this.worksheet.getCell("F1").value = "ตำแหน่ง"
    this.worksheet.getCell("G1").value = "เข้างาน"
    this.worksheet.getCell("H1").value = "เลิกงาน"
    this.worksheet.getColumn("B").width = 10
    this.worksheet.getColumn("F").width = 20
    this.worksheet.getColumn("G").width = 30
    this.worksheet.getColumn("H").width = 30
    this.worksheet.mergeCells('C1:E1')
    this.worksheet.getCell("A1").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("B1").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("C1").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("F1").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("G1").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("H1").alignment = { vertical: 'middle', horizontal: 'center' }

    docs.forEach((doc, i) => {
      this.worksheet.addRow([i + 1, new Date, doc.prefix, doc.firstName, doc.lastName, doc.position, doc.inTime, doc.outTime]);
    })
    // worksheet.getCell("A1").style = {fill: {type: 'pattern', pattern: 'solid', fgColor: {argb: 'FFFFFF00'}}}
    this.workbook.xlsx.writeBuffer().then(function (data: any) {
      var blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, "ReportB27.xlsx");
    });
  }
  //reportA
  async createA09(budget: any, budgetitems: Array<any>, indicators: Array<any>) {
    const getLevel1 = (): Array<any> => {
      try {
        let l1 = budgetitems ?? {}
        if (budget.showZero) l1 = l1.filter((e: any) => (e.months ?? [0]).reduce((a: number, b: number) => a + b, 0) !== 0)
        return l1
      } catch (error) {
        console.log(error)
        return []
      }
    }
    const getLevel2 = (i: number): Array<any> => {
      try {
        let l1 = budgetitems ?? {}
        let l2 = l1[i].children ?? {}
        if (budget.showZero) l2 = l2.filter((e: any) => (e.months ?? [0]).reduce((a: number, b: number) => a + b, 0) !== 0)
        return l2
      } catch (error) {
        console.log(error)
        return []
      }
    }
    const getLevel3 = (i: number, j: number): Array<any> => {
      try {
        let l1 = budgetitems ?? {}
        let l2 = l1[i].children ?? {}
        let l3 = l2[j].children ?? {}
        if (budget.showZero) l3 = l3.filter((e: any) => (e.months ?? [0]).reduce((a: number, b: number) => a + b, 0) !== 0)
        return l3
      } catch (error) {
        console.log(error)
        return []
      }
    }


    const resolveIndicator = (id: string) => {
      try {
        let found = indicators.find(ind => ind.id == id)
        if (found === undefined) return { code: "", name: "" }
        return found.code
      } catch (error) {
        return ""
      }
    }
    const indicatorsText = (): string => {
      try {
        let allIndicators: Array<any> = (indicators ?? [])
        let budgetIndicators: Array<string> = []
        try {
          budgetIndicators = budget.data.indicators as Array<string>
        } catch (error) {

        }
        // console.table(allIndicators)
        // console.table(budgetIndicators)
        let result = allIndicators.filter((allIndicator: any) => {
          return budgetIndicators.includes(allIndicator.id)
        })
        // console.log({result})
        // .map((el:any)=>{
        //   return el.code
        // })
        return result.map(rs => (rs.code ?? "")).join(", ")
      } catch (error) {
        console.log(error)
        return "-"
      }
      return "-"
    }
    const kpi6 = () => {
      try {
        let found = budget.data.kpi6.map((a: any, i: number) => {
          return i + 1 + "." + a
        })
        console.log('found', found)
        return found
      } catch (error) {

      }
    }
    const kpi12 = () => {
      try {
        let found = budget.data.kpi12.map((a: any, i: number) => {
          return i + 1 + "." + a
        })
        console.log('found', found)
        return found
      } catch (error) {

      }
    }
    // const 
    let totalBudget = budgetitems.reduce((a, b) => a + b.sum, 0)
    //header
    this.worksheet.addRow([`แผนการปฏิบัติงานและแผนการใช้จ่ายงบประมาณ ประจำปีงบประมาณ พ.ศ. ${budget.budgetYear ?? new Date().getFullYear() + 543}`])
    this.worksheet.mergeCells('A1:T1')
    this.worksheet.getCell("A1").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("A2").value = `รายการที่ : ${budget.code || ""} - ${budget.name || ""}`
    this.worksheet.mergeCells('A2:K2')
    this.worksheet.getCell("L2").value = `งบประมาณประจำปี : ${(totalBudget || 0).formatComma()} บาท`
    this.worksheet.mergeCells('L2:T2')
    this.worksheet.getCell("A3").value = `KPI 6 เดือน`
    this.worksheet.mergeCells('A3:B3')
    this.worksheet.getCell("C3").value = `${budget.data.kpi6 ?? ""}`
    this.worksheet.mergeCells('C3:K3')
    this.worksheet.getCell("C3").alignment = {
      wrapText: true
    }
    this.worksheet.mergeCells('L3:T3')
    this.worksheet.getCell("A4").value = `KPI 12 เดือน`
    this.worksheet.mergeCells('A4:B4')
    this.worksheet.getCell("C4").value = `${budget.data.kpi12 ?? ""}`
    this.worksheet.mergeCells('C4:K4')
    this.worksheet.getCell("C4").alignment = {
      wrapText: true
    }
    this.worksheet.mergeCells('L4:T4')
    this.worksheet.getCell("A5").value = `ตัวชี้วัดทัี่เกี่ยวข้อง`
    this.worksheet.mergeCells('A5:B5')
    this.worksheet.getCell("C5").value = indicatorsText()
    this.worksheet.getCell("C5").alignment = {
      wrapText: true
    }
    this.worksheet.mergeCells('C5:K5')
    this.worksheet.mergeCells('L5:T5')
    // console.log("okosdkoasdk", indicatorsText())
    //body
    this.worksheet.getCell("A6").value = "ลำดับที่"
    this.worksheet.mergeCells('A6:A7')
    this.worksheet.getCell("A6").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("B6").value = "การดำเนินงานหลัก"
    this.worksheet.mergeCells('B6:C7')
    this.worksheet.getCell("B6").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("D6").value = "ผลผลิตและค่าเป้าหมาย"
    this.worksheet.mergeCells('D6:D7')
    this.worksheet.getCell("D6").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("E6").value = "ผู้รับผิดชอบ"
    this.worksheet.mergeCells('E6:E7')
    this.worksheet.getCell("E6").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("F6").value = `${budget.type || ""}`
    this.worksheet.mergeCells('F6:G6')
    this.worksheet.getCell("F6").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("F7").value = `งบอุดหนุน`
    this.worksheet.getCell("G7").value = `งบลงทุน`
    this.worksheet.getCell("H6").value = `${budget.budgetYear - 1 || 0}`
    this.worksheet.mergeCells('H6:J6')
    this.worksheet.getCell("F7").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("G7").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("H6").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("H7").value = "ต.ค."
    this.worksheet.getCell("I7").value = "พ.ย."
    this.worksheet.getCell("J7").value = "ธ.ค."
    this.worksheet.getCell("K6").value = `${budget.budgetYear || 0}`
    this.worksheet.mergeCells('K6:S6')
    this.worksheet.getCell("H7").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("I7").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("J7").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("K6").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("K7").value = "ม.ค."
    this.worksheet.getCell("K7").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("L7").value = "ก.พ."
    this.worksheet.getCell("L7").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("M7").value = "มี.ค."
    this.worksheet.getCell("M7").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("N7").value = "เม.ย."
    this.worksheet.getCell("N7").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("O7").value = "พ.ค."
    this.worksheet.getCell("O7").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("P7").value = "มิ.ย."
    this.worksheet.getCell("P7").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("Q7").value = "ก.ค."
    this.worksheet.getCell("Q7").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("R7").value = "ส.ค."
    this.worksheet.getCell("R7").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("S7").value = "ก.ย."
    this.worksheet.getCell("S7").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("T6").value = "ความเชื่อมโยง(Risk/KPI)"
    this.worksheet.mergeCells('T6:T7')
    this.worksheet.getCell("T6").alignment = { vertical: 'middle', horizontal: 'center' }
    //width
    this.worksheet.getColumn("A").width = 10
    this.worksheet.getColumn("B").width = 20
    this.worksheet.getColumn("C").width = 20
    this.worksheet.getColumn("D").width = 20
    this.worksheet.getColumn("E").width = 20
    this.worksheet.getColumn("F").width = 15
    this.worksheet.getColumn("G").width = 15
    this.worksheet.getColumn("H").width = 15
    this.worksheet.getColumn("I").width = 15
    this.worksheet.getColumn("J").width = 15
    this.worksheet.getColumn("K").width = 15
    this.worksheet.getColumn("L").width = 15
    this.worksheet.getColumn("M").width = 15
    this.worksheet.getColumn("N").width = 15
    this.worksheet.getColumn("O").width = 15
    this.worksheet.getColumn("P").width = 15
    this.worksheet.getColumn("Q").width = 15
    this.worksheet.getColumn("R").width = 15
    this.worksheet.getColumn("S").width = 15
    this.worksheet.getColumn("T").width = 30
    let current_row = 8
    let start_row = 8
    let last_item_row = 8
    getLevel1().forEach((lv1: any, i: number) => {
      this.worksheet.addRow([
        lv1.number,
        lv1.subject,
        null,
        lv1.product,
        lv1.responsible,
        lv1.type === 'งบอุดหนุน' ? lv1.months.reduce((a: any, b: any) => a + b, 0) : "",
        lv1.type === 'งบลงทุน' ? lv1.months.reduce((a: any, b: any) => a + b, 0) : "",
        ...lv1.months,
        lv1.indicators.map((ind: any) => resolveIndicator(ind)).join(", "),
      ]);
      let row = this.worksheet.getRow(current_row++)
      row.eachCell({ includeEmpty: true }, (cell, cn) => {
        // console.table(lv1.monthsFlag)
        if (cn >= 8) {
          if (lv1.monthsFlag[cn - 8] == true) {
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: {
                argb: 'FFD1E2F5'
              }
            };
          }
        }
      })
      // #d1e2f5
      getLevel2(i).forEach((lv2: any, j: number) => {
        this.worksheet.addRow([
          "",
          `  ${lv2.number}${lv2.subject}`,
          null,
          lv2.product,
          lv2.responsible,
          lv2.type === 'งบอุดหนุน' ? lv2.months.reduce((a: any, b: any) => a + b, 0) : "",
          lv2.type === 'งบลงทุน' ? lv2.months.reduce((a: any, b: any) => a + b, 0) : "",
          ...lv2.months,
          lv2.indicators.map((ind: any) => resolveIndicator(ind)).join(", "),
        ]);
        let row = this.worksheet.getRow(current_row++)
        row.eachCell({ includeEmpty: true }, (cell, cn) => {
          // console.table(lv2.monthsFlag)
          if (cn >= 8) {
            if (lv2.monthsFlag[cn - 8] == true) {
              cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                  argb: 'FFe4ecfc'
                }
              };
            }
          }
        })
        getLevel3(i, j).forEach((lv3: any, k: number) => {
          this.worksheet.addRow([
            "",
            lv3.subject,
            null,
            lv3.product,
            lv3.responsible,
            lv3.type === 'งบอุดหนุน' ? lv3.months.reduce((a: any, b: any) => a + b, 0) : "",
            lv3.type === 'งบลงทุน' ? lv3.months.reduce((a: any, b: any) => a + b, 0) : "",
            ...lv3.months,
            lv3.indicators.map((ind: any) => resolveIndicator(ind)).join(", "),
          ]);
          let row = this.worksheet.getRow(current_row++)
          row.eachCell({ includeEmpty: true }, (cell, cn) => {
            // console.table(lv3.monthsFlag)
            if (cn >= 8) {
              if (lv3.monthsFlag[cn - 8] == true) {
                cell.fill = {
                  type: 'pattern',
                  pattern: 'solid',
                  fgColor: {
                    argb: 'FFf5f8f9'
                  }
                };
              }
            }
          })
        })
      })
      for (var i = 0; i < 14; i++) {
        this.worksheet.getColumn(6 + i).numFmt = '#,##0.00;[Red]-$#,##0.00;';
      }
    })
    console.log(current_row, start_row)
    for (let i = 0; i < current_row - start_row; i++) {
      this.worksheet.mergeCells(start_row + i, 2, start_row + i, 3)
      this.worksheet.getRow(start_row + i).getCell(1).alignment = {
        wrapText: true,
        vertical: 'top',
        horizontal: 'left'
      }
      this.worksheet.getRow(start_row + i).height = 30

    }
    let result: Array<any> = []
    for (var i = 0; i < 12; i++) {
      result.push(getLevel1().map(lv1 => lv1.months[i]).reduce((a, b) => a + b, 0))
      console.log(result)
    }
    // let row = this.worksheet.getRow(current_row++)
    // console.log('row',row)

    this.worksheet.addRow([
      "รวมงบประมาณประจำปี",
      "",
      "",
      "",
      "",
      getLevel1().filter(lv1 => (lv1.type === 'งบอุดหนุน')).map(bi => bi.months.reduce((a: any, b: any) => a + b, 0)).reduce((a, b) => a + b, 0).formatComma(),
      getLevel1().filter(lv1 => (lv1.type === 'งบลงทุน')).map(bi => bi.months.reduce((a: any, b: any) => a + b, 0)).reduce((a, b) => a + b, 0).formatComma(),
      ...result,
      ""
    ])

    this.worksheet.addRow([
      "",
      "",
      "",
      "",
      "",
      getLevel1().map(bi => bi.months.reduce((a: any, b: any) => a + b, 0)).reduce((a, b) => a + b, 0).formatComma(),
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
    ])
    let lastRow = this.worksheet.rowCount
    this.worksheet.mergeCells(lastRow - 1, 1, lastRow, 5)
    this.worksheet.mergeCells(lastRow, 6, lastRow, 7)
    for (var i = 0; i < 13; i++) {
      this.worksheet.mergeCells(lastRow - 1, 8 + i, lastRow, 8 + i)
    }
    this.worksheet.getRow(lastRow).eachCell({ includeEmpty: true }, (cell, cn) => {
      cell.alignment = {
        vertical: 'middle',
        horizontal: 'right',
      }
      cell.numFmt = '#,##0.00;[Red]-$#,##0.00;';
    })
    this.worksheet.eachRow({ includeEmpty: true }, (row, rn) => {
      if (rn >= 6) {

        row.eachCell({ includeEmpty: true }, (cell, cn) => {
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
          };
        })
      }
    })
    this.worksheet.addRow([])
    this.worksheet.addRow([])
    this.worksheet.addRow([])
    this.worksheet.addRow([])
    this.worksheet.addRow(["", "............................ลงชื่อ"])
    this.worksheet.addRow(["", "        ชาญยุทธ คำหวาน       "])
    // return 
    this.workbook.xlsx.writeBuffer().then(function (data: any) {
      var blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, "ReportA09.xlsx");
    })
  }
  async createA01(docs: Array<any>, budgetYear: Number, sum: any) {
    //header
    this.worksheet.addRow([`รายงานความเชื่อมโยงตัวชี้วัด ประจำปีงบประมาณ พ.ศ. ${budgetYear}`])
    this.worksheet.getCell("A1").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.mergeCells('A1:H1')
    this.worksheet.getCell("A2").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("A2").value = `ลำดับ`
    this.worksheet.getCell("B2").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("B2").value = `ตัวชี้วัด`
    this.worksheet.getCell("C2").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("C2").value = `รายการ`
    this.worksheet.getCell("D2").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("D2").value = `ข้อการดำเนินงาน/กิจกรรม`
    this.worksheet.getCell("E2").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("E2").value = `ผู้รับผิดชอบ`
    this.worksheet.getCell("F2").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("F2").value = `งบประมาณ`
    this.worksheet.getCell("G2").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("G2").value = `แหล่งงบประมาณ`
    this.worksheet.getCell("H2").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("H2").value = `ฝ่ายและสำนักที่รับผิดชอบ`
    //width
    this.worksheet.getColumn("A").width = 10
    this.worksheet.getColumn("B").width = 40
    this.worksheet.getColumn("C").width = 30
    this.worksheet.getColumn("D").width = 30
    this.worksheet.getColumn("E").width = 20
    this.worksheet.getColumn("F").width = 20
    this.worksheet.getColumn("G").width = 20
    this.worksheet.getColumn("H").width = 20
    docs.forEach((doc: any, i: number) => {
      doc.items.forEach((item: any) => {
        this.worksheet.addRow([
          `${i + 1}`,
          `${doc.name}`,
          `${item.code}` + "" + `${item.name}`,
          `${item.number}` + "" + `${item.subject}`,
          `${item.responsible}`,
          `${(item.sum ?? 0).formatFull()}`,
          `${(item.type ?? ",").split(",")[0]}`,
          `${item.sector}`,
        ])
      })
    });
    this.worksheet.eachRow({ includeEmpty: true }, (row, rn) => {
      if (rn >= 2) {
        row.eachCell({ includeEmpty: true }, (cell, cn) => {
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
          };
        })
      }
    })
    this.worksheet.addRow([
      "",
      "",
      "",
      "",
      "รวมงบประจำปี",
      sum.annual.formatFull(),
      "",
      "",
    ])
    this.worksheet.addRow([
      "",
      "",
      "",
      "",
      "รวมงบกองทุน",
      sum.foundation.formatFull(),
      "",
      "",
    ])
    this.worksheet.addRow([
      "",
      "",
      "",
      "",
      "รวมงบสะสม",
      sum.collected.formatFull(),
      "",
      "",
    ])
    this.worksheet.addRow([
      "",
      "",
      "",
      "",
      "รวมงบส่วนกลาง",
      sum.central.formatFull(),
      "",
      "",
    ])
    this.worksheet.addRow([
      "",
      "",
      "",
      "",
      "รวมงบรายได้",
      sum.income.formatFull(),
      "",
      "",
    ])
    this.worksheet.addRow([
      "",
      "",
      "",
      "",
      "รวมงบประมาณแผ่นดิน",
      sum.country.formatFull(),
      "",
      "",
    ])
    this.worksheet.addRow([
      "",
      "",
      "",
      "",
      "รวมทั้งสิ้น",
      sum.total.formatFull(),
      "",
      "",
    ])
    this.worksheet.addRow([])
    this.worksheet.addRow([])
    this.worksheet.addRow([])
    this.worksheet.addRow([])
    this.worksheet.addRow(["", (".").repeat(60) + "ลงชื่อ"])
    this.worksheet.addRow(["", "        ชาญยุทธ คำหวาน       "])
    let current_row = 2
    let row = this.worksheet.getRow(current_row++)
    this.workbook.xlsx.writeBuffer().then(function (data: any) {
      var blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, "ReportA01.xlsx");
    });
  }
  async createA02(docs: Array<any>, budgetYear: Number) {
    //header
    this.worksheet.addRow([`การรายงานผลใแต่ละกิจกรรมที่สอดคล้องกับตัวชี้วัด หรือความเสี่ยง`])
    this.worksheet.getCell("A1").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.mergeCells('A1:I1')
    this.worksheet.addRow([`ประจำปีงบประมาณ พ.ศ. ${budgetYear}`])
    this.worksheet.getCell("A2").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.mergeCells('A2:I2')
    this.worksheet.getCell("A3").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("A3").value = `ลำดับ`
    this.worksheet.getCell("B3").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("B3").value = `ตัวชี้วัด`
    this.worksheet.getCell("C3").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("C3").value = `รายการ`
    this.worksheet.getCell("D3").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("D3").value = `ข้อการดำเนินงาน`
    this.worksheet.getCell("E3").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("E3").value = `ผลการดำเนินงาน`
    this.worksheet.getCell("F3").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("F3").value = `ผู้รับผิดชอบ`
    this.worksheet.getCell("G3").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("G3").value = `งบประมาณ`
    this.worksheet.getCell("H3").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("H3").value = `แหล่งงบประมาณ`
    this.worksheet.getCell("I3").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("I3").value = `ฝ่ายและสำนักที่รับผิดชอบ`
    //width
    this.worksheet.getColumn("A").width = 10
    this.worksheet.getColumn("B").width = 40
    this.worksheet.getColumn("C").width = 30
    this.worksheet.getColumn("D").width = 30
    this.worksheet.getColumn("E").width = 20
    this.worksheet.getColumn("F").width = 20
    this.worksheet.getColumn("G").width = 20
    this.worksheet.getColumn("H").width = 20
    this.worksheet.getColumn("I").width = 20
    docs.forEach((doc: any, i: number) => {
      doc.items.forEach((item: any) => {
        this.worksheet.addRow([
          `${i + 1}`,
          `${doc.name}`,
          `${item.code}` + "" + `${item.name}`,
          `${item.number}` + "" + `${item.subject}`,
          ``,
          `${item.responsible}`,
          `${(item.sum ?? 0).formatFull()}`,
          `${(item.type ?? ",").split(",")[0]}`,
          `${item.sector}`,
        ])
      })
    });
    this.worksheet.eachRow({ includeEmpty: true }, (row, rn) => {
      if (rn >= 3) {
        row.eachCell({ includeEmpty: true }, (cell, cn) => {
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
          };
        })
      }
    })
    this.worksheet.addRow([])
    this.worksheet.addRow([])
    this.worksheet.addRow([])
    this.worksheet.addRow([])
    this.worksheet.addRow(["", (".").repeat(60) + "ลงชื่อ"])
    this.worksheet.addRow(["", "        ชาญยุทธ คำหวาน       "])
    let current_row = 2
    let row = this.worksheet.getRow(current_row++)
    this.workbook.xlsx.writeBuffer().then(function (data: any) {
      var blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, "ReportA02.xlsx");
    });
  }
  async createA03(docs: Array<any>, budgetYear: Number) {
    //header
    this.worksheet.addRow([`รายละเอียดการปรับรายการเบิก/ยืม`])
    this.worksheet.getCell("A1").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.mergeCells('A1:H1')
    this.worksheet.addRow([`ประจำปีงบประมาณ พ.ศ. ${budgetYear}`])
    this.worksheet.getCell("A2").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.mergeCells('A2:H2')
    this.worksheet.getCell("A3").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("A3").value = `ลำดับ`
    this.worksheet.getCell("B3").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("B3").value = `เลขที่SMIS`
    this.worksheet.getCell("C3").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("C3").value = `จำนวนเงิน`
    this.worksheet.getCell("D3").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("D3").value = `วันที่ทำรายการ (วัน/เดือน/ปี)เป็น พ.ศ.`
    this.worksheet.getCell("E3").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("E3").value = `รหัสข้อการดำเนินงาน(bdgltrmID)`
    this.worksheet.getCell("F3").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("F3").value = `รหัสข้อการดำเนินงาน (bdgID)`
    this.worksheet.getCell("G3").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("G3").value = `สาเหตุที่ดำเนินการลบเลข SMIS`
    this.worksheet.getCell("H3").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("H3").value = `ผู้ดำเนินการลบ`
    //width
    this.worksheet.getColumn("A").width = 10
    this.worksheet.getColumn("B").width = 40
    this.worksheet.getColumn("C").width = 30
    this.worksheet.getColumn("D").width = 30
    this.worksheet.getColumn("E").width = 20
    this.worksheet.getColumn("F").width = 20
    this.worksheet.getColumn("G").width = 20
    this.worksheet.getColumn("H").width = 20
    // this.worksheet.getColumn("I").width = 20

    docs.forEach((doc: any, i: number) => {
      this.worksheet.addRow([
        `${i + 1}`,
        `${doc.number}`,
        `${(doc.value ?? 0).formatFull()}`,
        `${(new Date(doc.createdAt).toThaiShort())}`,
        `${doc.budgetItem}`,
        `${(doc.budget ?? 0).formatFull()}`,
        `${doc.reason}`,
        `${doc.deleteBy}`,
      ])
    });
    this.worksheet.eachRow({ includeEmpty: true }, (row, rn) => {
      if (rn >= 3) {
        row.eachCell({ includeEmpty: true }, (cell, cn) => {
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
          };
        })
      }
    })
    this.worksheet.addRow([])
    this.worksheet.addRow([])
    this.worksheet.addRow([])
    this.worksheet.addRow([])
    this.worksheet.addRow(["", (".").repeat(60) + "ลงชื่อ"])
    this.worksheet.addRow(["", "        ชาญยุทธ คำหวาน       "])
    let current_row = 2
    let row = this.worksheet.getRow(current_row++)
    this.workbook.xlsx.writeBuffer().then(function (data: any) {
      var blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, "ReportA03.xlsx");
    });
  }
  async createA05(docs: Array<any>, budgetYear: Number) {
    //header
    this.worksheet.addRow([`รายงานสรุปแผนและผลการเบิกจ่ายโดยแยกประเภทงบประมาณ และแหล่งงบประมาณ\r\nประจำปีงบประมาณ พ.ศ. ${budgetYear}`])
    this.worksheet.getCell("A1").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.mergeCells('A1:I1')
    this.worksheet.getCell("A2").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("A2").value = `ลำดับ`
    this.worksheet.getCell("B2").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("B2").value = `รายการที่`
    this.worksheet.getCell("C2").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("C2").value = `ฝ่ายและสำนักที่รับผิดชอบ`
    this.worksheet.getCell("D2").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("D2").value = `แหล่งงบประมาณ`
    this.worksheet.getCell("E2").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("E2").value = `งบประมาณ`
    this.worksheet.mergeCells('E2:F2')
    this.worksheet.getCell("G2").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("G2").value = `ผลการเบิกจ่าย`
    this.worksheet.getCell("H2").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("H2").value = `งบประมาณคงเหลือ`
    this.worksheet.getCell("I2").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("I2").value = `หมายเหตุ`
    this.worksheet.mergeCells('A2:A3')
    this.worksheet.mergeCells('B2:B3')
    this.worksheet.mergeCells('C2:C3')
    this.worksheet.mergeCells('D2:D3')
    this.worksheet.mergeCells('G2:G3')
    this.worksheet.mergeCells('H2:H3')
    this.worksheet.mergeCells('I2:I3')
    this.worksheet.getCell("E3").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("E3").value = `งบอุดหนุน`
    this.worksheet.getCell("F3").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("F3").value = `งบลงทุน`
    //width
    this.worksheet.getColumn("A").width = 10
    this.worksheet.getColumn("B").width = 40
    this.worksheet.getColumn("C").width = 30
    this.worksheet.getColumn("D").width = 30
    this.worksheet.getColumn("E").width = 20
    this.worksheet.getColumn("F").width = 20
    this.worksheet.getColumn("G").width = 20
    this.worksheet.getColumn("H").width = 20
    this.worksheet.getColumn("I").width = 20

    let sum1 = 0
    let sum2 = 0
    let sum3 = 0
    let sum4 = 0
    let sum5 = 0

    docs.forEach((el: any, i: number) => {
      this.worksheet.addRow([i + 1, `${el.code} ${el.name}`, el.sector ?? "-", el.source ?? "-"
        , el.investmentBudget ?? 0
        , el.subsidyBudget ?? 0
        , el.disbursement ?? 0
        , el.remainBudget ?? 0
        , el.reason ?? "-"]);
      sum1 += el.investmentBudget
      sum2 += el.subsidyBudget
      sum3 += el.disbursement
      sum4 += el.remainBudget
      sum5 = sum1 + sum2
    });

    let lastRow = 1

    this.worksheet.eachRow({ includeEmpty: true }, (row, rn) => {
      if (rn >= 2) {
        row.eachCell({ includeEmpty: true }, (cell, cn) => {
          if (cn >= 5 && cn <= 8) row.getCell(cn).numFmt = '#,##0.00;[Red]-#,##0.00';
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
          };
        })
      }
      lastRow = rn
    })
    this.worksheet.addRow([null, null, null, "รวม", sum1, sum2, sum3, sum4, null])
    this.worksheet.addRow([null, null, null, "รวมทั้งสิ้น", sum5])

    this.worksheet.eachRow({ includeEmpty: true }, (row, rn) => {
      if (rn >= lastRow) {
        row.eachCell({ includeEmpty: true }, (cell, cn) => {
          if (cn >= 5 && cn <= 8) row.getCell(cn).numFmt = '#,##0.00;[Red]-#,##0.00';
          if (cn >= 5 && cn <= 8) {
            cell.border = {
              top: { style: 'thin' },
              left: { style: 'thin' },
              bottom: { style: 'thin' },
              right: { style: 'thin' }
            };
          }
        })
      }
      lastRow = rn
    })
    this.worksheet.mergeCells(lastRow, 5, lastRow, 6)
    this.worksheet.addRow([])
    this.worksheet.addRow([])
    this.worksheet.addRow([])
    this.worksheet.addRow([])
    this.worksheet.addRow(["", (".").repeat(60) + "ลงชื่อ"])
    this.worksheet.addRow(["", "        ชาญยุทธ คำหวาน       "])
    let current_row = 2
    let row = this.worksheet.getRow(current_row++)
    this.workbook.xlsx.writeBuffer().then(function (data: any) {
      var blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, "ReportA05.xlsx");
    });
  }
  async createA04(docs: Array<any>, budgetYear: Number) {
    //header
    this.worksheet.addRow([`รายละเอียดกิจกรรมประจมาณที่ต้องการจากแหล่งเงินและแผนการปฏิบัติงานฯ`])
    this.worksheet.getCell("A1").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.mergeCells('A1:I1')
    this.worksheet.addRow([`ประจำปีงบประมาณ พ.ศ. ${budgetYear}`])
    this.worksheet.getCell("A2").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.mergeCells('A2:I2')
    this.worksheet.getCell("A3").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("A3").value = `ลำดับ`
    this.worksheet.mergeCells('A3:A4')
    this.worksheet.getCell("B3").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("B3").value = `กิจกรรม / ข้อการดำเนินงาน`
    this.worksheet.mergeCells('B3:B4')
    this.worksheet.getCell("C3").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("C3").value = `ผู้รับผิดชอบ`
    this.worksheet.mergeCells('C3:C4')
    this.worksheet.getCell("D3").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("D3").value = `งบประมาณ`
    this.worksheet.mergeCells('D3:E3')
    this.worksheet.getCell("D4").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("D4").value = `งบอุดหนุน`
    this.worksheet.getCell("E4").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("E4").value = `งบลงทุน`
    this.worksheet.getCell("F3").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("F3").value = `แหล่งงบประมาณ`
    this.worksheet.mergeCells('F3:F4')
    this.worksheet.getCell("G3").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("G3").value = `รายการที่`
    this.worksheet.mergeCells('G3:G4')
    this.worksheet.getCell("H3").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("H3").value = `ฝ่ายและสำนักที่รับผิดชอบ`
    this.worksheet.mergeCells('H3:H4')
    this.worksheet.getCell("I3").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("I3").value = `หมายเหตุ`
    this.worksheet.mergeCells('I3:I4')
    //width
    this.worksheet.getColumn("A").width = 10
    this.worksheet.getColumn("B").width = 40
    this.worksheet.getColumn("C").width = 30
    this.worksheet.getColumn("D").width = 30
    this.worksheet.getColumn("E").width = 20
    this.worksheet.getColumn("F").width = 20
    this.worksheet.getColumn("G").width = 20
    this.worksheet.getColumn("H").width = 20
    this.worksheet.getColumn("I").width = 20
    docs.forEach((doc: any, i: number) => {
      this.worksheet.addRow([
        `${i + 1}`,
        `${doc.number}` + "" + `${doc.subject}`,
        `${doc.responsible}`,
        `${(doc.type) === 'งบอุดหนุน' ? (doc.value ?? 0).formatFull() : ""}`,
        `${(doc.type) !== 'งบอุดหนุน' ? (doc.value ?? 0).formatFull() : ""}`,
        `${doc.source ?? ""}`,
        `${doc.projectCode}` + "" + `${doc.project}`,
        `${doc.sector}`,
        ``,
      ])
    });
    this.worksheet.eachRow({ includeEmpty: true }, (row, rn) => {
      if (rn >= 3) {
        row.eachCell({ includeEmpty: true }, (cell, cn) => {
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
          };
        })
      }
    })
    this.worksheet.addRow([])
    this.worksheet.addRow([])
    this.worksheet.addRow([])
    this.worksheet.addRow([])
    this.worksheet.addRow(["", (".").repeat(60) + "ลงชื่อ"])
    this.worksheet.addRow(["", "        ชาญยุทธ คำหวาน       "])
    let current_row = 2
    let row = this.worksheet.getRow(current_row++)
    this.workbook.xlsx.writeBuffer().then(function (data: any) {
      var blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, "ReportA04.xlsx");
    });
  }


  async createA07(docs: Array<any>, budgetYear: number, sumtotal: Array<any>, budgetType: string = "แหล่งงบประมาณประจำปี"): Promise<any> {
    let sums = sumtotal ?? []
    //header
    this.worksheet.addRow([`รายงานแผนเทียบกับผลการเบิกจ่ายโดยแยกเป็นรายฝ่ายและสำนักของ กกท. (มีร้อยละ) และสามารถเปรียบเทียบการแสดงผลระหว่างระหว่างฝ่ายและสำนัก`])
    this.worksheet.addRow([`แหล่งงบประมาณ : ${budgetType}`])
    this.worksheet.addRow([sums[0].formatFull()])
    this.worksheet.addRow([`ประจำปีงบประมาณ พ.ศ. ${budgetYear}`])
    this.worksheet.getCell("A1").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("A2").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("A3").alignment = { vertical: 'middle', horizontal: 'center' }

    this.worksheet.addRow(["ลำดับ", "รายการที่", "แหล่งงบประมาณ", "งบประมาณ", "", "", "", "ผลการเบิกจ่าย", "", "งบประมาณคงเหลือ", "คงเหลือคิดเป็น\r\nร้อยละ", "ผลการเบิกจ่าย\r\nคิดเป็นร้อยละ\r\nของภาพรวม\r\n(งบประจำปี)"])
    this.worksheet.addRow(["", "", "", "รวม\r\n(อุดหนุน + ลงทุน)", "งบอุดหนุน", "งบลงทุน", "ร้อยละ", "งบประมาณ", "ร้อยละ", "", ""])
    this.worksheet.addRow(["", "รวมทั้งสิ้น", "", ...sums.map(s => ((s ?? 0)))])
    this.worksheet.mergeCells('A1:L1')
    this.worksheet.mergeCells('A2:L2')
    this.worksheet.mergeCells('A3:L3')
    this.worksheet.mergeCells('A4:L4')

    this.worksheet.getColumn("A").width = 10
    this.worksheet.getColumn("B").width = 75
    this.worksheet.getColumn("C").width = 25
    this.worksheet.getColumn("D").width = 17
    this.worksheet.getColumn("E").width = 17
    this.worksheet.getColumn("F").width = 17
    this.worksheet.getColumn("G").width = 17
    this.worksheet.getColumn("H").width = 17
    this.worksheet.getColumn("I").width = 17
    this.worksheet.getColumn("J").width = 17
    this.worksheet.getColumn("K").width = 17
    this.worksheet.getColumn("L").width = 17

    this.worksheet.mergeCells('A5:A6')
    this.worksheet.mergeCells('B5:B6')
    this.worksheet.mergeCells('C5:C6')
    this.worksheet.mergeCells('D5:G5')
    this.worksheet.mergeCells('H5:I5')
    this.worksheet.mergeCells('J5:J6')
    this.worksheet.mergeCells('K5:K6')
    this.worksheet.mergeCells('L5:L6')

    this.worksheet.getCell("L5").alignment = {
      wrapText: true
    }
    docs.forEach((el: any, i: number) => {
      this.worksheet.addRow(["", el.sector, "",
        (el.sumSector ?? 0),
        (el.sumBudget1 ?? 0),
        (el.sumBudget2 ?? 0),
        (el.sumPercentage ?? 0),
        (el.sumErp ?? 0),
        (el.sumPercentageErp ?? 0),
        (el.sumRemain ?? 0),
        (el.sumPercentageRemain ?? 0),
        (((el.sumErp ?? 0) / sums[0]) * 100)
      ])
      el.projects.forEach((pj: any, pji: number) => {
        this.worksheet.addRow([pji + 1, `${pj.code} ${pj.name}`, pj.source,
        (pj.sumBudget ?? 0),
        (pj.budget1 ?? 0),
        (pj.budget2 ?? 0),
        (pj.percentage ?? 0),
        (pj.sumErp ?? 0),
        (pj.percentageErp ?? 0),
        (pj.remaining ?? 0),
        (pj.percentageRemain ?? 0),
        (pj.percentageRemain ?? 0),
        ])
      })
    })

    this.worksheet.eachRow({ includeEmpty: true }, (row, rn) => {
      if (rn <= 6) {
        row.eachCell({ includeEmpty: true }, (cell, cn) => {
          cell.alignment = {
            vertical: 'middle',
            horizontal: 'center'
          }
        })
      }
      if (rn > 4) {
        row.eachCell({ includeEmpty: true }, (cell, cn) => {
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
          };
          if (cn >= 4) {
            cell.alignment = {
              vertical: 'middle',
              horizontal: 'right'
            }
            cell.numFmt = '#,##0.00;[Red]-$#,##0.00;';
          }
        })
      }
    })
    this.worksheet.addRow([])
    this.worksheet.addRow([])
    this.worksheet.addRow([])
    this.worksheet.addRow([])
    this.worksheet.addRow(["", (".").repeat(60) + "ลงชื่อ"])
    this.worksheet.addRow(["", "        ชาญยุทธ คำหวาน       "])

    this.workbook.xlsx.writeBuffer().then(function (data: any) {
      var blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, "ReportA07.xlsx");
    });
  }
  async createA10(flatted: Array<any>, budgetYear: Number) {
    //header
    this.worksheet.addRow([`แผนการปฏิบัติงานและแผนการใช้จ่ายงบประะมาณ ประจำปีงบประมาณ พ.ศ. ${budgetYear}`])
    this.worksheet.getCell("A1").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.mergeCells('A1:L1')
    // this.worksheet.addRow([`ประจำปีงบประมาณ พ.ศ. ${budgetYear}`])
    // this.worksheet.getCell("A2").alignment = { vertical: 'middle', horizontal: 'center' }
    // this.worksheet.mergeCells('A2:I2')
    this.worksheet.getCell("A2").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("A2").value = `ผลผลิต / กิจกรรม / รายการ`
    this.worksheet.mergeCells('A2:C3')
    this.worksheet.getCell("D2").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("D2").value = `ฝ่าย / สำนัก`
    this.worksheet.mergeCells('D2:D3')
    this.worksheet.getCell("E2").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("E2").value = `ไตรมาส 1`
    this.worksheet.mergeCells('E2:E3')
    this.worksheet.getCell("F2").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("F2").value = `ไตรมาส 2`
    this.worksheet.mergeCells('F2:F3')
    this.worksheet.getCell("G2").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("G2").value = `ไตรมาส 3`
    this.worksheet.mergeCells('G2:G3')
    this.worksheet.getCell("H2").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("H2").value = `ไตรมาส 4`
    this.worksheet.mergeCells('H2:H3')
    this.worksheet.getCell("I2").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("I2").value = `งบประมาณได้รับจัดสรร`
    this.worksheet.mergeCells('I2:I3')
    this.worksheet.getCell("J2").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("J2").value = `งบประมาณกองทุนฯ`
    this.worksheet.mergeCells('J2:J3')
    this.worksheet.getCell("K2").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("K2").value = `เงินสะสมของ กกท.`
    this.worksheet.mergeCells('K2:K3')
    this.worksheet.getCell("L2").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("L2").value = `หน้า`
    this.worksheet.mergeCells('L2:L3')
    //width
    this.worksheet.getColumn("A").width = 10
    this.worksheet.getColumn("B").width = 10
    this.worksheet.getColumn("C").width = 30
    this.worksheet.getColumn("D").width = 30
    this.worksheet.getColumn("E").width = 20
    this.worksheet.getColumn("F").width = 20
    this.worksheet.getColumn("G").width = 20
    this.worksheet.getColumn("H").width = 40
    this.worksheet.getColumn("I").width = 40
    this.worksheet.getColumn("J").width = 40
    this.worksheet.getColumn("K").width = 40
    this.worksheet.getColumn("L").width = 40
    flatted.forEach((doc: any, i: number) => {
      let startText = ""
      this.worksheet.addRow([
        i==0?"\t\t\tรวมงบประมาณทั้งสื้น":doc.isProject?'รายการ':doc.name,
        doc.code??null,
        doc.isProject?`${doc.name}`:null,
        `${doc.sector??""}`,
        doc.quarter[0],
        doc.quarter[1],
        doc.quarter[2],
        doc.quarter[3],
        doc.quarter[0]+doc.quarter[1]+doc.quarter[2]+doc.quarter[3],
        ``,
        ``,
        ``,
        doc.pages
      ])
      let lastRow2 = this.worksheet.rowCount
      if(!doc.isProject){
        this.worksheet.mergeCells(lastRow2,1,lastRow2,3)
      }
    });
    this.worksheet.eachRow({ includeEmpty: true }, (row, rn) => {
      if (rn >= 3) {
        row.eachCell({ includeEmpty: true }, (cell, cn) => {
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
          };
          cell.numFmt = '#,##0.00;[Red]-$#,##0.00;-';
        })
        
      }
    })
    this.worksheet.addRow([])
    this.worksheet.addRow([])
    this.worksheet.addRow([])
    this.worksheet.addRow([])
    this.worksheet.addRow(["", (".").repeat(60) + "ลงชื่อ"])
    this.worksheet.addRow(["", "        ชาญยุทธ คำหวาน       "])
    let current_row = 2
    let row = this.worksheet.getRow(current_row++)
    this.workbook.xlsx.writeBuffer().then(function (data: any) {
      var blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, "ReportA10.xlsx");
    });
  }
  createA08(docs: Array<any>, budgetYear: number = 2565) {
    let data: Array<any> = []
    let sum1 = 0
    let sum2 = 0
    let sum3 = 0
    let sum4 = 0
    let sum5 = 0
    let sum6 = 0
    let sum7 = 0
    let sum8 = 0
    this.worksheet.addRow([`รายงานค่าใช้จ่ายในประเภทต่างๆที่อยู่ในหน้าแผนฯ โดยสามารถรวมและแยกเป็นฝ่ายและสำนักต่างๆ`])
    this.worksheet.addRow(['การจ้างผู้ช่วยปฏิบัติงานฯ'])
    this.worksheet.addRow([`ประจำปีงบประมาณ พ.ศ. ${budgetYear}`])
    this.worksheet.addRow([""])
    this.worksheet.mergeCells('A1:M1')
    this.worksheet.mergeCells('A2:M2')
    this.worksheet.mergeCells('A3:M3')
    this.worksheet.getCell("A1").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("A2").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("A3").alignment = { vertical: 'middle', horizontal: 'center' }

    this.worksheet.addRow(["ลำดับ", "รายการที่", "ฝ่ายและสำนักที่รับผิดชอบ", "แหล่งงบประมาณ", "วุฒิการศึกษา (จำนวนคน)", "", "", "", "จำนวนงบประมาณ", "", "", "", "หมายเหตุ"])
    this.worksheet.addRow(["", "", "", "", "ป.ตรี", "ป.โท", "อื่นๆ", "รวม", "ป.ตรี", "ป.โท", "อื่นๆ", "รวม"])
    this.worksheet.mergeCells("A5:A6")
    this.worksheet.mergeCells("B5:B6")
    this.worksheet.mergeCells("C5:C6")
    this.worksheet.mergeCells("D5:D6")
    this.worksheet.mergeCells("E5:H5")
    this.worksheet.mergeCells("I5:L5")
    this.worksheet.mergeCells("M5:M6")

    this.worksheet.getColumn("A").width = 5
    this.worksheet.getColumn("B").width = 75
    this.worksheet.getColumn("C").width = 25
    this.worksheet.getColumn("D").width = 17
    this.worksheet.getColumn("E").width = 17
    this.worksheet.getColumn("F").width = 17
    this.worksheet.getColumn("G").width = 17
    this.worksheet.getColumn("H").width = 17
    this.worksheet.getColumn("I").width = 17
    this.worksheet.getColumn("J").width = 17
    this.worksheet.getColumn("K").width = 17
    this.worksheet.getColumn("L").width = 17
    docs.forEach((el: any, i: number) => {
      this.worksheet.addRow([
        i + 1,
        `${el.code} ${el.name}`,
        `${el.sector}`,
        `${el.source}`,
        el.bachelor ?? 0,
        el.master ?? 0,
        el.other ?? 0,
        ((el.bachelor ?? 0) + (el.master ?? 0) + (el.other ?? 0)),
        el.bachelorAmount ?? 0,
        el.masterAmount ?? 0,
        el.otherAmount ?? 0,
        ((el.bachelorAmount ?? 0) + (el.masterAmount ?? 0) + (el.otherAmount ?? 0)),
        el.reason ?? ""
      ])
      sum1 += el.bachelor
      sum2 += el.master
      sum3 += el.other
      sum4 += el.bachelor + el.master + el.other;
      sum5 += el.bachelorAmount
      sum6 += el.masterAmount
      sum7 += el.otherAmount
      sum8 += el.bachelorAmount + el.masterAmount + el.otherAmount;
    });


    this.worksheet.addRow([null,null,null,"รวม",sum1,sum2,sum3,sum4,sum5,sum6,sum7,sum8, ""])

    this.worksheet.eachRow({ includeEmpty: true }, (row, rn) => {
      if (rn <= 5) {
        row.eachCell({ includeEmpty: true }, (cell, cn) => {
          cell.alignment = {
            vertical: 'middle',
            horizontal: 'center'
          }
        })
      }
      if (rn > 4) {
        row.eachCell({ includeEmpty: true }, (cell, cn) => {
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
          };
          if (cn >= 5 && cn <= 8) {
            if (rn == 5 || rn == 6) {
              cell.alignment = {
                vertical: 'middle',
                horizontal: 'center'
              }
            } else {

              cell.alignment = {
                vertical: 'middle',
                horizontal: 'right'
              }
            }
            cell.numFmt = '#,##0;[Red]-$#,##0;-';
          }
          if (cn >= 9 && cn <= 12) {
            cell.alignment = {
              vertical: 'middle',
              horizontal: 'right'
            }
            cell.numFmt = '#,##0.00;[Red]-$#,##0.00;-';
          }
        })
      }
    })
    this.worksheet.addRow([])
    this.worksheet.addRow([])
    this.worksheet.addRow([])
    this.worksheet.addRow([])
    this.worksheet.addRow(["", (".").repeat(60) + "ลงชื่อ"])
    this.worksheet.addRow(["", "        ชาญยุทธ คำหวาน       "]) 

    this.workbook.xlsx.writeBuffer().then(function (data: any) {
      var blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, "ReportA08.xlsx");
    });
  }
  async createPT(budgetYear: number) {
    //headerหน่วยงาน / ส่วนราชการเจ้าของงบประมาณ  
    this.worksheet.getCell("A1").alignment = { vertical: 'middle' }
    this.worksheet.getCell("A1").value = `แผนการเบิกจ่ายเงินงบประมาณงบเงินอุดหนุน  ประเภทเงินอุดหนุนทั่วไป พ.ศ.  ${budgetYear}`
    this.worksheet.mergeCells('A1:M1')
    //
    this.worksheet.getCell("A2").alignment = { vertical: 'middle' }
    this.worksheet.getCell("A2").value = `หน่วยงาน/ส่วนราชการเจ้าของงบประมาณ`
    this.worksheet.mergeCells('A2:C2')
    this.worksheet.getCell("D2").alignment = { vertical: 'middle' }
    this.worksheet.getCell("D2").value = `การกีฬาแห่งประเทศไทย`
    this.worksheet.mergeCells('D2:F2')
    this.worksheet.getCell("G2").alignment = { vertical: 'middle' }
    this.worksheet.getCell("G2").value = `องค์กรที่ได้รับเงินอุดหนุน`
    this.worksheet.mergeCells('G2:H2')
    this.worksheet.getCell("I2").alignment = { vertical: 'middle' }
    this.worksheet.getCell("I2").value = `การกีฬาแห่งประเทศไทย`
    this.worksheet.mergeCells('I2:K2')
    //
    this.worksheet.getCell("A3").alignment = { vertical: 'middle' }
    this.worksheet.getCell("A3").value = `รหัสหน่วยงาน`
    this.worksheet.getCell("B3").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("B3").value = `50604`
    this.worksheet.getCell("C3").alignment = { vertical: 'middle' }
    this.worksheet.getCell("C3").value = `ลักษณะงาน`
    this.worksheet.getCell("D3").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("D3").value = `081011`
    this.worksheet.getCell("E3").alignment = { vertical: 'middle' }
    this.worksheet.getCell("E3").value = `ประเภทผลผลิต/ โครงการ`
    this.worksheet.mergeCells('E3:F3')
    this.worksheet.getCell("G3").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("G3").value = `1`
    this.worksheet.getCell("H3").alignment = { vertical: 'middle', horizontal: 'right' }
    this.worksheet.getCell("H3").value = `บัญชี`
    this.worksheet.getCell("I3").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("I3").value = `35006`
    this.worksheet.getCell("J3").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("J3").value = `งบเงินอุดหนุน`
    //
    this.worksheet.getCell("A4").alignment = { vertical: 'middle' }
    this.worksheet.getCell("A4").value = `แหล่งงบประมาณ`
    this.worksheet.getCell("B4").alignment = { vertical: 'middle' }
    this.worksheet.getCell("B4").value = `ยุทธศาสตร์เสริมสร้างศักยภาพการกีฬา`
    this.worksheet.mergeCells('B4:M4')
    //
    this.worksheet.getCell("A5").alignment = { vertical: 'middle' }
    this.worksheet.getCell("A5").value = `ผลผลิต/โครงการ`
    this.worksheet.getCell("B5").alignment = { vertical: 'middle' }
    this.worksheet.getCell("B5").value = `ผลผลิตที่ 1`
    this.worksheet.getCell("C5").alignment = { vertical: 'middle' }
    this.worksheet.getCell("C5").value = `การบริหารจัดการกีฬาเพื่อความเป็นเลิศ`
    this.worksheet.mergeCells('C5:M5')
    //
    this.worksheet.getCell("A6").alignment = { vertical: 'middle' }
    this.worksheet.getCell("A6").value = `กิจกรรมที่ 1`
    this.worksheet.getCell("B6").alignment = { vertical: 'middle' }
    this.worksheet.getCell("B6").value = `พัฒนานักกีฬาเพื่อความเป็นเลิศ`
    this.worksheet.mergeCells('B6:H6')
    this.worksheet.getCell("I6").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("I6").value = `รหัสงบประมาณ`
    this.worksheet.getCell("J6").alignment = { vertical: 'middle' }
    this.worksheet.getCell("J6").value = `5005050505050`
    this.worksheet.getCell("L6").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("L6").value = `รหัสผลผลิต`
    this.worksheet.getCell("M6").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("M6").value = `5005050505050`
    //
    this.worksheet.getCell("A7").alignment = { vertical: 'middle' }
    this.worksheet.getCell("A7").value = `รายการที่ 101/01`
    this.worksheet.getCell("B7").alignment = { vertical: 'middle' }
    this.worksheet.getCell("B7").value = `การส่งเสริมสนับสนุน`
    this.worksheet.mergeCells('B7:H7')
    this.worksheet.getCell("I7").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("I7").value = `รหัสกิจกรรมหลัก`
    this.worksheet.getCell("J7").alignment = { vertical: 'middle' }
    this.worksheet.getCell("J7").value = `5005050505050`
    this.worksheet.getCell("L7").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("L7").value = `รหัสบัญชีย่อย`
    this.worksheet.getCell("M7").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("M7").value = `5005050505050`
    //
    this.worksheet.addRow([""])
    this.worksheet.getCell("A9").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("A9").value = `วงเงินงบประมาณ`
    this.worksheet.mergeCells('A9:A10')
    this.worksheet.getCell("B9").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("B9").value = `ไตรมาสที่ 1`
    this.worksheet.mergeCells('B9:D9')
    this.worksheet.getCell("E9").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("E9").value = `ไตรมาสที่ 2`
    this.worksheet.mergeCells('E9:G9')
    this.worksheet.getCell("H9").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("H9").value = `ไตรมาสที่ 3`
    this.worksheet.mergeCells('H9:J9')
    this.worksheet.getCell("K9").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("K9").value = `ไตรมาสที่ 4`
    this.worksheet.mergeCells('K9:M9')
    this.worksheet.getCell("B10").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("B10").value = `ตุลาคม ${budgetYear-1}`
    this.worksheet.getCell("C10").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("C10").value = `พฤศจิกายน ${budgetYear-1}`
    this.worksheet.getCell("D10").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("D10").value = `ธันวาคม ${budgetYear-1}`
    this.worksheet.getCell("E10").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("E10").value = `มกราคม ${budgetYear}`
    this.worksheet.getCell("F10").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("F10").value = `กุมภาพันธ์ ${budgetYear}`
    this.worksheet.getCell("G10").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("G10").value = `มีนาคม ${budgetYear}`
    this.worksheet.getCell("H10").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("H10").value = `เมษายน ${budgetYear}`
    this.worksheet.getCell("I10").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("I10").value = `พฤษภาคม ${budgetYear}`
    this.worksheet.getCell("J10").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("J10").value = `มิถุนายน ${budgetYear}`
    this.worksheet.getCell("K10").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("K10").value = `กรกฎาคม ${budgetYear}`
    this.worksheet.getCell("L10").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("L10").value = `สิงหาคม ${budgetYear}`
    this.worksheet.getCell("M10").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("M10").value = `กันยายน ${budgetYear}`
    //width
    this.worksheet.getColumn("A").width = 18
    this.worksheet.getColumn("B").width = 16
    this.worksheet.getColumn("C").width = 16
    this.worksheet.getColumn("D").width = 16
    this.worksheet.getColumn("E").width = 16
    this.worksheet.getColumn("F").width = 16
    this.worksheet.getColumn("G").width = 16
    this.worksheet.getColumn("H").width = 16
    this.worksheet.getColumn("I").width = 16
    this.worksheet.getColumn("J").width = 16
    this.worksheet.getColumn("K").width = 16
    this.worksheet.getColumn("L").width = 16
    this.worksheet.getColumn("M").width = 16
    this.worksheet.addRow([""])
    this.worksheet.addRow([""])
    let lastRow2 = this.worksheet.rowCount
    this.worksheet.addRow([`หมายเหตุ`,(".").repeat(400),"","","","","","","","","","", ""])
    this.worksheet.addRow(["",(".").repeat(400),"","","","","","","","","","", ""])
    this.worksheet.mergeCells(lastRow2+1,2,lastRow2+1,13)
    this.worksheet.mergeCells(lastRow2+2,2,lastRow2+2,13) 
    this.worksheet.addRow([])
    this.worksheet.addRow([])
    this.worksheet.addRow([])
    this.worksheet.addRow([])
    this.worksheet.addRow(["","","","","","","","","", `ลงชื่อ`+(".").repeat(60),"","",""])
    this.worksheet.addRow(["","","","","","","","","", "(ชาญยุทธ คำหวาน)","","",""]) 
    this.worksheet.addRow(["","","","","","","","","", "หัวหน้างานพัฒนาและประสานแผน","","",""]) 
    this.worksheet.mergeCells(lastRow2+7,10,lastRow2+7,12)
    this.worksheet.mergeCells(lastRow2+8,10,lastRow2+8,12)
    this.worksheet.mergeCells(lastRow2+9,10,lastRow2+9,12)

    this.worksheet.eachRow({ includeEmpty: true }, (row, rn) => {
      if (rn > 8 && rn <12) {
        row.eachCell({ includeEmpty: true }, (cell, cn) => {
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
          };
        })
      }
    })
    this.workbook.xlsx.writeBuffer().then(function (data: any) {
      var blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, "ฎีกา.xlsx");
    });

  }
}
