import { RouteConfig } from "vue-router";
import SectorMain from "../../views/position/Main.vue";
import PositionList from "../../views/position/List.vue";
import PositionForm from "../../views/position/Form.vue";

const routes: Array<RouteConfig> = [
  {
    path: "position",
    component: SectorMain,
    meta:{
      "ac": "Setting,r"
    },
    children: [
      {
        path: "",
        name: "PositionList",
        component: PositionList,
        meta:{
          "ac": "Setting,r"
        },
      },
      {
        path: "form",
        name: "PositionForm",
        component: PositionForm,
        meta:{
          "ac": "Setting,r"
        },
      },
      {
        path: "edit/:id",
        name: "PositionEdit",
        component: PositionForm,
        meta:{
          "ac": "Setting,r"
        },
      },
    ],
  },
];

export default routes;
