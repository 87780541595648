import {  RouteConfig } from 'vue-router'

import StrategyMain from '../../views/strategy/Main.vue'
import StrategyList from '../../views/strategy/List.vue'
import StrategyForm from '../../views/strategy/Form.vue'
const routes: Array<RouteConfig> = [
  {
    path: 'strategy',
    component: StrategyMain,
    meta:{
      "ac": "KPI,r"
    },
    children: [
      {
        path: '',
        name: 'strategy List',
        component: StrategyList,
        meta:{
          "ac": "KPI,r"
        },
      },
      {
        path: 'form',
        name: 'strategy Form',
        component: StrategyForm,
        meta:{
          "ac": "KPI,r"
        },
      },
      {
        path: 'edit/:id',
        name: 'strategy Edit',
        component: StrategyForm,
        meta:{
          "ac": "KPI,r"
        },
      },
    ]
  },
]
export default routes