































































import { ADDRESS } from "@/setting";
import axios from "axios";
import { Component, Vue } from "vue-property-decorator";
import { DocumentDocx } from "@/document/docx/B";
@Component({
  components: {
  },
})
export default class List extends Vue {
  docs:Array<any> = []
  limit: number = 15;
  page: number = 1;
  pages: number = 0;
  total: number = 0;
  filter1: any = {}
  filter2: any = {}
  show = 25
  types:Array<any> = []
  sector:any = {}
  // sector:string = ""


  async createDocx(item:any) {
    const doc = new DocumentDocx();
    await doc.reportB01(item);
    doc.save("Report_B01.docx");
  }
  clearSearch(){
    this.filter1 = 0
    this.filter2 = ""
    this.fetchData()
  }
  getSector(id:string){
    try {
      let found = this.$store.state.sectors.find((sector:any)=>sector.id===id)
      return found.name
    } catch (error) {
      return "-"
    }
  }
  fetchData(){
    this.docs = []
    let query = {
      search: {
        ...this.filter1,
        ...this.filter2
      },
      limit: this.limit,
      skip: (this.page-1)*this.limit,
      sort: {
       createdAt:"desc"
      }
    }
    axios.post(`${ADDRESS}/indicators/paginate`,query).then((response) => {
      console.log(response)
      this.docs = response.data.docs;
      this.total = response.data.total;
      this.pages = Math.ceil(response.data.total / this.limit);
    });
  }
  deleteItem(item: any) {
    var c = confirm(`คุณแน่ใจหรือว่าต้องการลบรายการนี้`);
    if (c) {
      axios.delete(`${ADDRESS}/indicator/delete/${item.id}`).then((response) => {
        console.log(response)
        this.fetchData();
      });
    }
  }
  fetchKPIType(){
    axios.get(`${ADDRESS}/setting/get-by-name/ประเภทตัวชี้วัด`).then(response => {
      let data = response.data
      let member = []
      try {
        member = data.doc.member
      } catch (error) { }
      this.types = member
    })
  }
  mounted() {
    console.log(this.$route)
     this.filter1.budgetYear = (new Date().getFullYear() + 543) + ((new Date().getMonth()+1)>10?1:0)
    this.fetchKPIType()
    this.fetchData()
  }
}
