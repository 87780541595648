import {  RouteConfig } from 'vue-router'

import DebugMain from '../../views/debug/Main.vue'
import DebugLogin from '../../views/debug/Login.vue'
import DebugDocument from '../../views/debug/document/Main.vue'
import TableButtonMain from '../../views/debug/TableButtonMain.vue'
const routes: Array<RouteConfig> = [
  {
    path: '/portal/debug',
    component: DebugMain,
    children: [
      {
        path: 'document',
        name: 'Debug',
        component: DebugDocument
      },
      {
        path: 'login',
        name: 'DebugLogin',
        component: DebugLogin
      },
      {
        path: 'table',
        name: 'TableButtonMain',
        component: TableButtonMain
      },
    ]
  },
]
export default routes