










































import { Component, Vue, Watch } from "vue-property-decorator";
import axios from "axios";
import { ADDRESS } from "@/setting";
import { active } from "d3-transition";
@Component({
  props:{
    activeItem:Object,
    budget: Object,
  },
  components: {},
})
export default class PlanKPIModal extends Vue {
  activeItem!:any
  indicators:Array<any> = []
  budget!:any
  mounted(){
    this.fetchIndicatorsByBudget()
  }

  updateItem(){
    
  }

  pushIndicators() {
    let indicators: Array<any> = []
    if(this.activeItem.indicators!=undefined) indicators = this.activeItem.indicators
    if (!Array.isArray(indicators)) indicators = [];
    indicators.push("");
    this.$set(this.activeItem, "indicators", indicators);
  }
  @Watch('budget.budgetYear')
  fetchIndicatorsByBudget(){
    let  query = {
        search: {
          budgetYear : this.budget.budgetYear,
        },
        skip: 0,
        limit: 9999,
        sort:{
          code : "asc"
        }
      }
    axios.post(`${ADDRESS}/indicators/paginate`,query).then(response => {
      console.log('indicators',response)
      this.indicators = response.data.docs;
    })
  }
}
