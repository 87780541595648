import {  RouteConfig } from 'vue-router'
import BudgetAdjustMain from '../../views/budgetAdjust/Main.vue'
import BudgetAdjust from '../../views/budgetAdjust/List.vue'
import BudgetRequestList from '../../views/budgetAdjust/requestList.vue'
import BudgetRequestForm from '../../views/budgetAdjust/requestForm.vue'
import BudgetRequestApproval from '../../views/budgetAdjust/requestApprove.vue'

import BudgetAdjustList from '../../views/budgetAdjust/adjustList.vue'
import BudgetAdjustForm from '../../views/budgetAdjust/adjustForm.vue'
import BudgetAdjustHistory from '../../views/budgetAdjust/history.vue'
import BudgetAdjustApprove from '../../views/budgetAdjust/approve.vue'

const routes: Array<RouteConfig> = [
  {
    path: 'budget',
    // name: 'BudgetAdjustMain',
    component: BudgetAdjustMain,
    children: [
      {
        path: 'adjust',
        // name: 'BudgetAdjust',
        component: BudgetAdjust,
        meta:{
          "ac": "Adjust,r"
        },
      },
      {
        path: 'request/list/:project_id',
        // name: 'BudgetRequestList',
        component: BudgetRequestList,
        meta:{
          "ac": "Adjust,r"
        },
      },
      {
        path: 'request/form/:project_id',
        // name: 'BudgetRequestForm',
        component: BudgetRequestForm,
        meta:{
          "ac": "Adjust,r"
        },
      },
      {
        path: 'request/edit/:project_id/:id',
        // name: 'BudgetRequestForm',
        component: BudgetRequestForm,
        meta:{
          "ac": "Adjust,r"
        },
      },
      {
        path: 'request/approval/:project_id/:id',
        // name: 'BudgetRequestForm',
        component: BudgetRequestApproval,
        meta:{
          "ac": "Adjust,r"
        },
      },
      {
        path: 'adjust/list/:project_id',
        // name: 'BudgetAdjustList',
        component: BudgetAdjustList,
        meta:{
          "ac": "Adjust,r"
        },
      },
      {
        path: 'adjust/form/:project_id',
        // name: 'BudgetAdjustForm',
        component: BudgetAdjustForm,
        meta:{
          "ac": "Adjust,r"
        },
      },
      {
        path: 'adjust/edit/:project_id/:adjustid',
        // name: 'BudgetAdjustEdit',
        component: BudgetAdjustForm,
        meta:{
          "ac": "Adjust,r"
        },
      },
      {
        path: 'adjust/history/:project_id/',
        // name: 'BudgetAdjustHistory',
        component: BudgetAdjustHistory,
        meta:{
          "ac": "Adjust,r"
        },
      },
      {
        path: 'adjust/plan/:project_id/',
        // name: 'BudgetAdjustPlan',
        component: BudgetAdjustHistory,
        meta:{
          "ac": "Adjust,r"
        },
      },
      {
        path: 'adjust/approve/:project_id/:adjustid',
        // name: 'BudgetAdjustApprove',
        component: BudgetAdjustApprove,
        meta:{
          "ac": "Adjust,r"
        },
      },
    ]
  },
]
export default routes