




































import { Component, Vue } from 'vue-property-decorator';
import axios from "axios";
import { ADDRESS } from "@/setting";

@Component({
  components: {

  },
})
export default class positionList extends Vue {
  page: number = 1;
  pages: number = 0;
  limit: number = 15;
  total: number = 0;
  docs:Array<any> = []
  filter1:any = {}
  sort:any = {
    }
    searchString = ""
    address = ADDRESS

  fetchData(){
    this.docs = []
    let query = {
      skip: this.limit*(this.page-1),
      limit: this.limit,
      // search
    }
    axios.post(`${ADDRESS}/positions/paginate`,query).then((response) => {
      console.log(response)
      this.docs = response.data.docs;
      this.total = response.data.total;
      this.pages = Math.ceil(response.data.total / this.limit);
    });
  }
  mounted() {
    this.fetchData()
  }
  deleteItem(item: any) {
    var c = confirm(`คุณแน่ใจหรือว่าต้องการลบรายการนี้`);
    if (c) {
      axios.delete(`${ADDRESS}/position/delete/${item.id}`).then((response) => {
        console.log(response)
        this.fetchData();
      });
    }
  }
}
