import {  RouteConfig } from 'vue-router'

import IndicatorMain from '../../views/indicator/Main.vue'
import IndicatorList from '../../views/indicator/List.vue'
import IndicatorForm from '../../views/indicator/Form.vue'
import IndicatorReviewMain from '../../views/indicatorReview/Main.vue'
import IndicatorReviewList from '../../views/indicatorReview/List.vue'
import IndicatorReviewListReview from '../../views/indicatorReview/ListReview.vue'
import IndicatorReviewForm from '../../views/indicatorReview/Form.vue'
import ListKPI from '../../views/indicatorReview/ListKPI.vue'
import Copy from '../../views/process/Copy.vue'
const routes: Array<RouteConfig> = [
  {
    path: 'process/copy',
    name: 'Copy',
    component: Copy,
    meta:{
      "ac": "KPI,r"
    },
  },
  {
    path: 'kpi/:budgetYear/:sector',
    name: 'List KPI',
    component: ListKPI,
    meta:{
      "ac": "KPI,r"
    },
  },
  {
    path: 'indicator',
    component: IndicatorMain,
    meta:{
      "ac": "KPI,r"
    },
    children: [
      {
        path: '',
        name: 'Indicator List',
        component: IndicatorList,
        meta:{
          "ac": "KPI,r"
        },
      },
      {
        path: 'form',
        name: 'Indicator Form',
        component: IndicatorForm,
        meta:{
          "ac": "KPI,r"
        },
      },
      {
        path: 'edit/:id',
        name: 'Indicator Edit',
        component: IndicatorForm,
        meta:{
          "ac": "KPI,r"
        },
      },
    ]
  },
  {
    path: 'indicator-review',
    component: IndicatorReviewMain,
    meta:{
      "ac": "KPI,r"
    },
    children: [
      {
        path: '',
        name: 'IndicatorReview List',
        component: IndicatorReviewList,
        meta:{
          "ac": "KPI,r"
        },
      },
      {
        path: 'list/:id',
        name: 'IndicatorReviewListReview Form',
        component: IndicatorReviewListReview,
        meta:{
          "ac": "KPI,r"
        },
      },
      {
        path: 'form/:id',
        name: 'IndicatorReview Form',
        component: IndicatorReviewForm,
        meta:{
          "ac": "KPI,r"
        },
      },
      {
        path: 'edit/:indicatorid/:id',
        name: 'IndicatorReview Edit',
        component: IndicatorReviewForm,
        meta:{
          "ac": "KPI,r"
        },
      },
    ]
  }
]
export default routes