






























import { Component, Vue } from "vue-property-decorator";
import axios from "axios";
import { ADDRESS } from "@/setting";

@Component({
  components: {},
})
export default class Form extends Vue {
  doc: any = {};
  ADDRESS = ADDRESS;

  
  submitForm() {
    if(this.$route.params.id !== undefined){
      axios.patch(`${ADDRESS}/position/update/${this.$route.params.id}`,this.doc)
        .then(response=>{
            console.log("เสร็จแล้ว!")
            console.log(response.data)
            console.log(response);
            alert("ดำเนินการบันทึกสำเร็จแล้ว");
        })
    } else {
      axios.post(`${ADDRESS}/position/create`,this.doc)
        .then(response=>{
            console.log("เสร็จแล้ว!")
            console.log(response.data)
            console.log(response);
            alert("ดำเนินการบันทึกสำเร็จแล้ว");
        })
    }
  }
  fetchData() {
    axios.get(`${ADDRESS}/position/get/${this.$route.params.id}`).then(response=>{
      this.doc = response.data.doc
    })
  }
  mounted() {
    if(this.$route.params.id !== undefined){
      this.fetchData()
    }
  }
}
