


















import { Component, Vue } from 'vue-property-decorator';
import axios from "axios"
import { ADDRESS } from "@/setting";
@Component({
  components: {
  },
})
export default class Copy extends Vue {
  doc:any = {}
  address = ADDRESS

  mounted(){
  }
  submitForm() {
      let prep = {
        fromYear: this.doc.fromYear,
        toYear: this.doc.toYear
      }
      axios.post(`${ADDRESS}/process/copy-indicator-strategy`,prep)
      .then((response) => {
        console.log(response)
        alert("ดำเนินการบันทึกสำเร็จแล้ว")
      })
  }
}
