




























































import { Component, Vue } from 'vue-property-decorator';
import axios from "axios";
import { ADDRESS } from "@/setting";
import ModalReview from "./components/ModalReview.vue"
import UserForm from "./Form.vue"

@Component({
  components: {
    ModalReview,
    UserForm
  },
})
export default class List extends Vue {
  page: number = 1;
  pages: number = 0;
  limit: number = 15;
  show: number = 20;
  total: number = 0;
  docs:Array<any> = []
  filter1:any = {}
  sort:any = {
    month:-1,
    year:-1,
  }
  userId:string = ""
  searchString = ""
  address = ADDRESS

  isMyself() {
    if(this.$store.state.username) {

    }
  }
  resolveSector(_id:string):string{
    try {
      let foundName = this.$store.state.sectors.find((item:any)=>item.id==_id)
      return foundName.name
    } catch (error) {
      return _id
    }
  }
  getAccesses(a:any){
    let result = ""
    switch (a.split(",")[0]){
      case "KPI" : result =  "โปรไฟล์ตัวชี้วัด "; break;
      case "Budget" : result =  "งบประมาณ"; break;
      case "Adjust" : result =  "การปรับแผน"; break;
      case "ERP" : result =  "การเบิก/ยืมเงินในระบบ ERP"; break;
      case "Follow" : result =  "ติดตามผลการดำเนินงาน"; break;
      case "Report" : result =  "รายงาน"; break;
      case "Dashboard" : result =  "MIS Dashboard"; break;
      case "PR" : result =  "ประชาสัมพันธ์"; break;
      case "WFH" : result =  "Work From Home"; break;
      case "UAC" : result =  "การจัดการผู้ใช้งาน"; break;
      case "Setting" : result =  "การตั้งค่า"; break;
      case "Risk" : result =  "ความเสี่ยง"; break;
    } 
    switch (a.split(",")[1]) {
      case "r": result += " (อ่าน)"; break;
      case "w": result += " (เขียน)"; break;
      case "x": result += " (ลบ)"; break;
        break;
      default:
        break;
    }
    return result
  }
  fetchData(){
    this.docs = []
    let sort:any = this.sort
    // let search = `WHERE firstName LIKE '%${this.searchString}%' OR lastName LIKE '%${this.searchString}%' OR CONCAT(firstName,' ',lastName) LIKE '%${this.searchString}%'`
    let firstName = ""
    let lastName = ""
    try { firstName = this.searchString.split(' ')[0]; lastName = this.searchString.split(' ')[1]; } catch(err) { }
    let query = {
      limit: this.show,
      skip: (this.page-1)*this.show,
      search:{
        OR:[
          {
            firstName: {
              contains: this.searchString,
            }
          },
          {
            lastName: {
              contains: this.searchString,
            }
          },
          {
            username: {
              contains: this.searchString,
            }
          },
          {
            AND:[
              {
                firstName: {
                  contains: firstName,
                }
              },
              {
                lastName: {
                  startsWith: lastName,
                }
              },
            ]
          }
        ]
      },
    }
    axios.post(`${ADDRESS}/users/paginate`,query).then((response) => {
      console.log('users',response)
      this.docs = response.data.docs;
      this.total = response.data.total;
      this.pages = Math.ceil(response.data.total / this.show);
    });
  }
  mounted() {
    this.fetchData()
  }
  deleteItem(id: string) {
    var c = confirm(`คุณแน่ใจหรือว่าต้องการลบรายการนี้`);
    if (c) {
      axios.delete(`${ADDRESS}/user/delete/${id}`).then((response) => {
        console.log(response)
        this.fetchData();
      });
    }
  }
}
