































































































import { Component, Vue } from 'vue-property-decorator';
import axios from "axios";
import { ADDRESS } from "@/setting";
import { ConfirmAction } from "@/decorators"
@Component({
  components: {
  },
})
export default class PRForm extends Vue {
  doc: any = {
    attachments: []
  };
  address = ADDRESS;

  @ConfirmAction("ยืนยันลบเอกสารแนบ?")
  deleteAttachments(i:number){
    this.doc.attachments.splice(i,1)
  }

  @ConfirmAction("ยืนยันลบภาพประกอบ?")
  deleteImages(i:number){
    this.doc.images.splice(i,1)
  }

  uploaded(field:string, ev:any){
    console.log('uploaded', ev)
    this.doc[field] = ev
  }
  
  submitForm() {
    if (this.$route.params.id == undefined) {
      axios.post(`${ADDRESS}/publication/create`,{
        ...this.doc,
        category : "ประชาสัมพันธ์"
      })
      .then((response) => {
        console.log(response)
        alert("ดำเนินการบันทึกสำเร็จแล้ว")
        this.$router.push("/portal/pr")
      })
    } else {
      axios.patch(`${ADDRESS}/publication/update/${this.doc.id}`,this.doc)
      .then((response) => {
        console.log(response)
        alert("ดำเนินการแก้ไขสำเร็จแล้ว")
        this.$router.push("/portal/pr")
      })
    }
  }
  fetchData() {
    axios.get(`${ADDRESS}/publication/get/${this.$route.params.id}`).then((response) => {
      console.log(response)
      if(response.data.status === "success") {
      let data = response.data.doc;
      this.doc = data;
      } else {
        console.log(`พบปัญหาในการดึงข้อมูลจากแม่ข่าย , ${response.data.error}`)
      }
    });
  }
  mounted() {
    if (this.$route.params.id != undefined) this.fetchData()
  }
}
