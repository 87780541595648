



































import { ADDRESS } from "@/setting";
import axios from "axios";
import { forEach } from "lodash";
import { Component, Vue, Watch } from "vue-property-decorator";
@Component({
  components: {},
  props:{
    value:Array,
    label:Array,
    disabled:Boolean,
    type:String
  }
})
export default class Responsible extends Vue {
  value!:Array<string>
  val:Array<any> = []
  type!:string
  mounted(){
    if(this.value==undefined){
      if(this.type == 'sector1') this.val = [{},{},{}]
      if(this.type == 'sector2') this.val = [{}]
    } else if (Array.isArray(this.value)) {
        this.val = this.value
        let subArray : any
        if(this.type == 'sector1') subArray = JSON.parse(JSON.stringify(new Array(3-this.value.length).fill({})))
        if(this.type == 'sector2') subArray = JSON.parse(JSON.stringify(new Array(1-this.value.length).fill({})))
        this.val.push(...subArray)
    }
  }
  emitUp(){
    this.$emit("input", this.val)
  }
  options:Array<any> = []
  fetchOptions(search:string, loading:boolean) {
    axios.post(`${ADDRESS}/activedirectories/paginate`, {
      search:{
        displayName:{
          contains:search,
        },
        NOT:{
          OR:[
            { displayName:{ startsWith:"งาน" } },
            { displayName:{ startsWith:"กอง" } },
            { displayName:{ startsWith:"ฝ่าย" } },
            { displayName:{ startsWith:"สำนัก" } },
            { displayName:{ startsWith:"FN_" } },
          ]
        }
      },
      limit: 10,
      skip: 0,
    })
    .then((response) => {
      this.options = response.data.docs.map((el:any)=>el.displayName)
    })
    .catch((error) => {
      console.log(error)
    })
  }
  optionsFhai:Array<any> = []
  fetchOptionsFhai(search:string, loading:boolean) {
    axios.post(`${ADDRESS}/activedirectories/paginate`, {
      search:{
        displayName:{
          contains:search,
        },
        AND:{
          OR:[
            // { displayName:{ startsWith:"งาน" } },
            // { displayName:{ startsWith:"กอง" } },
            { displayName:{ startsWith:"ฝ่าย" } },
            { displayName:{ startsWith:"สำนัก" } },
          ]
        }
      },
      limit: 10,
      skip: 0,
    })
    .then((response) => {
      this.optionsFhai = response.data.docs.map((el:any)=>el.displayName)
    })
    .catch((error) => {
      console.log(error)
    })
  }
  optionsKong:Array<any> = []
  fetchOptionsKong(search:string, loading:boolean) {
    axios.post(`${ADDRESS}/activedirectories/paginate`, {
      search:{
        displayName:{
          contains:search,
        },
        AND:{
          OR:[
            { displayName:{ startsWith:"กอง" } },
          ]
        }
      },
      limit: 10,
      skip: 0,
    })
    .then((response) => {
      this.optionsKong = response.data.docs.map((el:any)=>el.displayName)
    })
    .catch((error) => {
      console.log(error)
    })
  }
  optionsNgarn:Array<any> = []
  fetchOptionsNgarn(search:string, loading:boolean) {
    axios.post(`${ADDRESS}/activedirectories/paginate`, {
      search:{
        displayName:{
          contains:search,
        },
        AND:{
          OR:[
            { displayName:{ startsWith:"งาน" } },
          ]
        }
      },
      limit: 10,
      skip: 0,
    })
    .then((response) => {
      this.optionsNgarn = response.data.docs.map((el:any)=>el.displayName)
    })
    .catch((error) => {
      console.log(error)
    })
  }
  optionsAll:Array<any> = []
  fetchOptionsAll(search:string, loading:boolean) {
    axios.post(`${ADDRESS}/activedirectories/paginate`, {
      search:{
        displayName:{
          contains:search,
        },
        AND:{
          OR:[
            { displayName:{ startsWith:"งาน" } },
            { displayName:{ startsWith:"ฝ่าย" } },
            { displayName:{ startsWith:"สำนัก" } },
            { displayName:{ startsWith:"กอง" } },
          ]
        }
      },
      limit: 10,
      skip: 0,
    })
    .then((response) => {
      this.optionsAll = response.data.docs.map((el:any)=>el.displayName)
    })
    .catch((error) => {
      console.log(error)
    })
  }
}
