










import { Component, Vue } from "vue-property-decorator";
import axios from "axios"

@Component({
  components: {
  },
})
export default class LandingSector extends Vue {
  mounted(){
    // getData()
  }
}
