
















































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import axios from "axios";
import { ADDRESS } from "@/setting";
import { active } from "d3-transition";
import ModalReview from "./components/ModalReview.vue"
import { DateTime } from "luxon";
import TableRow from "../budget/planComponents/TableRow.vue"
@Component({
  components: {
    ModalReview,
    TableRow
  },
})
export default class Form extends Vue {
  budget: any = {
    annuity:[]
  }
  budgetitems:Array<any> = []
  follows:Array<any> = []
  doc: any = {}
  erps:Array<any> = []
  activeItem: any = {
    months:[],
    monthTracks:[],
    monthsFlag:[],
    indicators:[]
  }
  activeFollow: any = {

  }
  activeFollowIndex: number = 0
  indicators:Array<any> = []
  active_i: number = -1
  active_j: number = -1
  active_k: number = -1
  levIndex:number = -1
  l1:Array<any> = []
  responsible:Array<any> = []
  types:Array<any> = []
  values:Array<any> = []
  link = "kpi"
  today = new Date()
  followOnly:Boolean = false

  mapMonth = [0,4,5,6,7,8,9,10,11,12, 1, 2, 3]

  fetchERP(){
    this.erps = []
    let query = {
      skip: 0,
      limit: 9999,
      search:{
        project_id : this.$route.params.id,
        NOT:{
          status:"deleted"
        }
      }
    }
    axios.post(`${ADDRESS}/erps/paginate`,query).then((response) => {
      this.erps = response.data.docs;
    });
  }
  get indicatorsText(){
    try {
      let result = (((this.budget.data||{})).indicator||[]).map((el:any)=>{
        let found = this.indicators.find(ind=>ind.id==el)
        return `${found.name} ${found.intro}`
      })
      return result.join("\n, ")
    } catch (error) {
      
    }
    return "-"
  }
  get flatted(){
    let prep:Array<any> = []
    try {
      this.budgetitems.forEach(e0=>{
        prep.push(e0)
        e0.children.forEach((e1:any)=>{
          prep.push(e1)
          e1.children.forEach((e2:any)=>{
            prep.push(e2)
            e2.children.forEach((e3:any)=>{
              prep.push(e3)
            })
          })
        })
      })
    } catch (error) {
      
    }
    return prep
  }

  get flatten(){
    let resuts:Array<any> = []
    try {
      this.budgetitems.forEach(l1 => {
        let clone = JSON.parse(JSON.stringify(l1))
        delete clone.children
        resuts.push(clone)
        l1.children.forEach((l2:any) => {
          let clone = JSON.parse(JSON.stringify(l2))
          delete clone.children
          resuts.push(clone)
          l2.children.forEach((l3:any) => {
            let clone = JSON.parse(JSON.stringify(l3))
            delete clone.children
            resuts.push(clone)
          })
        })
      })
    } catch (error) {
      
    }
    return resuts
  }
  checkFollow(id:string):boolean{
    if(!this.followOnly) return true
    return this.budget.followList.findIndex((fl:any)=>fl==id)!=-1;
  }
  sumMonths(item:any,search:String) {
    try {
      let result = (item.budgetItemType||"").search(search)!=-1?(item.months||[]).reduce((a:any,b:any)=>a+b,0):0
      return result
      } catch (error) {
      return 0
    }
  }
  sumMonthsERP(item:any,search:String) {
    try {
      let result = (item.budgetItemType||"").search(search)!=-1?(item.monthsERP||[]).reduce((a:any,b:any)=>a+b,0):0
      return result
      } catch (error) {
      return 0
    }
  }
  sumFollow(item:any) {
    try {
      let result = this.follows.filter(f=>f.budgetItem_id==item.id).map(m=>m.actualSpend).reduce((a,b)=>a+b,0)
      return result
    } catch (error) {
      return 0
    }
  }
  getFollowValue(item:any, m:number){
    try {
      let found = this.follows.find(f=>f.month==m&&f.budgetItem_id==item.id)
      return found.actualSpend
    } catch (error) {
      return 0
    }
  }
  get percentProgress(){
    try {
      let sum = this.follows.map(f=>f.progress??0).reduce((a,b)=>a+b, 0)
      let length = this.follows.length
      return sum/length
    } catch (error) {
      return 0
    }
  }
  get percentWithdrawn(){
    try {
      let budget = this.budgetitems.map(f=>f.months.reduce((a:number,b:number)=>a+b,0)).reduce((a,b)=>a+b, 0)
      let spends = this.budgetitems.map(f=>f.monthsERP.reduce((a:number,b:number)=>a+b,0)).reduce((a,b)=>a+b, 0)
      // let length = this.follows.length
      return (budget/spends) * 100
    } catch (error) {
      return 0
    }
  }

  updateFollowsStatus(ev:any,id:string) {
    let status = ev.target.value
    let prep = JSON.parse(JSON.stringify(this.follows))
    let month = this.mapMonth[DateTime.now().month]
    let index = prep.findIndex((f:any) => f.budgetItem_id == id && f.month == month)
    console.log('status',index,month)
    if(index != -1){
      prep[index].status = status
    } else {
      prep.push({
        budgetItem_id:id,
        project_id:this.$route.params.id,
        month: month,
        status:status,
        tag: true,
        progress:0,
        note:"",
        category:"",
        expenses:"",
        petitionNumber:"",
        budgetCode:"",
        budgetReceive:0,
        procurement:0,
        actualSpend:0
      })
    }
    console.log(prep)
    axios.post(`${ADDRESS}/follow`, {follows: prep})
      .then((response) => {
        console.log(response)
          setTimeout(() => {
            this.fetchFollows()
          }, 350);
      })
  }

  openModalById(id:string){
    try {
      let isFound = false
      this.budgetitems.forEach((bi1,i)=>{
        if(bi1.id===id) {
          this.activeItem = this.budgetitems[i];
          this.activeItem.previous = JSON.parse(JSON.stringify(this.activeItem))
          isFound = true
        }
      });
      if(!isFound)
      this.budgetitems.forEach((bi1,i)=>{
        bi1.children.forEach((bi2:any,j:number)=>{
          if(bi2.id===id) {
            this.activeItem = this.budgetitems[i].children[j];
            this.activeItem.previous = JSON.parse(JSON.stringify(this.activeItem))
            isFound = true
          }
        })
      });
      if(!isFound)
      this.budgetitems.forEach((bi1,i)=>{
        bi1.children.forEach((bi2:any,j:number)=>{
          bi2.children.forEach((bi3:any,k:number)=>{
            if(bi3.id===id) {
              this.activeItem = this.budgetitems[i].children[j].children[k];
              this.activeItem.previous = JSON.parse(JSON.stringify(this.activeItem))
              isFound = true
            }
          })
        })
      });
      (this.$refs.openmodal as HTMLElement).click()
    } catch (error) {
      console.log(error)
      alert("พบปัญหาในการแก้ไขรายการ")
    }
  }
  getPercent(id:string){
    try {
      let results = this.follows.filter(item => item.budgetItem_id == id)
      let max = Math.max(...results.map(r=>r.month))
      let found = results.find(rs=>rs.month == max)
    return found.progress
    } catch (error) {
      return 0
    }
  }
  getProgress(id:string){
    try {
      let results = this.follows.filter(item => item.budgetItem_id == id)
      let max = Math.max(...results.map(r=>r.month))
      let found = results.find(rs=>rs.month == max)
    return found.status
    } catch (error) {
      return 0
    }
  }
  sumSurplus() {
    try {
      let sum = 0
      return sum
    } catch (error) {
      return 0
    }
  }
  getSurplus(budgets:Array<number>,erps:Array<number>):number{
    let sum = 0
    budgets.forEach((m,i) => {
      if(erps[i]==0) sum += 0
      else sum += m-erps[i]
    });
    return Math.abs(sum)
  }
  getRemaining(budgets:Array<number>,erps:Array<number>):number{
    let sum1 = budgets.reduce((a,b)=>a+b,0)
    let sum2 = erps.reduce((a,b)=>a+b,0)
    let sum3 = this.getSurplus(budgets,erps)
    return sum1 - sum2 - sum3
  }
  getLevel1(){
    try {
      this.l1 = this.budgetitems ?? {}
      return this.l1
    } catch(error) {
      return []
      // console.log(error)
    }
  }
  getLevel2(i:number){
    try {
    let l1 = this.budgetitems ?? {}
    let l2 = l1[i].children ?? {}
    return l2
    } catch(error) {
      return []
      // console.log(error)
    }
  }
  getLevel3(i:number,j:number){
    try {
    let l1 = this.budgetitems ?? {}
    let l2 = l1[i].children ?? {}
    let l3 = l2[j].children ?? {}
    return l3
    } catch(error) {
      return []
      // console.log(error)
    }
  }
  updateItem(){
    if(this.active_i!=-1&&this.active_j==-1&&this.active_k==-1){
      this.budgetitems[this.active_i] = this.activeItem
    } else if (this.active_i!=-1&&this.active_j!=-1&&this.active_k==-1) {
      this.budgetitems[this.active_i].children[this.active_j] = this.activeItem
    } else if (this.active_i!=-1&&this.active_j!=-1&&this.active_k!=-1) {
      this.budgetitems[this.active_i].children[this.active_j].children[this.active_k] = this.activeItem
    }
    this.active_i = -1
    this.active_j = -1
    this.active_k = -1
  }
  fetchBudget() {
    axios
      .get(`${ADDRESS}/budget/get/${this.$route.params.id}`)
      .then((response) => {
        // console.log(response.data);
        if (response.data.status === "success") {
          let data = response.data.doc;
          this.budget = data
          this.budget.annuity = [0,0,0,0,0,0,0,0,0,0,0,0]
          if(this.budget.followList==null) this.budget.followList = []
          
        } else {
          console.log(`พบปัญหาในการดึงข้อมูลจากแม่ข่าย , ${response.data.error}`);
        }
      });
  }

  patchAnnualBudget(){
    axios.patch(`${ADDRESS}/budget/update/${this.budget.id}`,this.budget).then(response => {
      alert("บันทึกข้อมูลงบประมาณตามปีงบประมาณสำเร็จ!")
      this.fetchBudget()
      
    })
  }
  fetchIndicator() {
    let promise = new Promise((resolve,reject)=>{
    let query = {
      skip: 0,
      limit: 999
    }
    axios
      .post(`${ADDRESS}/indicators/paginate`, query)
      .then((response) => {
        console.log(response.data);
        if (response.data.status === "success") {
          let data = response.data.docs;
          this.$set(this, "indicators", data)
          resolve('ok indicator')
        } else {
          reject(response.data.error)
          console.log(`พบปัญหาในการดึงข้อมูลจากแม่ข่าย , ${response.data.error}`);
        }
      });
    })
    return promise
  }
  getLatestRevision(){
    return new Promise((resolve,reject)=>{
    axios
      .get(`${ADDRESS}/budgetitem/get-items-last-revision/${this.$route.params.id}`)
      .then((response) => {
        let revision = 1
        try {
          revision = response.data.result.last_revision
        } catch (error) {
          
        }
        resolve(revision)
      }).catch(err=>{
        reject(1)
      })
    })
  }

  revision = 1
  async fetchData() {
    // if(this.$route.name=="BudgetPlanAdjusting") revision = await this.getLatestRevision()
    // else if(this.$route.name=="BudgetPlanAdjustingWithID") revision = await this.getLatestRevision()
    // else if(this.$route.name=="BudgetPlanApproval") revision = await this.getLatestRevision()
    // console.log('revision',revision, `${ADDRESS}/budgetitem/get-items/${this.$route.params.id}/${revision}`)
    console.log("WHERE", `${ADDRESS}/budgetitem/get-items/${this.$route.params.id}/${this.revision}`)
    axios
      .get(`${ADDRESS}/budgetitem/get-items/${this.$route.params.id}/${this.revision}`)
      .then((response) => {
        console.log('revision2', response.data);
        if (response.data.status === "success") {
          let data = response.data.docs;
          console.log('budget items', data)
          this.budgetitems = data.map((el:any)=>{
            return {
              ...el,
              edit:false
            }
          });
        } else {
          console.log(`พบปัญหาในการดึงข้อมูลจากแม่ข่าย , ${response.data.error}`);
        }
    });
  }

  fetchFollows() {
    console.log('fetching follows')
    let query = {
      // search: `WHERE project_id = ${this.$route.params.id}`,
      skip:0,
      limit: 5000
    }
    axios
      .post(`${ADDRESS}/follows/paginate`, query)
      .then((response) => {
        // console.log('follows', response.data);
        if (response.data.status === "success") {
          let data = response.data.docs;
          this.follows = data
        } else {
          console.log(`พบปัญหาในการดึงข้อมูลจากแม่ข่าย , ${response.data.error}`);
        }
    });
  }

  mounted() {
    this.fetchBudget()
    this.fetchData()
    this.fetchFollows()
    this.fetchIndicator()
    this.fetchERP()
  }
}
