
















































import { Component, Vue } from 'vue-property-decorator';
import axios from "axios"
import { ADDRESS } from "@/setting";
@Component({
  components: {
  },
})
export default class List extends Vue {
  docs:Array<any> = []
  show: number = 20;
  page: number = 1;
  pages: number = 0;
  total: number = 0;
  filter1: any = {}
  filter2: any = {}
  types:Array<any> = []
  address = ADDRESS

  mounted(){
    this.fetchData()
    this.fetchKPIType()
  }
  clearSearch(){
    this.filter1 = 0
    this.filter2 = ""
    this.fetchData()
  }
  fetchKPIType(){
    axios.get(`${ADDRESS}/setting/get-by-name/ประเภทตัวชี้วัด`).then(response => {
      let data = response.data
      let member = []
      try {
        member = data.result.member
      } catch (error) { }
      this.types = member
    })
  }
  fetchData(){
    this.docs = []
    let search:Array<string> = []
    let searchQuery = ""
    if(this.filter1 !== 0) search.push(`budgetYear = ${this.filter1}`)
    if(this.filter2 !== "") search.push(`type ='${this.filter2}'`)
    if(search.length > 0) searchQuery = `WHERE ${search.join(" AND ")}`
    let query = {
      skip: this.show*(this.page-1),
      show: this.show,
      search:{
        ...this.filter1,...this.filter2
      },
      //search:searchQuery,
    }
    console.log(query)
    axios.post(`${ADDRESS}/indicators/paginate`,query).then((response) => {
      // console.log(response)
      this.docs = response.data.docs;
      this.total = response.data.total;
      this.pages = Math.ceil(response.data.total / this.show);
    });
  }
}
