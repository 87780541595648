








import { Component, Vue, Watch } from "vue-property-decorator";
import {createNumberMask} from "text-mask-addons"
import VMasker from "vanilla-masker"
import * as _ from "lodash"
@Component({
  props:{
    value:Number,
    disabled:Boolean,
  },
  components: {
    
  },
})
export default class JMNumber extends Vue {
  inputValue:string|number = 0
  value!:number
  disabled!:boolean
  uuid:string = "_"
  initialized = false
  currencyMask = createNumberMask({
    prefix: '',
    allowDecimal: true,
    includeThousandsSeparator: true,
    allowNegative: false,
    // requireDecimal: true
  });
  @Watch("value")
  updateValue(){
    this.initialized = true    
    this.inputValue = this.value
  }
  created(){
    // this.uuid = Math.round(Math.random()*100000).toString()
  }
  mounted(){
    this.updateValue()
  }

  emitUp(){
    let result:any = this.inputValue as string
    result = result.split(',').join('')
    result = parseFloat(result)
    console.log(this.inputValue,result)
    this.$emit("input", result)
  }

}
