




































































































import { DateTime } from "luxon";
import { Component, Vue } from "vue-property-decorator";
import { Watch } from "vue-property-decorator";
@Component({
  components: {},
  props: {
    value: [Date,String],
    label: String,
    disabled:Boolean
  },
})
export default class JMEventCalendar extends Vue {
  value!: Date | string;
  isActive: boolean = false;
  showYear: boolean = false;
  selectedYear: string = (new Date().getFullYear() + 543).toString();
  selectedMonth: string = (new Date().getMonth() + 1).toString();
  selectedDate = new Date(); 
  dayArr: Array<DateTime> = [];
  yearArr: Array<number> = [];
  monthArr: Array<number> = [];
  strTyping: string = "";
  isDetail: boolean = false;
  get selectedDateTime(): DateTime {
    return DateTime.fromJSDate(this.selectedDate);
  }
  isSameDate(date1: DateTime, date2: DateTime): boolean {
    return date1.toISODate() === date2.toISODate();
  }
  isToday(date: DateTime): boolean {
    return this.isSameDate(date, DateTime.local());
  }
  monthFromInt(i:number){
    return DateTime.fromObject({month:i}).reconfigure({ outputCalendar: "buddhist" }).setLocale("th").toFormat("MMMM");
  }
  toThaiLong(d:string|Date){
    let dt:DateTime = DateTime.now();
    if( typeof d == "string") dt = DateTime.fromISO(d)
    else dt = DateTime.fromJSDate(d)
    let string = dt.reconfigure({ outputCalendar: "buddhist" }).setLocale("th").toFormat("dd LLLL yyyy");
    // console.log(typeof d, string,"/", dt,'/',d)
    return string
  }
  getDate(d:DateTime){
    return d.toFormat("d");
  }
  isSameMonth(d:DateTime){
    return d.toFormat("L") == DateTime.fromJSDate(this.selectedDate).toFormat("L");
  }
  @Watch("value")
  updateValue() {
    this.monthArr = []
    if (typeof this.value == "string") {
      let dt = DateTime.fromISO(this.value);
      this.selectedDate = new Date(this.value);
      this.selectedMonth = dt.toObject().month.toString();
      this.selectedYear = (dt.toObject().year+543).toString();
    } else {
      try {
        let dt = DateTime.fromJSDate(this.value);
        this.selectedDate = this.value;
        this.selectedMonth = dt.toObject().month.toString();
        this.selectedYear = (dt.toObject().year+543).toString();
      } catch (error) {
        
      }
    }
    if (this.value == undefined) {
      this.selectedDate = new Date();
    }
    for (var i = 0; i < 200; i++) {
      this.yearArr.push(new Date().getFullYear() - 100 + i + 543);
    }
    for (var i = 0; i < 12; i++) {
      this.monthArr.push(i);
    }
  }
  keymonitor(ev: any) {
    this.strTyping += ev.key;
    if (this.strTyping.length >= 8) {
      let dd = this.strTyping.substring(0, 2);
      let mm = this.strTyping.substring(2, 4);
      let yyyy = parseInt(this.strTyping.substring(4, 8)) - 543;
      this.strTyping = "";
      let date = new Date(`${yyyy}-${mm}-${dd}`);
      this.$emit("input", date);
      this.isActive = false;
    }
  }

  mounted() {
    if (this.$route.path.search("detail/") != -1) this.isDetail = true;
    this.updateValue()
    this.$nextTick(()=>{
      this.init();
    })
  }
  init() {
    this.dayArr = [];
    let firstDateOfMonth = DateTime.fromJSDate(this.selectedDate).startOf("month") //new Date(this.selectedDate).firstDayOfMonth();
    let lastDateOfMonth = DateTime.fromJSDate(this.selectedDate).endOf("month") //new Date(this.selectedDate).lastDayOfMonth();
    let firstDayOfMonth = this.shiftWkDay(firstDateOfMonth.weekday);
    let lastDayOfMonth = this.shiftWkDay(lastDateOfMonth.weekday);
    let lastWeekDiff = 7 - lastDayOfMonth; 
    // console.log('firstDayOfMonth',firstDayOfMonth,firstDateOfMonth.toJSON())
    // console.log('lastDayOfMonth',lastDayOfMonth,lastDateOfMonth)
    for (var i = 1; i<= firstDayOfMonth-1; i++){
      this.dayArr.push(firstDateOfMonth.minus({ day: firstDayOfMonth - i }));
    }
    for (var i = 0; i < lastDateOfMonth.toObject().day; i++ ) {
      this.dayArr.push(firstDateOfMonth.plus({days:i}));
    }
    for (var i = 1; i <= lastWeekDiff; i++) {
      this.dayArr.push(lastDateOfMonth.plus({days:i}));
    }
  }
  changeMonth() {
    let mo = parseInt(this.selectedMonth) - 1;
    let date = new Date(this.selectedDate);
    date.setMonth(mo);
    this.selectedDate = date as Date;
    this.init();
    // console.log("change");
    this.$emit("input", date);
    this.$emit("change", date);
  }
  changeYear(ev: InputEvent) {
    let num = 0;
    let target = ev.target as HTMLInputElement;
    num = parseInt(target.value);
    let date = new Date(this.value);
    date.setFullYear(num - 543);
    this.selectedDate = date;
    this.selectedYear = num.toString();
    this.$emit("input", date);
    this.$emit("change", date);
    this.init();
  }
  shiftWkDay(i:number){
    let arr = [-1,2,3,4,5,6,7,1];
    return arr[i]
  }
  changeDate(date: DateTime) {
    // this.selectedDate = date.toJSDate();
    this.$set(this, "selectedDate", date.toJSDate());
    this.$emit("input", this.selectedDate);
    this.$emit("change", date);
    this.isActive = false;
  }
  // checkDay(date: Date) {
  //   let classArray = [];
  //   if (date.getDay() == 0) classArray.push("sun");
  //   if (date.getDay() == 6) classArray.push("sat");
  //   if (date.getMonth() < new Date(this.selectedDate).getMonth())
  //     classArray.push("prev");
  //   if (date.getMonth() > new Date(this.selectedDate).getMonth())
  //     classArray.push("prev");
  //   // if (date.isSameDate(new Date(this.selectedDate))) classArray.push("active");
  //   return classArray;
  // }
}
