















































import { Component, Vue } from "vue-property-decorator";
import axios from "axios";
import { ADDRESS } from "@/setting";
import { DocumentExcel } from "@/document/xlsx"

@Component({
  components: {},
})
export default class B19 extends Vue {
  docs:Array<any> = []
  kpi:Array<any> = []

  async saveAsExcel() {
    let docExcel = new DocumentExcel()
    docExcel.createB19(this.docs)
  }
  fetchData() {
    let query = {
      budgetYear : parseInt(this.$route.params.budgetYear),
      // sector : this.$route.params.sector
    }
    console.log('query',query)
    axios.post(`${ADDRESS}/report/B19`,query).then((response) => {
      console.log('report/B19',response)
      this.docs = response.data.result;
      this.kpi = response.data.nokpi;
    });
  }
  mounted() {
    this.fetchData()
  }
}
