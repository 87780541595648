








































































































import { Component, Vue } from "vue-property-decorator";
import axios from "axios";
import { ADDRESS } from "@/setting";
import * as _ from "lodash"
import DocumentA from "@/document/pdf";
import { DocumentExcel } from "@/document/xlsx";
@Component({
  components: {},
})
export default class A06 extends Vue {
  ADDRESS = ADDRESS;
  strategicItems: Array<any> = [];
  tacticsItems: Array<any> = [];
  planItems: Array<any> = [];
  projects: Array<any> = [];
  docs: Array<any> = [
    {
      id: "",
      items: [
        {
          id: "",
          name: "แผนงาน lv1",
          children: [
            {
              id: "",
              name: "ผลผลิต lv2",
              children: [
                {
                  id: "",
                  name: "กิจกรรม lv3",
                  children: [
                    {
                      id: "",
                      name: "รายการ lv4",
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ];
  
  updateProject(lv4: string, i: number, j: number, k: number, l: number, m: number) {
    let project = this.projects.find((project) => project.id == lv4);
    let sector = (this.projects.find(project=>project)??{sector:"-"}).sector
    let code = (this.projects.find(project=>project)??{code:"-"}).code
    let name = (this.projects.find(project=>project)??{name:"-"}).name
    this.docs[i].items[j].children[k].children[l].children[m].quarter = project.quarter;
    this.docs[i].items[j].children[k].children[l].children[m].sector = sector;
    this.docs[i].items[j].children[k].children[l].children[m].name = name;
    this.docs[i].items[j].children[k].children[l].children[m].code = code;
    this.cleanParentBudget()
  }

  cleanParentBudget() {
    let docs:Array<any> = Object.assign([],this.docs);
    for (const s1 of docs) {
      let sumMonths_1:Array<number> = Object.assign([],new Array(4).fill(0));
      for (const s2 of s1.items) {
          let sumMonths_2:Array<number> = Object.assign([],new Array(4).fill(0));
          for (const s3 of s2.children) {
            let sumMonths_3:Array<number> = Object.assign([],new Array(4).fill(0));
            for (const s4 of s3.children) {
              let sumMonths_4:Array<number> = Object.assign([],new Array(4).fill(0));
              for (const s5 of s4.children) {
                s5.quarter.forEach((el:number,i:number) => {
                  sumMonths_4[i] += el
                });
              }
              s4.quarter = sumMonths_4;
              s4.quarter.forEach((el:number,i:number) => {
                sumMonths_3[i] += el
              });
            }
            s3.quarter = sumMonths_3;
            s3.quarter.forEach((el:number,i:number) => {
              sumMonths_2[i] += el
            });
          }
          s2.quarter = sumMonths_2;
          s2.quarter.forEach((el:number,i:number) => {
            sumMonths_1[i] += el
          });
      }
      s1.quarter = sumMonths_1;
    }
    this.docs = docs
  }

  get flatted(){
    let prep:Array<any> = []
    let docs:Array<any> = Object.assign([],this.docs)
    docs.forEach(e0=>{
      let e0prep = Object.assign({},e0)
      delete e0prep.items
      e0prep.isTotal = true
      // delete e0prep.months
      prep.push(e0prep)
      e0.items.forEach((e1:any)=>{
        let e1prep = Object.assign({},e1)
        delete e1prep.children
        // delete e1prep.months
        prep.push(e1prep)
        e1.children.forEach((e2:any)=>{
        let e2prep = Object.assign({},e2)
        delete e2prep.children
        // delete e2prep.months
        prep.push(e2prep)
          e2.children.forEach((e3:any)=>{
            let e3prep = Object.assign({},e3)
            delete e3prep.children
            // delete e3prep.months
            prep.push(e3prep)
            e3.children.forEach((e4:any)=>{
              let e4prep = Object.assign({},e4)
              e4prep.isProject = true
              e4prep.name = (this.projects.find(project=>project)??{name:"-"}).name
              e4prep.sector = (this.projects.find(project=>project)??{sector:"-"}).sector
              e4prep.code = (this.projects.find(project=>project)??{code:"-"}).code
              prep.push(e4prep)
            })
          })
        })
      })
    })
    return prep
  }
  getReport() {
    let query = {
      budgetYear: parseInt(this.$route.query.budgetYear as string),
    };
    console.log("query", query);
    axios.post(`${ADDRESS}/report/A10`, query).then((response) => {
      console.log("report/${name}", response);
      this.strategicItems = response.data.strategicItems;
      this.tacticsItems = response.data.tacticsItems;
      this.planItems = response.data.planItems;
      this.projects = response.data.projects;
    });
  }
  async generateReportExcel() {
    console.log("generateExcel");
    let docExcel = new DocumentExcel();
    docExcel.createA10(this.flatted, 2565);
  }
  async generateReportPDF() {
    console.log("generatePDF");
    const documentA = new DocumentA();
    documentA.create10(this.flatted, 2565);
    documentA.save(`reportA10.pdf`);
  }
  mounted() {
    this.getReport();
    this.cleanParentBudget()
  }
  pushStrategic() {
    this.docs.forEach((el: any, i: number) => {
      el.items.push({
        id: "",
        name: "แผนงาน lv1",
        children: [],
      });
    });
  }
  pushTactics(n: number) {
    console.log("index", n);
    this.docs.forEach((el: any, i: number) => {
      el.items[n].children.push({
        id: "",
        name: "ผลผลิต lv2",
        children: [],
      });
    });
  }
  pushPlan(n: number, m: number) {
    console.log("index2", n, m);
    this.docs.forEach((el: any, i: number) => {
      el.items[n].children[m].children.push({
        id: "",
        name: "กิจกรรม lv3",
        children: [],
      });
    });
  }
  pushProjects(n: number, m: number, o: number) {
    console.log("index2", n, m, o);
    this.docs.forEach((el: any, i: number) => {
      el.items[n].children[m].children[o].children.push({
        id: "",
        name: "รายการ lv4",
      });
    });
  }
}
