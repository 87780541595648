




































































import { ADDRESS } from "@/setting";
import axios from "axios";
import { Component, Vue } from "vue-property-decorator";
import sparkline from "@fnando/sparkline";
import Chart from 'chart.js/auto';

@Component({
  components: {},
})
export default class Main extends Vue {
  test: string = "";
  progress = 0;
  docs: Array<any> = [];
  limit: number = 15;
  page: number = 1;
  pages: number = 0;
  total: number = 0;
  filter1:any = {}
  filter2:any = {}
  filter3:any = {}
  types: Array<any> = [];
  totalBudgetMonths: Array<number> = [];
  totalERPMonths: Array<number> = [];
  activeCharts:Array<any> = []
  clearSearch() {
    this.filter1 = 0;
    this.filter2 = "";
    this.fetchData();
  }

  fetchData() {
    return new Promise(async (resolve, reject) => {
      console.log("fetching...")
      // this.docs = []
      let query = {
        search:{
          ...this.filter1,
          ...this.filter2,
          ...this.filter3,
        }
      }
      let response = await axios.post(`${ADDRESS}/dashboards/list`, query);
      this.docs = response.data.docs;
      this.totalBudgetMonths = response.data.totalBudgetMonths
      this.totalERPMonths = response.data.totalERPMonths
      
      resolve("ok");
    });
  }
  initAllChart(){
    let docs = (this.docs??[])
    this.initChartBar('chart-summary',this.totalBudgetMonths,this.totalERPMonths)
    docs.forEach((doc,i)=>{
      this.initChartBar('chart-bar-'+i,doc.months,doc.erpsMonths)
      this.initChartPie('chart-pie-'+i,doc.budgetAmount-doc.erpAmount,doc.erpAmount)
    })
  }
  async initAll(){
    for(const chart of this.activeCharts){
      console.log('chart', chart)
      await chart.destroy()
    }
    await this.fetchData();
    this.initAllChart()
    
  }
  mounted() {
    this.initAll()
  }
  initChartSummary(name:string,budget:Array<number>,actual:Array<number>) {
    var element = document.getElementById(name) as HTMLCanvasElement;
    var ctx = element.getContext("2d") as CanvasRenderingContext2D;
    const myChart = new Chart(ctx, {
      type: "bar",
      data: {
        labels: [0,1,2,3,4,5,6,7,8,9,10,11].map(el=>this.resolveMonth(el)),
        datasets: [
          {
            label: "งบประมาณ",
            data: budget,
            backgroundColor: [
              "rgba(54, 162, 235, 0.7)",
            ],
            borderColor: [
              "rgba(54, 162, 235, 1)",
            ],
            borderWidth: 1,
          },
          {
            label: "เบิกจ่ายจริง",
            data: actual,
            backgroundColor: [
              "rgba(75, 192, 192, 0.7)",
            ],
            borderColor: [
              "rgba(75, 192, 192, 1)",
            ],
            borderWidth: 1,
          },
          {
            label: "ส่วนต่าง (งบประมาณ-เบิกจ่าย)",
            type: 'line',
            data: actual.map((ac,i)=>Math.abs(ac-budget[i])),
            backgroundColor: [
              "rgba(192, 75, 75, 0.7)",
            ],
            borderColor: [
              "rgba(192, 75, 75, 1)",
            ],
            borderWidth: 1,
          },
          //
        ],
      },
      options: {
        scales: {
          y: {
            beginAtZero: true,
          },
        },
      },
    });
    this.activeCharts.push(myChart)
  }
  initChartBar(name:string,budget:Array<number>,actual:Array<number>) {
    var element = document.getElementById(name) as HTMLCanvasElement;
    var ctx = element.getContext("2d") as CanvasRenderingContext2D;
    const myChart = new Chart(ctx, {
      type: "bar",
      data: {
        labels: [0,1,2,3,4,5,6,7,8,9,10,11].map(el=>this.resolveMonth(el)),
        datasets: [
          {
            label: "งบประมาณ",
            data: budget,
            backgroundColor: [
              "rgba(54, 162, 235, 0.7)",
            ],
            borderColor: [
              "rgba(54, 162, 235, 1)",
            ],
            borderWidth: 1,
          },
          {
            label: "เบิกจ่ายจริง",
            data: actual,
            backgroundColor: [
              "rgba(75, 192, 192, 0.7)",
            ],
            borderColor: [
              "rgba(75, 192, 192, 1)",
            ],
            borderWidth: 1,
          },
          {
            label: "ส่วนต่าง (งบประมาณ-เบิกจ่าย)",
            type: 'line',
            data: actual.map((ac,i)=>Math.abs(ac-budget[i])),
            backgroundColor: [
              "rgba(192, 75, 75, 0.7)",
            ],
            borderColor: [
              "rgba(192, 75, 75, 1)",
            ],
            borderWidth: 1,
          },
          //
        ],
      },
      options: {
        scales: {
          y: {
            beginAtZero: true,
          },
        },
      },
    });
    this.activeCharts.push(myChart)
  }
  initChartPie(name:string,budget:number,actual:number) {
    var element = document.getElementById(name) as HTMLCanvasElement;
    var ctx = element.getContext("2d") as CanvasRenderingContext2D;
    const myChart = new Chart(ctx, {
      type: "doughnut",
      data: {
        labels: [
          'เบิกจ่ายแล้ว',
          'คงเหลือ',
        ],
        datasets: [{
          label: 'ข้อมูลทั้งโครงการ',
          data: [ actual,budget],
          backgroundColor: [
            'rgba(75, 192, 192, 1)',
            'rgb(54, 162, 235)'
          ],
          // hoverOffset: 4
        }]
      },
    });
    this.activeCharts.push(myChart)
  }

  resolveMonth(n: number) {
    let year = 2565;
    switch (n) {
      case 0:
        return "ตุลาคม " + year;
        break;
      case 1:
        return "พฤศจิกายน " + year;
        break;
      case 2:
        return "ธันวาคม " + year;
        break;
      case 3:
        return "มกราคม " + (year + 1);
        break;
      case 4:
        return "กุมภาพันธ์ " + (year + 1);
        break;
      case 5:
        return "มีนาคม " + (year + 1);
        break;
      case 6:
        return "เมษายน " + (year + 1);
        break;
      case 7:
        return "พฤษภาคม " + (year + 1);
        break;
      case 8:
        return "มิถุนายน " + (year + 1);
        break;
      case 9:
        return "กรกฏาคม " + (year + 1);
        break;
      case 10:
        return "สิงหาคม " + (year + 1);
        break;
      case 11:
        return "กันยายน " + (year + 1);
        break;

      default:
        return n;
        break;
    }
  }
}
