import {  RouteConfig } from 'vue-router'

import BudgetMain from '../../views/budget/Main.vue'
import BudgetForm from '../../views/budget/Form.vue'
import BudgetList from '../../views/budget/List.vue'
import BudgetPlan from '../../views/budget/Plan.vue'
import PlanPreview from '../../views/budget/PlanPreview.vue'
import D3list from '../../views/budget/D3List.vue'
import D301form from '../../views/budget/D301Form.vue'
import D302form from '../../views/budget/D302Form.vue'
import Petition from '../../views/budget/Petition.vue'

const routes: Array<RouteConfig> = [
  {
    path: 'budget',
    component: BudgetMain,
    meta:{
      "ac": "Budget,r"
    },
    children: [
      {
        path: '',
        // name: 'Budget',
        component: BudgetList,
        meta:{
          "ac": "Budget,r"
        },
      },
      {
        path: 'form',
        // name: 'BudgetForm',
        component: BudgetForm,
        meta:{
          "ac": "Budget,r"
        },
      },
      {
        path: 'edit/:id',
        // name: 'BudgetEdit',
        component: BudgetForm,
        meta:{
          "ac": "Budget,r"
        },
      },
      {
        path: 'detail/:id',
        // name: 'BudgetDetail',
        component: BudgetForm,
        meta:{
          "ac": "Budget,r"
        },
      },
      {
        path: 'plan/:id',
        name: 'BudgetPlanEdit',
        component: BudgetPlan,
        meta:{
          "ac": "Budget,r"
        },
      },
      {
        path: 'plan-adjusting/:id/:adjustment_id',
        name: 'BudgetPlanAdjustingWithID',
        component: BudgetPlan,
        meta:{
          "ac": "Budget,r"
        },
      },
      {
        path: 'plan-adjusting/:id',
        name: 'BudgetPlanAdjusting',
        component: BudgetPlan,
        meta:{
          "ac": "Budget,r"
        },
      },
      {
        path: 'plan-approval/:id/:adjustment_id',
        name: 'BudgetPlanApproval',
        component: BudgetPlan,
        meta:{
          "ac": "Budget,r"
        },
      },
      {
        path: 'plan-preview/:id',
        name: 'PlanPreview',
        component: PlanPreview,
        meta:{
          "ac": "Budget,r"
        },
      },
      {
        path: 'd3/:id',
        name: 'D301',
        component: D3list,
        meta:{
          "ac": "Budget,r"
        },
      },
      {
        path: 'd3-01/form/:id',
        name: 'D301Form',
        component: D301form,
        meta:{
          "ac": "Budget,r"
        },
      },
      {
        path: 'd3-01/edit/:id',
        name: 'D301edit',
        component: D301form,
        meta:{
          "ac": "Budget,r"
        },
      },
      {
        path: 'd3-02/form/:id',
        name: 'D302form',
        component: D302form,
        meta:{
          "ac": "Budget,r"
        },
      },
      {
        path: 'd3-02/edit/:id',
        name: 'D302edit',
        component: D302form,
        meta:{
          "ac": "Budget,r"
        },
      },
      {
        path: 'petition/:id',
        name: 'Petition',
        component: Petition,
        meta:{
          "ac": "Budget,r"
        },
      },
    ]
  },
]
export default routes