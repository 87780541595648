import MyDocument from "../../index";
import pdfMake from "pdfmake/build/pdfmake.js";
import { TDocumentDefinitions } from "pdfmake/interfaces";
import IReport01, { IReport01Item } from "@/interface/report01";
import IReport11 from "@/interface/report11";
import * as _ from "lodash"
export default class DocumentPDF extends MyDocument {
  _docDefinition: TDocumentDefinitions = {
    content: "พบข้อผิดพลาดในการสร้างเอกสาร PDF",
  };
  _styles = {
    bold: {
      bold: true,
    },
  };
  _fonts: any = {
    Roboto: {
      normal: "https://fonts.jmandjm.com/staticfonts/sarabun_regular.ttf",
      bold: "https://fonts.jmandjm.com/staticfonts/sarabun_bold.ttf",
      italics: "https://fonts.jmandjm.com/staticfonts/sarabun_italic.ttf",
      bolditalics: "https://fonts.jmandjm.com/staticfonts/sarabun_bolditalic.ttf",
    },
  };


  // "รายงานแบบที่ 1",
  async createC01(doc: Array<any>): Promise<any> {
    this.init = true;
    let tableWidth: Array<any> = new Array(28).fill("auto")
    tableWidth[1] = "*"
    let sumTotalChanges = 0
    for(const d of doc){
      for(const i of [0,1,2,3,4,5,6,7,8,9,10,11]){
        sumTotalChanges += d.months[i] - (d.previous ?? { months: [0,0,0,0,0,0,0,0,0,0,0,0] }).months[i]
      }
    }
    this._docDefinition = {
      pageSize: "A4",
      pageOrientation: "landscape",
      pageMargins: [40, 20, 40, 20],
      styles: this._styles,
      content: [
        {
          text: [{ text: `ประวัติการเปลี่ยนแปลงระหว่างปรับแผน ครั้งที่ `, style: "bold", },
          ],
          alignment: "center",
          margin: [0, 0, 0, 10],
        },
        {
          style: "tableExample",
          alignment: "center",
          fontSize: 6,
          table: {
            widths: tableWidth,
            body: [
              [
                { text: "ข้อการดำเนินการ", rowSpan: 2 },
                { text: "การดำเนินงาน", rowSpan: 2 },
                { text: "จำนวนเงินก่อนหน้านี้", colSpan: 12 },
                "พ.ย.",
                "ธ.ค.",
                "ม.ค.",
                "ก.พ.",
                "มี.ค.",
                "เม.ย.",
                "พ.ค.",
                "มิ.ย.",
                "ก.ค.",
                "ส.ค.",
                "ก.ย.",

                { text: "จำนวนเงินที่เปลี่ยนแปลง", colSpan: 12 },
                "พ.ย.",
                "ธ.ค.",
                "ม.ค.",
                "ก.พ.",
                "มี.ค.",
                "เม.ย.",
                "พ.ค.",
                "มิ.ย.",
                "ก.ค.",
                "ส.ค.",
                "ก.ย.",

                { text: "ยอดที่มีการเปลี่ยนแปลงแต่ละยอด", rowSpan: 2 },
                { text: "เหตุผลที่ขอปรับ", rowSpan: 2 },

              ],
              [
                "",
                "",
                "ต.ค.",
                "พ.ย.",
                "ธ.ค.",
                "ม.ค.",
                "ก.พ.",
                "มี.ค.",
                "เม.ย.",
                "พ.ค.",
                "มิ.ย.",
                "ก.ค.",
                "ส.ค.",
                "ก.ย.",

                "ต.ค.",
                "พ.ย.",
                "ธ.ค.",
                "ม.ค.",
                "ก.พ.",
                "มี.ค.",
                "เม.ย.",
                "พ.ค.",
                "มิ.ย.",
                "ก.ค.",
                "ส.ค.",
                "ก.ย.",

                "ยอดที่มีการเปลี่ยนแปลงแต่ละยอด",
                "เหตุผลที่ขอปรับ"
              ],
              ...doc.map((item: any) => {
                let previousMonths = (item.previous ?? { months: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] }).months
                let previousMonthsFlag = (item.previous ?? { monthsFlag: [false, false, false, false, false, false, false, false, false, false, false, false] }).monthsFlag
                return [
                  item.number ?? 0,
                  item.subject ?? "",
                  ...previousMonths.map((m: number) => ({ text: m.formatComma(), alignment: "right" })),
                  ...item.months.map((m: number, i: number) => {
                    let text = m.formatComma()
                    text += "\r\n"
                    text += previousMonths[i] < m ? "+" : ""
                    text += (m - previousMonths[i]).formatComma()
                    let fillColor
                    if (previousMonthsFlag[i] !== item.monthsFlag[i]) fillColor = "#e4ecfc"
                    return {
                      text,
                      alignment: "right",
                      fillColor
                    }
                  }),
                  { text: (item.months.reduce((a: number, b: number) => a + b, 0) - (item.previous ?? { months: [] }).months.reduce((a: number, b: number) => a + b, 0)).formatComma(), alignment: "right" },
                  item.reason ?? "",
                ]
              }),
              [
                { text: "ยอดรวมที่มีการปรับ", colSpan: 26 },
                "",
                ...[0,1,2,3,4,5,6,7,8,9,10,11].map((i:number)=>{
                  let sum = 0
                  for(const d of doc){
                    sum += (d.previous ?? { months: [0,0,0,0,0,0,0,0,0,0,0,0] }).months[i]
                  }
                  return ""
                }),
                ...[0,1,2,3,4,5,6,7,8,9,10,11].map((i:number)=>{
                  let sum = 0
                  for(const d of doc){
                    sum += d.months[i]
                  }
                  return ""
                }),
                { text: sumTotalChanges.formatComma(), alignment: "right"},
                ""
              ],
              // [
              //   { text: "ยอดรวมที่มีการปรับ", colSpan: 2 },
              //   "",
              //   ...[0,1,2,3,4,5,6,7,8,9,10,11].map((i:number)=>{
              //     let sum = 0
              //     for(const d of doc){
              //       sum += (d.previous ?? { months: [0,0,0,0,0,0,0,0,0,0,0,0] }).months[i]
              //     }
              //     return {text:sum.formatComma(), alignment: "right"}
              //   }),
              //   ...[0,1,2,3,4,5,6,7,8,9,10,11].map((i:number)=>{
              //     let sum = 0
              //     for(const d of doc){
              //       sum += d.months[i]
              //     }
              //     return {text:sum.formatComma(), alignment: "right"}
              //   }),
              //   { text: sumTotalChanges.formatComma(), alignment: "right"},
              //   ""
              // ],
            ],
          },
        },
      ],
    };
  }

  save(name: string) {
    let pdfDocGenerator = pdfMake
      .createPdf(this._docDefinition, undefined, this._fonts)
      .download(this.init ? name : "error.txt");
  }

}