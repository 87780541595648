

























































































import { Component, Vue } from "vue-property-decorator";
import axios from "axios";
import { ADDRESS } from "@/setting";
@Component({
  components: {},
})
export default class B11 extends Vue {
  docs:Array<any> = []
  sumBudget:number = 0

  resolveSectorFhai(_id:string):string{
    try {
      let code = this.$store.state.sectors.find((item:any)=>item.id==_id).code
      let foundName = this.$store.state.sectors.filter((item:any)=>item.level==3).find((item:any)=>item.code==code)
      return foundName.name
    } catch (error) {
      return ""
    }
  }
  fetchData() {
    let query = {
      budgetYear : parseInt(this.$route.params.budgetYear),
      sector : this.$route.params.sector
    }
    axios.post(`${ADDRESS}/report/B11`,query).then((response) => {
      console.log('report/B11',response)
      this.docs = response.data.result;
      this.sumBudget = response.data.sumBudget;
    });
  }
  mounted() {
    this.fetchData()
  }
}
