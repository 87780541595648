
















import { Component, Vue } from "vue-property-decorator";
import SideNavigation from "@/components/SideNavigation.vue";

@Component({
  components: {
    SideNavigation
  },
})
export default class Portal extends Vue {

  get breadcrumps(){
    try {
      let arr:Array<any> = []
      let path = ""
      this.$route.path.split('/').forEach(el=>{
        path += el + "/"
        let name = this.replaceText(el)
        arr.push({ name, path, isEdit: path.search("edit") > -1 })
      })
      return arr
    } catch (error) {
      return []
    }
  }
  mounted(){
    console.log(this.$route)
  }
  replaceText(source:string) {
    var array = {
      "budget": "งบประมาณ",
      "portal": "SMIS",
      "pr": "ข่าวสารประชาสัมพันธ์",
      "document": "เอกสาร",
      "form": "แบบฟอร์ม",
      "edit": "แบบแก้ไข",
    };
    for (var val in array) {
      //@ts-ignore
      source = source.split(val).join(array[val]);
    }
    return source;
  };
}
