













































































import { ADDRESS } from "@/setting";
import axios from "axios";
import { Component, Vue, Watch } from "vue-property-decorator";
import DocumentC from "@/document/pdf/C"
@Component({
  props:{
    flatted:Array,
  },
  components: {},
})
export default class History extends Vue {
  flatted:Array<any> = []

  get filtered(){
    return this.flatted.filter(f=>f.previous!=null)
  }
  getSumFinal(){
    let sum = 0
    for(const doc of this.filtered){
      let prev = (doc.previous??{months:[0,0,0,0,0,0,0,0,0,0,0,0]}).months
      let curr = (doc.months)
      for(const m of [0,1,2,3,4,5,6,7,8,9,10,11]){
        let res = curr[m] - prev[m]
        sum += res
      }
    }
    return sum
  }
  getSumMonthLastRowPrevious(i:number){
    let sum = 0
    for(const doc of this.filtered){
      let months = (doc.previous??{months:[0,0,0,0,0,0,0,0,0,0,0,0]}).months
      sum += months[i]
    }
    return sum
  }
  getSumMonthLastRow(i:number){
    let sum = 0
    for(const doc of this.filtered){
        sum += doc.months[i]
    }
    return sum
  }
  sumMonthitem(item:any) {
    try {
      let result = (item.months.reduce((a:any,b:any)=>a+b,0)-item.previous.months.reduce((a:any,b:any)=>a+b,0))
      return result
    } catch (error) {
      return 0
    }
  }
  generatePDF(){
    console.log("generatePDF")
    const documentC = new DocumentC()
    documentC.createC01(this.flatted)
    documentC.save(`ประวัติการเปลี่ยนแปลงระหว่างแผน.pdf`)
  }

  resolveMonth(n:number){
    switch (n) {
      case 1:
        return "ต.ค." 
        break;
      case 2:
        return "พ.ย." 
        break;
      case 3:
        return "ธ.ค." 
        break;
      case 4:
        return "ม.ค."
        break;
      case 5:
        return "ก.พ."
        break;
      case 6:
        return "มี.ค."
        break;
      case 7:
        return "เม.ย."
        break;
      case 8:
        return "พ.ค."
        break;
      case 9:
        return "มิ.ย."
        break;
      case 10:
        return "ก.ค."
        break;
      case 11:
        return "ส.ค."
        break;
      case 12:
        return "ก.ย."
        break;
    
      default:
        return n
        break;
    }
  }
}
