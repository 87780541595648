
























































import { Component, Vue } from 'vue-property-decorator';
import axios from "axios"
import { ADDRESS } from "@/setting";

@Component({
  components: {
  },
})
export default class DocumentList extends Vue {
  page: number = 1;
  pages: number = 0;
  limit: number = 15;
  total: number = 0;
  docs:Array<any> = [
    {}
  ]
  filter1:any = {}
  sort:any = {
  }
  active: number = 0
  address = ADDRESS  

  mounted(){
    this.fetchData()
  }
  openModalItem(i:number) {
    this.active = i;
    (this.$refs.openmodal as HTMLElement).click()
  }
  fetchData(){
    this.docs = []
    let query = {
      limit: this.limit,
      skip: (this.page-1)*this.limit,
      sort: {
        createdAt: "desc"
      }
    }
    axios.post(`${ADDRESS}/documents/paginate`,query).then((response) => {
      this.docs = response.data.docs;
      this.total = response.data.total;
      this.pages = Math.ceil(response.data.total / this.limit);
    });
  }
  deleteItem(id: string) {
    var c = confirm(`คุณแน่ใจหรือว่าต้องการลบรายการนี้`);
    if (c) {
      axios.delete(`${ADDRESS}/document/delete/${id}`).then((response) => {
        console.log(response)
        this.fetchData();
      });
    }
  }
}
