

































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import axios from "axios";
import { ADDRESS } from "@/setting";
import { active } from "d3-transition";
import vueslect from "vue-select";
import { ConfirmAction } from "@/decorators";
import { DateTime } from "luxon";
import { isNull } from "lodash";
@Component({
  props: {
    value: Object,
    budgetitem: Object,
    detail: Boolean,
    erps: Array,
    disableModal: Boolean
    
  },
  components: {
    vueslect,
  },
})
export default class ERPModal extends Vue {
  value!: any;
  disableModal!:boolean
  budgetitem!: any;
  detail!: boolean;
  innovizERP: any = {};
  erps!: Array<any>;
  ZPs: Array<any> = [];
  @Watch("value.months")
  fixMonths() {
    if (!Array.isArray(this.value.months)) this.$set(this.value, "months", []);
  }

  // @ConfirmAction("กรุณาตรวจสอบข้อมูลก่อนส่ง")
  emitUpdateItem() {
    this.$emit("updateItem", this.value);
   
  }

  get isDisabled(){
    if(this.value.id===undefined)
    return true
    else
    return false
  }
  
  get maxMoneyAllowed() {
    try {
      if (this.value.budgetItemType === "PR") {
        try {
          let reduced = this.budgetitem.months.reduce(
            (a: number, b: number) => a + b,
            0
          );
          return reduced;
        } catch (error) {}
      }
      let month = 0;
      if (typeof this.value.fromDateTransaction === "string")
        month = DateTime.fromISO(this.value.fromDateTransaction).month;
      else if (isNull(this.value.fromDateTransaction))
        month = DateTime.now().month;
      else month = DateTime.fromJSDate(this.value.fromDateTransaction).month;
      let remainBudget = 0;
      let lastMonthSpent = 0;
      let thisMonthBudget = 0;
      let thisMonthSpent = 0;
      let lastMonthBudget = 0;
      try { lastMonthBudget = this.budgetitem.months[this.convertMonth(month-1)] } catch(error) {}
      try { thisMonthBudget = this.budgetitem.months[this.convertMonth(month)]; } catch (error) {}
      let thisMonthERPs = this.erps.filter(erp=>{
        let erpMonth = DateTime.fromISO(erp.fromDateTransaction).month;
        return erp.budgetItem_id === this.budgetitem.id && erpMonth === month
      })
      let lastMonthERPs = this.erps.filter(erp=>{
        let erpMonth = DateTime.fromISO(erp.fromDateTransaction).month;
        return erp.budgetItem_id === this.budgetitem.id && erpMonth === month-1
      })
      thisMonthSpent = thisMonthERPs.reduce((a,b)=>a+b.value,0)
      lastMonthSpent = lastMonthERPs.reduce((a,b)=>a+b.value,0)
      remainBudget = lastMonthBudget - lastMonthSpent
      
      // console.log({erps:this.erps,thisMonthERPs,thisMonthSpent})
      // console.log({month,thisMonthBudget,remainBudget, thisMonthSpent},"AAA");
      console.log({remainBudget, thisMonthBudget, thisMonthSpent})
      console.log(this.convertMonth(month))
      return (remainBudget??0) + (thisMonthBudget??0) - (thisMonthSpent??0);
    } catch (error) {
      console.log({error})
      return 0;
    }
  }
  get totalERP() {
    try {
      return this.value.items
        .map((el: any) => el.amount)
        .reduce((a: number, b: number) => a + b, 0);
    } catch (error) {
      return 0;
    }
  }

  pushErpItem() {
    let items = JSON.parse(JSON.stringify(this.value.items ?? []));
    try {
      if (!Array.isArray(items)) {
        items = [];
      }
      items.push({});
      this.$set(this.value, "items", items);
    } catch (error) {
      console.log(error);
    }
  }
  updateERPValue() {
    let result: any;
    try {
      this.value.value = this.totalERP;
      if (this.maxMoneyAllowed < this.totalERP) {
        alert("คุณใช้เงินเกินจากงบประมาณที่กำหนด!");
        this.value.items.forEach((el: any) => {
          el.amount = 0;
        });
        this.value.value = 0;
      }
    } catch (error) {
      console.log(error);
    }
  }
  async updateSchemaAndUnit() {
    console.log("updating schema...");
    let items = JSON.parse(JSON.stringify(this.value.items ?? []));
    for (let item of items) {
      console.log("items", this.ZPs.find((zp) => zp.name == item.name).name);
      item.name = this.ZPs.find((zp) => zp.name == item.name).name;
      item.unit = this.ZPs.find((zp) => zp.name == item.name).unit;
      item.schema = this.ZPs.find((zp) => zp.name == item.name).accountCode;
      item.ZPCode = this.ZPs.find((zp) => zp.name == item.name).code;
    }
    this.$set(this.value, "items", items);
    // activeErp.items
  }
  calculateQuarter(from: number, to: number) {
    try {
      let filter = this.budgetitem.months.filter(
        (m: number, i: number) => i >= 0 && i <= 2
      ) as Array<number>;
      return filter.reduce((a, b) => a + b, 0);
    } catch (error) {
      return 0;
    }
  }
  mounted() {
    this.init();
    console.log(this.detail)
  }
  async init() {
    await this.fetchInnovizERP();
    await this.fetchZP();
  }
  fetchInnovizERP() {
    return new Promise((resolve, reject) => {
      let prep = {
        code: this.value.code,
      };
      axios.post(`${ADDRESS}/innoviz/erp-inbound`, prep).then((response) => {
        console.log("response.data", response.data);
        this.innovizERP = response.data.doc;
        try {
          this.innovizERP.IVZS_POLineText =
            this.innovizERP.IVZS_POLineText.split("|").join(" ");
        } catch (error) {}
        resolve("ok");
      });
    });
  }

  fetchZP() {
    return new Promise((resolve, reject) => {
      axios.get(`${ADDRESS}/accountZPs/list`).then((response) => {
        console.log(response.data);
        if (response.data.status === "success") {
          let data = response.data.docs;
          this.ZPs = data;
          this.ZPs = this.ZPs.map((zp) => {
            return {
              ...zp,
              fullName: `${zp.name} - ${zp.code}`,
            };
          });
          resolve(this.ZPs);
        } else {
          reject(response.data.error);
        }
      });
    });
  }

  
  convertMonth(i:number):number {
    switch (i) {
      case 10 : return 1;
      case 11 : return 2;
      case 12 : return 3;
      case 1 :  return 4;
      case 2 :  return 5;
      case 3 :  return 6;
      case 4 :  return 7;
      case 5 :  return 8;
      case 6 :  return 9;
      case 7 :  return 10;
      case 8 :  return 11;
      case 9 :  return 12;
      default: return 1
    }
  }
}
