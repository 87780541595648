

























































































































































import { Component, Vue } from "vue-property-decorator";
import axios from "axios";
import { ADDRESS } from "@/setting";
import { TDocumentDefinitions } from "pdfmake/interfaces";
import ExcelJS from "exceljs"
import { saveAs } from 'file-saver';
import pdfMake from "pdfmake/build/pdfmake.js";
import * as _ from "lodash"
@Component({
  components: {},
})
export default class A06 extends Vue {
  ADDRESS = ADDRESS;
  strategicItems: Array<any> = [];
  tacticsItems: Array<any> = [];
  planItems: Array<any> = [];
  projects: Array<any> = [];
  docs: Array<any> = [
    {
      id: "",
      items: [
        {
          id: "",
          name: "แผนงาน lv1",
          children: [
            {
              id: "",
              name: "ผลผลิต lv2",
              children: [
                {
                  id: "",
                  name: "กิจกรรม lv3",
                  children: [
                    {
                      id: "",
                      name: "รายการ lv4",
                      months:[0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ];
  updateProject(lv4: string, i: number, j: number, k: number, l: number, m: number) {
    // console.log({lv4,i,j,k,l,m})
    let sumPlanned = 0
    let sumERP = 0
    let sector = ""
    let project = this.projects.find((project) => project.id == lv4);
    console.log('project',project)
    let months: Array<number> = Object.assign([], new Array(12).fill(0));
    let erps: Array<number> = Object.assign([], new Array(12).fill(0));
    let preps:Array<any> = []
    months = project.months??Object.assign([],new Array(12).fill(0))
    erps = project.monthsERP??Object.assign([],new Array(12).fill(0))
    for(var x=0;x<12;x++){
      sumPlanned += months[x]
      sumERP += erps[x]
      preps.push(months[x])
      preps.push(erps[x])
      sector = (this.projects.find(project=>project)??{sector:"-"}).sector
      if(x===2||x===5||x===8||x===11){
        if(x===2){
          preps.push(months[0]+months[1]+months[2])
          preps.push(erps[0]+erps[1]+erps[2])
        } else if(x===5) {
          preps.push(months[3]+months[4]+months[5])
          preps.push(erps[3]+erps[4]+erps[5])
        }  else if(x===8) {
          preps.push(months[6]+months[7]+months[8])
          preps.push(erps[6]+erps[7]+erps[8])
        } else if(x===11) {
          preps.push(months[9]+months[10]+months[11])
          preps.push(erps[9]+erps[10]+erps[11])
        }
      }
    }
    preps.push(sumPlanned)
    preps.push(sumERP)
    this.docs[i].items[j].children[k].children[l].children[m].months = preps;
    this.docs[i].items[j].children[k].children[l].children[m].sector = sector;
    this.cleanParentBudget()
  }
  cleanParentBudget() {
    let docs:Array<any> = Object.assign([],this.docs);
    for (const s1 of docs) {
      let sumMonths_1:Array<number> = Object.assign([],new Array(34).fill(0));
      for (const s2 of s1.items) {
          let sumMonths_2:Array<number> = Object.assign([],new Array(34).fill(0));
          for (const s3 of s2.children) {
            let sumMonths_3:Array<number> = Object.assign([],new Array(34).fill(0));
            for (const s4 of s3.children) {
              let sumMonths_4:Array<number> = Object.assign([],new Array(34).fill(0));
              for (const s5 of s4.children) {
                console.log(s5)
                s5.months.forEach((el:number,i:number) => {
                  sumMonths_4[i] += el
                });
              }
              s4.months = sumMonths_4;
              s4.months.forEach((el:number,i:number) => {
                sumMonths_3[i] += el
              });
            }
            s3.months = sumMonths_3;
            s3.months.forEach((el:number,i:number) => {
              sumMonths_2[i] += el
            });
          }
          s2.months = sumMonths_2;
          s2.months.forEach((el:number,i:number) => {
            sumMonths_1[i] += el
          });
      }
      s1.months = sumMonths_1;
    }
    this.docs = docs
  }
  get flatted(){
    let prep:Array<any> = []
    let docs:Array<any> = Object.assign([],this.docs)
    docs.forEach(e0=>{
      let e0prep = Object.assign({},e0)
      delete e0prep.items
      e0prep.isTotal = true
      // delete e0prep.months
      console.log('e0prep',e0prep)
      prep.push(e0prep)
      e0.items.forEach((e1:any)=>{
        let e1prep = Object.assign({},e1)
        delete e1prep.children
        // delete e1prep.months
        prep.push(e1prep)
        e1.children.forEach((e2:any)=>{
        let e2prep = Object.assign({},e2)
        delete e2prep.children
        // delete e2prep.months
        prep.push(e2prep)
          e2.children.forEach((e3:any)=>{
            let e3prep = Object.assign({},e3)
            delete e3prep.children
            // delete e3prep.months
            prep.push(e3prep)
            e3.children.forEach((e4:any,num:number)=>{
              let e4prep = Object.assign({},e4)
              e4prep.isProject = true
              e4prep.number = num + 1
              e4prep.name = (this.projects.find(project=>project)??{name:"-"}).name
              e4prep.sector = (this.projects.find(project=>project)??{sector:"-"}).sector
              prep.push(e4prep)
            })
          })
        })
      })
    })
    return prep
  }
  getReport() {
    let query = {
      budgetYear: parseInt(this.$route.query.budgetYear as string),
    };
    console.log("query", query);
    axios.post(`${ADDRESS}/report/A06`, query).then((response) => {
      console.log("report/${name}", response);
      this.strategicItems = response.data.strategicItems;
      this.tacticsItems = response.data.tacticsItems;
      this.planItems = response.data.planItems;
      this.projects = response.data.projects;
    });
  }
  generateReportExcel() {
    console.log("generateExcel");
    let workbook = new ExcelJS.Workbook()
    let worksheet = workbook.addWorksheet('Report')
    worksheet.addRow([`รายงานสรุปแผนและผลการเบิกจ่ายเป็นรายเดือน/รายไตรมาส/รายปี`])
    worksheet.mergeCells('A1:AN1')
    worksheet.getCell("A1").alignment = { vertical: 'middle', horizontal: 'center' }
    worksheet.addRow([`ประจำปีงบประมาณ พ.ศ. ${this.$route.query.budgetYear}`])
    worksheet.mergeCells('A2:AN2')
    worksheet.getCell("A2").alignment = { vertical: 'middle', horizontal: 'center' }
    worksheet.getCell("A3").value = "ลำดับ"
    worksheet.mergeCells('A3:A6')
    worksheet.getCell("A3").alignment = { vertical: 'middle', horizontal: 'center' }
    worksheet.getCell("B3").value = "ผลผลิต/กิจกรรม/รายการ"
    worksheet.mergeCells('B3:B6')
    worksheet.getCell("B3").alignment = { vertical: 'middle', horizontal: 'center' }
    worksheet.getCell("C3").value = "ฝ่ายและสำนักที่รับผิดชอบ"
    worksheet.mergeCells('C3:C6')
    worksheet.getCell("C3").alignment = { vertical: 'middle', horizontal: 'center' }
    worksheet.getCell("D3").value = "แหล่งงบประมาณ"
    worksheet.mergeCells('D3:D6')
    worksheet.getCell("D3").alignment = { vertical: 'middle', horizontal: 'center' }
    worksheet.getCell("E3").value = "งบประมาณ"
    worksheet.mergeCells('E3:F4')
    worksheet.getCell("E3").alignment = { vertical: 'middle', horizontal: 'center' }
    worksheet.getCell("E5").value = "งบอุดหนุน"
    worksheet.mergeCells('E5:E6')
    worksheet.getCell("E5").alignment = { vertical: 'middle', horizontal: 'center' }
    worksheet.getCell("F5").value = "งบลงทุน"
    worksheet.mergeCells('F5:F6')
    worksheet.getCell("F5").alignment = { vertical: 'middle', horizontal: 'center' }
    worksheet.getCell("G3").value = "แผน/ผลการเบิกจ่าย"
    worksheet.mergeCells('G3:AN3')
    worksheet.getCell("G3").alignment = { vertical: 'middle', horizontal: 'center' }
    worksheet.getCell("G4").value = "ไตรมาสที่ 1"
    worksheet.mergeCells('G4:N4')
    worksheet.getCell("G4").alignment = { vertical: 'middle', horizontal: 'center' }
    worksheet.getCell("O4").value = "ไตรมาสที่ 2"
    worksheet.mergeCells('O4:V4')
    worksheet.getCell("O4").alignment = { vertical: 'middle', horizontal: 'center' }
    worksheet.getCell("W4").value = "ไตรมาสที่ 3"
    worksheet.mergeCells('W4:AD4')
    worksheet.getCell("W4").alignment = { vertical: 'middle', horizontal: 'center' }
    worksheet.getCell("AE4").value = "ไตรมาสที่ 4"
    worksheet.mergeCells('AE4:AL4')
    worksheet.getCell("AE4").alignment = { vertical: 'middle', horizontal: 'center' }
    worksheet.getCell("AM4").value = "รวมทั้งสิ้น"
    worksheet.mergeCells('AM4:AN4')
    worksheet.getCell("AM4").alignment = { vertical: 'middle', horizontal: 'center' }
    //ไตรมาส1
    worksheet.getCell("G5").value = "ต.ค."
    worksheet.mergeCells('G5:H5')
    worksheet.getCell("G5").alignment = { vertical: 'middle', horizontal: 'center' }
    worksheet.getCell("I5").value = "พ.ย."
    worksheet.mergeCells('I5:J5')
    worksheet.getCell("I5").alignment = { vertical: 'middle', horizontal: 'center' }
    worksheet.getCell("K5").value = "ธ.ค."
    worksheet.mergeCells('K5:L5')
    worksheet.getCell("K5").alignment = { vertical: 'middle', horizontal: 'center' }
    worksheet.getCell("M5").value = "รวม"
    worksheet.mergeCells('M5:N5')
    worksheet.getCell("M5").alignment = { vertical: 'middle', horizontal: 'center' }
    //ไตรมาส2
    worksheet.getCell("O5").value = "ม.ค."
    worksheet.mergeCells('O5:P5')
    worksheet.getCell("O5").alignment = { vertical: 'middle', horizontal: 'center' }
    worksheet.getCell("Q5").value = "ก.พ."
    worksheet.mergeCells('Q5:R5')
    worksheet.getCell("Q5").alignment = { vertical: 'middle', horizontal: 'center' }
    worksheet.getCell("S5").value = "มี.ค."
    worksheet.mergeCells('S5:T5')
    worksheet.getCell("S5").alignment = { vertical: 'middle', horizontal: 'center' }
    worksheet.getCell("U5").value = "รวม"
    worksheet.mergeCells('U5:V5')
    worksheet.getCell("U5").alignment = { vertical: 'middle', horizontal: 'center' }
    //ไตรมาส3
    worksheet.getCell("W5").value = "เม.ย."
    worksheet.mergeCells('W5:X5')
    worksheet.getCell("W5").alignment = { vertical: 'middle', horizontal: 'center' }
    worksheet.getCell("Y5").value = "พ.ค."
    worksheet.mergeCells('Y5:Z5')
    worksheet.getCell("Y5").alignment = { vertical: 'middle', horizontal: 'center' }
    worksheet.getCell("AA5").value = "มิ.ย."
    worksheet.mergeCells('AA5:AB5')
    worksheet.getCell("AA5").alignment = { vertical: 'middle', horizontal: 'center' }
    worksheet.getCell("AC5").value = "รวม"
    worksheet.mergeCells('AC5:AD5')
    worksheet.getCell("AC5").alignment = { vertical: 'middle', horizontal: 'center' }
    //ไตรมาส4
    worksheet.getCell("AE5").value = "ก.ค."
    worksheet.mergeCells('AE5:AF5')
    worksheet.getCell("AE5").alignment = { vertical: 'middle', horizontal: 'center' }
    worksheet.getCell("AG5").value = "ส.ค."
    worksheet.mergeCells('AG5:AH5')
    worksheet.getCell("AG5").alignment = { vertical: 'middle', horizontal: 'center' }
    worksheet.getCell("AI5").value = "ก.ย."
    worksheet.mergeCells('AI5:AJ5')
    worksheet.getCell("AI5").alignment = { vertical: 'middle', horizontal: 'center' }
    worksheet.getCell("AK5").value = "รวม"
    worksheet.mergeCells('AK5:AL5')
    worksheet.getCell("AK5").alignment = { vertical: 'middle', horizontal: 'center' }
    //แผนผล
    worksheet.getCell("AM5").value = "แผน"
    worksheet.mergeCells('AM5:AM6')
    worksheet.getCell("AM5").alignment = { vertical: 'middle', horizontal: 'center' }
    worksheet.getCell("AN5").value = "ผล"
    worksheet.mergeCells('AN5:AN6')
    worksheet.getCell("AN5").alignment = { vertical: 'middle', horizontal: 'center' }
    worksheet.getCell("G6").value = "แผน"
    worksheet.getCell("G6").alignment = { vertical: 'middle', horizontal: 'center' }
    worksheet.getCell("H6").value = "ผล"
    worksheet.getCell("H6").alignment = { vertical: 'middle', horizontal: 'center' }
    worksheet.getCell("I6").value = "แผน"
    worksheet.getCell("I6").alignment = { vertical: 'middle', horizontal: 'center' }
    worksheet.getCell("J6").value = "ผล"
    worksheet.getCell("J6").alignment = { vertical: 'middle', horizontal: 'center' }
    worksheet.getCell("K6").value = "แผน"
    worksheet.getCell("K6").alignment = { vertical: 'middle', horizontal: 'center' }
    worksheet.getCell("L6").value = "ผล"
    worksheet.getCell("L6").alignment = { vertical: 'middle', horizontal: 'center' }
    worksheet.getCell("M6").value = "แผน"
    worksheet.getCell("M6").alignment = { vertical: 'middle', horizontal: 'center' }
    worksheet.getCell("N6").value = "ผล"
    worksheet.getCell("N6").alignment = { vertical: 'middle', horizontal: 'center' }
    worksheet.getCell("O6").value = "แผน"
    worksheet.getCell("O6").alignment = { vertical: 'middle', horizontal: 'center' }
    worksheet.getCell("P6").value = "ผล"
    worksheet.getCell("P6").alignment = { vertical: 'middle', horizontal: 'center' }
    worksheet.getCell("Q6").value = "แผน"
    worksheet.getCell("Q6").alignment = { vertical: 'middle', horizontal: 'center' }
    worksheet.getCell("R6").value = "ผล"
    worksheet.getCell("R6").alignment = { vertical: 'middle', horizontal: 'center' }
    worksheet.getCell("S6").value = "แผน"
    worksheet.getCell("S6").alignment = { vertical: 'middle', horizontal: 'center' }
    worksheet.getCell("T6").value = "ผล"
    worksheet.getCell("T6").alignment = { vertical: 'middle', horizontal: 'center' }
    worksheet.getCell("U6").value = "แผน"
    worksheet.getCell("U6").alignment = { vertical: 'middle', horizontal: 'center' }
    worksheet.getCell("V6").value = "ผล"
    worksheet.getCell("V6").alignment = { vertical: 'middle', horizontal: 'center' }
    worksheet.getCell("W6").value = "แผน"
    worksheet.getCell("W6").alignment = { vertical: 'middle', horizontal: 'center' }
    worksheet.getCell("X6").value = "ผล"
    worksheet.getCell("X6").alignment = { vertical: 'middle', horizontal: 'center' }
    worksheet.getCell("Y6").value = "แผน"
    worksheet.getCell("Y6").alignment = { vertical: 'middle', horizontal: 'center' }
    worksheet.getCell("Z6").value = "ผล"
    worksheet.getCell("Z6").alignment = { vertical: 'middle', horizontal: 'center' }
    worksheet.getCell("AA6").value = "แผน"
    worksheet.getCell("AA6").alignment = { vertical: 'middle', horizontal: 'center' }
    worksheet.getCell("AB6").value = "ผล"
    worksheet.getCell("AB6").alignment = { vertical: 'middle', horizontal: 'center' }
    worksheet.getCell("AC6").value = "แผน"
    worksheet.getCell("AC6").alignment = { vertical: 'middle', horizontal: 'center' }
    worksheet.getCell("AD6").value = "ผล"
    worksheet.getCell("AD6").alignment = { vertical: 'middle', horizontal: 'center' }
    worksheet.getCell("AE6").value = "แผน"
    worksheet.getCell("AE6").alignment = { vertical: 'middle', horizontal: 'center' }
    worksheet.getCell("AF6").value = "ผล"
    worksheet.getCell("AF6").alignment = { vertical: 'middle', horizontal: 'center' }
    worksheet.getCell("AG6").value = "แผน"
    worksheet.getCell("AG6").alignment = { vertical: 'middle', horizontal: 'center' }
    worksheet.getCell("AH6").value = "ผล"
    worksheet.getCell("AH6").alignment = { vertical: 'middle', horizontal: 'center' }
    worksheet.getCell("AI6").value = "แผน"
    worksheet.getCell("AI6").alignment = { vertical: 'middle', horizontal: 'center' }
    worksheet.getCell("AJ6").value = "ผล"
    worksheet.getCell("AJ6").alignment = { vertical: 'middle', horizontal: 'center' }
    worksheet.getCell("AK6").value = "แผน"
    worksheet.getCell("AK6").alignment = { vertical: 'middle', horizontal: 'center' }
    worksheet.getCell("AL6").value = "ผล"
    worksheet.getCell("AL6").alignment = { vertical: 'middle', horizontal: 'center' }
    worksheet.getCell("AM6").value = "แผน"
    worksheet.getCell("AM6").alignment = { vertical: 'middle', horizontal: 'center' }
    worksheet.getCell("AN6").value = "ผล"
    worksheet.getCell("AN6").alignment = { vertical: 'middle', horizontal: 'center' }
    //width
    worksheet.getColumn("A").width = 10
    worksheet.getColumn("B").width = 80
    worksheet.getColumn("C").width = 60
    worksheet.getColumn("D").width = 40
    worksheet.getColumn("E").width = 30
    worksheet.getColumn("F").width = 30
    worksheet.getColumn("G").width = 30
    worksheet.getColumn("H").width = 30
    worksheet.getColumn("I").width = 30
    worksheet.getColumn("J").width = 30
    worksheet.getColumn("K").width = 30
    worksheet.getColumn("L").width = 30
    worksheet.getColumn("M").width = 30
    worksheet.getColumn("N").width = 30
    worksheet.getColumn("O").width = 30
    worksheet.getColumn("P").width = 30
    worksheet.getColumn("Q").width = 30
    worksheet.getColumn("R").width = 30
    worksheet.getColumn("S").width = 30
    worksheet.getColumn("T").width = 30
    worksheet.getColumn("U").width = 30
    worksheet.getColumn("V").width = 30
    worksheet.getColumn("W").width = 30
    worksheet.getColumn("X").width = 30
    worksheet.getColumn("Y").width = 30
    worksheet.getColumn("Z").width = 30
    worksheet.getColumn("AA").width = 30
    worksheet.getColumn("AB").width = 30
    worksheet.getColumn("AC").width = 30
    worksheet.getColumn("AD").width = 30
    worksheet.getColumn("AE").width = 30
    worksheet.getColumn("AF").width = 30
    worksheet.getColumn("AG").width = 30
    worksheet.getColumn("AH").width = 30
    worksheet.getColumn("AI").width = 30
    worksheet.getColumn("AJ").width = 30
    worksheet.getColumn("AK").width = 30
    worksheet.getColumn("AL").width = 30
    worksheet.getColumn("AM").width = 30
    worksheet.getColumn("AN").width = 30
    let lastRow = worksheet.rowCount
    
    this.flatted.forEach((doc: any) => {
      if(doc.isProject) {
        worksheet.addRow(
          [
            doc.number,doc.name,doc.sector,"","","",...doc.months
          ]
        )
      } else {
        worksheet.addRow(
          [
            doc.isTotal == true ? 'รวมงบประมาณทั้งสิ้น' : doc.name,"","","","","",...doc.months
          ]
        )
        let lastRow2 = worksheet.rowCount
        // console.log({lastRow2})
        worksheet.mergeCells(lastRow2,1,lastRow2,2)
      }
    })
    worksheet.eachRow({ includeEmpty: true }, (row, rn) => {
      if (rn > 2) {
        row.eachCell({ includeEmpty: true }, (cell, cn) => {
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
          };
        })
      }
      if(rn >= 7) {
        row.eachCell({ includeEmpty: true }, (cell, cn) => {
          if (cn >= 5) {
            cell.alignment = {
              vertical: 'middle',
              horizontal: 'right'
            }
            cell.numFmt = '#,##0.00;[Red]-$#,##0.00;';
          }
        })
      }
    })

    workbook.xlsx.writeBuffer().then(function (data: any) {
      var blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, "ReportA06.xlsx");
    });
  }
  async generateReportPDF() {
    let _docDefinition: TDocumentDefinitions = {
      content: "พบข้อผิดพลาดในการสร้างเอกสาร PDF",
    };
    let _styles = {
      bold: {
        bold: true,
      },
    };
    let _fonts: any = {
      Roboto: {
        normal: "https://fonts.jmandjm.com/staticfonts/sarabun_regular.ttf",
        bold: "https://fonts.jmandjm.com/staticfonts/sarabun_bold.ttf",
        italics: "https://fonts.jmandjm.com/staticfonts/sarabun_italic.ttf",
        bolditalics: "https://fonts.jmandjm.com/staticfonts/sarabun_bolditalic.ttf",
      },
    };
    let init = true
    _docDefinition = {
      pageSize: "A0",
      pageOrientation: "landscape",
      pageMargins: [40, 20, 40, 10],
      styles: _styles,
      defaultStyle:{
        alignment: 'center'
      },
      content: [
        {
          text: [
            { text: `รายงานสรุปแผนและผลการเบิกจ่ายเป็นรายเดือน/รายไตรมาส/รายปี`, style: "bold", },
            { text: `ประจำปีงบประมาณ พ.ศ. ${this.$route.query.budgetYear}`, style: "bold", }
          ],
          alignment: "center",
          margin: [0, 0, 0, 10],
        },
        {
          style: "tableExample",
          alignment: "center",
          table: {
            widths: [
              20, "*", 60, 50, 60, 60, 
              60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 
              60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 
              60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 
              60, 60, 60, 60
              ],
              //40
            headerRows: 4,
            body: [
              [
                { text: 'ลำดับ', rowSpan: 4},
                { text: 'ผลผลิต/กิจกรรม/รายการ', rowSpan: 4},
                { text: 'ฝ่ายและสำนักที่รับผิดชอบ', rowSpan: 4},
                { text: 'แหล่งงบประมาณ', rowSpan: 4},
                { text: 'งบประมาณ', rowSpan: 2, colSpan: 2 },
                ...new Array(1).fill({text:""}),
                { text: 'แผน/ผลการเบิกจ่าย', colSpan: 34 },
                ...new Array(33).fill({text:""}),
              ],
              [
                ...new Array(6).fill({text:""}),
                {text:'ไตรมาสที่ 1', colSpan: 8},
                ...new Array(7).fill({text:""}),
                {text:'ไตรมาสที่ 2', colSpan: 8},
                ...new Array(7).fill({text:""}),
                {text:'ไตรมาสที่ 3', colSpan: 8},
                ...new Array(7).fill({text:""}),
                {text:'ไตรมาสที่ 4', colSpan: 8},
                ...new Array(7).fill({text:""}),
                {text:'รวมทั้งสิ้น', colSpan: 2},
                ...new Array(1).fill({text:""}),
              ],
              [
                {text:''}, 
                {text:''}, 
                {text:''}, 
                {text:''},
                {text:'งบอุดหนุน', rowSpan: 2}, 
                {text:'งบลงทุน', rowSpan: 2}, 
                {text:'ต.ค.', colSpan: 2},
                ...new Array(1).fill({text:""}),
                {text:'พ.ย.', colSpan: 2},
                ...new Array(1).fill({text:""}),
                {text:'ธ.ค.', colSpan: 2},
                ...new Array(1).fill({text:""}),
                {text:'รวม', colSpan: 2},
                ...new Array(1).fill({text:""}),
                {text:'ม.ค.', colSpan: 2},
                ...new Array(1).fill({text:""}),
                {text:'ก.พ.', colSpan: 2},
                ...new Array(1).fill({text:""}),
                {text:'มี.ค.', colSpan: 2},
                ...new Array(1).fill({text:""}),
                {text:'รวม', colSpan: 2},
                ...new Array(1).fill({text:""}),
                {text:'เม.ย.', colSpan: 2},
                ...new Array(1).fill({text:""}),
                {text:'พ.ค.', colSpan: 2},
                ...new Array(1).fill({text:""}),
                {text:'มิ.ย.', colSpan: 2},
                ...new Array(1).fill({text:""}),
                {text:'รวม', colSpan: 2},
                ...new Array(1).fill({text:""}),
                {text:'ก.ค.', colSpan: 2},
                ...new Array(1).fill({text:""}),
                {text:'ส.ค.', colSpan: 2},
                ...new Array(1).fill({text:""}),
                {text:'ก.ย.', colSpan: 2},
                ...new Array(1).fill({text:""}),
                {text:'รวม', colSpan: 2},
                ...new Array(1).fill({text:""}),
                {text:'แผน', rowSpan: 2},
                {text:'ผล', rowSpan: 2},
              ],
              [
                {text:''},
                {text:''}, 
                {text:''}, 
                {text:''}, 
                {text:''},
                {text:''},
                {text:'แผน'},
                {text:'ผล'},
                {text:'แผน'},
                {text:'ผล'},
                {text:'แผน'},
                {text:'ผล'},
                {text:'แผน'},
                {text:'ผล'},
                {text:'แผน'},
                {text:'ผล'},
                {text:'แผน'},
                {text:'ผล'},
                {text:'แผน'},
                {text:'ผล'},
                {text:'แผน'},
                {text:'ผล'},
                {text:'แผน'},
                {text:'ผล'},
                {text:'แผน'},
                {text:'ผล'},
                {text:'แผน'},
                {text:'ผล'},
                {text:'แผน'},
                {text:'ผล'},
                {text:'แผน'},
                {text:'ผล'},
                {text:'แผน'},
                {text:'ผล'},
                {text:'แผน'},
                {text:'ผล'},
                {text:'แผน'},
                {text:'ผล'},
                {text:''},
                {text:''},
              ],
              ...this.flatted.map(el=>{
                let months:Array<number> = el.months
                if(el.isProject) return [
                  { text: el.number??"", alignment: 'center' },
                  { text: el.name??"", alignment: 'left' },
                  { text: el.sector, alignment: 'center'},"","","",
                  ...months.map(m=>({text:m.formatFull(), alignment:'right'}))
                ]
                else return [
                  { text: el.isTotal == true ? 'รวมงบประมาณทั้งสิ้น' : el.name??"", colSpan: 2, alignment: el.isTotal == true ? 'center' : 'left' },"",
                  "","","","",
                  ...months.map(m=>({text:m.formatFull(), alignment:'right'}))
                ]
              })
            ],
          },
        }, 
      ],
    };
    let pdfDocGenerator = pdfMake
      .createPdf(_docDefinition, undefined, _fonts)
      .download(init ? "ReportA06" : "error.txt");
  }
  mounted() {
    this.getReport();
    this.cleanParentBudget()
  }
  pushStrategic() {
    this.docs.forEach((el: any, i: number) => {
      el.items.push({
        id: "",
        name: "แผนงาน lv1",
        children: [],
      });
    });
  }
  pushTactics(n: number) {
    console.log("index", n);
    this.docs.forEach((el: any, i: number) => {
      el.items[n].children.push({
        id: "",
        name: "ผลผลิต lv2",
        children: [],
      });
    });
  }
  pushPlan(n: number, m: number) {
    console.log("index2", n, m);
    this.docs.forEach((el: any, i: number) => {
      el.items[n].children[m].children.push({
        id: "",
        name: "กิจกรรม lv3",
        children: [],
      });
    });
  }
  pushProjects(n: number, m: number, o: number) {
    console.log("index2", n, m, o);
    this.docs.forEach((el: any, i: number) => {
      el.items[n].children[m].children[o].children.push({
        id: "",
        name: "รายการ lv4",
      });
    });
  }
}
