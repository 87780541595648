






































import { Component, Vue } from 'vue-property-decorator';
import axios from "axios";
import { ADDRESS } from "@/setting";

@Component({
  components: {
  },
})
export default class List extends Vue {
  docs:Array<any> = []
  filter1:any = {}
  show: number = 20;
  page: number = 1;
  pages: number = 0;
  total: number = 0;
  sort:any = {
    month:-1,
    year:-1,
  }
  address = ADDRESS;
  mounted() {
    this.fetchData()
  }
  fetchData(){
    this.docs = []
    let sort:any = this.sort
    let query = {
      search:{...this.filter1},
      page:this.page,
      limit:this.show,
      skip:0,
      sort
    }
    axios.post(`${ADDRESS}/person/paginate`,query).then((response) => {
      console.log(response)
      this.docs = response.data.docs;
      this.total = response.data.total;
      this.pages = Math.ceil(response.data.total / this.show);
    });
  }
  deleteItem(id: string) {
    var c = confirm(`คุณแน่ใจหรือว่าต้องการลบรายการนี้`);
    if (c) {
      axios.delete(`${ADDRESS}/person/${id}`).then((response) => {
        console.log(response)
        this.fetchData();
      });
    }
  }
}
