







































import { Component, Vue } from 'vue-property-decorator';
import axios from "axios"
import { ADDRESS } from "@/setting"

@Component({
  components: {
  },
})
export default class List extends Vue {
  docs:Array<any> = []
  filter1:any = {}
  show: number = 20;
  limit: number = 15;
  page: number = 1;
  pages: number = 0;
  total: number = 0;
  sort:any = {
    month:-1,
    year:-1,
  }
  address = ADDRESS
  loading:boolean = false

  mounted(){
    this.fetchData()
  }
  updatePaginate(i: number) {
    this.page = i;
    // console.log(this.page);
    this.fetchData();
  }
  get paginate() {
    let arr = new Array(this.pages).fill(0);
    return arr;
  }
  clearPage() {
    if (this.show == 999999999) {
      this.page = 0;
      this.fetchData();
    }
  }
  fetchData(){
    this.docs = []
    let query = {
      skip: this.limit*(this.page-1),
      limit: this.limit,
    }
    axios.post(`${ADDRESS}/accountZPs/paginate`,query).then((response) => {
      console.log(response)
      this.docs = response.data.docs;
      this.total = response.data.total;
      this.pages = Math.ceil(response.data.total / this.limit);
      this.loading = false
      // this.total = response.data.total;
      // this.pages = Math.ceil(response.data.total / this.show);
    });
  }
  deleteItem(id: string) {
    var c = confirm(`คุณแน่ใจหรือว่าต้องการลบรายการนี้`);
    if (c) {
      axios.delete(`${ADDRESS}/accountZP/delete/${id}`).then((response) => {
        console.log(response)
        this.fetchData();
      });
    }
  }
}
