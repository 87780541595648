









import { Component, Vue } from "vue-property-decorator";
import axios from "axios";
import { ADDRESS } from "@/setting";

@Component({
  components: {},
  props: {
    value: Array,
    folder: String,
    label: String,
    username: String,
  },
})
export default class JMUpload extends Vue {
  value!:Array<string>
  folder!: string;
  label!: string;
  uploadPercentage: number = 0;
  address = ADDRESS
  isDetail:boolean = false;
  something:string = "";






  mounted(){
    if(this.$route.path.search("detail/")!=-1) this.isDetail = true
  }
  submitfile(ev: InputEvent) {
    //@ts-ignore
    const file = this.$refs.myfile.files[0]
    const size = file.size / 1024 / 1024
    console.log('size',size)
    if(size<250){
      const formData = new FormData()
      formData.set("name", `single`);
      formData.append("file", file);
      axios
        .post(
          `${ADDRESS}/upload`,
          formData,
          {
            headers:{"Content-Type": "multipart/form-data"},
            onUploadProgress: (progressEvent) => {
              this.uploadPercentage = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
            },
        }).then(response=>{
          console.log(response)
          this.$emit("input", response.data.path);
        })
    } else {
      alert("ภาพใหญ่เกิน 250 MB กรุณาเลือกไฟล์ใหม่")
    }
  }
  // submitfile(ev: InputEvent) {
  //   let myinput = this.$refs[`myfile`] as HTMLInputElement;
  //   var file = myinput!.files![0];
  //   var fileSize = file.size / 1024;
  //   console.log("ขนาดไฟล์" + fileSize);
  //   if (fileSize > 250 * 1024) {
  //     alert("ไฟล์มีขนาดมากกว่า 250 MB กรุณาเลือกไฟล์ใหม่");
  //   } else {
  //     const self = this;
  //     const formData = new FormData();
  //     formData.set("name", `${this.folder}`);
  //     formData.append("file", file);
  //     axios
  //       .post(`${ADDRESS}/upload/`, formData, {
  //         headers: {
  //           "Content-Type": "multipart/form-data",
  //         },
  //         onUploadProgress: function(progressEvent: ProgressEvent) {
  //           self.uploadPercentage = Math.round(
  //             (progressEvent.loaded * 100) / progressEvent.total
  //           );
  //         }.bind(self),
  //       })
  //       .then(function(response: any) {
  //         let newObj = self.value
  //         if(!Array.isArray(newObj)) newObj = []
  //         if(newObj.length>=5){
  //           alert("คุณสามารถอัพโหลดได้ 5 รายการเท่านั้น")
  //         }
  //         else{
  //         newObj.push(response.data.file.path)
          
  //         self.$emit("input", newObj);
  //         console.log(response);
  //         }
  //       })
  //       .catch(function(err: Error) {
  //         console.log(err);
  //       });
  //   }
  // }
}
