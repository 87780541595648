

































import { Component, Vue } from 'vue-property-decorator';
import axios from "axios"
import { ADDRESS } from "@/setting";
@Component({
  components: {
  },
})
export default class ListKPI extends Vue {
  docs:Array<any> = []
  search:Array<any> = []
  show: number = 20;
  page: number = 1;
  pages: number = 0;
  total: number = 0;
  address = ADDRESS

  mounted(){
    this.fetchData()
  }
  fetchData() {
    axios
      .post(`${ADDRESS}/indicator/report/indicator-budget`,
      {        
        budgetYear : this.$route.params.budgetYear,
        sector : this.$route.params.sector
      })
      .then((response) => {
        // console.log("fffff")
        if (response.data.status === "success") {
          this.docs = response.data.docs;
          console.log(response.data);
        } else {
          console.log(`พบปัญหาในการดึงข้อมูลจากแม่ข่าย , ${response.data.error}`);
        }
    });
  }
}
