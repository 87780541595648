export function ConfirmAction(warning?: string) {
  return function(target: Object, key:string, descriptor:PropertyDescriptor){
    const original = descriptor.value
    descriptor.value = function(...args:any[]){
      const allow = confirm(warning??"คุณแน่ใจหรือไม่?")
      if(allow){
        const result = original.apply(this, args)
        return result
      } else {
        return null
      }
    }
  }
}