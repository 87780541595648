


































































import { Component, Vue } from "vue-property-decorator";
import axios from "axios";
import { ADDRESS } from "@/setting";

@Component({
  components: {},
})
export default class B20 extends Vue {
  docs:Array<any> = []
  sumBudget:number = 0
  sumSurplus:number = 0
  sumRemaining:number = 0
  sumWithdrawn:number = 0
  sumMonths:Array<any> = []

  fetchData() {
    let query = {
      budgetYear : parseInt(this.$route.params.budgetYear),
      sector : this.$route.params.sector
    }
    axios.post(`${ADDRESS}/report/B20`,query).then((response) => {
      console.log('report/B20',response)
      this.docs = response.data.result;
      this.sumBudget = response.data.sumBudget;
      this.sumSurplus = response.data.sumSurplus;
      this.sumRemaining = response.data.sumRemaining;
      this.sumWithdrawn = response.data.sumWithdrawn;
      this.sumMonths = response.data.sumMonths;
    });
  }
  mounted() {
    this.fetchData()
  }
}
