






















































































































































































































































import { ADDRESS } from "@/setting";
import axios from "axios";
import { Component, Vue } from "vue-property-decorator";
import { DateTime } from "luxon";
@Component({
  components: {
  },
})
export default class D302Form extends Vue {
  doc : any = {}
  budget: any = {}
  sumOriginal: Number = 0
  sumModified: Number = 0

  getRequestId() {
    let id = this.$route.query.adjustment
    axios
      .get(`${ADDRESS}/budgetAdjustment/get/${id}`)
      .then((response) => {
        console.log(response.data);
        if (response.data.status === "success") {
          let data = response.data.doc;
          this.doc.request_id = data.request_id 
          this.doc.adjustment_id = data.id
          if(this.$route.query.id == undefined) 
          this.getData()
          } else {
          console.log(`พบปัญหาในการดึงข้อมูลจากแม่ข่าย , ${response.data.error}`);
        }
      });
  }
  getData() {
    axios
      .get(`${ADDRESS}/budgetRequest/get/${this.doc.request_id}`)
      .then((response) => {
        console.log('getData',response.data);
        if (response.data.status === "success") {
          let data = response.data.doc;
          this.doc.tableAdjust = data.tableAdjust
          this.sumOriginal = (this.doc.tableAdjust[0]??[]).original.reduce((a:any,b:any)=>a+b,0).formatFull()
          this.sumModified = (this.doc.tableAdjust[0]??[]).modified.reduce((a:any,b:any)=>a+b,0).formatFull()
          // console.log('data',data);
          } else {
          console.log(`พบปัญหาในการดึงข้อมูลจากแม่ข่าย , ${response.data.error}`);
        }
      });
  }
  fetchBudget() {
    let project_id = this.$route.params.id
    axios
      .get(`${ADDRESS}/budget/get/${project_id}`)
      .then((response) => {
        console.log(response.data);
        if (response.data.status === "success") {
          let data = response.data.doc;
          this.budget = data
          this.doc.budgetYear = this.budget.budgetYear 
          this.doc.revision = 1
          this.doc.name = this.budget.name
          this.doc.type = this.budget.source
          this.doc.sector = this.budget.sector
          this.doc.project_id = this.budget.id
          console.log('this.doc',this.doc);
          } else {
          console.log(`พบปัญหาในการดึงข้อมูลจากแม่ข่าย , ${response.data.error}`);
        }
      });
  }
  mounted() {
    this.fetchBudget()
    this.getRequestId()
    if(this.$route.query.id != undefined){
      this.getDocument()
    }
  }
  getDocument() {
    axios
      .get(`${ADDRESS}/documentadjust/get/${this.$route.query.id}`)
      .then((response) => {
        console.log('getData',response.data);
        if (response.data.status === "success") {
          let data = response.data.doc;
          this.doc = data
          this.sumOriginal = (this.doc.tableAdjust[0]??[]).original.reduce((a:any,b:any)=>a+b,0).formatFull()
          this.sumModified = (this.doc.tableAdjust[0]??[]).modified.reduce((a:any,b:any)=>a+b,0).formatFull()
          // console.log('data',data);
          } else {
          console.log(`พบปัญหาในการดึงข้อมูลจากแม่ข่าย , ${response.data.error}`);
        }
      });
  }
  submitForm() {
    let prep = {
      ...this.doc,
      project_id: this.$route.params.id,
      modifiedAt : DateTime.now().toJSDate(),
      documentType : "ย้ายเงินข้ามโครงการ"
    }
    console.log('prep',prep)
    if (this.$route.query.id == undefined) {
      axios.post(`${ADDRESS}/documentadjust/create`, prep)
      .then((response) => {
        console.log('response',response)
        alert("ดำเนินการบันทึกสำเร็จแล้ว")
        this.$router.push(`/portal/budget/d3/${this.$route.params.id}?adjustment=${this.$route.query.adjustment}?&documentType=ย้ายเงินข้ามโครงการ`)
      })
    } else {
      console.log({prep})
      axios.patch(`${ADDRESS}/documentadjust/update/${this.doc.id}`,prep)
      .then((response) => {
        console.log('response',response)
        alert("ดำเนินการแก้ไขสำเร็จแล้ว")
        this.$router.push(`/portal/budget/d3/${this.$route.params.id}?adjustment=${this.$route.query.adjustment}?&documentType=ย้ายเงินข้ามโครงการ`)
      })
    }
  }
  resolveMonth(n:number){
    switch (n) {
      case 1:
        return "ต.ค." 
        break;
      case 2:
        return "พ.ย." 
        break;
      case 3:
        return "ธ.ค." 
        break;
      case 4:
        return "ม.ค."
        break;
      case 5:
        return "ก.พ."
        break;
      case 6:
        return "มี.ค."
        break;
      case 7:
        return "เม.ย."
        break;
      case 8:
        return "พ.ค."
        break;
      case 9:
        return "มิ.ย."
        break;
      case 10:
        return "ก.ค."
        break;
      case 11:
        return "ส.ค."
        break;
      case 12:
        return "ก.ย."
        break;
    
      default:
        return n
        break;
    }
  }
}
