









































import { Component, Vue } from "vue-property-decorator";
import axios from "axios";
import { ADDRESS } from "@/setting";
import { DocumentExcel } from "@/document/xlsx"

@Component({
  components: {},
})
export default class B16 extends Vue {
  docs:Array<any> = []
  sum:number = 0
  async saveAsExcel() {
    let docExcel = new DocumentExcel()
    docExcel.createB16(this.docs, 2564)
  }

  fetchData() {
    let query = {
      budgetYear : parseInt(this.$route.params.budgetYear),
      sector : this.$route.params.sector
    }
    axios.post(`${ADDRESS}/report/B16`,query).then((response) => {
      console.log('report/B16',response)
      this.docs = response.data.result;
      this.sum = response.data.sum;
    });
  }
  mounted() {
    this.fetchData()
  }
}
