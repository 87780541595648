import { DateTime } from "luxon";
export {};

declare global {
  interface Number {
    to2Digit(): number;
    to2DigitFormat(): string;
    formatFull(): string;
    formatComma(): string;
    isUnderline(): string|null;
  }
}

Number.prototype.isUnderline = function () {
  if(this === 0) return null
  else return "underline"
}

Number.prototype.to2Digit = function () {
  return parseFloat(this.toFixed(2))
}

Number.prototype.to2DigitFormat = function () {
  if(this === 0) return "-"
  let result = this.toFixed(2)
  return result.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

Number.prototype.formatFull = function () {
  if(this === 0) return "-"
  if(this === null) return "-"
  let result = this.toFixed(2)
  return result.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

Number.prototype.formatComma = function () {
  if(this === 0) return "-"
  if(this === null) return "-"
  let result = this.toFixed(0)
  return result.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}
