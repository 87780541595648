import MyDocument from "../index";
import {
  Document,
  ImageRun,
  Packer,
  Paragraph,
  AlignmentType,
  UnderlineType,
  HorizontalPositionAlign,
  VerticalPositionAlign,
  TextRun,
  Table,
  TableCell,
  TableRow,
  WidthType,
  Alignment,
  FrameAnchorType,
  BorderStyle,
  TabStopType,
} from "docx";
import { size } from "lodash";

let stripHTML = (text: string) => {
  return text.replace(/<\/?[^>]+(>|$)/g, "").replace(/&nbsp;/g, "");
};

export class DocumentDocx extends MyDocument {
  _doc!: Document;
  fontName = "TH Sarabun New";
  arr: Array<any> = [];
  defaultTabStop = [
    {
      type: TabStopType.LEFT,
      position: 500,
    },
  ]
  TabStop = [
    {
      type: TabStopType.LEFT,
      position: 150,
    },
  ]
  tableNoBorder = {
    top: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
    bottom: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
    left: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
    right: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
  }
  async create01(doc: any,budgetYear: number,items: Array<any>,indicators: Array<any>): Promise<any> {
    console.log('doc.data',doc.data)
    this.init = true;
    let data: Array<any> = [];
    let data2: Array<any> = [];
    ((doc.data ?? {}).sectors??[]).forEach((el: any, i: number) => {
      data.push(
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph(
                  `${el.prefix ?? ""}${el.firstName ?? ""} ${
                    el.lastName ?? ""
                  } ${el.position ?? "-"} email: ${el.email ?? "-"} เบอร์โทร: ${
                    el.tel ?? "-"
                  }`
                ),
              ],
              margins: {
                left: 500,
              },
              borders: this.tableNoBorder,
            }),
          ],
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [new Paragraph(`(ลงชื่อ${".".repeat(60)})`)],
              margins: {
                left: 500,
              },
              borders: this.tableNoBorder,
            }),
          ],
        })
      );
    });
    (items??[]).forEach((lv1: any, i: number) => {
      data2.push(
        new TableRow({
          children: [
            new TableCell({
              width: { size: 8000, type: WidthType.DXA },
              children: [
                new Paragraph(
                  `${lv1.number ?? ""}. ${
                    lv1.budgetItemType == "เตรียมการและบริหารจัดการ"
                      ? "เตรียมการและบริหารจัดการ"
                      : lv1.subject ?? "-"
                  }`
                ),
              ],
              margins: {
                left: 500,
              },
              borders: this.tableNoBorder,
            }),
            new TableCell({
              width: { size: 3000, type: WidthType.DXA },
              children: [
                new Paragraph({
                  text: `${((lv1.months??[]).reduce((a:number,b:any)=>a+(typeof b === 'number'?b:0),0)).formatComma()} บาท`,
                  alignment: AlignmentType.RIGHT,
                }),
              ],
              borders: this.tableNoBorder,
            }),
          ],
        })
      );
      (lv1.children??[]).forEach((lv2: any, i: number) => {
        data2.push(
          new TableRow({
            children: [
              new TableCell({
                width: { size: 8000, type: WidthType.DXA },
                margins: {
                  left: 1000,
                },
                children: [
                  new Paragraph(
                    `${lv2.number ?? ""} ${
                      lv2.budgetItemType == "เตรียมการและบริหารจัดการ"
                        ? "เตรียมการและบริหารจัดการ"
                        : lv2.subject ?? "-"
                    }`
                  ),
                ],
                borders: this.tableNoBorder,
              }),
              new TableCell({
                width: { size: 2000, type: WidthType.DXA },
                children: [
                  new Paragraph({
                    text: `${((lv2.months??[]).reduce((a:number,b:any)=>a+(typeof b === 'number'?b:0),0)).formatComma()} บาท`,
                    // text: `${JSON.stringify(lv2.months)}`,
                    alignment: AlignmentType.RIGHT,
                  }),
                ],
                borders: this.tableNoBorder,
              }),
            ],
          })
        );
        (lv2.children??[]).forEach((lv3: any, i: number) => {
          data2.push(
            new TableRow({
              children: [
                new TableCell({
                  width: { size: 8000, type: WidthType.DXA },
                  margins: {
                    left: 1000,
                  },
                  children: [
                    new Paragraph(
                      `- ${lv3.subject ?? ""} ${lv3.qty ?? "-"} คน`
                    ),
                  ],
                  borders: {
                    top: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
                    bottom: {
                      style: BorderStyle.NONE,
                      size: 0,
                      color: "FFFFFF",
                    },
                    left: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
                    right: {
                      style: BorderStyle.NONE,
                      size: 0,
                      color: "FFFFFF",
                    },
                  },
                }),
                new TableCell({
                  width: { size: 2000, type: WidthType.DXA },
                  children: [
                    new Paragraph({
                      text: `${lv3.qty ?? "-"} คน`,
                      alignment: AlignmentType.RIGHT,
                    }),
                  ],
                  borders: {
                    top: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
                    bottom: {
                      style: BorderStyle.NONE,
                      size: 0,
                      color: "FFFFFF",
                    },
                    left: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
                    right: {
                      style: BorderStyle.NONE,
                      size: 0,
                      color: "FFFFFF",
                    },
                  },
                }),
              ],
            })
          );
        });
      });
    });

    const resolveIndicator = (id: string) => {
      try {
        let found = indicators.find((ind) => ind.id == id);
        if (found === undefined) return { code: "", name: "" };
        return found.code;
      } catch (error) {
        return "";
      }
    };
    const document = new Document({
      styles: {
        paragraphStyles: [
          {
            id: "Normal",
            name: "Normal",
            basedOn: "Normal",
            next: "Normal",
            quickFormat: true,
            run: {
              color: "000000",
              size: 15 * 2,
              font: {
                name: this.fontName,
              },
            },
            paragraph: {
              // spacing: {
              //   after: 120,
              // },
            },
          },
        ],
      },
      sections: [
        {
          children: [
            new Paragraph({
              children: [
                new TextRun({
                  text: `แผนการปฏิบัติงานและแผนการใช้จ่ายงบประมาณ ประจำปีงบประมาณ พ.ศ.${budgetYear}`,
                  size: 15 * 2,
                  bold: true,
                }),
              ],
              alignment: AlignmentType.CENTER,
            }),
            new Paragraph({
              children: [
                new ImageRun({
                  data: Buffer.from(require("./line").default, "base64"),
                  transformation: {
                    width: 530,
                    height: 1,
                  },
                }),
              ],
              alignment: AlignmentType.CENTER,
            }),
            new Paragraph({}),
            new Paragraph({
              text: `1.ผลผลิต กกท.ที่ ${(doc.data ?? {}).strategics ?? ""}`,
            }),
            new Paragraph({ text: `2.กลยุทธ์(กิจกรรมหลัก) กกท.ที่` }),
            ...((doc.data ?? {}).tactics??[]).map((item: any, i: number) => {
              return new Paragraph({
                children: [
                  new TextRun({
                    text: `\t${item}`,
                  }),
                ],
                tabStops: this.defaultTabStop,
              });
            }),
            new Paragraph({ text: `3.แผนงานหลัก (กิจกรรมย่อย) กกท.ที่` }),
            ...((doc.data ?? {}).plans??[]).map((item: any, i: number) => {
              return new Paragraph({
                children: [
                  new TextRun({
                    text: `\t${item}`,
                  }),
                ],
                tabStops: this.defaultTabStop,
              });
            }),
            new Paragraph({ text: `\tตัวชี้วัดพันธกิจ ฝ่าย/สำนัก`,
            tabStops: this.TabStop, }),
            ...((doc.data ?? {}).indicators??[]).map((item: any, i: number) => {
              return new Paragraph({
                children: [
                  new TextRun({
                    text: `\t${resolveIndicator(item)}`,
                  }),
                ],
                tabStops: this.defaultTabStop,
              });
            }),
            new Paragraph({
              text: `4.รายการที่ ${doc.code ?? ""} ${doc.name ?? ""}`,
            }),
            new Paragraph({ text: `5.หลักการและเหตุผล` }),
            new Paragraph({
              text: `\t${stripHTML((doc.data ?? {}).principles ?? "")}`,
              //@ts-ignore
              alignment: "thaiDistribute",
              tabStops: this.defaultTabStop,
            }),
            new Paragraph({ text: `6.วัตถุประสงค์ (Objective)` }),
            ...((doc.data ?? {}).objectives??[]).map((item: any, i: number) => {
              return new Paragraph({
                children: [
                  new TextRun({
                    text: `${item}`,
                  }),
                ],
                tabStops: this.defaultTabStop,
              });
            }),
            new Paragraph({
              text: `7.ความเชื่อมโยงกับยุทธศาสตร์และแผนระดับชาติ (Project Goal)`,
            }),
            new Paragraph({
              text: `\t${stripHTML((doc.data ?? {}).goals ?? "")}`,
              //@ts-ignore
              alignment: "thaiDistribute",
              tabStops: this.defaultTabStop,
            }),
            new Paragraph({ text: `8.กลุ่มเป้าหมายของรายการ` }),
            new Paragraph({
              text: `\t${stripHTML((doc.data ?? {}).target ?? "")}`,
              //@ts-ignore
              alignment: "thaiDistribute",
              tabStops: this.defaultTabStop,
            }),
            new Paragraph({ text: `9.ตัวชี้วัดและค่าเป้าหมาย 6 เดือน` }),
            ...((doc.data ?? {}).kpi6??[]).map((item: any, i: number) => {
              return new Paragraph({
                children: [
                  new TextRun({
                    text: `\t${item}`,
                  }),
                ],
                tabStops: this.defaultTabStop,
              });
            }),
            new Paragraph({ text: `\tตัวชี้วัดและค่าเป้าหมาย 12 เดือน`,
            tabStops: this.TabStop, }),
            ...((doc.data ?? {}).kpi12??[]).map((item: any, i: number) => {
              return new Paragraph({
                children: [
                  new TextRun({
                    text: `\t${item}`,
                  }),
                ],
                tabStops: this.defaultTabStop,
              });
            }),
            new Paragraph({ text: `10.สถานที่ดำเนินการ` }),
            new Paragraph({ text: `\t${(doc.data ?? {}).location ?? ""}`,
            tabStops: this.defaultTabStop,}),
            new Paragraph({ text: `11.ระยะเวลาดำเนินการ` }),
            new Paragraph({ text: `\t${(doc.data ?? {}).period ?? ""}` ,
            tabStops: this.defaultTabStop,}),
            new Paragraph({ text: `12.วิธีดำเนินการ` }),
            ...((doc.data ?? {}).strategies??[]).map((item: any, i: number) => {
              return new Paragraph({
                children: [
                  new TextRun({
                    text: `\t${item}`,
                  }),
                ],
                tabStops: this.defaultTabStop,
              });
            }),
            new Paragraph({
              text: `13.แผนการปฎิบัติงานและแผนการใช้งานงบประมาณ ${(
                (doc.data ?? {}).budget[0]??0
              ).formatComma()} บาท`,
            }),
            new Table({
              width: { size: 9000, type: WidthType.DXA },
              rows: [...data2],
            }),
            new Paragraph({ text: `14.ผลที่คาดว่าจะได้รับ` }),
            ...((doc.data ?? {}).expected??[]).map((item: any, i: number) => {
              return new Paragraph({
                children: [
                  new TextRun({
                    text: `\t${item}`,
                  }),
                ],
                tabStops: this.defaultTabStop,
              });
            }),
            new Paragraph({ text: `15.ผู้รับผิดชอบ` }),
            new Paragraph({
              text: `\t${
                (doc.data ?? {}).coordinator[0].prefix ?? ""
              }${(doc.data ?? {}).coordinator[0].firstName ?? ""} ${
                (doc.data ?? {}).coordinator[0].lastName ?? ""
              } เบอร์โทร ${(doc.data ?? {}).coordinator[0].tel ?? ""}`,
              tabStops: this.defaultTabStop,
            }),
            new Table({
              width: { size: 9000, type:WidthType.DXA, },
              rows: [...data],
            }),
          ],
        },
      ],
    });
    this._blob = await Packer.toBlob(document);
  }
  async createB01(): Promise<any> {
    this.init = true;
    const document = new Document({
      sections: [
        {
          children: [
            new Paragraph({
              frame: {
                position: {
                  x: 1000,
                  y: 2000,
                },
                width: 4000,
                height: 1000,
                anchor: {
                  horizontal: FrameAnchorType.MARGIN,
                  vertical: FrameAnchorType.MARGIN,
                },
                alignment: {
                  x: HorizontalPositionAlign.CENTER,
                  y: VerticalPositionAlign.TOP,
                },
              },
              children: [
                new TextRun({
                  // text:`รายละเอียดตัวชี้วัดเพื่อประกอบคำรับรองการปฏิบัติงาน\r\nกกท. ประจำปีงบประมาณ พ.ศ. ........ `,
                  // size: 15*2,
                  text: "รายละเอียดตัวชี้วัดเพื่อประกอบคำรับรองการปฏิบัติงาน กกท. ประจำปีงบประมาณ พ.ศ. ........",
                  size: 15 * 2,
                }),
              ],
              alignment: AlignmentType.CENTER,
            }),
            // new Paragraph({
            //   children: [
            //   new ImageRun({
            //     data: Buffer.from(require("./line").default, "base64"),
            //     transformation: {
            //       width: 530,
            //       height: 1,
            //     }
            //   })
            // ], alignment: AlignmentType.CENTER,
            // }),
          ],
        },
      ],
    });
    this._blob = await Packer.toBlob(document);
  }
}
