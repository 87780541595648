











































import { Component, Vue } from 'vue-property-decorator';
import axios from "axios"
import { ADDRESS } from "@/setting";
@Component({
  components: {
  },
})
export default class List extends Vue {
  doc:any = {}
  docs:Array<any> = []
  show: number = 20;
  page: number = 1;
  pages: number = 0;
  total: number = 0;
  address = ADDRESS

  mounted() {
    this.fetchReview()
    this.fetchData()
  }
  fetchReview() {
    axios
      .get(`${ADDRESS}/indicator/get/${this.$route.params.id}`)
      .then((response) => {
        console.log(response.data);
        if (response.data.status === "success") {
          let data = response.data.doc;
          this.doc = data;
        } else {
          console.log(`พบปัญหาในการดึงข้อมูลจากแม่ข่าย , ${response.data.error}`);
        }
      });
  }
  deleteItem(id: string) {
    var c = confirm(`คุณแน่ใจหรือว่าต้องการลบรายการนี้`);
    if (c) {
      axios.delete(`${ADDRESS}/indicatorReview/delete/${id}`).then((response) => {
        console.log(response)
        this.fetchData();
      });
    }
  }
  fetchData() {
    this.docs = []
    let search:Array<string> = []
    let searchQuery = ""
    let query = {
      search:`WHERE indicator_id LIKE '%${this.$route.params.id}%'`,
    }
    axios.post(`${ADDRESS}/indicatorReviews/paginate`,query).then((response) => {
      console.log(response.data);
      if (response.data.status === "success") {
      this.docs = response.data.docs;
      this.total = response.data.total;
      this.pages = Math.ceil(response.data.total / this.show);
      } else {
        console.log(`พบปัญหาในการดึงข้อมูลจากแม่ข่าย , ${response.data.error}`);
      }
    });
  }
}
