import {  RouteConfig } from 'vue-router'

import DashboardMain from '../../views/dashboard/Main.vue'
import DashboardList from '../../views/dashboard/List.vue'

const routes: Array<RouteConfig> = [
  {
    path: 'Dashboard',
    component: DashboardMain,
    meta:{
      "ac": "Dashboard,r"
    },
    children: [
      {
        path: '',
        name: 'Dashboard List',
        component: DashboardList,
        meta:{
          "ac": "Dashboard,r"
        },
      },
    ]
  },
]
export default routes