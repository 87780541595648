


















































































































import { Component, Vue } from "vue-property-decorator";
import axios from "axios";
import { ADDRESS } from "@/setting";
import { DateTime } from "luxon";
import { ConfirmAction } from "@/decorators";

@Component({
  components: {},
})
export default class Form extends Vue {
  doc: any = {
    assigns:[]
  };
  budgets:Array<any> = []
  address = ADDRESS
  pages: number = 0;
  total: number = 0;
  show: number = 999;
  get isDetail (){
    if(this.$route.path.search(`/detail/`)!=-1) return true
    else return false
  }
  pushAssign(){
    if(!Array.isArray(this.doc.assigns)) this.doc.assigns = []
    this.doc.assigns.push("")
  }
  submitForm() {
    let prep = {
      ...this.doc,
      finishDate: undefined,
      originator:this.$store.state.userId
    }
    if (this.$route.params.id == undefined) {
      axios.post(`${ADDRESS}/assignment/create`, prep)
      .then((response) => {
        // console.log(response)
        alert("ดำเนินการบันทึกสำเร็จแล้ว")
        this.$router.push("/portal/assignment")
      })
    } else {
      axios.patch(`${ADDRESS}/assignment/update/${this.doc.id}`,prep)
      .then((response) => {
        // console.log(response)
        alert("ดำเนินการแก้ไขสำเร็จแล้ว")
        this.$router.push("/portal/assignment")
      })
    }
  }
  fetchData() {
    axios
      .get(`${ADDRESS}/assignment/get/${this.$route.params.id}`)
      .then((response) => {
        // console.log(response.data);
        if (response.data.status === "success") {
          let data = response.data.doc;
          this.doc = data;
        } else {
          console.log(`พบปัญหาในการดึงข้อมูลจากแม่ข่าย , ${response.data.error}`);
        }
      });
  }
  fetchBudgetAnnual(){
    this.budgets = []
    let search:Array<string> = []
    let searchQuery = ""
    let query = {
      skip: 0,
      limit: 9999,
    }
    axios.post(`${ADDRESS}/budgets/paginate`,query).then((response) => {
      console.log(response)
      console.log(response)
      this.budgets = response.data.result;
      // this.total = response.data.total;
      // this.pages = Math.ceil(response.data.total / this.show);
    });
  }
  mounted() {
    this.fetchBudgetAnnual()
    this.fetchData();
  }
  @ConfirmAction("ยืนยันลบมอบหมาย?")
  deleteAssigns(index:number){
    this.doc.assigns.splice(index, 1)
  }
  @ConfirmAction("ยืนยันลบเอกสารแนบ?")
  deleteAttachments(i:number){
    this.doc.attachments.splice(i,1)
  }
}
