








































import { Component, Vue } from "vue-property-decorator";
import axios from "axios";
import { ADDRESS } from "@/setting";

@Component({
  components: {
  },
})
export default class B03 extends Vue {
  docs:Array<any> = []
  sum:number = 0

  fetchData() {
    let query = {
      budgetYear : parseInt(this.$route.params.budgetYear),
      sector : this.$route.params.sector
    }
    axios.post(`${ADDRESS}/report/B03`,query).then((response) => {
      console.log('report/B03',response)
      this.docs = response.data.result;
      this.sum = response.data.sum;
    });
  }
  mounted() {
    this.fetchData()
  }
}
