











import { Component, Vue, Watch } from "vue-property-decorator";
@Component({
  props: {
    budget: Object
  },
})
export default class Form extends Vue {
  budget!:any
  resolveStatus(item:any){
    try {
      if(item.status==="เห็นชอบ")
        return `${item.status}โดย ${item.responsibles[item.step].name} แล้ว`
      else if(item.status==="ตีกลับ")
        return  `คำขอของคุณโดนตีกลับเพราะ<br/> ${item.comment||'"ไม่มีคำอธิบาย"'}`
      else if(item.status==="ส่งคืน")
        return  `คำขอของคุณถูกส่งคืนเพราะ<br/> ${item.comment||'"ไม่มีคำอธิบาย"'}`
    } catch (error) {
      return "-"
    }
  }
}
