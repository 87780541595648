




















































import { Component, Vue } from "vue-property-decorator";
import axios from "axios";
import { ADDRESS } from "@/setting";
import { DateTime } from "luxon";
@Component({
  components: {
  },
})
export default class adjustForm extends Vue {
  doc: any = {
    attachments: [],
    responsibles:[
      {
        name:"",
        type:"ผู้รับผิดชอบ",
        position:""
      },
      {
        name:"",
        type:"ผู้อำนวยการกอง",
        position:""
      },
      {
        name:"",
        type:"ผู้อำนวยการฝ่าย/สำนัก",
        position:""
      }
    ]
  };
  budget: any = {}
  name1: string = ""
  name2: string = ""
  name3: string = ""
  position1: string = ""
  position2: string = ""
  position3: string = ""
  address = ADDRESS;
  
  pushResponsibles(i:any) {
    try { 
      let array = this.doc.responsibles
      array = []
      array.push({name:this.doc.responsibles.name[i],position:this.doc.responsibles.position[i]})
      this.$set(this.doc,"responsibles", array)
    } catch (error) {
      
    }
  }
  submitForm() {
    let prep = {
      ...this.doc,
      adjustDate : DateTime.now().toJSDate(),
      project_id: this.budget.id,
      attachments:this.doc.attachments,
      modifiedAt : DateTime.now().toJSDate(),
      modifiedBy : this.$store.state.fullName,
      responsibles: this.doc.responsibles,
    }
    console.log('prep',prep)
    // if (this.doc.id == undefined) {
    //   axios.post(`${ADDRESS}/adjustment/create`, prep)
    //   .then((response) => {
    //     console.log('response',response)
    //     alert("ดำเนินการบันทึกสำเร็จแล้ว")
    //     this.$router.push(`/portal/budget/adjust/list/${this.$route.params.id}`)
    //   })
    // } else {
    //   axios.patch(`${ADDRESS}/adjustment/update/${this.doc.id}`,prep)
    //   .then((response) => {
    //     console.log('response',response)
    //     alert("ดำเนินการแก้ไขสำเร็จแล้ว")
    //     this.$router.push(`/portal/budget/adjust/list/${this.$route.params.id}`)
    //   })
    // }
  }
  fetchData() {
    axios
      .get(`${ADDRESS}/adjustment/get/${this.$route.params.adjustid}`)
      .then((response) => {
        console.log(response.data);
        if (response.data.status === "success") {
          let data = response.data.doc;
          this.doc = data
          this.doc.modifiedBy = this.$store.state.fullName
        } else {
          console.log(`พบปัญหาในการดึงข้อมูลจากแม่ข่าย , ${response.data.error}`);
        }
      });
  }
  fetchBudget() {
    axios
      .get(`${ADDRESS}/budget/get/${this.$route.params.id}`)
      .then((response) => {
        console.log('budget/get',response.data);
        if (response.data.status === "success") {
          let data = response.data.doc;
          this.budget = data
        } else {
          console.log(`พบปัญหาในการดึงข้อมูลจากแม่ข่าย , ${response.data.error}`);
        }
      });
  }
  mounted() {
    this.fetchBudget()
    if(this.$route.params.adjustid != undefined) this.fetchData()
  }
}
