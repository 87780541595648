





























































import { Component, Vue } from "vue-property-decorator";
import axios from "axios";
import { ADDRESS } from "@/setting";
import draggable from "vuedraggable";
import { DateTime } from "luxon";
import { gt } from "lodash";

@Component({
  components: {
    draggable,
  },
})
export default class Report extends Vue {
  docs: Array<any> = [];
  budgetItem: Array<any> = [];
  address = ADDRESS;
  filter1: number = 0;
  filter2: string = "";
  filter3: any = {}
  fromDate: Date = DateTime.now().startOf("month").toJSDate();
  toDate: Date = DateTime.now().endOf("month").toJSDate();
  show: number = 20;
  page: number = 1;
  pages: number = 0;
  total: number = 0;
  sectors: Array<any> = [];
  types: Array<any> = [];
  active_i: Array<any> = [];
  deleteReason: string = "";
  sumAmount: number = 0;
  budgetYear:number = new Date().getFullYear()+543
  get fhais(){
    let data = (this.$store.state.sectors||[]).filter((sector:any)=>sector.level==3&&(sector.name??"").substring(0,3)!=="กอง")
    return data
  }
  get currentBudgetYear(){
    let year = new Date().getFullYear()+543
    let month = new Date().getMonth() + 1
    if(month >= 10) year += 1
    return year
  }
  updateBudgetYear(ev:number){
    this.$set(this, "budgetYear", ev)
  }
  fetchSector() {
    return new Promise((resolve, reject) => {
      axios.get(`${ADDRESS}/sectors/list`).then((response) => {
        if (response.data.status === "success") {
          this.sectors = response.data.docs;
          resolve("ok sector");
        } else {
          reject();
          console.log(
            `พบปัญหาในการดึงข้อมูลจากแม่ข่าย , ${response.data.error}`
          );
        }
      });
    });
  }

  getItem(id: string) {
    let result = "";
    return (this.budgetItem.find((bi) => bi.id == id) ?? {}).subject;
  }

  toThaiFormat(dt: string) {
    var dtThai = DateTime.fromISO(dt)
      .reconfigure({ outputCalendar: "buddhist" })
      .setLocale("th")
      .toFormat("LLLL");
    return dtThai;
  }
  getMonth(i:number) {
    switch (i) {
      case 0 : return  "ตุลาคม"; break;
      case 1 : return  "พฤศจิกายน"; break;
      case 2 : return  "ธันวาคม"; break;
      case 3 : return  "มกราคม"; break;
      case 4 : return  "กุมภาพันธ์"; break;
      case 5 : return  "มีนาคม"; break;
      case 6 : return  "เมษายน"; break;
      case 7 : return  "พฤษภาคม"; break;
      case 8 : return  "มิถุนายน"; break;
      case 9 : return  "กรกฎาคม"; break;
      case 10 : return  "สิงหาคม"; break;
      case 11 : return  "กันยายน"; break;
    }
  }
  fetchData() {
    let promise = new Promise((resolve, reject) => {
      this.docs = [];
      let query = {
          budgetYear:this.budgetYear??this.currentBudgetYear,
          ...this.filter3,
          limit:2000,
          skip:0,
          sort:{
            createdAt:'desc'
          }
      };
      console.log("query", query);
      axios.post(`${ADDRESS}/report/B06`, query).then((response) => {
        console.log("report/B06", response.data.status);
        if (response.data.status === "success") {
          this.docs = response.data.results;
          this.sumAmount = response.data.sumAmount;
          // this.total = response.data.total;
          // this.pages = Math.ceil(response.data.total / this.show);
          resolve("ok data");
        } else {
          reject();
          console.log(
            `พบปัญหาในการดึงข้อมูลจากแม่ข่าย , ${response.data.error}`
          );
        }
      });
    });
    return promise;
  }
  fetchBudgetItem() {
    return new Promise((resolve, reject) => {
      let prep: Array<string> = [];
      this.docs.forEach((doc) => {
        prep.push(`${doc.budgetItem_id}`);
      });
      console.log("docs", prep);
      axios
        .post(`${ADDRESS}/budget-items/paginate`, {
          limit: 999,
          skip: 0,
          search: {
            id: { in: prep },
          },
        })
        .then((response) => {
          if (response.data.status === "success") {
            this.budgetItem = response.data.result;
            resolve("ok BudgetItem");
          } else {
            reject();
            console.log(
              `พบปัญหาในการดึงข้อมูลจากแม่ข่าย , ${response.data.error}`
            );
          }
        });
    });
  }
  mounted() {
    let status = "";
    this.main();
    this.budgetYear = this.currentBudgetYear
  }
  async main() {
    let today = new Date()
    let month = today.getMonth() + 1
    let year = today.getFullYear()
    if(month >= 10) year += 1

    this.fromDate = DateTime.fromObject({year: year-1, month: 10, day: 1}).toJSDate();
    this.toDate = DateTime.fromObject({year: year, month: 9, day: 30}).toJSDate();
    await this.fetchData();
    await this.fetchSector();
    // await this.fetchBudgetItem();
  }
}
