











import { Component, Vue } from "vue-property-decorator";
import { Watch } from "vue-property-decorator";
import { Store } from "vuex";
import axios, { AxiosResponse } from "axios";
import { ADDRESS } from "@/setting";
import _ from "lodash";

@Component({
  components: {},
  props: {
    value: Object,
    label: String,
    disabled:Boolean
  },
})
export default class JMOfficer extends Vue {
  name: string = "";
  search: string = "";
  value?: any;
  show: boolean = false;
  users: Array<any> = [];
  debouncedHandler:any
  created() {
    this.debouncedHandler = _.debounce(event => {
      this.listData()
    }, 300);
  }
  beforeUnmount() {
    this.debouncedHandler.cancel();
  }
  mounted() {
    this.listData();
    console.log('value:',this.value)
    this.search = (this.value!.prefix??"") + (this.value!.firstName??"") + " " + (this.value!.lastName??"")//this.value ?? "";
  }
  emitUp(item: any) {
    let prep = {
      prefix: item.prefix,
      firstName: item.firstName,
      lastName: item.lastName,
      name: (item.firstName??"") + " " + (item.lastName??""),
      id: item.id,
      position: item.position,
      tel: item.extensionNumber,
      sector: this.resolveSector(item.sector3),
      ngarn: this.resolveSector(item.sector5),
      email: item.email
    }
    this.$emit("input", prep);
    this.$emit("change", prep);
    // this.$emit("input", item);
    this.search = (item.prefix??"") + (item.firstName??"") + " " + (item.lastName??"")
  }
  resolveSector(_id:string):string{
    try {
      let foundName = this.$store.state.sectors.find((item:any)=>item.id==_id)
      return foundName.name
    } catch (error) {
      return _id
    }
  }

  listData() {
    if (this.search.length > 2) {
      this.show = true;
      
      let firstName = ""
      let lastName = ""
      try { firstName = this.search.split(' ')[0]; lastName = this.search.split(' ')[1]; } catch(err) { }
      let query = {
        limit:999,
        skip:0,
        search:{
          OR:[
            {
              firstName: {
                contains: this.search,
              }
            },
            {
              lastName: {
                contains: this.search,
              }
            },
            {
              AND:[
                {
                  firstName: {
                    contains: firstName,
                  }
                },
                {
                  lastName: {
                    startsWith: lastName,
                  }
                },
              ]
            }
          ]
        },
      }
      axios
        .post(`${ADDRESS}/users/paginate`,query)
        .then((response) => {
          console.log(response.data)
          this.users = response.data.docs;
        });
    }
  }
}
