





























































import { Component, Vue } from "vue-property-decorator";
import axios from "axios";
import { ADDRESS } from "@/setting";

@Component({
  components: {},
})
export default class B26 extends Vue {
  docs:Array<any> = []
  filter1:any= {}
  filter2:any= {}
  filter3:any= {}
  sumBudget:number = 0
  sumWithdrawn:number = 0
  
  fetchData() {
    let query = {
      budgetYear : this.filter1.budgetYear,
      sector : this.filter2.sector,
      // sort: {

      // }
    }
    axios.post(`${ADDRESS}/report/B26`,query).then((response) => {
      console.log('report/B26',response)
      this.docs = response.data.result;
      this.sumBudget = response.data.sumBudget;
      this.sumWithdrawn = response.data.sumWithdrawn;
    });
  }
  percentBudgetItem(n:number) {
    let result = (n/this.sumBudget)*100
    return result
  }
  mounted() {
    this.fetchData();
  }
}
