










































import { ADDRESS } from "@/setting";
import axios from "axios";
import { Component, Vue, Watch } from "vue-property-decorator";
import DocumentC from "@/document/pdf/C"
@Component({
  props: {
    budget: Object,
    flatted: Array,
  },
  components: {},
})
export default class HistoryD1 extends Vue {
  budget!: any
  flatted!: Array<any>
  requests: Array<any> = []
  mounted() {
    this.init()
  }
  async init() {
    await this.fetchBudgetRequest()
  }
  getSubject(id:string){
    try {
      return this.flatted.find(flat=>flat.id===id).subject
    } catch (error) {
      return ""
    }
  }
  fetchBudgetRequest() {
    return new Promise((resolve, reject) => {
      this.requests = []
      let query = {
        limit: 9999,
        skip: 0,
        search: {
          project_id: this.$route.params.id
        },
        sort: {
          createdAt: "desc"
        }
      }
      console.log({ query })
      axios.post(`${ADDRESS}/budgetRequests/paginate`, query).then((response) => {
        console.log('budgetRequests', response)
        this.requests = response.data.docs;
        resolve("done")
      });
    })
  }

  resolveMonth(n: number) {
    switch (n) {
      case 1:
        return "ต.ค."
        break;
      case 2:
        return "พ.ย."
        break;
      case 3:
        return "ธ.ค."
        break;
      case 4:
        return "ม.ค."
        break;
      case 5:
        return "ก.พ."
        break;
      case 6:
        return "มี.ค."
        break;
      case 7:
        return "เม.ย."
        break;
      case 8:
        return "พ.ค."
        break;
      case 9:
        return "มิ.ย."
        break;
      case 10:
        return "ก.ค."
        break;
      case 11:
        return "ส.ค."
        break;
      case 12:
        return "ก.ย."
        break;

      default:
        return n
        break;
    }
  }
}
