











































































































import { Component, Vue } from "vue-property-decorator";
import axios from "axios";
import { ADDRESS } from "@/setting";
import draggable from "vuedraggable";
import { DateTime } from "luxon";
import ModalInfo from "./components/ModalInfo.vue"
import Modalfollowperformance from "./components/Modalfollowperformance.vue"
import TableRow from "../budget/planComponents/TableRow.vue"
@Component({
  components: {
    draggable,
    ModalInfo,
    Modalfollowperformance,
    TableRow
  },
})
export default class Form extends Vue {
  budget: any = {}
  budgetitems:Array<any> = []
  follows:Array<any> = []
  tracks:Array<any> = []
  erps:Array<any> = []
  address = ADDRESS;
  activeItem: any = {
    months:[],
    monthsFlag:[],
    indicators:[]
  }
  activeTrack: any = {

  }
  adjustment: any = {};
  activeTrackIndex: number = 0
  indicators:Array<any> = []
  active_i: number = -1
  active_j: number = -1
  active_k: number = -1
  levIndex:number = -1
  today = new Date()
  followOnly:Boolean = false
  showActual:boolean = false

  mapMonth = [0,4,5,6,7,8,9,10,11,12, 1, 2, 3]

  fetchERP(){
    return new Promise((resolve,reject)=>{

      this.erps = []
      let query = {
        skip: 0,
        limit: 9999,
        search:{
          project_id : this.$route.params.id,
          NOT:{
            status:"deleted"
          }
        }
      }
      axios.post(`${ADDRESS}/erps/paginate`,query).then((response) => {
        this.erps = response.data.docs;
        resolve("done")
      });
    })
  }
  get flatted(){
    let prep:Array<any> = []
    try {
      this.budgetitems.forEach(e0=>{
        prep.push(e0)
        e0.children.forEach((e1:any)=>{
          prep.push(e1)
          e1.children.forEach((e2:any)=>{
            prep.push(e2)
            e2.children.forEach((e3:any)=>{
              prep.push(e3)
            })
          })
        })
      })
    } catch (error) {
      
    }
    return prep
  }

  get indicatorsText(){
    try {
      let result = (((this.budget.data||{})).indicators||[]).map((el:any)=>{
        let found = this.indicators.find(ind=>ind.id==el)
        return found
      })
      return result
    } catch (error) {
      
    }
    return "-"
  }

  resolveSectorFhai(_id:string):string{
    try {
      let code = this.$store.state.sectors.find((item:any)=>item.id==_id).code
      let foundName = this.$store.state.sectors.filter((item:any)=>item.level==3).find((item:any)=>item.code==code)
      return foundName.name
    } catch (error) {
      return ""
    }
  }
  openModalById(id:string){
    try {
      let isFound = false
      this.budgetitems.forEach((bi1,i)=>{
        if(bi1.id===id) {
          this.activeItem = this.budgetitems[i];
          this.activeItem.previous = JSON.parse(JSON.stringify(this.activeItem))
          isFound = true
        }
      });
      if(!isFound)
      this.budgetitems.forEach((bi1,i)=>{
        bi1.children.forEach((bi2:any,j:number)=>{
          if(bi2.id===id) {
            this.activeItem = this.budgetitems[i].children[j];
            this.activeItem.previous = JSON.parse(JSON.stringify(this.activeItem))
            isFound = true
          }
        })
      });
      if(!isFound)
      this.budgetitems.forEach((bi1,i)=>{
        bi1.children.forEach((bi2:any,j:number)=>{
          bi2.children.forEach((bi3:any,k:number)=>{
            if(bi3.id===id) {
              this.activeItem = this.budgetitems[i].children[j].children[k];
              this.activeItem.previous = JSON.parse(JSON.stringify(this.activeItem))
              isFound = true
            }
          })
        })
      });
      (this.$refs.openmodal as HTMLElement).click()
    } catch (error) {
      console.log(error)
      alert("พบปัญหาในการแก้ไขรายการ")
    }
  }

  get flatten(){
    let resuts:Array<any> = []
    try {
      this.budgetitems.forEach(l1 => {
        let clone = JSON.parse(JSON.stringify(l1))
        delete clone.children
        resuts.push(clone)
        l1.children.forEach((l2:any) => {
          let clone = JSON.parse(JSON.stringify(l2))
          delete clone.children
          resuts.push(clone)
          l2.children.forEach((l3:any) => {
            let clone = JSON.parse(JSON.stringify(l3))
            delete clone.children
            resuts.push(clone)
          })
        })
      })
    } catch (error) {
      
    }
    return resuts
  }
  checkFollow(id:string):boolean{
    if(!this.followOnly) return true
    return this.budget.followList.findIndex((fl:any)=>fl==id)!=-1;
  }
  sumFollow(item:any) {
    try {
      let result = this.follows.filter(f=>f.budgetItem_id==item.id).map(m=>m.actualSpend).reduce((a,b)=>a+b,0)
      return result
    } catch (error) {
      return 0
    }
  }
  getFollowValue(item:any, m:number){
    try {
      let found = this.follows.find(f=>f.month==m&&f.budgetItem_id==item.id)
      return found.actualSpend
    } catch (error) {
      return 0
    }
  }
  sumMonths(item:any,search:String) {
    try {
      let result = (item.budgetItemType||"").search(search)!=-1?(item.months||[]).reduce((a:any,b:any)=>a+b,0):0
      return result
      } catch (error) {
      return 0
    }
  }
  sumMonthsERP(item:any,search:String) {
    try {
      let result = (item.budgetItemType||"").search(search)!=-1?(item.monthsERP||[]).reduce((a:any,b:any)=>a+b,0):0
      return result
      } catch (error) {
      return 0
    }
  }
  get percentProgress(){
    try {
      let sum = this.tracks.map(f=>f.progress??0).reduce((a,b)=>a+b, 0)
      let length = this.tracks.length
      return sum/length
    } catch (error) {
      return 0
    }
  }
  get percentWithdrawn(){
    try {
      let budget = this.budgetitems.map(f=>f.months.reduce((a:number,b:number)=>a+b,0)).reduce((a,b)=>a+b, 0)
      let spends = this.budgetitems.map(f=>f.monthsERP.reduce((a:number,b:number)=>a+b,0)).reduce((a,b)=>a+b, 0)
      // let length = this.follows.length
      return (budget/spends) * 100
    } catch (error) {
      return 0
    }
  }

  updateTracksStatus(ev:any,id:string) {
    let status = ev.target.value
    let prep = JSON.parse(JSON.stringify(this.tracks))
    let month = this.mapMonth[DateTime.now().month]
    let index = prep.findIndex((f:any) => f.budgetItem_id == id && f.month == month)
    console.log('status',index,month)
    if(index != -1){
      prep[index].status = status
    } else {
      prep.push({
        budgetItem_id:id,
        project_id:this.$route.params.id,
        month: month,
        status:status,
        progress:0,
        note:"",
        fromDateRange:"",
        toDateRange:"",
      })
    }
    console.log(prep)
    axios.post(`${ADDRESS}/budget/track/`, {tracks: prep})
      .then((response) => {
        console.log(response)
          setTimeout(() => {
            this.fetchTracks()
          }, 350);
      })
  }
  fetchTracks() {
    console.log('fetching teacks')
    let query = {
      limit: 500,
      skip: 0,
      search: {
        project_id: this.budget.id,
      },
      sort: {
        createdAt : "desc"
      },
    }
    axios
      .post(`${ADDRESS}/tracks/paginate`, query)
      .then((response) => {
        if (response.data.status === "success") {
          let data = response.data.docs;
          this.tracks = data
        } else {
          console.log(`พบปัญหาในการดึงข้อมูลจากแม่ข่าย , ${response.data.error}`);
        }
    });
  }
  getPercent(id:string){
    try {
      let results = this.tracks.filter(item => item.budgetItem_id == id)
      let max = Math.max(...results.map(r=>r.month))
      let found = results.find(rs=>rs.month == max)
    return found.progress
    } catch (error) {
      return 0
    }
  }
  getProgress(id:string){
    try {
      let results = this.tracks.filter(item => item.budgetItem_id == id)
      let max = Math.max(...results.map(r=>r.month))
      let found = results.find(rs=>rs.month == max)
    return found.planed
    } catch (error) {
      return 0
    }
  }
  // getParentForLevel2(under:string){
  //   try {
  //     let parent2 = this.flatten.find(item => item.id == under)
  //     return {parent2:parent2.subject}
  //   } catch (error) {
  //     return {}
  //   }
  // }
  // getParentForLevel3(under:string){
  //   try {
  //     let parent3 = this.flatten.find(item => item.id == under)
  //     let parent2 = parent3.find((item:any) => item.id == parent3.under)
  //     return {
  //       parent2:parent2.subject,
  //       parent3:parent3.subject
  //     }
  //   } catch (error) {
  //     return {}
  //   }
  // }
  // getBudget(i:number) {
  //   this.getLevel1()?.forEach((m) => {
  //     console.log("budget",m)
  //   })
  // }
  getSurplus(budgets:Array<number>,erps:Array<number>):number{
    let sum = 0
    budgets.forEach((m,i) => {
      if(erps[i]==0) sum += 0
      else sum += m-erps[i]
    });
    return Math.abs(sum)
  }
  getRemaining(budgets:Array<number>,erps:Array<number>):number{
    let sum1 = budgets.reduce((a,b)=>a+b,0)
    let sum2 = erps.reduce((a,b)=>a+b,0)
    let sum3 = this.getSurplus(budgets,erps)
    return sum1 - sum2 - sum3
  }
  toThaiFormat(dt: string) {
    var dtThai = DateTime.fromISO(dt)
      .reconfigure({ outputCalendar: "buddhist" })
      .setLocale("th")
      .toFormat("LLLL");
    return dtThai;
  }
  fetchBudget() {
    axios
      .get(`${ADDRESS}/budget/get/${this.$route.params.id}`)
      .then((response) => {
        console.log(response.data);
        if (response.data.status === "success") {
          let data = response.data.doc;
          this.budget = data
        } else {
          console.log(`พบปัญหาในการดึงข้อมูลจากแม่ข่าย , ${response.data.error}`);
        }
      });
  }
  getLatestRevision():Promise<number>{
    return new Promise((resolve,reject)=>{
    axios
      .get(`${ADDRESS}/budgetitem/get-items-last-revision/${this.$route.params.id}`)
      .then((response) => {
        let revision = 1
        try {
          revision = response.data.docs._max.revision
        } catch (error) {
          
        }
        resolve(revision)
      }).catch(err=>{
        reject(1)
      })
    })
  }
  revision = 1
  
  async fetchBudgetItem() {
    return new Promise((resolve,reject)=>{
      try {
        if (this.adjustment.revision !== undefined)
          this.revision = this.adjustment.bindWithRevision;
      } catch (error) {}
      let body = {
        budget_id: this.$route.params.id,
        adjustment_id: this.adjustment.id,
        revision: this.revision,
      };
      console.log({body})
      axios.post(`${ADDRESS}/budgetitem/get-items`, body).then((response) => {
        if (response.data.status === "success") {
          let data = response.data.results;
          console.log("budget items", response);
          this.budgetitems = data.map((el: any) => {
            return {
              ...el,
              edit: false,
            };
          });
          resolve("done")
        } else {
          reject(response.data.error)
          console.log(`พบปัญหาในการดึงข้อมูลจากแม่ข่าย , ${response.data.error}`);
        }
      });
    })
  }


  getLevel1(): Array<any> {
    try {
      let l1 = this.budgetitems ?? {};
      if (this.budget.showZero)
        l1 = l1.filter(
          (e: any) =>
            (e.months ?? [0]).reduce((a: number, b: number) => a + b, 0) !== 0
        );
      return l1;
    } catch (error) {
      console.log(error);
      return this.budgetitems;
    }
  }
  getLevel2(i: number) {
    try {
      let l1 = this.budgetitems ?? {};
      let l2 = l1[i].children ?? {};
      if (this.budget.showZero)
        l2 = l2.filter(
          (e: any) =>
            (e.months ?? [0]).reduce((a: number, b: number) => a + b, 0) !== 0
        );
      return l2;
    } catch (error) {
      console.log(error);
    }
  }
  getLevel3(i: number, j: number) {
    try {
      let l1 = this.budgetitems ?? {};
      let l2 = l1[i].children ?? {};
      let l3 = l2[j].children ?? {};
      if (this.budget.showZero)
        l3 = l3.filter(
          (e: any) =>
            (e.months ?? [0]).reduce((a: number, b: number) => a + b, 0) !== 0
        );
      return l3;
    } catch (error) {
      console.log(error);
    }
  }
  openModalItem(i:number,a:number) {
    this.activeItem = this.budgetitems[i];
    this.active_i = i;
    this.levIndex = a;
    this.activeTrackIndex = this.tracks.findIndex(f=>f.budgetItem_id==this.activeItem.id&&f.month==a+1)
    console.log(this.activeItem.id, a+1, this.activeTrackIndex)
    if(this.activeTrackIndex != -1){
      this.activeTrack = this.tracks[this.activeTrackIndex]
    } else {
      this.activeTrack = {
        budgetItem_id:this.activeItem.id,
        project_id:this.$route.params.id,
        month: a+1,
        status:"",
        progress: 0,
        note:"",
        fromDateRange:new Date(),
        toDateRange:new Date()
      }
    }
    (this.$refs.openmodal as HTMLElement).click()
  }
  openModalChild(i:number,j:number,a:number) {
    this.activeItem = this.budgetitems[i].children[j];
    this.active_i = i;
    this.active_j = j;
    this.levIndex = a;
    console.log('tracks',this.tracks)
    this.activeTrackIndex = this.tracks.findIndex(f=>f.budgetItem_id==this.activeItem.id&&f.month==a+1)
    console.log(this.activeItem.id, a+1, this.activeTrackIndex)
    if(this.activeTrackIndex != -1){
      this.activeTrack = this.tracks[this.activeTrackIndex]
    } else {
      this.activeTrack = {
        budgetItem_id:this.activeItem.id,
        project_id:this.$route.params.id,
        month: a+1,
        status:"",
        progress: 0,
        note:"",
        fromDateRange:new Date(),
        toDateRange:new Date()
      }
      console.log('activeTrack',this.activeTrack)
    }
    (this.$refs.openmodal as HTMLElement).click()
  }
  openModalChildChild(i:number,j:number,k:number) {
    this.activeItem = this.budgetitems[i].children[j].children[k];
    this.active_i = i;
    this.active_j = j;
    this.active_k = k;
    (this.$refs.openmodal as HTMLElement).click()
  }
  openModalInfo() {
    (this.$refs.openmodal2 as HTMLElement).click()
  }
  getMonth(i:number) {
    switch (i) {
      case 0 : return  "ตุลาคม"; break;
      case 1 : return  "พฤศจิกายน"; break;
      case 2 : return  "ธันวาคม"; break;
      case 3 : return  "มกราคม"; break;
      case 4 : return  "กุมภาพันธ์"; break;
      case 5 : return  "มีนาคม"; break;
      case 6 : return  "เมษายน"; break;
      case 7 : return  "พฤษภาคม"; break;
      case 8 : return  "มิถุนายน"; break;
      case 9 : return  "กรกฎาคม"; break;
      case 10 : return  "สิงหาคม"; break;
      case 11 : return  "กันยายน"; break;
    }
  }
  submitForm() {
    axios
      .patch(`${ADDRESS}/budget/update/${this.$route.params.id}`, {...this.budget})
      .then((response) => {
        console.log(response);
        alert("ดำเนินการแก้ไขสำเร็จแล้ว");
        // this.$router.push("/portal/budget")
      });
  }
  fetchIndicatorsByBudget() {
    return new Promise((resolve,reject)=>{
      let query = {
        skip: 0,
        limit: 5000,
      };
      axios.post(`${ADDRESS}/indicators/paginate`, query).then((response) => {
        this.indicators = response.data.docs;
        resolve("done")
      });
    })
  }
  fetchAdjustment(): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${ADDRESS}/budgetAdjustment/get/${this.$route.params.adjustment_id}`
        )
        .then((response) => {
          this.adjustment = response.data.doc ?? { status: "Open" };
          resolve("done");
        })
        .catch((error) => {
          reject("error");
        });
    });
  }
  mounted() {
    this.init()
  }
  async init(){
    await this.fetchBudget();
    await this.fetchIndicatorsByBudget();
    await this.fetchAdjustment();
    await this.fetchBudgetItem()
    this.fetchTracks()
    await this.fetchERP()
  }
}
