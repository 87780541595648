








































































































import { ADDRESS } from '@/setting';
import axios from 'axios';
import { update } from 'lodash';
import { DateTime } from 'luxon';
import { Component, Vue } from 'vue-property-decorator';
import { DocumentExcel } from "@/document/xlsx"

@Component({
  components: {
  },
})
export default class Attendant extends Vue {
  today = new Date()
  time = ""
  show: number = 5;
  page: number = 1;
  pages: number = 0;
  total: number = 0;
  docs:Array<any> = []
  list:Array<any> = []
  fromDate: Date = DateTime.now().startOf("month").toJSDate()
  toDate: Date = DateTime.now().endOf("month").toJSDate()

  async saveAsExcel() {
    let docExcel = new DocumentExcel()
    docExcel.createB27(this.docs)
  }
  get userInTime(){
    try {
      return new Date(this.docs[0].inTime).toTime()
    } catch (error) {
      return "-"
    }
  }
  get userOutTime(){
    try {
      return new Date(this.docs[0].outTime).toTime()
    } catch (error) {
      return "-"
    }
  }
  print() {
    window.print();
  }
  mounted(){
    this.updateTime()
    this.fetchData()
    this.fetchList()
  }
  updateTime(){
    let self = this
    self.time = DateTime.now().toFormat("H:mm:ss");
    setTimeout(() => { 
      this.updateTime()
     }, 1000);
  }
  checkin(){
    let prep = {
      staff:this.$store.state.userId,
      inTime: new Date()
    }
    axios.post(`${ADDRESS}/attendant/create`,prep).then(response=>{
      this.fetchData()
      this.fetchList()
    })
  }
  checkout(){
    let prep = {
      staff:this.$store.state.userId,
      outTime: new Date()
    }
    axios.patch(`${ADDRESS}/attendant/update/${this.docs[0].id}`,prep).then(response=>{
      console.log(response)
      this.fetchData()
      this.fetchList()
    })
  }
  openModal() {
  (this.$refs.openmodal as HTMLElement).click()
  }
  fetchData(){
    let dt = DateTime.fromJSDate(this.today)
    let dto = dt.toObject()
    let day = dto.day
    let month = dto.month
    let year = dto.year
    console.log(dt)
    let query = {
      // search: `WHERE staff = '${this.$store.state.userId}' AND inDay = ${day} AND inMonth = ${month} AND inYear = ${year}`,
      // search: `WHERE inDay = ${day} AND inMonth = ${month} AND inYear = ${year}`,
      search:{
        staff:this.$store.state.userId,
        inTime:{
          gte: dt.startOf('day').toISO(),
          lt:  dt.endOf('day').toISO()
        }
      },
      skip:0,
      limit:999
    }
    axios.post(`${ADDRESS}/attendants/paginate`,query).then(response=>{
      console.log("response",response.data)
      this.docs = response.data.docs
    })
  }
  fetchList(){

    
    let query = {
      search: {
      //search: `WHERE inDay = ${day} AND inMonth = ${month} AND inYear = ${year}`,
       staff:this.$store.state.userId,
       inTime:{
          gte: this.fromDate,
          lt:  this.toDate
        }
        },
      skip:0,
      limit:999
    }
    axios.post(`${ADDRESS}/attendants/paginate`,query).then(response=>{
      console.log("CCC")
      console.log(response)
      this.list = response.data.docs;
      this.total = response.data.total;
      this.pages = Math.ceil(response.data.total / this.show);
    })
  }
}
