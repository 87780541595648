


























































































import { Component, Vue } from 'vue-property-decorator';
import axios from "axios"
import { ADDRESS } from "@/setting"
import draggable from "vuedraggable";
import * as _ from "lodash"
import { ConfirmAction } from '@/decorators';
@Component({
  components: {
    draggable
  },
})
export default class List extends Vue {
  page: number = 1;
  pages: number = 0;
  show: number = 200;
  total: number = 0;
  docs:Array<any> = []
  filter1:any = {}
  categories:Array<string> = [
    'ผู้ว่า',
    'รองผู้ว่า',
    'คณะกรรมการ',
    'ฝ่าย',
    'สำนักงาน',
    'สำนัก',
    'กอง',
    'งาน',
  ]
  sectors:Array<any> = [
    {
      name:"หน่วยงานที่ 1",
      category:"ด้าน",
      level:1,
      edit:false,
      children:[
        {
          name:"หน่วยงานที่ 1.1",
          category:"ด้าน",
          level:2,
          edit:false,
          children:[
            {
              name:"หน่วยงานที่ 1.1.1",
              category:"ด้าน",
              level:3,
              edit:false,
              children:[
                {
                  name:"หน่วยงานที่ 1.1.1",
                  category:"ด้าน",
                  level:4,
                  edit:false,
                  children:[
                    {
                      name:"หน่วยงานที่ 1.1.1.1",
                      category:"ด้าน",
                      level:5,
                      edit:false,
                      children:[],
                    }
                  ],
                }
              ],
            }
          ],
        }
      ],
    },
    {
      name:"หน่วยงานที่ 2",
      category:"ด้าน",
      level:1,
      edit:false,
      children:[],
    },
  ]
  address = ADDRESS
  pushSector1(){
    this.sectors.push({
      name:"หน่วยงานใหม่",
      category:"ฝ่าย",
      level:1,
      edit:true,
      children:[],
    })
  }
  pushSector2(i:number){
    this.sectors[i].children.push({
      name:"หน่วยงานใหม่",
      category:"ฝ่าย",
      level:2,
      edit:true,
      children:[],
    })
  }
  pushSector3(i:number,j:number){
    this.sectors[i].children[j].children.push({
      name:"หน่วยงานใหม่",
      category:"ฝ่าย",
      level:3,
      edit:true,
      children:[],
    })
  }
  pushSector4(i:number,j:number,k:number){
    this.sectors[i].children[j].children[k].children.push({
      name:"หน่วยงานใหม่",
      category:"กอง",
      level:4,
      edit:true,
      children:[],
    })
  }
  pushSector5(i:number,j:number,k:number,l:number){
    this.sectors[i].children[j].children[k].children[l].children.push({
      name:"หน่วยงานใหม่",
      category:"งาน",
      level:5,
      edit:true,
      children:[],
    })
  }
  mounted(){
    this.fetchData()
  }
  updateData(){
    axios.post(`${ADDRESS}/sectors/multiple-upsert`,this.sectors).then((response) => {
      console.log(response)
    })
  }
  fetchData(){
    axios.get(`${ADDRESS}/sectors/structure`).then((response) => {
      console.log(response.data)
      // this.docs = response.data;
      this.sectors = response.data
    });
  }
  @ConfirmAction("ยืนยันลบ?")
  deleteSectors(i:number, id:string){
    axios.delete(`${ADDRESS}/sector/delete/${id}`).then((response) => {
      console.log(response.data,id)
    })
    this.sectors.splice(i, 1)
  }
  @ConfirmAction("ยืนยันลบ?")
  deleteSectors2(i:number,j:number, id:string){
    axios.delete(`${ADDRESS}/sector/delete/${id}`).then((response) => {
      console.log(response.data,id)
    })
    this.sectors[i].children.splice(j,1)
    
  }
  @ConfirmAction("ยืนยันลบ?")
  deleteSectors3(i:number,j:number,k:number, id:string){
    axios.delete(`${ADDRESS}/sector/delete/${id}`).then((response) => {
      console.log(response.data,id)
    })
    this.sectors[i].children[j].children.splice(k,1)
    
  }
  @ConfirmAction("ยืนยันลบ?")
  deleteSectors4(i:number,j:number,k:number,l:number, id:string){
    axios.delete(`${ADDRESS}/sector/delete/${id}`).then((response) => {
      console.log(response.data,id)
    })
    this.sectors[i].children[j].children[k].children.splice(l,1)
    
  }
  // @ConfirmAction("ยืนยันลบ?")
  // deleteSectors5(i:number,j:number,k:number,l:number,m:number, id:string){
  //   axios.delete(`${ADDRESS}/sector/delete/${id}`).then((response) => {
  //     console.log(response.data,id)
  //   })
  //   this.sectors[i].children[j].children[k].children[l].children.splice(m,1)
    
  // }
}
