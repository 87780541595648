

















































































import { Component, Vue } from 'vue-property-decorator';
import axios from "axios"
import { ADDRESS } from "@/setting"
import store from '@/store';
import { DocumentExcel } from "@/document/xlsx"

@Component({
  components: {
  },
})
export default class List extends Vue {
  store = store
  docs:Array<any> = []
  types:Array<any> = []
  sectors:Array<any> = []
  budgetYears:Array<any> = []
  show: number = 20;
  limit: number = 15;
  page: number = 1;
  pages: number = 0;
  total: number = 0;
  filter1:any = {}
  filter2:any = {}
  filter3:any = {}
  search:string = ""
  filter: any = {};
  address = ADDRESS
  loading:boolean = false

  async saveAsExcel1() {
    let docExcel = new DocumentExcel()
    docExcel.createB03(this.docs, 2564)
  }
  async saveAsExcel2() {
    let docExcel = new DocumentExcel()
    docExcel.createB04(this.docs, 2564)
  }
  
  mounted(){
    this.filter1.budgetYear = (new Date().getFullYear() + 543) + ((new Date().getMonth()+1)>10?1:0)
    this.fetchBudgetType()
    this.fetchBudgetYear()
    this.fetchData()
  }
  resolveSector(_id:string):string{
    try {
      let foundName = this.sectors.find(item=>item.id==_id)
      return foundName.name
    } catch (error) {
      return "-"
    }
  }
  resolveSectorFhai(_id:string):string{
    try {
      let code = this.$store.state.sectors.find((item:any)=>item.id==_id).code
      let foundName = this.$store.state.sectors.filter((item:any)=>item.category=="ฝ่าย"||item.category=="สำนักงาน").find((item:any)=>item.code==code)
      return foundName.name
    } catch (error) {
      return ""
    }
  }
  clearSearch(){
    this.filter1 = {}
    this.filter2 =  {}
    this.filter3 =  {}
    this.search =  ""
    this.fetchData()
  }
  updatePaginate(i: number) {
    this.page = i;
    // console.log(this.page);
    this.fetchData();
  }
  get paginate() {
    let arr = new Array(this.pages).fill(0);
    return arr;
  }
  clearPage() {
    if (this.limit == 999999999) {
      this.page = 0;
      this.fetchData();
    }
  }
  fetchData(){
    this.loading = true
    this.docs = []
    let query = {
      skip: this.limit*(this.page-1),
      limit: this.limit,
      search:{
        ...this.filter1,
        ...this.filter2,
        ...this.filter3,
      },
      sort:{
        createdAt:"desc"
      }

    }
    if(this.search!=""){
      query.search.OR = [
        {code: {contains: this.search}},
        {name: {contains: this.search}}
      ]
    }
    axios.post(`${ADDRESS}/budgets/paginate`,query).then((response) => {
      console.log(response)
      this.loading = false
      this.docs = response.data.docs;
      this.total = response.data.total;
      this.pages = Math.ceil(response.data.total / this.limit);
      this.loading = false
    });
  }

  fetchBudgetYear(){
    console.log('ปีงบประมาณ')
    let promise = new Promise((resolve,reject)=>{
    axios
      .get(`${ADDRESS}/setting/get-by-name/ปีงบประมาณ`)
      .then((response) => {
        console.log(response.data);
        if (response.data.status === "success") {
          let data = response.data.doc;
          this.budgetYears = data.member;
          if (this.$route.params.id != undefined) {
            this.fetchData();
          }
          resolve('ok budget year')
        } else {
          reject(response.data.error)
          console.log(`พบปัญหาในการดึงข้อมูลจากแม่ข่าย , ${response.data.error}`);
        }
      });
    })
    return promise
  }
  fetchBudgetType(){
    let promise = new Promise((resolve,reject)=>{
    axios
      .get(`${ADDRESS}/setting/get-by-name/แหล่งงบประมาณในคำขอ`)
      .then((response) => {
        console.log(response.data);
        if (response.data.status === "success") {
          let data = response.data.doc;
          this.types = data.member;
          if (this.$route.params.id != undefined) {
            this.fetchData();
          }
          resolve('ok budget type')
        } else {
          reject(response.data.error)
          console.log(`พบปัญหาในการดึงข้อมูลจากแม่ข่าย , ${response.data.error}`);
        }
      });
    })
    return promise
  }
  deleteItem(id: string) {
    var c = confirm(`คุณแน่ใจหรือว่าต้องการลบรายการนี้`);
    if (c) {
      axios.delete(`${ADDRESS}/budget/delete/${id}`).then((response) => {
        console.log(response)
        this.fetchData();
      });
    }
  }
}
