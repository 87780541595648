





import { DateTime } from "luxon";
import { Component, Vue } from "vue-property-decorator";
import { Watch } from "vue-property-decorator";
@Component({
  components: {},
  props: {
    value: Number,
    label: String,
  },
})
export default class JMBudgetYear extends Vue {
  selectedYear: number = DateTime.now().get('month');
  value!:number
  year = new Date().getFullYear() + 543
  month = new Date().getMonth() + 1
  emitUp(item: any){
    this.$emit("input", this.year)
    this.$emit("change", this.year)
  }
  mounted(){
    if(this.value == undefined){
      if(this.month >= 10) this.year += 1
      this.emitUp(this.year)
    } else {
      this.year = this.value
    }
  }
}
