































































































import { Component, Vue } from "vue-property-decorator";
import axios from "axios";
import { ADDRESS } from "@/setting";
import HistoryAdjust from "./historyAdjust.vue"
@Component({
  components: {
    HistoryAdjust
  },
})
export default class Form extends Vue {
  types:Array<any> = []
  sectors:Array<any> = []
  doc: any = {
    category:"ปรับทีละรายการ",
  };
  docs:Array<any> = []
  request:Array<any> = []
  budget: any = {}
  forms:Array<any> = [];
  address = ADDRESS;
  show: number = 20;
  limit: number = 15;
  page: number = 1;
  pages: number = 0;
  total: number = 0;
  projectId:string = ""
  resolveStatus(item:any){
    try {
      if(item.status==="เห็นชอบ")
        return `${item.status}โดย ${item.responsibles[item.step].name} แล้ว`
      else if(item.status==="ตีกลับ")
        return  `คำขอของคุณโดนตีกลับเพราะ<br/> ${item.comment||'"ไม่มีคำอธิบาย"'}`
      else if(item.status==="ส่งคืน")
        return  `คำขอของคุณถูกส่งคืนเพราะ<br/> ${item.comment||'"ไม่มีคำอธิบาย"'}`
    } catch (error) {
      return "-"
    }
  }
  submitForm(){
    let prep = {
      budget_id : this.budget.id,
      adjustment : this.doc 
    }
    console.log(prep)
    axios.post(`${this.address}/process/budget-open-adjusting`,prep).then((res)=>{
      console.log('res',res)
      this.fetchData()
    }).catch((err)=>{
      console.log(err);
    });
  }
  fetchData() {
    this.docs = []
    let query = {
      skip: this.limit*(this.page-1),
      limit: this.limit,
      search: {
        project_id : this.$route.params.project_id
      },
      sort: {
        createdAt: "desc"
      }
    }
    axios.post(`${ADDRESS}/budgetAdjustments/paginate`,query).then((response) => {
      console.log('requests',response)
      this.docs = response.data.docs;
      this.total = response.data.total;
      this.pages = Math.ceil(response.data.total / this.limit);
    });
  }
  fetchRequest() {
    this.docs = []
    let query = {
      search: {
        project_id : this.$route.params.project_id,
        // status:"เห็นชอบ"
      }
    }
    axios.post(`${ADDRESS}/budgetRequests/paginate`,query).then((response) => {
      console.log(response.data)
      this.request = response.data.docs;
    });
  }
  fetchBudget() {
    axios
      .get(`${ADDRESS}/budget/get/${this.$route.params.project_id}`)
      .then((response) => {
        console.log(response.data);
        if (response.data.status === "success") {
          let data = response.data.doc;
          this.budget = data
          this.doc.name = this.budget.name
          this.doc.project_id = this.budget.id

          this.fetchData()
        } else {
          console.log(`พบปัญหาในการดึงข้อมูลจากแม่ข่าย , ${response.data.error}`);
        }
      });
  }
  deleteItem(item: any) {
    var c = confirm(`คุณแน่ใจหรือว่าต้องการลบรายการนี้`);
    if (c) {
      axios.delete(`${ADDRESS}/budgetAdjustments/delete/${item.id}`).then((response) => {
        console.log(response)
        this.fetchData();
      });
    }
  }

  resolveSector(_id:string):string{
    try {
      let foundName = this.sectors.find(item=>item.id==_id)
      return foundName.name
    } catch (error) {
      return "-"
    }
  }
  resolveSectorFhai(_id:string):string{
    try {
      let code = this.$store.state.sectors.find((item:any)=>item.id==_id).code
      let foundName = this.$store.state.sectors.filter((item:any)=>item.category=="ฝ่าย"||item.category=="สำนักงาน").find((item:any)=>item.code==code)
      return foundName.name
    } catch (error) {
      return ""
    }
  }
  fetchSector(){
    return new Promise((resolve,reject)=>{
      axios.get(`${ADDRESS}/sectors/list`).then((response) => {
        this.sectors = response.data.docs;
        resolve('ok sector')
      });
    })
  }
  mounted() {
    this.fetchBudget()
    this.fetchSector()
    this.fetchRequest()
  }
}
