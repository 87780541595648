











































































































































import { ADDRESS } from "@/setting";
import axios from "axios";
import { Component, Vue } from "vue-property-decorator";
import DocumentA from "@/document/pdf";
import { DocumentExcel } from "@/document/xlsx";

@Component({
  components: {},
})
export default class List extends Vue {
  show: number = 20;
  page: number = 1;
  pages: number = 0;
  total: number = 0;
  filter1: any = "";
  filter2: any = "";
  filter3: any = "";
  types: Array<any> = [];
  doc: Array<any> = [];
  sumtotal: Array<any> = [];
  sum: any = {};
  fetchKPIType() {
    axios
      .get(`${ADDRESS}/setting/get-by-name/ประเภทตัวชี้วัด`)
      .then((response) => {
        let data = response.data;
        let member = [];
        try {
          member = data.doc.member;
        } catch (error) {}
        this.types = member;
      });
  }
  mounted() {
    this.fetchKPIType();
  }
  //PDF
  async generateReportPDF(name: string) {
    console.log("generatePDF");
    const documentA = new DocumentA();
    if (name == "A01") {
      documentA.create01(this.doc, 2565, this.sum);
    } else if (name == "A02") {
      documentA.create02(this.doc, 2565);
    } else if (name == "A03") {
      documentA.create03(this.doc, 2565);
    } else if (name == "A04") {
      documentA.create04(this.doc, 2565);
    } else if (name == "A05") {
      documentA.create05(this.doc, 2565);
    } else if (name == "A07") {
      documentA.create07(this.doc, 2565, this.sumtotal);
    } else if (name == "A10") {
      documentA.create10;
    } else if (name == "A08") {
      documentA.create08(this.doc, 2565);
    } else {
      documentA.create01(this.doc, 2565, this.sum);
    }
    documentA.save(`report${name}.pdf`);
  }
  //EXCEL
  async generateReportExcel(name: string) {
    console.log("generateExcel");
    let docExcel = new DocumentExcel();
    if (name == "A01") {
      docExcel.createA01(this.doc, 2565, this.sum);
    } else if (name == "A02") {
      docExcel.createA02(this.doc, 2565);
    } else if (name == "A03") {
      docExcel.createA03(this.doc, 2565);
    } else if (name == "A04") {
      docExcel.createA04(this.doc, 2565);
    } else if (name == "A05") {
      docExcel.createA05(this.doc, 2565);
    } else if (name == "A07") {
      docExcel.createA07(this.doc, 2565, this.sumtotal);
    } else if (name == "A10") {
      docExcel.createA10(this.doc, 2565);
    } else if (name == "A08") {
      docExcel.createA08(this.doc, 2565);
    } else {
      docExcel.createA01(this.doc, 2565, this.sum);
    }
  }

  getReport(name: string, type: string) {
    let query = {};
    if (name == "A04" || name == "A03") {
      query = {
        budgetYear: 2565,
      };
    } else if (name == "A02" || name == "A01") {
      query = {
        budgetYear: 2565,
        type: "SAT:ตัวชี้วัดกกท.",
      };
    } else {
      query = {
        budgetYear: 2565,
        type: "SAT:ตัวชี้วัดกกท.",
      };
    }
    axios.post(`${ADDRESS}/report/${name}`, query).then((response) => {
      console.log("report/${name}", response);
      this.doc = response.data.result;
      this.sum = response.data.sum;
      this.sumtotal = response.data.sumtotal;
      if (type == "PDF") {
        this.generateReportPDF(name);
      } else {
        this.generateReportExcel(name);
      }
    });
  }
}
