import Vue from 'vue';
import App from './App.vue';
import "@popperjs/core";
import "../node_modules/bootstrap/dist/js/bootstrap";
import router from './router';
import './registerServiceWorker'
import store from './store'

import 'vue-select/dist/vue-select.css';
import '@/extensions/date.extensions'
import '@/extensions/number.extensions'

import JMSort from "@/components/JMSort.vue";
import JMTablePaginate from "@/components/JMTablePaginate.vue";
import JMPerson from "@/components/JMPerson.vue";
import JMProgress from "@/components/JMProgress.vue";
import JMUpload from "@/components/JMUpload.vue";
import JMUploads from "@/components/JMUploads.vue";
import JMSector from "@/components/JMSector.vue";
import JMToast from "@/components/JMToast.vue";
import JMBudgetYear from "@/components/JMBudgetYear.vue";
import JMUser from "@/components/JMUser.vue";
import JMUserId from "@/components/JMUserId.vue";
import JMCalendar from "@/components/JMCalendar.vue";
import JMNumber from "@/components/JMNumber.vue";
import Richtext from "@/views/budget/components/Richtext.vue";
import Sector from "@/views/budget/components/Sector.vue";
import TextArray from "@/views/budget/components/TextArray.vue";
import InputText from "@/views/budget/components/InputText.vue";
import InputNumber from "@/views/budget/components/InputNumber.vue";
import Textarea from "@/views/budget/components/Textarea.vue";
import Plan from "@/views/budget/components/Plan.vue";
import Attachment from "@/views/budget/components/Attachment.vue";
import Link from "@/views/budget/components/Link.vue";
import NoAccess from "@/components/NoAccess.vue";
import JMRichText from "@/components/JMRichText.vue";
import VueMask from 'v-mask'
import vSelect from 'vue-select'


Vue.config.productionTip = false;
Vue.use(VueMask);

Vue.component('v-select', vSelect)
Vue.component("JMSort", JMSort);
Vue.component("JMTablePaginate", JMTablePaginate);
Vue.component("JMPerson", JMPerson);
Vue.component("JMProgress", JMProgress);
Vue.component("JMUploads", JMUploads);
Vue.component("JMUpload", JMUpload);
Vue.component("NoAccess", NoAccess);
Vue.component("JMSector", JMSector);
Vue.component("JMToast", JMToast);
Vue.component("JMBudgetYear", JMBudgetYear);
Vue.component("JMUser", JMUser);
Vue.component("JMUserId", JMUserId);
Vue.component("JMCalendar", JMCalendar);
Vue.component("JMNumber", JMNumber);
Vue.component("Richtext", Richtext);
Vue.component("Sector", Sector);
Vue.component("TextArray", TextArray);
Vue.component("InputText", InputText);
Vue.component("InputNumber", InputNumber);
Vue.component("Textarea", Textarea);
Vue.component("Plan", Plan);
Vue.component("Attachment", Attachment);
Vue.component("Link", Link);
Vue.component("JMRichText", JMRichText);

// const newRouter = new VueRouter()
const app = new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
router.beforeEach((to, from, next) => {
  console.log({ to, from })
  let accesses = store.state.accesses as Array<string>
  if (to.meta?.ac === undefined) next()
  else {
    let canAccess = accesses.includes(to.meta?.ac)
    if(canAccess) next()
    else next({ name: 'NoAccess', query: { redirect: from.fullPath } })
    // console.dir(accesses)
    // console.dir(to.meta?.ac)
    console.log("access", accesses.includes(to.meta?.ac))
  }
  // if (accesses.includes(to.meta?.ac)) next()
  // else {
  //   if (to.name == "Home") next()
  //   else next({ name: 'Home' })
  // }
  // return true
  next()
})
const rewritePattern = require('regexpu-core');
const { generateRegexpuOptions } = require('@babel/helper-create-regexp-features-plugin/lib/util');

const { RegExp } = global;
try {
  new RegExp('a', 'u');
} catch (err) {
  // @ts-ignore
  global.RegExp = function (pattern, flags) {
    if (flags && flags.includes('u')) {
      return new RegExp(
        rewritePattern(
          pattern,
          flags,
          generateRegexpuOptions({ flags, pattern })
        )
      );
    }
    return new RegExp(pattern, flags);
  };
  // @ts-ignore
  global.RegExp.prototype = RegExp;
}