import {  RouteConfig } from 'vue-router'

import Main from '../../views/report/Main.vue'
import ReportCard from '../../views/report/ReportCard.vue'
import ReportList from '../../views/report/ReportList.vue'
import ReportB01 from '../../views/report/ReportB01.vue'
import ReportB02 from '../../views/report/ReportB02.vue'
import ReportB03 from '../../views/report/ReportB03.vue'
import ReportB04 from '../../views/report/ReportB04.vue'
import ReportB05 from '../../views/report/ReportB05.vue'
import ReportB06 from '../../views/report/ReportB06.vue'
import ReportB07 from '../../views/report/ReportB07.vue'
import ReportB08 from '../../views/report/ReportB08.vue'
import ReportB09 from '../../views/report/ReportB09.vue'
import ReportB10 from '../../views/report/ReportB10.vue'
import ReportB11 from '../../views/report/ReportB11.vue'
import ReportB12 from '../../views/report/ReportB12.vue'
import ReportB13 from '../../views/report/ReportB13.vue'
import ReportB14 from '../../views/report/ReportB14.vue'
import ReportB15 from '../../views/report/ReportB15.vue'
import ReportB16 from '../../views/report/ReportB16.vue'
import ReportB17 from '../../views/report/ReportB17.vue'
import ReportB18 from '../../views/report/ReportB18.vue'
import ReportB19 from '../../views/report/ReportB19.vue'
import ReportB20 from '../../views/report/ReportB20.vue'
import ReportB21 from '../../views/report/ReportB21.vue'
import ReportB22 from '../../views/report/ReportB22.vue'
import ReportB23 from '../../views/report/ReportB23.vue'
import ReportB24 from '../../views/report/ReportB24.vue'
import ReportB25 from '../../views/report/ReportB25.vue'
import ReportB26 from '../../views/report/ReportB26.vue'
import ReportB27 from '../../views/report/ReportB27.vue'
import ReportA06 from '../../views/report/ReportA06.vue'
import ReportA10 from '../../views/report/ReportA10.vue'
import PlanHistory from '../../views/report/PlanHistory.vue'
const routes: Array<RouteConfig> = [
  {
    path: 'report',
    component: Main,
    meta:{
      "ac": "Report,r"
    },
    children: [
      {
        path: 'card',
        name: 'Report Card',
        component: ReportCard,
        meta:{
          "ac": "Report,r"
        },
      },
      {
        path: 'list',
        name: 'Report List',
        component: ReportList,
        meta:{
          "ac": "Report,r"
        },
      },
      {
        path: 'B01',
        name: 'Report-B01',
        component: ReportB01,
        meta:{
          "ac": "Report,r"
        },
      },
      {
        path: 'B01/detail/:id',
        name: 'Report-Detail-B01',
        component: ReportB01,
        meta:{
          "ac": "Report,r"
        },
      },
      {
        path: 'B02/detail/:id',
        name: 'ReportB02',
        component: ReportB02,
        meta:{
          "ac": "Report,r"
        },
      },
      {
        path: 'B03/:budgetYear/:sector',
        name: 'ReportB03',
        component: ReportB03,
        meta:{
          "ac": "Report,r"
        },
      },
      {
        path: 'B04/:budgetYear/:sector',
        name: 'ReportB04',
        component: ReportB04,
        meta:{
          "ac": "Report,r"
        },
      },
      {
        path: 'B05/:budgetYear/:sector',
        name: 'ReportB05',
        component: ReportB05,
        meta:{
          "ac": "Report,r"
        },
      },
      {
        path: 'B06',
        name: 'ReportB06',
        component: ReportB06,
        meta:{
          "ac": "Report,r"
        },
      },
      {
        path: 'B07',
        name: 'ReportB07',
        component: ReportB07,
        meta:{
          "ac": "Report,r"
        },
      },
      {
        path: 'B08',
        name: 'ReportB08',
        component: ReportB08,
        meta:{
          "ac": "Report,r"
        },
      },
      {
        path: 'B09',
        name: 'ReportB09',
        component: ReportB09,
        meta:{
          "ac": "Report,r"
        },
      },
      {
        path: 'B10',
        name: 'ReportB10',
        component: ReportB10,
        meta:{
          "ac": "Report,r"
        },
      },
      {
        path: 'B11/:budgetYear/:sector',
        name: 'ReportB11',
        component: ReportB11,
        meta:{
          "ac": "Report,r"
        },
      },
      {
        path: 'B12',
        name: 'ReportB12',
        component: ReportB12,
        meta:{
          "ac": "Report,r"
        },
      },
      {
        path: 'B13/:budgetYear/:sector',
        name: 'ReportB13',
        component: ReportB13,
        meta:{
          "ac": "Report,r"
        },
      },
      {
        path: 'B14/:budgetYear/:sector',
        name: 'ReportB14',
        component: ReportB14,
        meta:{
          "ac": "Report,r"
        },
      },
      {
        path: 'B15/:budgetYear/:sector',
        name: 'ReportB15',
        component: ReportB15,
        meta:{
          "ac": "Report,r"
        },
      },
      {
        path: 'B16/:budgetYear/:sector',
        name: 'ReportB16',
        component: ReportB16,
        meta:{
          "ac": "Report,r"
        },
      },
      {
        path: 'B17/:budgetYear/:sector',
        name: 'ReportB17',
        component: ReportB17,
        meta:{
          "ac": "Report,r"
        },
      },
      {
        path: 'B18',
        name: 'ReportB18',
        component: ReportB18,
        meta:{
          "ac": "Report,r"
        },
      },
      {
        path: 'B19/:budgetYear/:sector',
        name: 'ReportB19',
        component: ReportB19,
        meta:{
          "ac": "Report,r"
        },
      },
      {
        path: 'B20/:budgetYear/:sector',
        name: 'ReportB20',
        component: ReportB20,
        meta:{
          "ac": "Report,r"
        },
      },
      {
        path: 'B21/:budgetYear/:sector',
        name: 'ReportB21',
        component: ReportB21,
        meta:{
          "ac": "Report,r"
        },
      },
      {
        path: 'B22/:budgetYear/:sector',
        name: 'ReportB22',
        component: ReportB22,
        meta:{
          "ac": "Report,r"
        },
      },
      {
        path: 'B23',
        name: 'ReportB23',
        component: ReportB23,
        meta:{
          "ac": "Report,r"
        },
      },
      {
        path: 'B24/:budgetYear/:sector',
        name: 'ReportB24',
        component: ReportB24,
        meta:{
          "ac": "Report,r"
        },
      },
      {
        path: 'B25',
        name: 'ReportB25',
        component: ReportB25,
        meta:{
          "ac": "Report,r"
        },
      },
      {
        path: 'B26',
        name: 'ReportB26',
        component: ReportB26,
        meta:{
          "ac": "Report,r"
        },
      },
      {
        path: 'B27',
        name: 'ReportB27',
        component: ReportB27,
        meta:{
          "ac": "Report,r"
        },
      },
      {
        path: 'B27',
        name: 'ReportB27',
        component: ReportB27
      },
      {
        path: 'A06',
        name: 'Report-A06',
        component: ReportA06,
        meta:{
          "ac": "Report,r"
        },
      },
      {
        path: 'A10',
        name: 'Report-A10',
        component: ReportA10,
        meta:{
          "ac": "Report,r"
        },
      },
    ]
  },
]
export default routes