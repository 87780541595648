import {  RouteConfig } from 'vue-router'

import ERPMain from '../../views/ERP/Main.vue'
import ERPList from '../../views/ERP/List.vue'
import ERPForm from '../../views/ERP/Form.vue'
import ERPDelete from '../../views/ERP/Delete.vue'
import ERPReport from '../../views/ERP/Summary.vue'

const routes: Array<RouteConfig> = [
  {
    path: 'ERP',
    component: ERPMain,
    children: [
      {
        path: '',
        name: 'ERP List',
        component: ERPList,
        meta:{
          "ac": "ERP,r"
        },
      },
      {
        path: 'form',
        name: 'ERP Form',
        component: ERPForm,
        meta:{
          "ac": "ERP,r"
        },
      },
      {
        path: 'delete',
        name: 'ERP Delete',
        component: ERPDelete,
        meta:{
          "ac": "ERP,r"
        },
      },
      {
        path: 'edit/:id',
        name: 'ERP Edit',
        component: ERPForm,
        meta:{
          "ac": "ERP,r"
        },
      },
      {
        path: 'report',
        name: 'ERP Report',
        component: ERPReport,
        meta:{
          "ac": "ERP,r"
        },
      },

    ]
  },
]
export default routes