







































































































































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import axios from "axios";
import { ADDRESS } from "@/setting";
import TableRow from "./planComponents/TableRow.vue";
import ItemBudget from "./planComponents/ItemBudget.vue";
import ItemKPI from "./planComponents/ItemKPI.vue";
import AdjustmentStep from "./planComponents/AdjustmentStep.vue";
import BudgetStep from "./planComponents/BudgetStep.vue";
import History from "./planComponents/History.vue";
import HistoryD1 from "./planComponents/HistoryD1.vue";
import Selecto from "selecto";
import { ConfirmAction } from "@/decorators";
import * as _ from "lodash";
import DocumentA from "@/document/pdf";
@Component({
  components: {
    TableRow,
    ItemBudget,
    ItemKPI,
    AdjustmentStep,
    BudgetStep,
    History,
    HistoryD1,
  },
})
export default class Plan extends Vue {
  budget: any = {
    showZero: false,
  };
  budgetitems: Array<any> = [];
  activeItem: any = {
    months: [],
    monthsFlag: [],
    indicators: [],
    budgetOverlap: [],
  };
  revision: number | null = null;
  indicators: Array<any> = [];
  sector: any = {
    name: "-",
  };
  tudNgern: boolean = false;
  sum = 0;
  active_i: number = -1;
  active_j: number = -1;
  active_k: number = -1;
  adjustment: any = {};
  erps: Array<any> = [];
  sectors: Array<any> = [];
  adjustment_index = 0;
  ADDRESS = ADDRESS

  getBudgetTypeTotalMonth(m:number, type:string){
    try {
      let sum = 0;
      let items = JSON.parse(JSON.stringify(this.budgetitems));
      for (const s1 of items) {
        if (s1.budgetItemType !== "") {
          console.log(s1.sourceCombined, s1.type)
          if(typeof s1.months[m] === 'number' && s1.type===type)
          sum += s1.months[m]
        }
        else {
          for (const s2 of s1.children) {
            if (s2.budgetItemType !== "") {
              console.log(s2.sourceCombined, s2.type)
              if(typeof s2.months[m] === 'number' && s2.type===type)
              sum += s2.months[m]
            } else {

              for (const s3 of s2.children) {
                if (s3.budgetItemType !== "") {
                  console.log(s3.sourceCombined, s3.type)
                  if(typeof s3.months[m] === 'number' && s3.type===type)
                  sum += s3.months[m]
                }
              }
            }
          }
        }
      }
    return sum;
    } catch (error) {
      return 0
    }
  }
  getGrandTotalMonth(m: number) {
    try {
      let sum = 0;
      let items = JSON.parse(JSON.stringify(this.budgetitems));
      for (const s1 of items) {
        if (s1.budgetItemType !== "") {
          if(typeof s1.months[m] === 'number')
          sum += s1.months[m]
        }
        else {
          for (const s2 of s1.children) {
            if (s2.budgetItemType !== "") {
              if(typeof s2.months[m] === 'number')
              sum += s2.months[m]
            } else {
              for (const s3 of s2.children) {
                if (s3.budgetItemType !== "") {
                  if(typeof s3.months[m] === 'number')
                  sum += s3.months[m]
                }
              }
            }
          }
        }
      }
    return sum;
    } catch (error) {
      return 0
    }
  }
  submitForm(silent?: boolean) {
    let prep = this.budgetitems;
    console.log("setting items...", prep);
    axios
      .post(`${ADDRESS}/budgetitem/set-items/${this.$route.params.id}/N`, prep)
      .then(async (response) => {
        console.log("set items success", prep);
        console.log("setting items done!", response);
        if (silent) {
          alert("ดำเนินการแก้ไขรายการสำเร็จแล้ว");
        } else {
        }
        // this.$router.push(`/portal/budget/edit/${this.$route.params.id}`)
        await this.fetchBudgetItem();
      })
      .catch((error) => {
        console.log("submit set items error", error);
      });
  }
  undoVersion() {
    let adjustments: Array<any> = [];
    let query = {
      search: {
        status: "Approved",
      },
      page: 1,
      limit: 99999,
      sort: {
        bindWithRevision: "desc",
      },
    };
    axios
      .post(`${ADDRESS}/budgetAdjustments/paginate`, query)
      .then((response) => {
        adjustments = response.data.docs;
        console.log({ adjustments });
        try {
          if (this.revision === undefined) {
            this.revision = adjustments[this.adjustment_index].bindWithRevision;
          }
          this.revision = adjustments[this.adjustment_index++].bindWithRevision;
        } catch (error) {
          console.log(error);
          // alert("คุณมาถึงเวอร์ชั่นแรกสุดที่ Approved แล้ว")
        }
        this.init();
      });
  }
  defaultVersion() {
    let adjustments: Array<any> = [];
    let query = {
      search: {
        status: "Approved",
      },
      page: 1,
      limit: 99999,
      sort: {
        bindWithRevision: "desc",
      },
    };
    axios
      .post(`${ADDRESS}/budgetAdjustments/paginate`, query)
      .then((response) => {
        adjustments = response.data.docs;
        console.log({ adjustments });
        try {
          this.revision = adjustments[adjustments.length - 1].bindWithRevision;
          this.adjustment_index = adjustments.length - 1;
        } catch (error) {
          console.log(error);
          // alert("คุณมาถึงเวอร์ชั่นแรกสุดที่ Approved แล้ว")
        }
        this.init();
      });
  }
  currentVersion() {
    let adjustments: Array<any> = [];
    let query = {
      search: {
        status: "Approved",
      },
      page: 1,
      limit: 99999,
      sort: {
        bindWithRevision: "desc",
      },
    };
    axios
      .post(`${ADDRESS}/budgetAdjustments/paginate`, query)
      .then((response) => {
        adjustments = response.data.docs;
        console.log({ adjustments });
        try {
          this.revision = adjustments[0].bindWithRevision;
          this.adjustment_index = 0;
        } catch (error) {
          console.log(error);
          // alert("คุณมาถึงเวอร์ชั่นแรกสุดที่ Approved แล้ว")
        }
        this.init();
      });
  }
  fetchSector() {
    return new Promise((resolve, reject) => {
      axios.get(`${ADDRESS}/sectors/list`).then((response) => {
        this.sectors = response.data.docs;
        resolve("ok sector");
      });
    });
  }
  resolveSector(_id: string): string {
    try {
      let foundName = this.sectors.find((item) => item.id == _id);
      return foundName.name;
    } catch (error) {
      return "-";
    }
  }
  get increaseAlert() {
    let sumString = this.sum.toString();
    let d1 = sumString[sumString.length - 2];
    let d2 = sumString[sumString.length - 1];
    let d = parseInt(d1 + d2);
    return (
      "ต้องเพิ่มจำนวนเงินอีก " +
      (100 - d) +
      ` จะได้ ${(this.sum + 100 - d).formatFull()}`
    );
  }
  get decreaseAlert() {
    let sumString = this.sum.toString();
    let d1 = sumString[sumString.length - 2];
    let d2 = sumString[sumString.length - 1];
    let d = parseInt(d1 + d2);
    return "ต้องลดจำนวนเงินอีก " + d + ` จะได้ ${(this.sum - d).formatFull()}`;
  }
  selecto() {
    let self = this;
    const selecto = new Selecto({
      container: document.body,
      rootContainer: null,
      //@ts-ignore
      selectableTargets: [
        ".drag-money-target",
        //@ts-ignore
        document.querySelector(".target2"),
      ],
      selectByClick: true,
      selectFromInside: true,
      continueSelect: false,
      toggleContinueSelect: "shift",
      keyContainer: window,
      hitRate: 100,
    });

    selecto.on("select", (e) => {
      var sum = 0;
      e.selected.forEach((el) => {
        let fl: any = el.innerHTML.split(",").join("");
        if (fl == "-") fl = 0;
        else fl = parseFloat(fl);
        sum += fl;
        self.sum = sum;
      });
      console.dir("e", sum);
      e.added.forEach((el) => {
        el.classList.add("selected");
      });
      e.removed.forEach((el) => {
        el.classList.remove("selected");
      });
    });
  }

  get items() {
    return this.indicators;
  }

  get indicatorsText() {
    try {
      let result = ((this.budget.data || {}).indicators || []).map(
        (el: any) => {
          let found = this.indicators.find((ind) => ind.id == el);
          return found;
        }
      );
      return result;
    } catch (error) {
      return "?";
    }
    return "-";
  }

  resolveIndicator(id: string) {
    try {
      let found = this.indicators.find((ind) => ind.id == id);
      return found.name;
    } catch (error) {
      return "-";
    }
  }

  submitApproval(status: string) {
    let step = this.adjustment.step ?? 0;
    if (status === "เห็นชอบ" && this.adjustment.attachments != undefined) {
      step = Math.abs(step) + 2;
    }
    // console.log(this.adjustment, step)
    console.log("attachments", this.adjustment.attachments);
    if (status === "เห็นชอบ" && this.adjustment.attachments == undefined)
      step = Math.abs(step) + 1;
    else if (status === "ตีกลับ") {
      step = -1 * (step + 1);
      this.rejectBudgetAdjusting();
    } else if (status === "ส่งคืน") {
      step = -1 * (step + 1);
      this.rejectBudgetAdjusting();
    }

    let prep = {
      status: status,
      step,
      comment: this.adjustment.comment,
      attachments: this.adjustment.attachments,
    };
    try {
      //@ts-ignore
      this.$refs.closemodal!.click();
    } catch (error) {}
    console.log("prep", prep);
    axios
      .patch(
        `${ADDRESS}/budgetAdjustment/update/${this.$route.params.adjustment_id}`,
        prep
      )
      .then((response) => {
        console.log("response", response);
        alert("ดำเนินการเห็นชอบเสร็จสิ้น");
        this.fetchAdjustment();
        // this.$router.push(`/portal/budget/adjust/list/${this.$route.params.project_id}`)
      });
  }

  rejectBudgetAdjusting() {
    axios
      .patch(`${ADDRESS}/budget/update/${this.budget.id}`, {
        status: "adjusting",
      })
      .then((res) => {
        this.fetchBudget();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  get flatted() {
    let prep: Array<any> = [];
    this.budgetitems.forEach((e0) => {
      prep.push(e0);
      e0.children.forEach((e1: any) => {
        prep.push(e1);
        e1.children.forEach((e2: any) => {
          prep.push(e2);
          e2.children.forEach((e3: any) => {
            prep.push(e3);
          });
        });
      });
    });
    return prep;
  }
  getLevel1(): Array<any> {
    try {
      let l1 = this.budgetitems ?? {};
      if (this.budget.showZero)
        l1 = l1.filter(
          (e: any) =>
            (e.months ?? [0]).reduce((a: number, b: number) => a + b, 0) !== 0
        );
      return l1;
    } catch (error) {
      console.log(error);
      return this.budgetitems;
    }
  }
  getLevel2(i: number) {
    try {
      let l1 = this.budgetitems ?? {};
      let l2 = l1[i].children ?? {};
      if (this.budget.showZero)
        l2 = l2.filter(
          (e: any) =>
            (e.months ?? [0]).reduce((a: number, b: number) => a + b, 0) !== 0
        );
      return l2;
    } catch (error) {
      console.log(error);
    }
  }
  getLevel3(i: number, j: number) {
    try {
      let l1 = this.budgetitems ?? {};
      let l2 = l1[i].children ?? {};
      let l3 = l2[j].children ?? {};
      if (this.budget.showZero)
        l3 = l3.filter(
          (e: any) =>
            (e.months ?? [0]).reduce((a: number, b: number) => a + b, 0) !== 0
        );
      return l3;
    } catch (error) {
      console.log(error);
    }
  }
  get totalBudet() {
    try {
      return this.getLevel1().reduce(
        (a: number, b: any) =>
          a + b.months.reduce((a: number, b: number) => a + (typeof b === 'number'?b:0), 0),
        0
      );
    } catch (error) {
      return 0;
    }
  }
  openModalById(id: string) {
    // if (this.budget.step >= 1 && this.budget.step <=10) return
    try {
      let isFound = false;
      this.budgetitems.forEach((bi1, i) => {
        if (bi1.id === id) {
          this.activeItem = this.budgetitems[i];
          this.activeItem.previous = JSON.parse(
            JSON.stringify(this.activeItem)
          );
          isFound = true;
          // cons
        }
      });
      if (!isFound)
        this.budgetitems.forEach((bi1, i) => {
          bi1.children.forEach((bi2: any, j: number) => {
            if (bi2.id === id) {
              this.activeItem = this.budgetitems[i].children[j];
              this.activeItem.previous = JSON.parse(
                JSON.stringify(this.activeItem)
              );
              isFound = true;
            }
          });
        });
      if (!isFound)
        this.budgetitems.forEach((bi1, i) => {
          bi1.children.forEach((bi2: any, j: number) => {
            bi2.children.forEach((bi3: any, k: number) => {
              if (bi3.id === id) {
                this.activeItem = this.budgetitems[i].children[j].children[k];
                this.activeItem.previous = JSON.parse(
                  JSON.stringify(this.activeItem)
                );
                isFound = true;
              }
            });
          });
        });
      (this.$refs.openmodal as HTMLElement).click();
    } catch (error) {
      console.log(error);
      alert("พบปัญหาในการแก้ไขรายการ");
    }
  }
  pushItem(item: any) {
    let { id, level } = item;
    let ii = 0;
    let jj = 0;
    let kk = 0;
    try {
      if (level == 1) {
        this.budgetitems.forEach((bi1, i) => {
          if (bi1.id === id) {
            this.activeItem = this.budgetitems[i];
            ii = i;
          }
        });
      } else if (level == 2) {
        this.budgetitems.forEach((bi1, i) => {
          if (bi1.id === id) {
            ii = i;
          }
          console.log(bi1.children);
          bi1.children.forEach((bi2: any, j: number) => {
            if (bi2.id === id) {
              this.activeItem = this.budgetitems[i].children[j];
              ii = i;
              jj = j;
            }
          });
        });
      } else if (level == 3) {
        this.budgetitems.forEach((bi1, i) => {
          if (bi1.id === id) {
            ii = i;
          }
          bi1.children.forEach((bi2: any, j: number) => {
            if (bi2.id === id) {
              this.activeItem = this.budgetitems[i].children[j];
              ii = i;
              jj = j;
            }
            bi2.children.forEach((bi3: any, k: number) => {
              this.activeItem = this.budgetitems[i].children[j].children[k];
              if (bi3.id === id) {
                ii = i;
                jj = j;
                kk = k;
              }
            });
          });
        });
      }
      let obj: any = {
        subject: "รายการใหม่**",
        edit: false,
        children: [],
        months: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        monthsFlag: [
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
        ],
      };

      if (level == 1) {
        obj.number = `${this.getLevel1()!.length + 1}`;
        this.budgetitems.push(obj);
      } else if (level == 2) {
        obj.number = `${this.getLevel1()!.length + 1}.${
          this.getLevel2(ii)!.length + 1
        }`;
        this.budgetitems[ii].children.push(obj);
      } else {
        obj.number = `${this.getLevel1()!.length + 1}.${
          this.getLevel2(ii)!.length + 1
        }.${this.getLevel3(ii, jj)!.length + 1}`;
        this.budgetitems[ii].children[jj].children.push(obj);
      }

      // this.$nextTick(() => {
      //   this.submitForm(false);
      // });
    } catch (error) {
      console.log(error);
      alert("พบปัญหาในการแทรกรายการ");
    }
  }
  get planIndicators() {
    try {
      let results = this.flatted
        .map((flt) => flt.indicators)
        .reduce((a, b) => a.concat(b), [])
        .filter((flt: string) => flt != "");
      try {
        const union = _.union(this.budget.data.indicators, results);
        // console.log(union)
        this.budget.data.indicators = union;
        console.log("this.budget.data", this.budget.data);
        let data: any;
        try {
          data = JSON.parse(this.budget.data);
        } catch (error) {
          data = this.budget.data;
        }
        data.indicators = this.budget.data.indicators;
        axios
          .patch(`${ADDRESS}/budget/update/${this.$route.params.id}`, { data })
          .then((response) => {
            console.log(response);
            // alert("ดำเนินการแก้ไขตัวชี้วัดสำเร็จแล้ว");
          });
      } catch (error) {
        console.log({ error });
      }
      return results;
    } catch (error) {}
    return [];
  }
  async fetchBudgetItem() {
    return new Promise((resolve,reject)=>{
      try {
        if (this.adjustment.revision !== undefined)
          this.revision = this.adjustment.bindWithRevision;
      } catch (error) {}
      let body = {
        budget_id: this.$route.params.id,
        // adjustment_id: this.adjustment.id,
        // revision: this.revision,
      };
      console.log({body})
      axios.post(`${ADDRESS}/budgetitem/get-items`, body).then((response) => {
        if (response.data.status === "success") {
          let data = response.data.results;
          console.log("budget items", response);
          this.budgetitems = data.map((el: any) => {
            return {
              ...el,
              edit: false,
            };
          });
          console.log({budgetitems:this.budgetitems})
          console.log("this.budgetitems length", this.budgetitems);
          if (this.budgetitems.length == 0) {
            this.pushItem({ level: 1 });
            console.log("this.budgetitems push");
            console.log("this.budgetitems", this.budgetitems);
            this.budget.showZero = false;
          }

          this.cleanParentBudget();
          resolve("done")
        } else {
          reject(response.data.error)
          console.log(`พบปัญหาในการดึงข้อมูลจากแม่ข่าย , ${response.data.error}`);
        }
      });
    })
  }
  fetchBudget() {
    return new Promise((resolve, reject) => {
      axios
        .get(`${ADDRESS}/budget/get/${this.$route.params.id}`)
        .then((response) => {
          console.log(response.data);
          if (response.data.status === "success") {
            let data = response.data.doc;
            this.budget = data;
            resolve("done");
          } else {
            reject(response.data.error);
            console.log(
              `พบปัญหาในการดึงข้อมูลจากแม่ข่าย , ${response.data.error}`
            );
          }
        });
    });
  }
  fetchIndicatorsByBudget() {
    return new Promise((resolve,reject)=>{
      let query = {
        skip: 0,
        limit: 5000,
      };
      axios.post(`${ADDRESS}/indicators/paginate`, query).then((response) => {
        this.indicators = response.data.docs;
        resolve("done")
      });
    })
  }
  fetchAdjustment(): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${ADDRESS}/budgetAdjustment/get/${this.$route.params.adjustment_id}`
        )
        .then((response) => {
          this.adjustment = response.data.doc ?? { status: "Open" };
          resolve("done");
        })
        .catch((error) => {
          reject("error");
        });
    });
  }
  fetchERP() {
    return new Promise((resolve, reject) => {
      this.erps = [];
      let query = {
        skip: 0,
        limit: 9999,
        search: {
          project_id: this.$route.params.id,
          NOT: {
            status: "deleted",
          },
        },
      };
      axios.post(`${ADDRESS}/erps/paginate`, query).then((response) => {
        this.erps = response.data.docs;
        resolve("done");
      });
    });
  }
  getSector(_id: string) {
    axios.get(`${ADDRESS}/sector/get/${_id}`).then((response) => {
      let data = response.data.doc;
      this.sector = data;
    });
  }
  cleanParentBudget() {
    let items = JSON.parse(JSON.stringify(this.budgetitems));
    let cleanLoop = () => {
      for (const s1 of items) {
        let tempS1Months = new Array(12).fill(0);
        if (s1.children.length > 0) {
          for (const s2 of s1.children) {
            let tempS2Months = new Array(12).fill(0);
           
              s2.months.forEach((m: number, i: number) => {
                tempS1Months[i] += m;
              });
            if (s2.children.length > 0) {
              for (const s3 of s2.children) {
                
                  s3.months.forEach((m: number, i: number) => {
                    tempS2Months[i] += m;
                  });
              }
              s2.months = tempS2Months;
            }
          }
          s1.months = tempS1Months;
        }
      }
    };
    cleanLoop(); // clean 3=>2
    cleanLoop(); // clean 2=>1

    this.budgetitems = JSON.parse(JSON.stringify(items));
  }
  patchZero() {
    axios
      .patch(`${ADDRESS}/budget/update/${this.$route.params.id}`, {
        showZero: this.budget.showZero,
      })
      .then((response) => {
        console.log(response);
      });
  }

  mounted() {
    this.init();
  }

  async init() {
    await this.fetchBudget();
    await this.fetchIndicatorsByBudget();
    await this.fetchAdjustment();
    await this.fetchBudgetItem();
    await this.fetchERP();
    this.fetchSector();
    this.selecto();
  }
  //PDF
  generateReportPDF() {
    console.log("generatePDF");
    const documentA = new DocumentA();
    documentA.create09(
      this.budget,
      this.budgetitems,
      this.indicators,
      this.budget.budgetYear
    );
    documentA.save(`reportA09.pdf`);
  }

  //project
  @ConfirmAction("คุณต้องการ ขออนุมัติ ใช่หรือไม่")
  budgetFirstRequest() {
    let prep = {
      budget_id: this.budget.id,
    };
    axios
      .post(`${ADDRESS}/process/budget-first-request`, prep)
      .then((response) => {
        console.log(response);
        this.init();
      });
  }
  @ConfirmAction("คุณต้องการ ขออนุมัติ ใช่หรือไม่")
  budgetEditRequest() {
    let prep = {
      budget_id: this.budget.id,
    };
    axios
      .post(`${ADDRESS}/process/budget-edit-request`, prep)
      .then((response) => {
        console.log(response);
      });
  }
  @ConfirmAction("คุณต้องการ เห็นชอบ ใช่หรือไม่")
  projectConsidering() {
    let prep = {
      budget_id: this.budget.id,
    };
    if (this.budget.status == "Request") {
      axios
        .post(`${ADDRESS}/process/budget-considering-request/1`, prep)
        .then((response) => {
          console.log(response);
          this.init();
        });
    } else if (this.budget.status == "Considering1") {
      axios
        .post(`${ADDRESS}/process/budget-considering-request/2`, prep)
        .then((response) => {
          console.log(response);
          this.init();
        });
    } else {
      axios
        .post(`${ADDRESS}/process/budget-considering-request/3`, prep)
        .then((response) => {
          console.log(response);
          this.init();
        });
    }
  }
  @ConfirmAction("คุณต้องการ อนุมัติแผน ใช่หรือไม่")
  budgetFirstApprove() {
    let prep = {
      budget_id: this.budget.id,
    };
    axios
      .post(`${ADDRESS}/process/budget-first-approve`, prep)
      .then((response) => {
        console.log(response);
        this.init();
      });
  }
  @ConfirmAction("คุณต้องการ ตีกลับ ใช่หรือไม่")
  budgetFirstReject() {
    let prep = {
      budget_id: this.budget.id,
    };
    axios
      .post(`${ADDRESS}/process/budget-first-reject`, prep)
      .then((response) => {
        console.log(response);
        this.init();
      });
  }
  //adjusting
  @ConfirmAction("คุณต้องการ ขออนุมัติการปรับแผน ใช่หรือไม่")
  budgetFinishAdjusting() {
    let prep = {
      budget_id: this.budget.id,
      adjust_id: this.adjustment.id,
    };
    axios
      .post(`${ADDRESS}/process/budget-finish-adjusting`, prep)
      .then((response) => {
        console.log(response);
        this.$router.push(
          `/portal/budget/adjust/list/${this.$route.params.id}`
        );
      });
  }
  @ConfirmAction("คุณต้องการ เห็นชอบ ใช่หรือไม่")
  budgetConsidering() {
    let prep = {
      budget_id: this.budget.id,
      reason: this.adjustment.comment,
      adjust_id: this.adjustment.id,
    };
    if (this.adjustment.status == "Pending Considering") {
      axios
        .post(`${ADDRESS}/process/budget-considering-approve-adjusting/1`, prep)
        .then((response) => {
          this.init();
          console.log(response);
        });
    } else if (this.adjustment.status == "Considering1") {
      axios
        .post(`${ADDRESS}/process/budget-considering-approve-adjusting/2`, prep)
        .then((response) => {
          this.init();
          console.log(response);
        });
    } else if (this.adjustment.status == "Considering2") {
      axios
        .post(`${ADDRESS}/process/budget-considering-approve-adjusting/3`, prep)
        .then((response) => {
          this.init();
          console.log(response);
        });
    } else if (this.adjustment.status == "Considering3") {
      axios
        .post(`${ADDRESS}/process/budget-considering-approve-adjusting/4`, prep)
        .then((response) => {
          this.init();
          console.log(response);
        });
    } else if (this.adjustment.status == "Considering4") {
      axios
        .post(`${ADDRESS}/process/budget-considering-approve-adjusting/5`, prep)
        .then((response) => {
          this.init();
          console.log(response);
        });
    } else {
      axios
        .post(`${ADDRESS}/process/budget-considering-approve-adjusting/6`, prep)
        .then((response) => {
          this.init();
          console.log(response);
        });
    }
  }
  @ConfirmAction("คุณต้องการ อนุมัติการปรับแผน ใช่หรือไม่")
  budgetConsideringApproveFinal() {
    let prep = {
      budget_id: this.budget.id,
      reason: this.adjustment.comment,
      budget: this.budget,
      adjust_id: this.adjustment.id,
    };
    if (this.adjustment.attachments != undefined) {
      axios
        .post(`${ADDRESS}/process/budget-considering-approve-final`, prep)
        .then((response) => {
          this.init();
          console.log(response);
        });
    } else {
      alert("กรุณาแนบไฟล์ แบบคำขออนุมัติปรับแผนฯ");
    }
  }
  @ConfirmAction("คุณต้องการ ตีกลับ ใช่หรือไม่")
  budgetRejectAdjusting() {
    let prep = {
      budget_id: this.budget.id,
      reason: this.adjustment.comment,
      adjust_id: this.adjustment.id,
    };
    let prep2 = {
      request_id: this.adjustment.request_id,
    };
    axios
      .post(`${ADDRESS}/process/budget-reject-adjusting`, prep)
      .then((response) => {
          this.init();
        console.log(response);
      });
    axios.post(`${ADDRESS}/process/request-reject`, prep2).then((response) => {
      this.init();
      console.log(response);
    });
  }
  @ConfirmAction("คุณต้องการ ส่งคืน ใช่หรือไม่")
  budgetReturnAdjusting() {
    let prep = {
      budget_id: this.budget.id,
      reason: this.adjustment.comment,
      adjust_id: this.adjustment.id,
    };
    let prep2 = {
      request_id: this.adjustment.request_id,
    };
    axios
      .post(`${ADDRESS}/process/budget-return-adjusting`, prep)
      .then((response) => {
        this.init();
        console.log(response);
      });
    axios.post(`${ADDRESS}/process/request-return`, prep2).then((response) => {
      this.init();
      console.log(response);
    });
  }
}
