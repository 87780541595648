import MyDocument from "../index";
import pdfMake from "pdfmake/build/pdfmake.js";
import htmlToPdfmake from "html-to-pdfmake"
import { TDocumentDefinitions } from "pdfmake/interfaces";
import IReport06, { IReport06Item } from "@/interface/report06";
import IReport08, { IReport08Item } from "@/interface/report08";
import IReport10, { IReport10Item } from "@/interface/report10";
import * as _ from "lodash"

let stripHTML = (text: string) => {
  return (
    text
      .replace(/<\/?[^>]+(>|$)/g, "")
      .replace(/&nbsp;/g, "")
  );
}
export default class DocumentPDF extends MyDocument {
  _docDefinition: TDocumentDefinitions = {
    content: "พบข้อผิดพลาดในการสร้างเอกสาร PDF",
  };
  _styles = {
    bold: {
      bold: true,
    },
  };
  _fonts: any = {
    Roboto: {
      normal: "https://fonts.jmandjm.com/staticfonts/sarabun_regular.ttf",
      bold: "https://fonts.jmandjm.com/staticfonts/sarabun_bold.ttf",
      italics: "https://fonts.jmandjm.com/staticfonts/sarabun_italic.ttf",
      bolditalics: "https://fonts.jmandjm.com/staticfonts/sarabun_bolditalic.ttf",
    },
  };
  htmlStyle:any = {
    a: {color:'blue', decoration:'underline'},
    strike: {decoration: 'lineThrough'},
    p: {margin:[0, 0, 0, 0]},
  }


  // "รายงานแบบที่ 1",
  async create01(docs: Array<any>,budgetYear:Number,sum:any): Promise<any> {
    let data: Array<Array<any>> = [];
    docs.forEach((el, i) => {
      el.items.forEach((item:any, j:number)=>{
        data.push([
          {text: i+1, rowSpan:j==0?el.items.length:1 },
          {text: (el.name??""), rowSpan:j==0?el.items.length:1},
          {text:(item.code??"")+""+(item.name??"")},
          {text:item.number + item.subject},
          {text:item.responsible},
          {text:(item.sum??0).formatFull(), alignment:"right"},
          {text:(item.type??"-,-").split(",")[0]},
          {text:item.sector}
        ])
      })
    });
    this.init = true;
    this._docDefinition = {
      pageSize: "A4",
      pageOrientation: "landscape",
      pageMargins: [40, 20, 40, 10],
      styles: this._styles,
      defaultStyle:{
        alignment:'center'
      },
      content: [
        {
          text: [{ text: `รายงานความเชื่อมโยงตัวชี้วัด ประจำปีงบประมาณ พ.ศ. ${budgetYear}`, style: "bold", },
          ],
          alignment: "center",
          margin: [0, 0, 0, 10],
        },
        {
          style: "tableExample",
          alignment: "center",
          table: {
            widths: [20, 180, 80, 125, 60, 60, 60, 95],
            body: [
              [
                "ลำดับ",
                "ตัวชี้วัด",
                "รายการ",
                "ข้อดำเนินการ/กิจกรรม",
                "ผู้รับผิดชอบ",
                "งบประมาณ",
                "แหล่งงบประมาณ",
                "ฝ่ายและสำนักที่รับผิดชอบ",
              ],
              ...data, 
            ],
          },
        }, 
        {
          layout: "noBorders",
          alignment: "center",
          table: {
            widths: [20, 180, 80, 125, 60, 60, 60, 95],
            body: 
            [{field:'annual',name:"รวมงบประจำปี"},{field:'foundation',name:"รวมงบกองทุน"},{field:'collected',name:"รวมงบสะสม"},{field:'central',name:"รวมงบส่วนกลาง"},{field:'income',name:"รวมงบรายได้"},{field:'country',name:"รวมงบประมาณแผ่นดิน"},{field:'total',name:"รวมทั้งสิ้น"}].map(item=>([
              "",
              "",
              "",
              "",
              item.name,
              (sum[item.field]??0).formatFull(),
              "",
              "",
            ]))
          },
        },
        {
          layout: "noBorders",
          alignment: "center",
          table: {
            widths: [20, 180],
            heights:50,
            body: 
            [
              [
                "",
                (".").repeat(60)+"ลงชื่อ",
              ],
              [
                "",
                "        ชาญยุทธ คำหวาน       ",
              ],

            ]
          },
        },
      ],
    };
  }

  // "รายงานแบบที่ 2",
  async create02(docs: Array<any>,budgetYear:Number): Promise<any> {
    let data: Array<Array<any>> = [];
    docs.forEach((el, i) => {
      el.items.forEach((item:any,j:number)=>{
        data.push([
          {text: i+1, rowSpan:j==0?el.items.length:1 },
          {text: (el.name??""), rowSpan:j==0?el.items.length:1},
          {text:(item.code??"")+""+(item.name??"")},
          {text:item.number + item.subject},
          {text:""},
          {text:item.responsible},
          {text:(item.sum??0).formatFull(), alignment:"right"},
          {text:(item.type??"-,-").split(",")[0]},
          {text:item.sector}
        ])
      })
    });
    this.init = true;
    this._docDefinition = {
      pageSize: "A4",
      pageOrientation: "landscape",
      pageMargins: [40, 20, 40, 10],
      styles: this._styles,
      defaultStyle:{
        alignment:'center'
      },
      content: [
        {
          text: [
            { text: `การรายงานผลใแต่ละกิจกรรมที่สอดคล้องกับตัวชี้วัด หรือความเสี่ยง\r\n`, style: "bold", },
            { text: `ประจำปีงบประมาณ พ.ศ. ${budgetYear}`, style: "bold", },
          ],
          alignment: "center",
          margin: [0, 0, 0, 10],
        },
        {
          style: "tableExample",
          alignment: "center",
          table: {
            widths: [20, 120, 80, 125, 60, 60, 60, 60, 95],
            body: [
              [
                "ลำดับ",
                "ตัวชี้วัด",
                "รายการ",
                "ข้อดำเนินการ",
                "ผลการดำเนินงาน",
                "ผู้รับผิดชอบ",
                "งบประมาณ",
                "แหล่งงบประมาณ",
                "ฝ่ายและสำนักที่รับผิดชอบ",
              ],
              ...data, 
            ],
          },
        }, 
        {
          layout: "noBorders",
          alignment: "center",
          table: {
            widths: [20, 180],
            body: 
            [
              [
                "",
                "",
              ],
              [
                "",
                "",
              ],
              [
                "",
                "",
              ],
              [
                "",
                "",
              ],
              [
                "",
                "",
              ],
              [
                "",
                "",
              ],
              [
                "",
                (".").repeat(60)+"ลงชื่อ",
              ],
              [
                "",
                "        ชาญยุทธ คำหวาน       ",
              ],

            ]
          },
        },
      ],
    };
  }

  // "รายงานแบบที่ 3",
  async create03(docs: Array<any>,budgetYear:Number): Promise<any> {
    let data: Array<Array<any>> = [];
    docs.forEach((el, i: number) => {
      data.push([ 
        {text: i+1},
        {text:(el.number)},
        {text: (el.value??0).formatFull()},
        {text:(new Date(el.createdAt).toThaiShort())},
        {text:(el.budgetItem??"")},
        {text: (el.budget??0).formatFull()},
        {text:(el.reason??"")},
        {text:(el.deleteBy??"")},
      ]);
    });
    this.init = true;
    this._docDefinition = {
      pageSize: "A4",
      pageOrientation: "landscape",
      pageMargins: [40, 20, 40, 20],
      content: [
        {
          text: [
            { text: `รายละเอียดการปรับรายการเบิก/ยืม\r\n`, style: "bold", },
            { text: `ประจำปีงบประมาณ พ.ศ. ${budgetYear}`, style: "bold", },
          ],
          alignment: "center",
          margin: [0, 0, 0, 10],
        },
        {
          style: "tableExample",
          alignment: "center",
          table: {
            widths: [20, 75, 75, 85, 85, 85, 160, 100],
            body: [
              [
                "ลำดับ",
                "เลขที่SMIS",
                "จำนวนเงิน",
                "วันที่ทำรายการ\r\n(วัน/เดือน/ปี)เป็น พ.ศ.",
                "รหัสข้อการดำเนินงาน\r\n(bdgltrmID)",
                "รหัสข้อการดำเนินงาน\r\n(bdgID)",
                "สาเหตุที่ดำเนินการ\r\nลบเลข SMIS",
                "ผู้ดำเนินการลบ",
              ],
              ...data,
            ],
          },
        },
        {
          text: "หมายเหตุ : กรณีลบแล้ว สามารถกู้เลข SMIS กลับคืนได้",
          margin: [0, 10, 0, 0],
        },
      ],
    };
  }

  // "รายงานแบบที่ 4",
  async create04(docs: Array<any>,budgetYear:Number): Promise<any> {
    let data: Array<Array<any>> = [];
      docs.forEach((el, i: number) => {
        data.push([ 
          {text: i+1},
          {text:(el.number??"")+""+(el.subject??""), alignment: 'left'},
          {text: (el.responsible??"")},
          {text: (el.type??"")==='งบอุดหนุน'?(el.value??0).formatFull():""},
          {text: (el.type??"")!=='งบอุดหนุน'?(el.value??0).formatFull():""},
          {text: (el.source??"")},
          {text:(el.projectCode??"")+""+(el.project??"")},
          {text: (el.sector??"")},
          {text: (el.reason??"")},
        ]);
      });
    this.init = true;
    this._docDefinition = {
      pageSize: "A4",
      pageOrientation: "landscape",
      pageMargins: [40, 20, 40, 20],
      styles: this._styles,
      content: [
        {
          text: [
            { text: `รายละเอียดกิจกรรมประจมาณที่ต้องการจากแหล่งเงินและแผนการปฏิบัติงานฯ\r\n`, style: "bold", },
            { text: `ประจำปีงบประมาณ พ.ศ. ${budgetYear}`, style: "bold", },
          ],
          alignment: "center",
          margin: [0, 0, 0, 10],
        },
        {
          style: 'tableExample',
          alignment: "center",
          table: {
            // widths: ['auto', '*', 'auto', 'auto', 'auto', 'auto', '*', 'auto', 'auto'],
            widths: ['*', 150, 'auto', 60, 70, 70, 70, 70, 100],
            headerRows: 2,
            body: [
              [{ text: 'ลำดับ', rowSpan: 2 },
              { text: 'กิจกรรม / ข้อการดำเนินงาน', rowSpan: 2 },
              { text: 'ผู้รับผิดชอบ', rowSpan: 2 },
              { text: 'งบประมาณ', colSpan: 2 }, '',
              { text: 'แหล่งงบประมาณ', rowSpan: 2 },
              { text: 'รายการที่', rowSpan: 2 },
              { text: 'ฝ่ายและสำนักที่รับผิดชอบ', rowSpan: 2 },
              { text: 'หมายเหตุ', rowSpan: 2 },
              ],
              ['', '', '', 'งบอุดหนุน', 'งบลงทุน', '', '', '', ''],
              ...data,
            ]
          }
        },
      ],
    };
  }

  // "รายงานแบบที่ 5",
  async create05(docs: Array<any>,budgetYear:number): Promise<any> {
    let data: Array<Array<any>> = [];
    let sum1 = 0
    let sum2 = 0
    let sum3 = 0
    let sum4 = 0
    let sum5 = 0
    docs.forEach((el, i: number) => {
      data.push([i + 1, `${el.code} ${el.name}`, el.sector??"-", el.source??"-"
      , {text:(el.investmentBudget??0).formatFull(), alignment: 'right'}
      , {text:(el.subsidyBudget??0).formatFull(), alignment: 'right'}
      , {text:(el.disbursement??0).formatFull(), alignment: 'right'}
      , {text:(el.remainBudget??0).formatFull(), alignment: 'right'}
      , el.reason??"-"]);
      sum1 += el.investmentBudget
      sum2 += el.subsidyBudget
      sum3 += el.disbursement
      sum4 += el.remainBudget
      sum5 = sum1 + sum2
    });
    this.init = true;
    console.log(data)
    this._docDefinition = {
      pageSize: "A4",
      pageOrientation: "landscape",
      pageMargins: [40, 20, 40, 20],
      styles: this._styles,
      content: [
        {
          text: [
            { text: `รายงานสรุปแผนและผลการเบิกจ่ายโดยแยกประเภทงบประมาณ และแหล่งงบประมาณ\r\n`, style: "bold", },
            { text: `ประจำปีงบประมาณ พ.ศ. ${budgetYear}`, style: "bold", },
          ],
          alignment: "center",
          margin: [0, 0, 0, 10],
        },
        {
          style: 'tableExample',
          alignment: "center",
          table: {
            widths: ['*', 150, 'auto', 60, 70, 70, 70, 70, 100],
            headerRows: 2,
            body: [
              [{ text: 'ลำดับ', rowSpan: 2 },
              { text: 'รายการที่', rowSpan: 2 },
              { text: 'ฝ่ายและสำนัก\r\nที่รับผิดชอบ', rowSpan: 2 },
              { text: 'แหล่งงบประมาณ', rowSpan: 2 },
              { text: 'งบประมาณ', colSpan: 2 }, '',
              { text: 'ผลการเบิกจ่าย', rowSpan: 2 },
              { text: 'งบประมาณคงเหลือ', rowSpan: 2 },
              { text: 'หมายเหตุ', rowSpan: 2 },
              ],
              ['', '', '', '', 'งบอุดหนุน', 'งบลงทุน', '', '',],
              ...data,
              [
                { text:"", border: [false, false, false, false] },
                { text:"", border: [false, false, false, false] },
                { text:"", border: [false, false, false, false] },
                {
                  text: 'รวม', alignment: "center",
                  border: [false, false, false, false]
                },
                {
                  text: `${(sum1??0).formatFull()}`, alignment: "right",
                  border: [true, false, true, true]
                },

                {
                  text: `${(sum2??0).formatFull()}`, alignment: "right",
                  border: [true, false, true, true]
                },

                {
                  text: `${(sum3??0).formatFull()}`, alignment: "right",
                  border: [true, false, true, true]
                },

                {
                  text: `${(sum4??0).formatFull()}`, alignment: "right",
                  border: [true, false, true, true]
                },
                { text:"", border: [false, false, false, false] },
              ],
              [
                { text:"", border: [false, false, false, false] },
                { text:"", border: [false, false, false, false] },
                { text:"", border: [false, false, false, false] },
                {
                  text: 'รวมทั้งสิ้น', alignment: "center",
                  border: [false, false, false, false]
                },
                {
                  text: `${(sum5??0).formatFull()}`, alignment: "right",
                  border: [true, false, true, true],
                  colSpan: 2
                },
                { text:"", border: [false, false, false, false] },
                { text:"", border: [false, false, false, false] },
                { text:"", border: [false, false, false, false] },
                { text:"", border: [false, false, false, false] },
              ],
            ]
          }
        },
        // {
        //   margin: [307, 0, 0, 0],
        //   table: {
        //     widths: [60, 50, 51, 70, 70],
        //     body: [
        //       [
        //         {
        //           text: 'รวม', alignment: "center",
        //           border: [false, false, false, false]
        //         },

        //         {
        //           text: `${sum1}`, alignment: "right",
        //           border: [true, false, true, true]
        //         },

        //         {
        //           text: `${sum2}`, alignment: "right",
        //           border: [true, false, true, true]
        //         },

        //         {
        //           text: `${sum3}`, alignment: "right",
        //           border: [true, false, true, true]
        //         },

        //         {
        //           text: `${sum4}`, alignment: "right",
        //           border: [true, false, true, true]
        //         },
        //       ],
        //     ]
        //   },
        // },
        // {
        //   margin: [307, 0, 0, 0],
        //   table: {
        //     widths: [60, 109],
        //     body: [
        //       [
        //         {
        //           text: 'รวมทั้งสิ้น', alignment: "center",
        //           border: [false, false, false, false]
        //         },

        //         {
        //           text: `${sum5}`, alignment: "right",
        //           border: [true, false, true, true]
        //         },
        //       ],
        //     ]
        //   },
        // }
      ],
    };
  }

  // "รายงานแบบที่ 6"
  async create06(doc: Array<any>,budgetYear:number): Promise<any> {
    let months = ["ต.ค.","พ.ย.","ธ.ค.","รวม","ม.ค.","ก.พ.","มี.ค.","รวม","เม.ย.","พ.ค.","มิ.ย.","รวม","ก.ค.","ส.ค.","ก.ย.","รวม"]
    let quarter = ["ไตรมาสที่ 1","ไตรมาสที่ 2","ไตรมาสที่ 3","ไตรมาสที่ 4"]
    let data: Array<Array<any>> = [];
    // doc.items.forEach((el: IReport06Item, i: number) => {
    //   data.push([
    //     { text: el.title, colSpan: 2, alignment: "left" }, '',
    //     '','',
    //     { text: el.subsidyBudget.to2DigitFormat(), alignment: "right" },
    //     { text: el.investmentBudget.to2DigitFormat(), alignment: "right" },
    //     ...el.quarter1.map(d => { return [{ text: d.to2DigitFormat(), alignment: "right" }]} ),
    //     ...el.quarter2.map(d => { return [{ text: d.to2DigitFormat(), alignment: "right" }]} ),
    //     ...el.quarter3.map(d => { return [{ text: d.to2DigitFormat(), alignment: "right" }]} ),
    //     ...el.quarter4.map(d => { return [{ text: d.to2DigitFormat(), alignment: "right" }]} ),
    //     { text: el.plan.to2DigitFormat(), alignment: "right" },
    //     { text: el.result.to2DigitFormat(), alignment: "right" },
    //   ]);
    //   el.products.forEach((product, j) => {
    //     data.push([
    //       { text: product.title, colSpan: 2, alignment: "left" ,decoration: "underline"}, '',
    //       '','',
    //       { text: product.subsidyBudget.to2DigitFormat(), alignment: "right" },
    //       { text: product.investmentBudget.to2DigitFormat(), alignment: "right" },
    //       ...product.quarter1.map(d => { return [{ text: d.to2DigitFormat(), alignment: "right" }]} ),
    //       ...product.quarter2.map(d => { return [{ text: d.to2DigitFormat(), alignment: "right" }]} ),
    //       ...product.quarter3.map(d => { return [{ text: d.to2DigitFormat(), alignment: "right" }]} ),
    //       ...product.quarter4.map(d => { return [{ text: d.to2DigitFormat(), alignment: "right" }]} ),
    //       { text: product.plan.to2DigitFormat(), alignment: "right" },
    //       { text: product.result.to2DigitFormat(), alignment: "right" },
    //     ])
    //     product.activities.forEach((activity, k) => {
    //       data.push([
    //         { text: activity.title, colSpan: 2, alignment: "left" }, '',
    //         '','',
    //         { text: activity.subsidyBudget.to2DigitFormat(), alignment: "right" },
    //         { text: activity.investmentBudget.to2DigitFormat(), alignment: "right" },
    //         ...activity.quarter1.map(d => { return [{ text: d.to2DigitFormat(), alignment: "right" }]} ),
    //         ...activity.quarter2.map(d => { return [{ text: d.to2DigitFormat(), alignment: "right" }]} ),
    //         ...activity.quarter3.map(d => { return [{ text: d.to2DigitFormat(), alignment: "right" }]} ),
    //         ...activity.quarter4.map(d => { return [{ text: d.to2DigitFormat(), alignment: "right" }]} ),
    //         { text: activity.plan.to2DigitFormat(), alignment: "right" },
    //         { text: activity.result.to2DigitFormat(), alignment: "right" },
    //       ]);
    //       activity.details.forEach((detail, l) => {
    //         data.push([
    //           l+1,
    //           { text: detail.title},
    //           { text: detail.sector},
    //           { text: detail.source},
    //           { text: detail.subsidyBudget.to2DigitFormat(), alignment: "right" },
    //           { text: detail.investmentBudget.to2DigitFormat(), alignment: "right" },
    //           ...detail.quarter1.map(d => { return [{ text: d.to2DigitFormat(), alignment: "right" }]} ),
    //           ...detail.quarter2.map(d => { return [{ text: d.to2DigitFormat(), alignment: "right" }]} ),
    //           ...detail.quarter3.map(d => { return [{ text: d.to2DigitFormat(), alignment: "right" }]} ),
    //           ...detail.quarter4.map(d => { return [{ text: d.to2DigitFormat(), alignment: "right" }]} ),
    //           { text: detail.plan.to2DigitFormat(), alignment: "right" },
    //           { text: detail.result.to2DigitFormat(), alignment: "right" },
    //         ]);
    //       })
    //     })
    //   });
    // });
    this.init = true;
    this._docDefinition = {
      pageSize: "A3",
      pageOrientation: "landscape",
      pageMargins: [5, 10, 5, 20],
      styles: this._styles,
      defaultStyle: {
        fontSize:6,
      },
      content: [
        {
          text: [
            { text: `รายงานสรุปแผนและผลการเบิกจ่ายเป็นรายเดือน/รายไตรมาส/รายปี\r\n`, style: "bold", },
            { text: `ประจำปีงบประมาณ พ.ศ. ${budgetYear}`, style: "bold", },
          ],
          alignment: "center",
          margin: [0, 0, 0, 10],
        },
        // {
        //   style: 'tableExample',
        //   alignment: "center",
        //   margin: [0, 0, 0, 10],
        //   table: {
        //     widths: [...new Array(6).fill("auto"),...new Array(34).fill("*")],
        //     headerRows: 4,
        //     body: [
        //       [
        //         { text: 'ลำดับ', rowSpan: 4 },
        //         { text: 'ผลผลิต/กิจกรรม/รายการ', rowSpan: 4 },
        //         { text: 'ฝ่ายและสำนักที่รับผิดชอบ', rowSpan: 4 },
        //         { text: 'แหล่งงบประมาณ', rowSpan: 4 },
        //         { text: 'งบประมาณ', colSpan: 2, rowSpan: 2 },"",
        //         { text: 'แผน/ผลการเบิกจ่าย', colSpan: 34 },
        //         '','','','','',
        //         '','','','','',
        //         '','','','','',
        //         '','','','','',
        //         '','','','','',
        //         '','','','','',
        //         '','',''
        //       ],
        //       [
        //         '','','','','','',
        //         ...(_.flatten(quarter.map(d => { return [{ text: d, colSpan: 8, alignment: "center" },"","","","","","",""] }))),
        //         {text: "รวมทั้งสิ้น",colSpan:2},'',
        //       ],

        //       [
        //         '','','','',
        //         { text: 'งบอุดหนุน', rowSpan: 2 },
        //         { text: 'งบลงทุน', rowSpan: 2 },
        //         ...(_.flatten(months.map(d => { return [{ text: d, colSpan: 2, alignment: "center" },""] }))),
        //         { text: 'แผน', rowSpan: 2 },
        //         { text: 'ผล', rowSpan: 2 },
        //       ],
        //       [
        //         '','','','','','',
        //         ...new Array(32).fill(true).map((o,i)=>{ return i%2==0?"แผน":"ผล" }),
        //         '','',
        //       ],
        //       [
        //         { text: `รวมงบประมาณทั้งสิ้น`, colSpan: 2 },'',
        //         '','',
        //         { text: `${doc.investmentBudget.to2DigitFormat()}`,alignment:"right" },
        //         { text: `${doc.subsidyBudget.to2DigitFormat()}`,alignment:"right" },
        //         ...doc.quarter1.map(d => { return [{ text: d.to2DigitFormat(), alignment: "right" }]} ),
        //         ...doc.quarter2.map(d => { return [{ text: d.to2DigitFormat(), alignment: "right" }]} ),
        //         ...doc.quarter3.map(d => { return [{ text: d.to2DigitFormat(), alignment: "right" }]} ),
        //         ...doc.quarter4.map(d => { return [{ text: d.to2DigitFormat(), alignment: "right" }]} ),
        //         { text: `${doc.plan.to2DigitFormat()}`,alignment:"right" },
        //         { text: `${doc.result.to2DigitFormat()}`,alignment:"right" },
        //       ],
        //       ...data,
        //       ]
        //     }
        //   },
        ],
      };
  }

  // "รายงานแบบที่ 7",
  async create07(docs: Array<any>, budgetYear:number,sumtotal: Array<any>, budgetType:string = "แหล่งงบประมาณประจำปี"): Promise<any> {
    let data: Array<Array<any>> = [];

    let sums = sumtotal??[]
    console.log({sums})
    data.push([
      '', { text: "รวมทั้งสิ้น", alignment: "left", colSpan:2 }, '',
      ...sums.map(s=>({ text:  (s??0).formatFull(), alignment: "right" })),
    ]);
    docs.forEach((el: any, i: number) => {
      data.push([
        '', { text: el.sector, decoration: "underline", alignment: "left", colSpan:2 }, '',
        { text: (el.sumSector??0).formatFull(), decoration: "underline", alignment: "right" },
        { text: (el.sumBudget1??0).formatFull(), decoration: "underline", alignment: "right" },
        { text: (el.sumBudget2??0).formatFull(), decoration: "underline", alignment: "right" },
        { text: (el.sumPercentage??0).formatFull(), decoration: "underline", alignment: "right" },
        { text: (el.sumErp??0).formatFull(), decoration: "underline", alignment: "right" },
        { text: (el.sumPercentageErp??0).formatFull(), decoration: "underline", alignment: "right" },
        { text: (el.sumRemain??0).formatFull(), decoration: "underline", alignment: "right" },
        { text: (el.sumPercentageRemain??0).formatFull(), decoration: "underline", alignment: "right" },
        { text: (((el.sumErp??0)/sums[0])*100).formatFull(), decoration: "underline", alignment: "right" },
      ]);
      el.projects.forEach((pj:any, pji:number) => {
        data.push([
          pji + 1,
          { text: `${pj.code} ${pj.name}`, alignment: "left" },
          { text: pj.source, alignment: "center" },
          { text: (pj.sumBudget??0).formatFull(), alignment: "right" },
          { text: (pj.budget1??0).formatFull(), alignment: "right" },
          { text: (pj.budget2??0).formatFull(), alignment: "right" },
          { text: (pj.percentage??0).formatFull(), alignment: "right" },
          { text: (pj.sumErp??0).formatFull(), alignment: "right" },
          { text: (pj.percentageErp??0).formatFull(), alignment: "right" },
          { text: (pj.remaining??0).formatFull(), alignment: "right" },
          { text: (pj.percentageRemain??0).formatFull(), alignment: "right" },
          { text: (pj.percentageRemain??0).formatFull(), alignment: "right" },
        ]);
      });
    });
    this.init = true;
    this._docDefinition = {
      pageSize: "A4",
      pageOrientation: "landscape",
      pageMargins: [20, 20, 20, 20],
      styles: this._styles,
      content: [
        {
          text: [
            { text: `รายงานแผนเทียบกับผลการเบิกจ่ายโดยแยกเป็นรายฝ่ายและสำนักของ กกท. (มีร้อยละ) และสามารถเปรียบเทียบการแสดงผลระหว่างระหว่างฝ่ายและสำนัก\r\n`, style: "bold", },
            { text: `แหล่งงบประมาณ : `, style: "bold", },
            { text: budgetType+`\r\n` },
            { text: `${sums[0].formatFull()}\r\n`, decoration: "underline" },
            { text: `ประจำปีงบประมาณ พ.ศ. ${budgetYear}`, style: "bold", },
          ],
          alignment: "center",
          margin: [0, 0, 0, 10],
        },
        {
          style: 'tableExample',
          alignment: "center",
          table: {
            widths: ['auto', 155, 70, 60, 60, 60, 30, 50, 30, 'auto', 'auto', 'auto'],
            headerRows: 2,
            body: [
              [{ text: 'ลำดับ', rowSpan: 2 },
              { text: 'รายการที่', rowSpan: 2 },
              { text: 'แหล่งงบประมาณ', rowSpan: 2 },
              { text: 'งบประมาณ', colSpan: 4 },
                "",
                "",
                "",
              { text: 'ผลการเบิกจ่าย', colSpan: 2 },
                "",
              { text: 'งบประมาณคงเหลือ', rowSpan: 2 },
              { text: 'คงเหลือคิดเป็น\r\nร้อยละ', rowSpan: 2 },
              { text: 'ผลการเบิกจ่าย\r\nคิดเป็นร้อยละ\r\nของการรวม\r\n(งบประจำปี)', rowSpan: 2 },
              ],
              ["", "", "", 'รวม\r\n(อนุดหนุน+ลงทุน)', 'งบอุดหนุน', 'งบลงทุน', 'ร้อยละ', 'งบประมาณ', 'ร้อยละ', "", "", ""],
              // [
              //   '',
              //   { text: 'รวมทั้งสิ้น', colSpan: 2 },
              //   '',
              //   doc.budget.to2DigitFormat(),
              //   doc.investmentBudget.to2DigitFormat(),
              //   doc.subsidyBudget.to2DigitFormat(),
              //   ((doc.budget / doc.annualBudget) * 100).to2Digit(),
              //   doc.disbursement.to2DigitFormat(),
              //   ((doc.disbursement / doc.budget) * 100).to2Digit(),
              //   doc.remainBudget.to2DigitFormat(),
              //   ((doc.remainBudget / doc.budget) * 100).to2Digit(),
              //   ((doc.disbursement / doc.annualBudget) * 100).to2Digit(),
              // ],
              ...data,
            ]
          }
        },
      ],
    };
  }
  wordcut = require("wordcut");


  // "รายงานแบบที่ 8",
  async create08(docs: Array<any>, budgetYear:number=2565): Promise<any> {
    let data:Array<any> = []
    let sum1 = 0
    let sum2 = 0
    let sum3 = 0
    let sum4 = 0
    let sum5 = 0
    let sum6 = 0
    let sum7 = 0
    let sum8 = 0
    docs.forEach((el: any, i: number) => {
      data.push([
        i + 1,
        { text:`${el.code} ${el.name}`, alignment:'left' },
        `${el.sector}`,
        `${el.source}`,
        { text:`${(el.bachelor??0).formatComma()}`, alignment: 'right' },
        { text:`${(el.master??0).formatComma()}`, alignment: 'right' },
        { text:`${(el.other??0).formatComma()}`, alignment: 'right' },
        { text:`${((el.bachelor??0)+(el.master??0)+(el.other??0)).formatComma()}`, alignment: 'right' },
        { text:`${(el.bachelorAmount??0).formatFull()}`, alignment: 'right' },
        { text:`${(el.masterAmount??0).formatFull()}`, alignment: 'right' },
        { text:`${(el.otherAmount??0).formatFull()}`, alignment: 'right' },
        { text:`${((el.bachelorAmount??0)+(el.masterAmount??0)+(el.otherAmount??0)).formatFull()}`, alignment: 'right' },
        el.reason??""
      ]);
      sum1 += el.bachelor
      sum2 += el.master
      sum3 += el.other
      sum4 += el.bachelor+el.master+el.other;
      sum5 += el.bachelorAmount
      sum6 += el.masterAmount
      sum7 += el.otherAmount
      sum8 += el.bachelorAmount+el.masterAmount+el.otherAmount;
    });
    this.init = true;
    this._docDefinition = {
      pageSize: "A4",
      pageOrientation: "landscape",
      pageMargins: [20, 20, 20, 20],
      styles: this._styles,
      content: [
        {
          text: [
            { text: `รายงานค่าใช้จ่ายในประเภทต่างๆที่อยู่ในหน้าแผนฯ โดยสามารถรวมและแยกเป็นฝ่ายและสำนักต่างๆ`, style: "bold", },
            { text: `การจ้างผู้ช่วยปฏิบัติงานฯ`, style: "bold", },
            { text: `ประจำปีงบประมาณ พ.ศ. ${budgetYear}`, style: "bold", },
          ],
          alignment: "center",
          margin: [0, 0, 0, 10],
        },

        {
          style: 'tableExample',
          alignment: "center",
          table: {
            widths: ['auto', 150, 70, 70, 30, 30, 30, 30, 45, 45, 45, 45, 70],
            headerRows: 2,
            body: [
              [
              { text: 'ลำดับ', rowSpan: 2 },
              { text: 'รายการที่', rowSpan: 2 },
              { text: 'ฝ่ายและสำนักที่\r\nรับผิดชอบ', rowSpan: 2 },
              { text: 'แหล่งงบประมาณ', rowSpan: 2 },
              { text: 'วุฒิการศึกษา(จำนวนคน)', colSpan: 4 }, '', '', '',
              { text: 'จำนวนงบประมาณ', colSpan: 4 }, '', '', '',
              { text: 'หมายเหตุ', rowSpan: 2 },
              ],
              ['', '', '', '', 'ป.ตรี', 'ป.โท', 'อื่นๆ', 'รวม', 'ป.ตรี', 'ป.โท', 'อื่นๆ', 'รวม', ''],
              ...data,
              [
                {
                  text: '',
                  border: [false, false, false, false]
                },
                {
                  text: '',
                  border: [false, false, false, false]
                },
                {
                  text: '',
                  border: [false, false, false, false]
                },
                {
                  text: 'รวม', alignment: "center", colSpan: 2,
                  border: [false, false, false, false]
                },
                {
                  text: `${(sum1??0).formatComma()}`, alignment: "right",
                  border: [true, false, true, true]
                },
                {
                  text: `${(sum2??0).formatComma()}`, alignment: "right",
                  border: [true, false, true, true]
                },
                {
                  text: `${(sum3??0).formatComma()}`, alignment: "right",
                  border: [true, false, true, true]
                },
                {
                  text: `${(sum4??0).formatFull()}`, alignment: "right",
                  border: [true, false, true, true]
                },
                {
                  text: `${(sum5??0).formatFull()}`, alignment: "right",
                  border: [true, false, true, true]
                },
                {
                  text: `${(sum6??0).formatFull()}`, alignment: "right",
                  border: [true, false, true, true]
                },
                {
                  text: `${(sum7??0).formatFull()}`, alignment: "right",
                  border: [true, false, true, true]
                },
                {
                  text: `${(sum8??0).formatFull()}`, alignment: "right",
                  border: [true, false, true, true]
                },
                {
                  text: '',
                  border: [false, false, false, false]
                },
              ]
            ]
          }
        },
      ],
    };
  }

  // "รายงานแบบที่ 9",
  async create09(budget: any, budgetitems: Array<any>, indicators:Array<any>,budgetYear:Number): Promise<any> {
    const getLevel1 = (): Array<any> => {
      try {
        let l1 = budgetitems ?? {}
        if (budget.showZero) l1 = l1.filter((e: any) => (e.months ?? [0]).reduce((a: number, b: number) => a + b, 0) !== 0)
        return l1
      } catch (error) {
        console.log(error)
        return []
      }
    }
    const getLevel2 = (i: number): Array<any> => {
      try {
        let l1 = budgetitems ?? {}
        let l2 = l1[i].children ?? {}
        if (budget.showZero) l2 = l2.filter((e: any) => (e.months ?? [0]).reduce((a: number, b: number) => a + b, 0) !== 0)
        return l2
      } catch (error) {
        console.log(error)
        return []
      }
    }
    const getLevel3 = (i: number, j: number): Array<any> => {
      try {
        let l1 = budgetitems ?? {}
        let l2 = l1[i].children ?? {}
        let l3 = l2[j].children ?? {}
        if (budget.showZero) l3 = l3.filter((e: any) => (e.months ?? [0]).reduce((a: number, b: number) => a + b, 0) !== 0)
        return l3
      } catch (error) {
        console.log(error)
        return []
      }
    }
    const resolveIndicator = (id:string) => {
      try {
        let found = indicators.find(ind=>ind.id==id)
        if(found === undefined) return {code:"",name:""}
        return found.code
      } catch (error) {
        return ""
      }
    }
    const indicatorsText = ():string => {
      try {
        let allIndicators:Array<any> = (indicators??[])
        let budgetIndicators:Array<string> = []
        try {
          budgetIndicators = budget.data.indicators as Array<string>
        } catch (error) {
          
        }
        // console.table(allIndicators)
        // console.table(budgetIndicators)
        let result = allIndicators.filter((allIndicator:any)=>{
          return budgetIndicators.includes(allIndicator.id)
        })
        // console.log({result})
        // .map((el:any)=>{
        //   return el.code
        // })
        return result.map(rs=>(rs.code??"")).join(", ")
      } catch (error) {
        console.log(error)
        return "-"
      }
      return "-"
    }
    const kpi6 = () => {
      try {
        let found = budget.data.kpi6.map((a: any,i:number) => {
          return i+1 + "." + a 
        })
        console.log('found',found)
        return found
      } catch (error) {
        
      }
    }
    const kpi12 = () => {
      try {
        let found = budget.data.kpi12.map((a: any,i:number) => {
          return i+1 + "." + a 
        })
        console.log('found',found)
        return found
      } catch (error) {
        
      }
    }
    let data: Array<any> = [];
    let totalBudget = budgetitems.reduce((a, b) => a + b.sum, 0)
    getLevel1().forEach((lv1: any,i:number) => {
      console.log('month',lv1.months)
      data.push([
        {text:lv1.number},
        {text:lv1.subject, alignment:'left',preserveLeadingSpaces:true},
        {text:lv1.product},
        {text:lv1.responsible},
        {text:lv1.type === 'งบอุดหนุน' ? ((lv1.months??[]).reduce((a:number,b:any)=>a+(typeof b === 'number'?b:0),0)).formatComma() : ""},
        {text:lv1.type === 'งบลงทุน' ? ((lv1.months??[]).reduce((a:number,b:any)=>a+(typeof b === 'number'?b:0),0)).formatComma() : ""},
        ...lv1.months.map((el:any,c:any)=>({text:(el??0).formatComma(),fillColor:lv1.monthsFlag[c] == true ?"#d1e2f5":"#ffffff"})),
        {text:lv1.indicators.map((ind:any)=>resolveIndicator(ind)).join(", ")}
      ])
      getLevel2(i).forEach((lv2: any,j:number) => {
        data.push([
          {text:""},
          {text:"\t"+lv2.number + lv2.subject, alignment:'left',preserveLeadingSpaces:true},
          {text:lv2.product},
          {text:lv2.responsible},
          {text:lv2.type === 'งบอุดหนุน' ? ((lv2.months??[]).reduce((a:number,b:any)=>a+(typeof b === 'number'?b:0),0)).formatComma() : ""},
          {text:lv2.type === 'งบลงทุน' ? ((lv2.months??[]).reduce((a:number,b:any)=>a+(typeof b === 'number'?b:0),0)).formatComma() : ""},
          // ...lv2.months.map((el:any,c:any)=>({text:(el??0)})),
          ...lv2.months.map((el:any,c:any)=>({text:typeof el ==='number'? (el??0).formatComma() : (0).formatComma(),fillColor:lv2.monthsFlag[c] == true ?"#e4ecfc":"#ffffff"})),
          {text:lv2.indicators.map((ind:any)=>resolveIndicator(ind)).join(", ")}
        ])
        getLevel3(i,j).forEach((lv3: any,k:number) => {
          data.push([
            {text:""},
            {text:"\t\t"+lv3.subject, alignment:'left',preserveLeadingSpaces:true},
            {text:lv3.product},
            {text:lv3.responsible},
            {text:lv3.type === 'งบอุดหนุน' ? (lv3.months.reduce((a: any, b: any) => a + (typeof b === 'number'?b:0)), 0) : ""},
            {text:lv3.type === 'งบลงทุน' ? (lv3.months.reduce((a: any, b: any) => a + (typeof b === 'number'?b:0)), 0) : ""},
            ...lv3.months.map((el:any,c:any)=>({text:(el??0).formatComma(),fillColor:lv3.monthsFlag[c] == true ?"#f5f8f9":"#ffffff"})),
            {text:lv3.indicators.map((ind:any)=>resolveIndicator(ind)).join(", ")}
          ])
        })
      })

    })
    let data3 : Array<any> = [];
    (budget.data||{}).kpi6.forEach((el:any,i:number)=>{
      data3.push({text:`${i+1}.${el}`, border: [false, false, false, false], alignment: "left"})
    })
    let data4 : Array<any> = [];
    (budget.data||{}).kpi12.forEach((el:any,i:number)=>{
      data4.push({text:`${i+1}.${el}`, border: [false, false, false, false], alignment: "left"})
    })
    let result : Array<any> = []
    for(var i = 0; i < 12; i++) {
      result.push(getLevel1().map(lv1=>lv1.months[i]).reduce((a,b)=>a+b,0))
      // console.log('ssssssss',getLevel1().filter(lv1=>(lv1.type === 'งบลงทุน')).map((lv1:any)=>lv1.months[i]).reduce((a,b)=>a+b,0))
    }
    let sum1 = 0;
    let sum2 = 0;
    this.init = true;
    this._docDefinition = {
      pageSize: "A4",
      pageOrientation: "landscape",
      pageMargins: [10, 20, 10, 20],
      styles: this._styles,
      defaultStyle: {
        fontSize: 10,
      },
      content: [
        {
          text: [
            { text: `แผนการปฎิบัติงานและแผนการใช้จ่ายงบประมาณ ประจำปีงบประมาณ พ.ศ. ${budgetYear}\r\n`, style: "bold", },
          ],
          alignment: "center",
          margin: [0, 0, 0, 10],
        },
        {
          style: 'tableExample',
          alignment: "center",
          table: {
            widths: [50, '*', '*'],
            body: [
              [{ text: `รายการที่ ${budget.code??''} - ${budget.name??''}`, colSpan: 2, border: [false, false, false, false], alignment: "left" }, '',
              { text: `งบประมาณประจำปี ${(totalBudget??0).formatComma()} บาท`, rowSpan: 2, border: [false, false, false, false], alignment: "left" },
              ],
              [
                { text: "KPI 6 เดือน", border: [false, false, false, false], alignment: "left" },
                { text: budget.data.kpi6.map((el:any,i:number)=>i+1 + "." + el + ""), border: [false, false, false, false], alignment: "left" },
                { text: "", border: [false, false, false, false], alignment: "left" },
              ],
              [
                { text: "KPI 12 เดือน", border: [false, false, false, false], alignment: "left" },
                { text: budget.data.kpi12.map((el:any,i:number)=>i+1 + "." + el + ""), border: [false, false, false, false], alignment: "left" },
                { text: "", border: [false, false, false, false], alignment: "left" },
              ],
              [
                { text: "ตัวชี้วัดที่เกี่ยวข้อง:", border: [false, false, false, false], alignment: "left" },
                { text: indicatorsText(), border: [false, false, false, false], alignment: "left" },
                { text: "", border: [false, false, false, false], alignment: "left" },
              ],
            ]
          }
        },
        {
          style: 'tableExample',
          alignment: "center",
          table: {
            widths: ['auto', 60, 70, 35, 35, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, '*'],
            headerRows: 2,
            body: [
              [
                { text: 'ลำดับที่', rowSpan: 2 },
                { text: 'การดำเนินงานหลัก', rowSpan: 2 },
                { text: 'ผลผลิตและค่าเป้าหมาย', rowSpan: 2 },
                { text: 'ผู้รับผิดชอบ', rowSpan: 2 },
                { text: 'งบอุดหนุน', rowSpan: 2 },
                { text: 'งบลงทุน', rowSpan: 2 },
                { text: `${budget.budgetYear - 1}`, colSpan: 3 }, '', '',
                { text: `${budget.budgetYear}`, colSpan: 9 }, '', '', '', '', '', '', '', '',
                { text: 'ความเชื่อมโยง', rowSpan: 2 },
              ],
              ['', '', '', '', '', '', 'ต.ค.', 'พ.ย.', 'ธ.ค.', 'ม.ค.', 'ก.พ.', 'มี.ค.', 'เม.ย.', 'พ.ค.', 'มิ.ย.', 'ก.ค.', 'ส.ค.', 'ก.ย.', ''],
              ...data,
              [
                { text: 'รวมงบประมาณประจำปี', colSpan: 4, rowSpan: 2, alignment: "right" },``, ``,``,
                getLevel1().filter(lv1=>(lv1.type === 'งบอุดหนุน')).map(bi=>bi.months.reduce((a:any,b:any)=>a+b,0)).reduce((a,b)=>a+b,0).formatComma(),
                getLevel1().filter(lv1=>(lv1.type === 'งบลงทุน')).map(bi=>bi.months.reduce((a:any,b:any)=>a+b,0)).reduce((a,b)=>a+b,0).formatComma(),
                ...result.map(rs=>({text:(rs??0).formatComma(), rowSpan: 2})),
                {text: "", rowSpan: 2} ,
              ],
              [
                '', '', '', '',
                { text: getLevel1().map(bi=>bi.months.reduce((a:any,b:any)=>a+b,0)).reduce((a,b)=>a+b,0).formatComma(), colSpan: 2 }, 
                '','', '', '', '', '', '', '', '', '', '', '', ''
              ]
            ]
          }
        },
        {
          layout: "noBorders",
          alignment: "center",
          table: {
            widths: [20, 180],
            heights:30,
            body: 
            [
              [
                "",
                "",
              ],
              [
                "",
                "",
              ],

            ]
          },
        },
        {
          layout: "noBorders",
          alignment: "center",
          table: {
            widths: [20, 180],
            body: 
            [
              [
                "",
                (".").repeat(60)+"ลงชื่อ",
              ],
              [
                "",
                "        ชาญยุทธ คำหวาน       ",
              ],

            ]
          },
        },
      ],
    };
  }

  // "รายงานแบบที่ 10",
  async create10(flatted: Array<any> ,budgetYear:Number): Promise<any> {
    this.init = true;
    this._docDefinition = {
      pageSize: "A3",
      pageOrientation: "landscape",
      pageMargins: [20, 20, 20, 20],
      styles: this._styles,
      content: [
        {
          text: [
            { text: `แผนการปฏิบัติงานและแผนการใช้จ่ายงบประมาณ ประจำปีงบประมาณ พ.ศ. ${budgetYear}`, style: "bold", },
          ],
          alignment: "center",
          margin: [0, 0, 0, 10],
        },
        {
          style: 'tableExample',
          alignment: "center",
          table: {
            widths: [30, 30, '*', 80, 50, 50, 50, 50, 50, 50, 50, 30],
            body: [
              [
                { text: 'ผลผลิต / กิจกรรม / รายการ', colSpan: 3 }, '', '',
                { text: 'ฝ่าย/สำนัก' },
                { text: 'ไตรมาส 1' },
                { text: 'ไตรมาส 2' },
                { text: 'ไตรมาส 3' },
                { text: 'ไตรมาส 4' },
                { text: 'งบประมาณ\r\nได้รับจัดสรร' },
                { text: 'งบประมาณ\r\กองทุนฯ' },
                { text: 'เงินสะสมของ\r\กกท.' },
                { text: 'หน้า' },
              ],
              ...flatted.map(el=>{
                let quarter:Array<number> = el.quarter
                if(el.isProject) return [
                  { text: 'รายการ'},
                  { text: el.code},
                  { text: el.name, alignment:'left'},
                  { text: el.sector},
                  ...quarter.map(m=>({text:m.formatFull(), alignment:'right'})),
                  { text: ''},
                  { text: ''},
                  { text: ''},
                  { text: ''},
                ]
                else return [
                  { text: el.isTotal == true ? 'รวมงบประมาณทั้งสิ้น' : el.name??"", colSpan: 3, alignment: el.isTotal == true ? 'center' : 'left' },"","",
                  "",
                  ...quarter.map(m=>({text:m.formatFull(), alignment:'right'})),
                  "","","",""
                ]
              })
            ]
          }
        },
      ],
    };
  }

  // "รายงานแบบที่ 11",
  async create11(doc: any,budgetYear:number, items:Array<any>, indicators:Array<any>): Promise<any> {
    const resolveIndicator = (id:string) => {
      try {
        let found = indicators.find(ind=>ind.id==id)
        if(found === undefined) return {code:"",name:""}
        return found.code
      } catch (error) {
        return ""
      }
    }
    let data: Array<any> = [];
    (doc.data??{}).sectors.forEach((el:any, i:number) => {
      data.push([
        {text:`${el.prefix??""}${el.firstName??""} ${el.lastName??""} ${el.position??"-"} email: ${el.email??"-"} เบอร์โทร: ${el.tel??"-"}`,margin: [40, 0, 0, 0]},
        {text:"(ลงชื่อ"+(".").repeat(60)+")",margin: [40, 0, 0, 0]},
      ])
    })
    let data2: Array<any> = [];
    items.forEach((lv1:any, i:number) => {
      data2.push([
        {alignment:'left', text: `${lv1.number??""}. ${(lv1.budgetItemType == 'เตรียมการและบริหารจัดการ')?"เตรียมการและบริหารจัดการ":lv1.subject??"-"}`},
        {alignment:'right', text:`${((lv1.months??[]).reduce((a:number,b:any)=>a+(typeof b === 'number'?b:0),0)).formatComma()} บาท`},
      ])
      lv1.children.forEach((lv2:any, j:number) => {
        data2.push([
          {alignment:'left',text:`${lv2.number??""} ${(lv2.budgetItemType == 'เตรียมการและบริหารจัดการ')?"เตรียมการและบริหารจัดการ":lv2.subject??"-"}`,margin: [10, 0, 0, 0]},
          {alignment:'right', text:`${((lv2.months??[]).reduce((a:number,b:any)=>a+(typeof b === 'number'?b:0),0)).formatComma()} บาท`,margin: [10, 0, 0, 0]},
        ])
        lv2.children.forEach((lv3:any, k:number) => {
          data2.push([
            {alignment:'left', text:`- ${lv3.subject} ${lv3.qty??"-"} คน`,margin: [10, 0, 0, 0]},
            {alignment:'right', text:lv3.qty??"-"+" คน",margin: [10, 0, 0, 0]},
          ])
        })
      })
    })
    let mm = (u: number) => {
      return u * 0.352777778;
    };
    this.init = true;
    this._docDefinition = {
      pageSize: "A4",
      // pageOrientation: "landscape",
      pageMargins: [40, 20, 40, 20],
      styles: this._styles,
      content: [
        { text: [{ text: `แผนการปฏิบัติงานและแผนการใช้จ่ายงบประมาณ ประจำปีงบประมาณ พ.ศ. ${budgetYear??0}`, style: "bold", fontSize:18},], 
        alignment: "center", margin: [0, 30, 0, 10],},
        {
          canvas: [
            {
              type: "line",
              x1: mm(150),
              y1: 5,
              x2: mm(1300),
              y2: 5,
              lineWidth: 0.5,
            },
          ],
        },
        { text: [{ text: `1. ผลผลิต กกท.ที่ ${(doc.data??{}).strategics??""}`},],
          margin: [20, 10, 0, 0],},
        { text: [{ text: `2. กลยุทธ์ (กิจกรรมหลัก) กกท. ที่` },],
          margin: [20, 0, 0, 0],},
          ...(doc.data??{}).tactics.map((el:any,a:any)=>({text:el??"",margin: [40, 0, 0, 0]})),
        { text: [{ text: `3. แผนงานหลัก (กิจกรรมหลัก) กกท. ที่` },],
          margin: [20, 0, 0, 0],},
          ...(doc.data??{}).plans.map((el:any,a:any)=>({text:el??"",margin: [40, 0, 0, 0]})),
          { text: [{ text: ` ตัวชี้วัดพันธกิจ ฝ่าย/สำนัก` },],
          margin: [30, 0, 0, 0],},
          ...(doc.data??{}).indicators.map((el:any,a:any)=>({text:resolveIndicator(el),margin: [40, 0, 0, 0]})),
        { text: [{ text: `4. รายการที่ ${doc.code??""} ${doc.name??""}` },],
          margin: [20, 0, 0, 0],},
        { text: [{ text: `5. หลักการและเหตุผล`},],
          margin: [20, 0, 0, 0],},
        { stack:htmlToPdfmake((doc.data??{}).principles??"",{defaultStyles:this.htmlStyle}), margin: [40, 0, 0, 0],},
        { text: [{ text: `6. วัตถุประสงค์ (Objective)` },],
          margin: [20, 0, 0, 0],},
          ...(doc.data??{}).objectives.map((el:any,a:any)=>({text:el??"",margin: [40, 0, 0, 0]})),
        { text: [{ text: `7. ความเชื่อมโยงกับยุทธาศาสตร์และแผนระดับชาติ (Project Goal)` },],
          margin: [20, 0, 0, 0],},
        { stack:htmlToPdfmake((doc.data??{}).goals??"",{defaultStyles:this.htmlStyle}), margin: [40, 0, 0, 0],},
        { text: [{ text: `8. กลุ่มเป้าหมายของรายการ` },],
          margin: [20, 0, 0, 0],},
          { stack:htmlToPdfmake((doc.data??{}).target??"",{defaultStyles:this.htmlStyle}), margin: [40, 0, 0, 0],},
        { text: [{ text: `9. ตัวชี้วัดและค่าเป้าหมาย 6 เดือน` },],
          margin: [20, 0, 0, 0],},
          ...(doc.data??{}).kpi6.map((el:any,a:any)=>({text:el??"",margin: [40, 0, 0, 0]})),     
        { text: [{ text: `   ตัวชี้วัดและค่าเป้าหมาย 12 เดือน` },],
          margin: [30, 0, 0, 0],},
          ...(doc.data??{}).kpi12.map((el:any,a:any)=>({text:el??"",margin: [40, 0, 0, 0]})), 

        { text: [{ text: `10. สถานที่ดำเนินการ` },],
          margin: [20, 0, 0, 0],},
        { text: [{ text: `${(doc.data??{}).location??""}`},],
          margin: [40, 0, 0, 0],},             

        { text: [{ text: `11. ระยะเวลาดำเนินการ` },],
          margin: [20, 0, 0, 0],},
        { text: [{ text: `${(doc.data??{}).period??""}`}],
          margin: [40, 0, 0, 0],},

        { text: [ { text: `12. วิธีดำเนินการ` },],
          margin: [20, 0, 0, 0],},
          ...(doc.data??{}).strategies.map((el:any,a:any)=>({text:el??"",margin: [40, 0, 0, 0]})), 
        { text: [{ text: `13. แผนการปฏิบัติงานและแผนการใช้จ่ายงบประมาณ ${((doc.data ?? {}).budget[0]??0).formatComma()} บาท` },],
        //`${((lv1.months??[]).reduce((a:number,b:any)=>a+(typeof b === 'number'?b:0),0)).formatComma()} บาท`
          margin: [20, 0, 0, 0],},
        {
          style: "tableExample",
          layout: 'noBorders',
          alignment: "center",
          margin:[40, 0, 40, 0],
          table: {
            widths: ["*", "*"],
            body: [
              ...data2, 
            ],
          },
        }, 
        { text: [{ text: `14. ผลที่คาดว่าจะได้รับ` },],
          margin: [20, 0, 0, 0],},
          ...(doc.data??{}).expected.map((el:any,a:any)=>({text:el??"",margin: [40, 0, 0, 0]})), 
        { text: [{ text: `15. ผู้รับผิดชอบ` },],
          margin: [20, 0, 0, 0],},
        { text: [{ text: `${((doc.data??{}).coordinator[0]??[]).prefix??""}${((doc.data??{}).coordinator[0]??[]).firstName??""} ${((doc.data??{}).coordinator[0]??[]).lastName??""} เบอร์โทร ${((doc.data??{}).coordinator[0]??[]).tel??""}`},],
          margin: [40, 0, 0, 0],},   
          ...data,
        {
          layout: "noBorders",
          alignment: "center",
          table: {
            widths: [20, 180],
            heights:60,
            body: 
            [
              [
                "",
                "",
              ],
              [
                "",
                "",
              ],

            ]
          },
        },
        {
          layout: "noBorders",
          alignment: "center",
          table: {
            widths: [20, 180],
            body: 
            [
              [
                "",
                (".").repeat(60)+"ลงชื่อ",
              ],
              [
                "",
                "        ชาญยุทธ คำหวาน       ",
              ],

            ]
          },
        },
      ],
    };
  }

  save(name: string) {
    let pdfDocGenerator = pdfMake
      .createPdf(this._docDefinition, undefined, this._fonts)
      .download(this.init ? name : "error.txt");
  }

}