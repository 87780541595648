























































































import { ADDRESS } from "@/setting";
import axios from "axios";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {
  },
})
export default class List extends Vue {
  docs:Array<any> = []
  show: number = 20;
  page: number = 1;
  pages: number = 0;
  total: number = 0;
  filter1: any = {}
  filter2: any = {}
  types:Array<any> = []
  clearSearch(){
    this.filter1 = 0
    this.filter2 = ""
    this.fetchData()
  }
  resolveSectorFhai(_id:string):string{
    try {
      let code = this.$store.state.sectors.find((item:any)=>item.id==_id).code
      let foundName = this.$store.state.sectors.filter((item:any)=>item.level==3).find((item:any)=>item.code==code)
      return foundName.name
    } catch (error) {
      return ""
    }
  }
  getRemaining(items:Array<any>){
    let sum = 0
    items.forEach((el,j)=>{
      for (let i = 0; i < 12; i++) {
        let erp = JSON.parse(items[j].monthsERP)[i] ?? 0
        let budget = JSON.parse(items[j].months)[i] ?? 0
        sum += ((budget??0) - (erp??0))
      }
    })
    return sum
  }
  getSurplus(items:Array<any>){
    let sum = 0
    items.forEach((el,j)=>{
      for (let i = 0; i < 12; i++) {
        let erp = JSON.parse(items[j].monthsERP)[i] ?? 0
        let budget = JSON.parse(items[j].months)[i] ?? 0
        if(erp>0) sum += ((budget??0) - (erp??0))
        // sum += erp
      }
    })
    return sum
  }
  fetchData(){
    this.docs = []
    let search:Array<string> = []
    let searchQuery = ""
    let query = {
      skip: this.show*(this.page-1),
      limit: this.show,
      search:{
        ...this.filter1,
        ...this.filter2
      },
      isFollow:true,
    }
    // console.log(query)
    axios.post(`${ADDRESS}/budgets/paginate`,query).then((response) => {
      // console.log(response)
      this.docs = response.data.docs;
      this.total = response.data.total;
      this.pages = Math.ceil(response.data.total / this.show);
    });
  }
  mounted() {
    this.filter1.budgetYear = (new Date().getFullYear() + 543) + ((new Date().getMonth()+1)>10?1:0)
    this.fetchData()
  }
}
