




















































import { Component, Vue } from "vue-property-decorator";
import axios from "axios";
import { ADDRESS } from "@/setting";
import { DateTime } from "luxon";
import { last } from "lodash";
@Component({
  components: {},
})
export default class List extends Vue {
  fromDate: Date = DateTime.now().startOf("month").toJSDate();
  toDate: Date = DateTime.now().endOf("month").toJSDate();
  docs: Array<any> = [];
  limit: number = 15;
  page: number = 1;
  pages: number = 0;
  total: number = 0;
  address = ADDRESS;
  mounted() {
    this.fetchData();
  }
  fetchData() {
    this.docs = [];
    let query = {
      search: {
        AND: [
          {
            assigns: {
              contains: this.$store.state.userId,
            },
            // assigns:{
            //   $regex : this.$store.state.userId
            // }
          },
          {
            createdAt: {
              gte: this.fromDate,
              lte: this.toDate,
            },
          },
        ],
      },
    };
    axios.post(`${ADDRESS}/assignments/paginate`, query).then((response) => {
      console.log(response.data);
      this.docs = response.data.docs;
      this.total = response.data.total;
      this.pages = Math.ceil(response.data.total / this.limit);
    });
  }
  getOriginator(item: any) {
    try {
      let prefix = item.originatorObj.prefix;
      let firstName = item.originatorObj.firstName;
      let lastName = item.originatorObj.lastName;
      return `${prefix}${firstName} ${lastName}`;
    } catch (error) {}
  }
  deleteItem(item: any) {
    var c = confirm(`คุณแน่ใจหรือว่าต้องการลบรายการนี้`);
    if (c) {
      axios
        .delete(`${ADDRESS}/assignment/delete/${item.id}`)
        .then((response) => {
          console.log(response);
          this.fetchData();
        });
    }
  }
}
