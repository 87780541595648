
























import { Component, Vue } from "vue-property-decorator";
// import { store } from "vuex";
import axios from "axios";
import { ADDRESS } from "@/setting";
import { DateTime } from "luxon";


@Component({
  components: {
  },
})
export default class Login extends Vue {
  username: string = "jtm@jmandjm.com" //"jtm@jmandjm.com";
  password: string = "gd8c5s4e" // "gd8c5s4e";
  today = new Date()
  attendants:Array<any> = []

  docs:Array<any> = []
  mounted(){
    this.fetchData()
  }
  checkin(){
    let prep = {
      staff:this.$store.state.userId,
      inTime: new Date()
    }
    axios.post(`${ADDRESS}/attendant/create`,prep).then(response=>{
      console.log("checkin",response.data)
    })
  }

  fetchAttendant(){
    let dt = DateTime.fromJSDate(this.today)
    let dto = dt.toObject()
    let day = dto.day
    let month = dto.month
    let year = dto.year
    console.log(dt)
    let query = {
            search:{
        staff:this.$store.state.userId,
        inTime:{
          gte: dt.startOf('day').toISO(),
          lt:  dt.endOf('day').toISO()
        }
      },
      skip:0,
      limit:999
    }
    axios.post(`${ADDRESS}/attendants/paginate`,query).then(response=>{
      this.attendants = response.data.docs
      let length = this.attendants.length
      if(length==0){
        console.log("วันนี้ผู้ใช้ ยังไม่ได้เช็คอิน")
        this.checkin()
      } else {
        console.log("วันนี้ผู้ใช้ เช็คอินแล้ว")
      }
      // this.$router.push('/portal/flashnews')
    })
  }
  fetchData() {
    this.docs = []
    let query = {
      skip:0,
      limit:1,
      search:"WHERE type = 'ข่าวด่วน'"
    }
    axios.post(`${ADDRESS}/publications/paginate`,query).then((response) => {
      console.log(response)
      this.docs = response.data.docs;
    });
  }
  submitLoginAD(){
    console.log("ok")
    axios.post(`${ADDRESS}/activedirectory/login/`, {
      username: this.username,
      password: this.password
    }).then((response)=>{
      console.log("AD!!!", response.data)
      if(response.data.status == "success"){
        this.$store.commit("setUser", response.data)
        this.fetchAttendant()
        // this.$router.push('/portal/attendant')
        // this.$router.push('/portal/flashnews')
      } 
      else{
        alert(response.data.result)
      }
    })
    .catch((error)=>{
      alert("ไม่พบผู้ใช้บนระบบ Active Directory ของ กกท.");
      console.log(error);
        if (error.status === 401) {
          alert("ไม่พบผู้ใช้");
        }
    })
  }
  submitLogin(){
    console.log("ok")
    axios.post(`${ADDRESS}/user/login/`, {
      username: this.username,
      password: this.password
    }).then((response)=>{
      console.log(response.data)
      if(response.data.status == "success"){
        console.log(response.data.result)
        this.$store.commit("setUser", response.data.result)
        this.fetchAttendant()
        // this.$router.push('/portal/attendant')
        // this.$router.push('/portal/flashnews')
      } 
      else{
        alert(response.data.result)
      }
    })
    .catch((error)=>{
      console.log(error);
        if (error.status === 401) {
          alert("ไม่พบผู้ใช้");
        }
    })
  }
}
