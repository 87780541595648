











import { Component, Vue } from "vue-property-decorator";
import { Watch } from "vue-property-decorator";
import { Store } from "vuex";
import axios, { AxiosResponse } from "axios";
import { ADDRESS } from "@/setting";
import _ from "lodash";

@Component({
  components: {},
  props: {
    value: String,
    label: String,
  },
})
export default class JMOfficer extends Vue {
  name: string = "";
  search: string = "";
  value?: string;
  show: boolean = false;
  users: Array<any> = [];
  debouncedHandler:any
  created() {
    this.debouncedHandler = _.debounce(event => {
      console.log('New value:', event.target.value);
      this.listData()
    }, 500);
  }
  beforeUnmount() {
    this.debouncedHandler.cancel();
  }
  mounted() {
    this.listData();
    console.log('value:',this.value)
    this.search = this.value ?? "";
  }
  emitUp(item: any) {
    this.$emit("input", (item.prefix??"") + item.firstName + "  " + item.lastName);
    // this.$emit("input", item);
    this.search = (item.prefix??"") + (item.firstName??"") + " " + (item.lastName??"")
  }

  listData() {
    if (this.search.length > 2) {
      this.show = true;
      axios
        .post(`${ADDRESS}/users/paginate`,{
          search:{
            firstName:this.search,
          },
          limit:999,
          skip:0
        })
        .then((response) => {
          this.users = response.data.docs;
        });
    }
  }
}
