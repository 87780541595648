



















































































import { Component, Vue } from "vue-property-decorator";
import { DocumentDocx } from "@/document/docx";
import { ADDRESS } from "@/setting";
import axios from "axios";

@Component({
  components: {},
})
export default class B02 extends Vue {
  address = ADDRESS;
  docs: Array<any> = [
    {
      name: "A01 (G1) ประเทศไทยประสบความสำเร็จในการแข่งขันกีฬานานาชาติ (1706)",
      children: [
        {
          name: "B01",
          children: [
            {
                name: "C01",
                children: [
                  {
                    name: "D01",
                    children: [
                      {}
                    ],
                  },
              ],
            },
          ],
        },
      ],
    },
    {
      name: "A02",
      children: [
        {
          name: "B02",
          children: [
            {
                name: "C02",
                children: [
                  {
                    name: "D02",
                    children: [
                      {}
                    ],
                  },
              ],
            },
          ],
        },
      ],
    },
  ];
  async createDocx() {
    const doc = new DocumentDocx();
    await doc.createB01();
    doc.save("Report_B01.docx");
  }
  fetchData() {
    axios
      .get(`${ADDRESS}/strategy/get/${this.$route.params.id}`)
      .then((response) => {
        console.log(response.data);
        if (response.data.status === "success") {
          let data = response.data.doc;
          this.docs = data;
        } else {
          console.log(`พบปัญหาในการดึงข้อมูลจากแม่ข่าย , ${response.data.error}`);
        }
      });
  }
  mounted() {
    this.fetchData();
  }
}
