import {  RouteConfig } from 'vue-router'

import SectorMain from '../../views/sector/Main.vue'
import SectorForm from '../../views/sector/Form.vue'
import SectorList from '../../views/sector/List.vue'
import CommitteeMain from '../../views/sector/CommitteeMain.vue'
import CommitteeForm from '../../views/sector/CommitteeForm.vue'
import CommitteeList from '../../views/sector/CommitteeList.vue'
const routes: Array<RouteConfig> = [
  {
    path: 'sector',
    component: SectorMain,
    meta:{
      "ac": "Setting,r"
    },
    children: [
      {
        path: '',
        name: 'SectorList',
        component: SectorList,
        meta:{
          "ac": "Setting,r"
        },
      },
      {
        path: 'form',
        name: 'SectorForm',
        component: SectorForm,
        meta:{
          "ac": "Setting,r"
        },
      },
      {
        path: 'edit/:id',
        name: 'SectorEdit',
        component: SectorForm,
        meta:{
          "ac": "Setting,r"
        },
      },
    ]
  },
  {
    path: 'committee',
    component: CommitteeMain,
    children: [
      {
        path: '',
        name: 'CommitteeList2',
        component: CommitteeList,
        meta:{
          "ac": "Setting,r"
        },
      },
      {
        path: 'form',
        name: 'CommitteeForm2',
        component: CommitteeForm,
        meta:{
          "ac": "Setting,r"
        },
      },
      {
        path: 'edit/:id',
        name: 'CommitteeEdit2',
        component: CommitteeForm,
        meta:{
          "ac": "Setting,r"
        },
      }
    ]
  },
]
export default routes