















import { Component, Vue } from "vue-property-decorator";
import { DocumentExcel } from "@/document/xlsx"
import axios from "axios";
import { ADDRESS } from "@/setting";
import { DateTime } from "luxon";
@Component({
  components: {},
})
export default class App extends Vue {
  pdfBLOB: Blob = new Blob();
  docs: Array<any> = []
  mounted(){
    this.fetchData()
  }
  fetchData(){
    axios.post(`${ADDRESS}/report/B14`,{
      budgetYear:2565
      // fromDate: DateTime.now().minus({days: 30}).toJSDate(),
      // toDate: DateTime.now().plus({days: 30}).toJSDate(),
    }).then(response=>{
      console.log("B14", response.data)
      this.docs = response.data.result
    })
  }
  async saveAsExcel() {
    let docExcel = new DocumentExcel()
    docExcel.createB14(this.docs, 2564)
  }

}
