




import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class JMSort extends Vue {
  sortKey!: string;
  modelValue!: any;
  sortDir: number = 1;
  mounted() {}
  getSort() {
    try {
      if (this.modelValue[this.sortKey] == 1) return "fa-sort-up";
      else if (this.modelValue[this.sortKey] == -1) return "fa-sort-down";
    } catch (error) {}
    return "fa-sort";
  }
  updateSort(key: string) {
    let sort: any = {};
    let dir = 0;
    if (this.modelValue[this.sortKey] != undefined)
      dir = -1 * this.modelValue[this.sortKey];
    else dir = 1;
    sort[this.sortKey] = dir;
    this.$emit("update:modelValue", sort);
    this.$emit("change");
  }
}
