import { render, staticRenderFns } from "./JMCalendar.vue?vue&type=template&id=c1bcb36c&scoped=true&lang=pug&"
import script from "./JMCalendar.vue?vue&type=script&lang=ts&"
export * from "./JMCalendar.vue?vue&type=script&lang=ts&"
import style0 from "./JMCalendar.vue?vue&type=style&index=0&id=c1bcb36c&lang=scss&scoped=true&"
import style1 from "./JMCalendar.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c1bcb36c",
  null
  
)

export default component.exports