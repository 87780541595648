





























































import { Component, Vue } from "vue-property-decorator";
import axios from "axios";
import { ADDRESS } from "@/setting";
import { DateTime } from "luxon";
import routes from "@/router/setting";
import { ConfirmAction } from "@/decorators";
@Component({
  components: {
  },
})
export default class adjustForm extends Vue {
  doc: any = {
    attachments: [],
    responsibles:[
      {
        name:"",
        type:"ผู้รับผิดชอบ",
        position:""
      },
      {
        name:"",
        type:"หัวหน้างาน",
        position:""
      },
      {
        name:"",
        type:"ผู้อำนวยการกอง",
        position:""
      },
      {
        name:"",
        type:"ผู้อำนวยการฝ่าย/สำนัก",
        position:""
      }
    ]
  };
  budget: any = {}
  name1: string = ""
  name2: string = ""
  name3: string = ""
  position1: string = ""
  position2: string = ""
  position3: string = ""
  address = ADDRESS;

  @ConfirmAction("คุณยืนยันว่าต้องการจะเห็นชอบคำขอ?")
  requestConsideringApprove() {
    let prep = {
      request_id: this.$route.params.id
    }
    let prep2 = {
      request_id: this.$route.params.id,
      adjustment:{
        category:""
      }
    }
    if(this.doc.status == 'Request') {
    axios.post(`${ADDRESS}/process/request-considering-approve/1`,prep).then((response)=> {
      console.log('Request',response)
      this.fetchData()
    })
    }
    else if(this.doc.status =='Considering1') {
    axios.post(`${ADDRESS}/process/request-considering-approve/2`,prep).then((response)=> {
      console.log('Considering1',response)
      this.fetchData()
    })
    }
    else {

    axios.post(`${ADDRESS}/process/request-considering-approve/3`,prep2).then((response)=> {
      console.log('Considering2',response)
      console.log(prep2)
     
      this.fetchData()
    })
    }
  }
  @ConfirmAction("คุณยืนยันว่าต้องการจะตีกลับคำขอ?")
  requestReject() {
    let prep = {
      request_id: this.$route.params.id
    }
    axios.post(`${ADDRESS}/process/request-reject`,prep).then((response)=> {
      console.log('rejected',response)
      this.fetchData()
    })
  }
  @ConfirmAction("คุณยืนยันว่าต้องการจะส่งคืนคำขอ?")
  requestReturn() {
    let prep = {
      request_id: this.$route.params.id
    }
    axios.post(`${ADDRESS}/process/request-return`,prep).then((response)=> {
      console.log('return',response)
      this.fetchData()
    })
  }
  pushResponsibles(i:any) {
    try { 
      let array = this.doc.responsibles
      array = []
      array.push({name:this.doc.responsibles.name[i],position:this.doc.responsibles.position[i]})
      this.$set(this.doc,"responsibles", array)
    } catch (error) {
      
    }
  }
  submitForm(status:string) {
    let step = (this.doc.step??0)
    if(status==="เห็นชอบ") step = Math.abs(step) + 1
    else if(status==="ตีกลับ") step = (-1 * (step + 1))
    else if(status==="ส่งคืน") step = (-1 * (step + 1))
    let prep = {
      status:status,
      step,
      comment:this.doc.comment,
      fullName:this.$store.state.fullName
    }
    try {
      //@ts-ignore
      this.$refs.closemodal!.click()
    } catch (error) {
      
    }
    console.log('prep',prep)
    axios.patch(`${ADDRESS}/budgetRequest/update/${this.doc.id}`,prep)
    .then((response) => {
      console.log('response',response)
      alert("ดำเนินการเห็นชอบเสร็จสิ้น")
      this.$router.push(`/portal/budget/request/list/${this.$route.params.project_id}`)
    })
  }
  fetchData() {
    axios
      .get(`${ADDRESS}/budgetRequest/get/${this.$route.params.id}`)
      .then((response) => {
        console.log(response.data);
        if (response.data.status === "success") {
          let data = response.data.doc;
          this.doc = data
          this.doc.modifiedBy = this.$store.state.fullName
        } else {
          console.log(`พบปัญหาในการดึงข้อมูลจากแม่ข่าย , ${response.data.error}`);
        }
      });
  }
  fetchBudget() {
    axios
      .get(`${ADDRESS}/budget/get/${this.$route.params.project_id}`)
      .then((response) => {
        console.log(response.data);
        if (response.data.status === "success") {
          let data = response.data.doc;
          this.budget = data
        } else {
          console.log(`พบปัญหาในการดึงข้อมูลจากแม่ข่าย , ${response.data.error}`);
        }
      });
  }
  mounted() {
    this.fetchBudget()
    if(this.$route.params.id != undefined) this.fetchData()
  }
}
