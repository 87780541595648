



































import { ADDRESS } from "@/setting";
import axios from "axios";
import { Component, Vue } from "vue-property-decorator";
@Component({
  components: {
  },
})
export default class D3List extends Vue {
  docs:Array<any> = []
  show: number = 20;
  limit: number = 15;
  page: number = 1;
  pages: number = 0;
  total: number = 0;

  fetchData(){
    this.docs = []
    let query = {
      limit: this.limit,
      skip: (this.page-1)*this.limit,
      search: {
        project_id:this.$route.params.id,
        documentType:this.$route.query.documentType
        
      },
      sort: {
        modifiedAt: "desc"
      }
    }
    axios.post(`${ADDRESS}/documentsadjusts/paginate`,query).then((response) => {
      console.log('documentsadjusts/paginate',response)
      this.docs = response.data.docs;
      this.total = response.data.total;
      this.pages = Math.ceil(response.data.total / this.show);
    });
  }
  mounted() {
    this.fetchData()
  }
}
