




































import { Component, Vue } from 'vue-property-decorator';
import axios from "axios";
import { ADDRESS } from "@/setting";
import { ConfirmAction } from "@/decorators";
@Component({
  components: {
  },
})
export default class DocumentForm extends Vue {
  doc: any = {};
  address = ADDRESS;

  
  submitForm() {
    if (this.$route.params.id == undefined) {
      axios.post(`${ADDRESS}/document/create`,{
        ...this.doc,
        category : "เอกสาร"

      })
      .then((response) => {
        console.log(response)
        alert("ดำเนินการบันทึกสำเร็จแล้ว")
        this.$router.push("/portal/document")
      })
    } else {
      axios.patch(`${ADDRESS}/document/update/${this.doc.id}`,this.doc)
      .then((response) => {
        console.log(response)
        alert("ดำเนินการแก้ไขสำเร็จแล้ว")
        this.$router.push("/portal/document")
      })
    }
  }
  fetchData() {
    axios.get(`${ADDRESS}/document/get/${this.$route.params.id}`).then((response) => {
      console.log(response)
      if(response.data.status === "success") {
      let data = response.data.doc;
      this.doc = data;
      } else {
        console.log(`พบปัญหาในการดึงข้อมูลจากแม่ข่าย , ${response.data.error}`)
      }
    });
  }
  mounted() {
    if
     (this.$route.params.id != undefined) this.fetchData()
  }
  @ConfirmAction("ยืนยันลบเอกสารแนบ?")
  deleteAttachments(i:number){
    this.doc.attachments.splice(i,1)
  }
}
