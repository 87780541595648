



















import { Component, Vue } from "vue-property-decorator";

@Component({
  props: {
    page:Number,
    pages:Number,
    total:Number,
    limit:Number,
    "onUpdate:modelValue": Function,
    modelValue: Object,
  },
})
export default class JMTablePaginate extends Vue {
  page!:number
  pages!:number
  total!:number
  show:number = 5
  limit!:number
  updatePaginate(i: number) {
    if(i>=1&&i<=this.pages)
      this.$emit('change',i)
  }
  get paginate() {
    let arr:Array<number> = [0]
    try {
      arr = new Array(this.pages).fill(0);
    } catch (error) {
      
    }
    return arr;
  }
}
