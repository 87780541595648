























































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import axios from "axios";
import { ADDRESS } from "@/setting";
import { DateTime } from "luxon";
import { ConfirmAction } from "@/decorators";
@Component({
  components: {
  },
})
export default class adjustForm extends Vue {
  doc: any = {
    attachments: [],
    sectors:[],
    responsible:{},
    isAdjust: true,
    isPlan: true,
    isType: true,
    isEffect:true,
    tablePlan:[ 
      [
        {
          oldPlan:"",
          newPlan:"",
          reason:""
        }
      ]
    ],
    tableAdjust:[{
      modified:[0,0,0,0,0,0,0,0,0,0,0,0],
      original:[0,0,0,0,0,0,0,0,0,0,0,0]
    }]
  };
  budget: any = {}
  name1: string = ""
  name2: string = ""
  name3: string = ""
  position1: string = ""
  position2: string = ""
  position3: string = ""
  address = ADDRESS;
  budgetitems:Array<any> = []
  print() {
    window.print();
  }
  changeMoney1(adjust:any) {
    const original = this.getLevel1Month(adjust.level1)
    adjust.original = Object.assign([],original)
    adjust.modified = Object.assign([],original)
    console.log({adjust})
  }
  changeMoney2(adjust:any) {
    const original = this.getLevel2Month(adjust.level1,adjust.level2)
    adjust.original = Object.assign([],original)
    adjust.modified = Object.assign([],original)
    console.log({adjust})
  }

  pushItem() {
    let main: Array<any> = this.doc.tablePlan;
    if (!Array.isArray(main)) main = [];
    main.push([
      {
        oldPlan:"",
        newPlan:"",
        reason:""
      }
    ])
  } 

  pushItem2() {
    let adjust: Array<any> = this.doc.tableAdjust;
    if (!Array.isArray(adjust)) adjust = [];
    adjust.push([])
  }  
  
  async fetchBudgetItem() {
    console.log("doc", this.doc)
    let body = {
      budget_id:this.doc.project_id,
    }
    axios
      .post(`${ADDRESS}/budgetitem/get-items`, body)
      .then((response) => {
        if (response.data.status === "success") {
          let data = response.data.results;
          console.log('budget items', data)
          this.budgetitems = data.map((el:any)=>{
            return {
              ...el,
              edit:false
            }
          });
          console.log('this.budgetitems',this.budgetitems)
        } else {
          console.log(`พบปัญหาในการดึงข้อมูลจากแม่ข่าย , ${response.data.error}`);
        }
    });
  }
  pushChildren(index:number)  {
    let tablePlan = Object.assign([],this.doc.tablePlan)
    if (!Array.isArray(tablePlan[index])) tablePlan[index] = [];
    // tablePlan[index][0] = {}
    // tablePlan[index][1] = {}
    tablePlan[index].push({
        oldPlan:"",
        newPlan:"",
        reason:""
    })
    console.log(tablePlan)
    this.$set(this.doc,"tablePlan",tablePlan)
  }
  updateActing(item:any) {
    if (item.isActing == true) {
      item.name = item.name + " (ปฏิบัติหน้าที่แทน)"
    } else {
      item.name = item.name.replace(" (ปฏิบัติหน้าที่แทน)", "")
    }
  }
  pushResponsibles(i:any) {
    try { 
      let array = this.doc.responsibles
      array = []
      array.push({name:this.doc.responsibles.name[i],position:this.doc.responsibles.position[i]})
      this.$set(this.doc,"responsibles", array)
    } catch (error) {
      
    }
  }
  fetchData() {
    return new Promise((resolve,reject)=>{
      axios
        .get(`${ADDRESS}/budgetRequest/get/${this.$route.params.id}`)
        .then((response) => {
          console.log(response.data);
          if (response.data.status === "success") {
            let data = response.data.doc;
            this.doc = data
            this.doc.modifiedBy = this.$store.state.fullName
            resolve("done")
          } else {
            console.log(`พบปัญหาในการดึงข้อมูลจากแม่ข่าย , ${response.data.error}`);
            reject("error")
          }
        });
    })
  }
  fetchBudget() {
    axios
      .get(`${ADDRESS}/budget/get/${this.doc.project_id}`)
      .then((response) => {
        console.log(response.data);
        if (response.data.status === "success") {
          let data = response.data.doc;
          this.budget = data
          if(this.$route.params.id == undefined){
            this.doc.responsible = this.budget.data.responsibles[0]
            this.doc.sectors = this.budget.data.sectors
          }
        } else {
          console.log(`พบปัญหาในการดึงข้อมูลจากแม่ข่าย , ${response.data.error}`);
        }
      });
  }
  async initData(){
    await this.fetchData()

    this.fetchBudget()
    this.fetchBudgetItem()
  }
  mounted() {
    this.initData()
  }
  @ConfirmAction("ยืนยันลบเอกสารแนบ?")
  deleteAttachments(i:number){
    this.doc.attachments.splice(i,1)
  }

  getLevel1():Array<any>{
    try {
      let l1 = this.budgetitems ?? {}
      if(this.budget.showZero) l1 = l1.filter((e:any)=>(e.months??[0]).reduce((a:number,b:number)=>a+b,0)!==0)
      return l1
    } catch(error) {
      console.log(error)
      return []
    }
  }
  getLevel2(id:string){
    try {
    let i = this.budgetitems.findIndex(bi=>bi.id===id)
    console.log(i)
    let l1 = this.budgetitems ?? {}
    console.log(i,l1[i])
    let l2 = l1[i].children ?? {}
    return l2
    } catch(error) {
      console.log(error)
    }
  }
  getLevel2Month(idl1:string, idl2:string){
    try {
      let l1 = this.budgetitems.find(bi=>bi.id===idl1)
      console.log({bi:this.budgetitems,l1})
      let l2 = l1.children.find((bi:any)=>bi.id===idl2)
      return l2.months
    } catch(error) {
      console.log(error)
      return [0,0,0,0,0,0,0,0,0,0,0,0]
    }
  }
  getLevel1Month(idl1:string){
    try {
      let l1 = this.budgetitems.find(bi=>bi.id===idl1)
      console.log({bi:this.budgetitems,l1})
      return l1.months
    } catch(error) {
      console.log(error)
      return [0,0,0,0,0,0,0,0,0,0,0,0]
    }
  }

  resolveMonth(n:number){
    switch (n) {
      case 1:
        return "ต.ค." 
        break;
      case 2:
        return "พ.ย." 
        break;
      case 3:
        return "ธ.ค." 
        break;
      case 4:
        return "ม.ค."
        break;
      case 5:
        return "ก.พ."
        break;
      case 6:
        return "มี.ค."
        break;
      case 7:
        return "เม.ย."
        break;
      case 8:
        return "พ.ค."
        break;
      case 9:
        return "มิ.ย."
        break;
      case 10:
        return "ก.ค."
        break;
      case 11:
        return "ส.ค."
        break;
      case 12:
        return "ก.ย."
        break;
    
      default:
        return n
        break;
    }
  }
}
