






































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import store from "@/store";

@Component({
  components: {},
  directives:{
    clickOutside:{
      bind(el, binding, vnode) {
        let clickOutsideEvent = function (event:any) {
          if (!(el === event.target || el.contains(event.target))) {
            // console.dir(binding)
            //@ts-ignore
            binding.value()
            // vnode.context[binding.expression](event);
          }
        };
        document.body.addEventListener("click", clickOutsideEvent);
      },
    }
  }
})
export default class SideNavigation extends Vue {
  store = store
  fullsize:boolean = false;
  counter = 0;

  hideNavigation(){
    this.fullsize = false
  }
  submitLogout(){
    this.$store.commit("setUser", {})
    this.$router.push('/')
  }
  @Watch("$route.path")
  hideAllRoutes(){
    setTimeout(() => {
      this.navigations.forEach((el,k)=>{
        this.$set(this.navigations[k], 'active', false)
      })
    }, 50);
  }
  toggleSize(){
    this.fullsize = !this.fullsize;
  }
  toggleNav1(a:number){
    let newValue = !(this.navigations[a].active)
    this.navigations.forEach((el,k)=>{
      this.$set(this.navigations[k], 'active', false)
    })
    this.$set(this.navigations[a], 'active', newValue)
  }
  toggleNav2(i:number,j:number){
    (this.navigations[i].children??[]).forEach((el,k) => {
      this.$set(this.navigations[i].children![k], 'active', false)
    });
    this.$set(this.navigations[i].children![j], 'active', !this.navigations[i].children![j].active)
  }
  navigations:Array<NavConfig> = [
    {
      title:"ตัวชี้วัด",
      // path:"/portal/kpi",
      icon:"fas fa-file-alt",
      active:false,
      children: [
        {
          title:"รายการตัวชี้วัด",
          path:"/portal/indicator",
          icon:"fad fa-file-alt",
        },
        {
          title:"รายการผังยุทธศาสตร์",
          path:"/portal/strategy",
          icon:"fad fa-file-alt",
        },
        {
          title:"รายการติดตามและประเมินตัวชี้วัด",
          path:"/portal/indicator-review",
          icon:"fad fa-file-alt",
        },
        {
          title:"รายการจัดเตรียมงบฯของปีถัดไป",
          path:"/portal/process/copy",
          icon:"fad fa-file-alt",
        },
      ]
    },
    {
      title:"ระบบงบประมาณ",
      path:"/portal/budget",
      active:false,
      icon:"fas fa-coins",
    },
    {
      title:"การปรับแผน",
      path:"/portal/budget/adjust",
      active:false,
      notification:"adjustNotification",
      icon:"far fa-sliders-h-square",  
    },
    {
      title:"การเบิก/ยืมเงินในระบบ ERP",
      active:false,
      notification:"erpNotification",
      icon:"fas fa-money-check-edit-alt",
      children:[
        {
          title:"รายการเบิก/ยืมเงิน",
          path:"/portal/erp",
          icon:"fad fa-money-check-edit-alt",
        },   
        {
          title:"สรุปรายการ ERP",
          path:"/portal/erp/report",
          icon:"fad fa-money-check-edit-alt",
        },
        {
          title:"ปรับรายการเบิก/ยืม",
          path:"/portal/erp/delete",
          notification:"erpNotification",
          icon:"fad fa-money-check-edit-alt",
        },
      ]
    },
    {
      title:"ติดตามผลการดำเนินงาน",
      active:false,
      icon:"fas fa-clipboard-list-check",
      children:[   
        {
          title:"รายการติดตามผลปฏิบัติงาน",
          path:"/portal/track",
          icon:"fad fa-clipboard-list-check",
        },
      ]
    },
    // {
    //   title:"รายงาน",
    //   // path:"/portal/report",
    //   icon:"fas fa-file-chart-line",
    //   children: [
    //     {
    //       title:"รายงานที่ 1",
    //       path:"/portal/report/01",
    //       icon:"fad fa-file-chart-line",
    //     },
    //     {
    //       title:"รายงานที่ 2",
    //       path:"/portal/report/02",
    //       icon:"fad fa-file-chart-line",
    //     },
    //     {
    //       title:"รายงานที่ 3",
    //       path:"/portal/report/03",
    //       icon:"fad fa-file-chart-line",
    //     },
    //     {
    //       title:"รายงานที่ 4",
    //       path:"/portal/report/04",
    //       icon:"fad fa-file-chart-line",
    //     },
    //     {
    //       title:"รายงานที่ 5",
    //       path:"/portal/report/05",
    //       icon:"fad fa-file-chart-line",
    //     },
    //     {
    //       title:"รายงานที่ 6",
    //       path:"/portal/report/06",
    //       icon:"fad fa-file-chart-line",
    //     },
    //     {
    //       title:"รายงานที่ 7",
    //       path:"/portal/report/07",
    //       icon:"fad fa-file-chart-line",
    //     },
    //     {
    //       title:"รายงานที่ 8",
    //       path:"/portal/report/08",
    //       icon:"fad fa-file-chart-line",
    //     },
    //     {
    //       title:"รายงานที่ 9",
    //       path:"/portal/report/09",
    //       icon:"fad fa-file-chart-line",
    //     },
    //     {
    //       title:"รายงานที่ 10",
    //       path:"/portal/report/10",
    //       icon:"fad fa-file-chart-line",
    //     },
    //     {
    //       title:"รายงานที่ 11",
    //       path:"/portal/report/11",
    //       icon:"fad fa-file-chart-line",
    //     },
    //     // {
    //     //   title:"รายงานB01",
    //     //   path:"/portal/report/B01",
    //     //   icon:"fad fa-file-chart-line",
    //     // },
    //     // {
    //     //   title:"รายงานB02",
    //     //   path:"/portal/report/B02",
    //     //   icon:"fad fa-file-chart-line",
    //     // },
    //     // {
    //     //   title:"รายงานB03",
    //     //   path:"/portal/report/B03/2565/6205c4f0a6aa34c5be2a8716",
    //     //   icon:"fad fa-file-chart-line",
    //     // },
    //     // {
    //     //   title:"รายงานB04",
    //     //   path:"/portal/report/B04/2565/6205c4f0a6aa34c5be2a8716",
    //     //   icon:"fad fa-file-chart-line",
    //     // },
    //     // {
    //     //   title:"รายงานB05",
    //     //   path:"/portal/report/B05/2565/6205c4f0a6aa34c5be2a8716",
    //     //   icon:"fad fa-file-chart-line",
    //     // },
    //     // {
    //     //   title:"รายงานB06",
    //     //   path:"/portal/report/B06",
    //     //   icon:"fad fa-file-chart-line",
    //     // },
    //     // {
    //     //   title:"รายงานB07",
    //     //   path:"/portal/report/B07",
    //     //   icon:"fad fa-file-chart-line",
    //     // },
    //     // {
    //     //   title:"รายงานB08",
    //     //   path:"/portal/report/B08",
    //     //   icon:"fad fa-file-chart-line",
    //     // },
    //     // {
    //     //   title:"รายงานB09",
    //     //   path:"/portal/report/B09",
    //     //   icon:"fad fa-file-chart-line",
    //     // },
    //     // {
    //     //   title:"รายงานB10",
    //     //   path:"/portal/report/B10",
    //     //   icon:"fad fa-file-chart-line",
    //     // },
    //     // {
    //     //   title:"รายงานB11",
    //     //   path:"/portal/report/B11/2565/6205c4f0a6aa34c5be2a8716",
    //     //   icon:"fad fa-file-chart-line",
    //     // },
    //     // {
    //     //   title:"รายงานB12",
    //     //   path:"/portal/report/B12",
    //     //   icon:"fad fa-file-chart-line",
    //     // },
    //     // {
    //     //   title:"รายงานB13",
    //     //   path:"/portal/report/B13/2565/6205c4f0a6aa34c5be2a8716",
    //     //   icon:"fad fa-file-chart-line",
    //     // },
    //     // {
    //     //   title:"รายงานB14",
    //     //   path:"/portal/report/B14/2565/6205c4f0a6aa34c5be2a8716",
    //     //   icon:"fad fa-file-chart-line",
    //     // },
    //     // {
    //     //   title:"รายงานB15",
    //     //   path:"/portal/report/B15/2565/6205c4f0a6aa34c5be2a8716",
    //     //   icon:"fad fa-file-chart-line",
    //     // },
    //     // {
    //     //   title:"รายงานB16",
    //     //   path:"/portal/report/B16/2565/6205c4f0a6aa34c5be2a8716",
    //     //   icon:"fad fa-file-chart-line",
    //     // },
    //     // {
    //     //   title:"รายงานB17",
    //     //   path:"/portal/report/B17/2565/6205c4f0a6aa34c5be2a8716",
    //     //   icon:"fad fa-file-chart-line",
    //     // },
    //     // {
    //     //   title:"รายงานB18",
    //     //   path:"/portal/report/B18",
    //     //   icon:"fad fa-file-chart-line",
    //     // },
    //     // {
    //     //   title:"รายงานB19",
    //     //   path:"/portal/report/B19/2565/6205c4f0a6aa34c5be2a8716",
    //     //   icon:"fad fa-file-chart-line",
    //     // },
    //     // {
    //     //   title:"รายงานB20",
    //     //   path:"/portal/report/B20/2565/6205c4f0a6aa34c5be2a8716",
    //     //   icon:"fad fa-file-chart-line",
    //     // },
    //     // {
    //     //   title:"รายงานB21",
    //     //   path:"/portal/report/B21/2565/6205c4f0a6aa34c5be2a8716",
    //     //   icon:"fad fa-file-chart-line",
    //     // },
    //     // {
    //     //   title:"รายงานB22",
    //     //   path:"/portal/report/B22/2565/6205c4f0a6aa34c5be2a8716",
    //     //   icon:"fad fa-file-chart-line",
    //     // },
    //     // {
    //     //   title:"รายงานB23",
    //     //   path:"/portal/report/B23",
    //     //   icon:"fad fa-file-chart-line",
    //     // },
    //     // {
    //     //   title:"รายงานB24",
    //     //   path:"/portal/report/B24/2565/6205c4f0a6aa34c5be2a8716",
    //     //   icon:"fad fa-file-chart-line",
    //     // },
    //     // {
    //     //   title:"รายงานB25",
    //     //   path:"/portal/report/B25",
    //     //   icon:"fad fa-file-chart-line",
    //     // },
    //     // {
    //     //   title:"รายงานB26",
    //     //   path:"/portal/report/B26",
    //     //   icon:"fad fa-file-chart-line",
    //     // },
    //     // {
    //     //   title:"รายงานB27",
    //     //   path:"/portal/report/B27",
    //     //   icon:"fad fa-file-chart-line",
    //     // },
    //   ]
    // },
    {
      title:"รายงาน",
      path:"/portal/report/list",
      icon:"fad fa-file-chart-line",
    },
    {
      title:"MIS Dashboard",
      path:"/portal/dashboard",
      icon:"fas fa-tachometer-alt",
    },
    {
      title:"ระบบประชาสัมพันธ์ ",
      icon:"fas fa-rss",
      children: [
        {
          title:"ประกาศข่าวสาร",
          path:"/portal/pr",
          icon:"fa-solid fa-newspaper"
        },
        {
          title:"ข่าวด่วน",
          path:"/portal/flashnews",
          icon:"fad fa-megaphone",
        },
        {
          title:"เอกสาร",
          path:"/portal/document",
          icon:"fad fa-briefcase",
        },
      ]
    },
    {
      title:"การมอบหมายงานออนไลน์",
      // path:"/portal/assignment",
      icon:"fas fa-home-lg-alt",
          children: [
            {
              title:"รายการมอบหมายงาน",
              path:"/portal/assignment",
              icon:"fad fa-list-alt"
            },
            {
              title:"ประวัติงานที่ถูกมอบหมาย",
              path:"/portal/assignment-reply",
              icon:"fad fa-list-alt"
            },
            {
              title:"การเข้างาน",
              path:"/portal/attendant",
              icon:"fad fa-sign-in",
            }
          ]
    },
    {
      title:"ระบบจัดการผู้ใช้งาน",
      // path:"/portal/uac",
      icon:"fas fa-users-class",
      children: [
        {
          title:"รายการผู้ใช้",
          path:"/portal/uac",
          icon:"fad fa-list-alt"
        },
      ]
    },
    {
      title:"ตั้งค่าระบบ",
      // path:"/portal/setting",
      active:false,
      icon:"fas fa-cogs",
      children:[
        {
          title:"ตั้งค่าระบบ",
          path:"/portal/setting",
          icon:"fad fa-list-alt"
        },
        {
          title:"ระบบจัดการตำแหน่ง",
          path:"/portal/position",
          icon:"fad fa-list-alt"
        },
        {
          title:"รายการสำนักงาน",
          path:"/portal/sector",
          icon:"fad fa-list-alt"
        },
        {
          title:"ตั้งค่ารหัสสินค้า",
          path:"/portal/account-zp",
          icon:"fad fa-list-alt"
        },
        // {
        //   title:"ตั้งค่าผังบัญชี",
        //   path:"/portal/account-schema",
        //   icon:"fad fa-list-alt"
        // },
        // {
        //   title:"ตั้งค่ามาสเตอร์บัญชี",
        //   path:"/portal/account-master",
        //   icon:"fad fa-list-alt"
        // },
      ]
    },
    {
      title:"ระบบบริหารความเสี่ยง",
      path:"https://risk.sat.or.th/",
      anchor:"https://risk.sat.or.th/",
      active:false,
      icon:"fas fa-analytics",
    },
      //{title:"ประชาสัมพันธ์",
        //path:"/portal/flashnews",
        //active:false,
        //icon:"fas fa-megaphone",
      //},
    {
      title:"Debug",
      path:"/portal/debug",
      icon:"fad fa-bug",
    },
  ]
}
interface NavConfig {
  title: String,
  path?: String,
  anchor?: String,
  name?: String,
  active?: Boolean,
  icon: String,
  notification?: String,
  children?: Array<NavConfigChildren>
}
interface NavConfigChildren{
  title: String,
  path: String,
  anchor?: String,
  name?: String,
  active?: Boolean,
  icon?: String,
  notification?: String,
  children?: Array<NavConfigChildren>
}
