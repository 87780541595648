import {  RouteConfig } from 'vue-router'

import Main from '../../views/publicrelation/Main.vue'
import DocumentForm from '../../views/publicrelation/DocumentForm.vue'
import DocumentList from '../../views/publicrelation/DocumentList.vue'
import PRForm from '../../views/publicrelation/PRForm.vue'
import PRList from '../../views/publicrelation/PRList.vue'
const routes: Array<RouteConfig> = [
  // {
  //   path: '',
  //   component: Main,
  //   name: "Public Relation"
  // },
  {
    path: 'pr', 
    name: 'Public PR List',
    component: PRList,
    meta:{
      "ac": "PR,r"
    },
  },
  {
    path: 'pr/form',
    name: 'Public PR Form ',
    component: PRForm,
    meta:{
      "ac": "PR,r"
    }, 
  },
  {
    path: 'pr/edit/:id',
    name: 'Public PR Edit ',
    component: PRForm,
    meta:{
      "ac": "PR,r"
    }, 
  },
  {
    path: 'pr/detail/:id',
    name: 'Public PR detail ',
    component: PRForm,
    meta:{
      "ac": "PR,r"
    }, 
  },
  {
    path: 'document',
    name: 'Public Document List',
    component: DocumentList,
    meta:{
      "ac": "PR,r"
    },
  },
  {
    path: 'document/form',
    name: 'Public Document Form ',
    component: DocumentForm,
    meta:{
      "ac": "PR,r"
    }, 
  },
  {
    path: 'document/edit/:id',
    name: 'Public Document Edit ',
    component: DocumentForm,
    meta:{
      "ac": "PR,r"
    }, 
  }
]
export default routes