










































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import axios from "axios";
import { ADDRESS } from "@/setting";

@Component({
  components: {},
  props:{
    isModal:Boolean,
    userId:String
  }
})
export default class Form extends Vue {
  doc: any = {
    sector1Obj: {},
    sector2Obj: {},
    sector3Obj: {},
    sector4Obj: {},
    roles: ["ผู้ใช้งานทั่วไป"],
    accesses: [
      "Risk,r",
      "Setting,r",
      "UAC,r",
      "WFH,r",
      "WFH,w",
      "PR,r",
      "Dashboard,r",
      "Report,r",
      "Follow,r",
      "ERP,r",
      "ERP,w",
      "Adjust,r",
      "Budget,r",
      "KPI,r",
    ],
  };
  userId!:string
  sectors: Array<any> = [];
  sector1: string = "";
  sector2: string = "";
  sector3: string = "";
  sector4: string = "";
  isDetail: boolean = false;
  address = ADDRESS;
  ADDRESS = ADDRESS;
  systems: Array<any> = [
    "KPI,KPI",
    "Budget,งบประมาณ",
    "Adjust,การปรับแผน",
    "ERP,การเบิก/ยืมเงินในระบบ ERP",
    "Follow,ติดตามผลการดำเนินงาน",
    "Report,รายงาน",
    "Dashboard,MIS Dashboard",
    "PR,ประชาสัมพันธ์",
    "WFH,Work From Home",
    "UAC,การจัดการผู้ใช้งาน",
    "Setting,การตั้งค่า",
    "Risk,ความเสี่ยง",
  ];
  positions:Array<any> = []



  checkAdmin(){
    if(this.doc.roles.includes("ผู้ดูแลระบบ")){
      this.doc.accesses = [
        "KPI,r",
        "Budget,r",
        "Adjust,w",
        "KPI,w",
        "Budget,w",
        "Adjust,r",
        "ERP,r",
        "Follow,w",
        "Report,r",
        "Dashboard,w",
        "PR,w",
        "WFH,w",
        "UAC,r",
        "Setting,w",
        "Risk,w",
        "Dashboard,x",
        "PR,x",
        "WFH,x",
        "UAC,x",
        "Setting,x",
        "KPI,x",
        "Budget,x",
        "Adjust,x",
        "ERP,x",
        "ERP,w",
        "Follow,x",
        "Report,x",
        "Report,w",
        "Follow,r",
        "Dashboard,r",
        "PR,r",
        "WFH,r",
        "Setting,r",
        "Risk,r",
        "UAC,w",
        "Risk,x"
      ]
    } else if (this.doc.roles.includes("ผู้บริหารระดับสูง CEO")) {
      this.doc.accesses = [
        "KPI,r",  
        "Budget,r",
        "Adjust,r",
        "ERP,r",
        "Follow,w",
        "Report,r",
        "UAC,r",
        "Follow,r",
        "Dashboard,r",
        "Dashboard,w",
        "PR,r",
        "WFH,r",
        "WFH,w",
        "Setting,r",
        "Risk,r",
        "Risk,w",
      ]
    } else if (this.doc.roles.includes("ผู้รับผิดชอบโครงการ")){
      this.doc.accesses = [
        "KPI,r",  
        "Budget,r",
        "Budget,w",
        "Adjust,r",
        "Adjust,w",
        "ERP,r",
        "ERP,w",
        "Follow,w",
        "Report,r",
        "Report,w",
        "UAC,r",
        "Follow,r",
        "Dashboard,r",
        "Dashboard,w",
        "PR,r",
        "WFH,r",
        "WFH,w",
        "Setting,r",
        "Risk,r",
        "Risk,w",
      ]
    } else if (this.doc.roles.includes("ผู้ประสานงานโครงการ")) {
      this.doc.accesses = [
        "KPI,r",  
        "KPI,w",
        "Budget,r",
        "Budget,w",
        "Adjust,r",
        "Adjust,w",
        "ERP,r",
        "ERP,w",
        "Follow,w",
        "Report,r",
        "Report,w",
        "UAC,r",
        "UAC,w",
        "Follow,r",
        "Dashboard,r",
        "Dashboard,w",
        "PR,r",
        "PR,w",
        "WFH,r",
        "WFH,w",
        "Setting,r",
        "Setting,w",
        "Risk,r",
        "Risk,w",
      ]
    } else if (this.doc.roles.includes("ผู้ใช้งานทั่วไป")) {
      this.doc.accesses = [
        "Risk,r",
        "Setting,r",
        "UAC,r",
        "WFH,r",
        "WFH,w",
        "PR,r",
        "Dashboard,r",
        "Report,r",
        "Follow,r",
        "ERP,r",
        "ERP,w",
        "Adjust,r",
        "Budget,r",
        "KPI,r",
      ]
    }
    else {
      this.doc.accesses = []
    }
  }
  getSystemName(item: string) {
    let arr = item.split(",");
    return arr[1];
  }
  getSystemId(item: string) {
    let arr = item.split(",");
    return arr[0];
  }
  submitForm() {
    let prep = this.doc;
    prep = {
      ...prep,
      // sector1: prep.sector1Obj.name,
      // sector2: prep.sector2Obj.name,
      // sector3: prep.sector3Obj.name,
      // sector4: prep.sector4Obj.name
    };
    if (this.$route.params.id == undefined) {
      axios.post(`${ADDRESS}/user/register`, prep).then((response) => {
        console.log(response);
        alert("ดำเนินการบันทึกสำเร็จแล้ว");
        this.$router.push("/portal/uac");
      });
    } else {
      axios
        .patch(`${ADDRESS}/user/update/${this.doc.id}`, prep)
        .then((response) => {
          console.log(response);
          alert("ดำเนินการแก้ไขสำเร็จแล้ว");
          this.$router.push("/portal/uac");
        });
    }
  }
  @Watch("userId")
  updateUserId(){
    this.fetchData()
  }

  fetchData() {
    axios
      .get(`${ADDRESS}/user/get/${this.$route.params.id??this.userId}`)
      .then((response) => {
        console.log(response);
        if (response.data.status === "success") {
          let data = response.data.doc;
          // data = data.map((el:any)=>{
          //   return {
          //     ...el,
          //   }
          // })
          this.doc = {
            ...data,
            sector1Obj:
              this.sectors.find((el) => el.id == data.sector1) ?? {},
            sector2Obj:
              this.sectors.find((el) => el.id == data.sector2) ?? {},
            sector3Obj:
              this.sectors.find((el) => el.id == data.sector3) ?? {},
            sector4Obj:
              this.sectors.find((el) => el.id == data.sector4) ?? {},
            sector5Obj:
              this.sectors.find((el) => el.id == data.sector5) ?? {},
          };
          if (!Array.isArray(this.doc.roles)) this.doc.roles = [];
          if (!Array.isArray(this.doc.accesses)) this.doc.accesses = [];
        } else {
          console.log(`พบปัญหาในการดึงข้อมูลจากแม่ข่าย , ${response.data.error}`);
        }
      });
  }
  fetchSector() {
    axios.get(`${ADDRESS}/sectors/list`).then((response) => {
      this.sectors = response.data.docs;
      if (this.$route.params.id != undefined) this.fetchData();
    });
  }
  fetchPosition(){
    axios.get(`${ADDRESS}/positions/list`).then((response) => {
      this.positions = response.data.docs;
    })
  }
  mounted() {
    if (
      this.$route.path.search(`/portal/uac/detail/${this.$route.params.id}`) !=
      -1
    )
      this.isDetail = true;
    this.fetchSector();
    this.fetchPosition()
  }
}
