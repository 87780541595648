























































































import { Component, Vue } from "vue-property-decorator";
import { DocumentDocx } from "@/document/docx/B";
import axios from "axios";
import { ADDRESS } from "@/setting";
@Component({
  components: {
  },
})
export default class B01 extends Vue {
  doc:any = {}
  async createDocx() {
    const docx = new DocumentDocx();
    await docx.reportB01(this.doc);
    docx.save("Report_B01.docx");
  }
  fetchData() {
    axios
      .get(`${ADDRESS}/indicator/get/${this.$route.params.id}`)
      .then((response) => {
        console.log(response.data);
        if (response.data.status === "success") {
          let data = response.data.doc;
          this.doc = data;
        } else {
          console.log(`พบปัญหาในการดึงข้อมูลจากแม่ข่าย , ${response.data.error}`);
        }
      });
  }
  mounted() {
    this.fetchData();
  }
}
