


























































import { Component, Vue } from "vue-property-decorator";
@Component({
  components: {},
  props:{
    value: Array,
    label: String,
    budgetYear: Number,
    id: String,
    disabled:Boolean
  }
})
export default class Plan extends Vue {
  name!:string
  value!:Array<number>
  budgetYear!:number
  byear:number = 2565
  val:Array<number> = []
  updateVal(){
    this.val = this.value ?? []
    this.byear = this.budgetYear ?? (new Date().getFullYear() + 543)
  }
  makePlan(){
    this.$emit("makePlan")
  }
  mounted(){
    this.updateVal()
  }
  emitUp(){
    this.$emit("input", this.val)
  }
  changeBudgetYear(){
    this.$emit("changeBudgetYear",this.byear)
  }
}
