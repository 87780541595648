
























import { Component, Vue } from "vue-property-decorator";
import axios from "axios";
import { ADDRESS } from "@/setting";
import draggable from "vuedraggable";
import { ConfirmAction } from "@/decorators";
@Component({
  components: {
    draggable,
  },
})
export default class Form extends Vue {
  doc: any = {
    member: [],
  };
  address = ADDRESS;

  pushMember() {
    let member: Array<any> = this.doc.member;
    if (!Array.isArray(member)) member = [];
    member.push({
      name: "",
      text1: "",
    });
    this.$set(this.doc, "member", member);
  }
  submitForm() {
    if (this.$route.params.id == undefined) {
      axios.post(`${ADDRESS}/accountSchema/create`, this.doc).then((response) => {
        console.log(response);
        alert("ดำเนินการบันทึกสำเร็จแล้ว");
        this.$router.push("/portal/account-schema")
      });
    } else {
      axios
        .patch(`${ADDRESS}/accountSchema/update/${this.$route.params.id}`, this.doc)
        .then((response) => {
          console.log(response);
          alert("ดำเนินการแก้ไขสำเร็จแล้ว");
          this.$router.push("/portal/account-schema")
        });
    }
  }
  fetchData() {
    console.log(`${ADDRESS}/accountSchema/get/${this.$route.params.id}`)
    axios
      .get(`${ADDRESS}/accountSchema/get/${this.$route.params.id}`)
      .then((response) => {
        console.log(response.data);
        if (response.data.status === "success") {
          let data = response.data.doc;
          this.doc = data;
        } else {
          console.log(`พบปัญหาในการดึงข้อมูลจากแม่ข่าย , ${response.data.error}`);
        }
      });
  }
  mounted() {
    if (this.$route.params.id != undefined) this.fetchData();
  }
  @ConfirmAction("ยืนยันลบตั้งค่า?")
  deleteMember(i:number){
    this.doc.member.splice(i, 1)
  }
}
