







































































import { Component, Vue } from 'vue-property-decorator';
import axios from "axios"
import { ADDRESS } from "@/setting";
import { DateTime } from "luxon"
import { gt } from 'lodash';
import store from '@/store';
import { createPopper } from '@popperjs/core';
@Component({
  components: {
  },
})
export default class List extends Vue {
  fromDate: Date = DateTime.now().startOf("month").toJSDate()
  toDate: Date = DateTime.now().endOf("month").toJSDate()
  store = store
  docs:Array<any> = []
  limit: number = 15;
  page: number = 1;
  pages: number = 0;
  total: number = 0;
  address = ADDRESS
  userId:string = ""
  mounted(){
    this.fetchData()
  }
  fetchData(){
    this.docs = []
  let today = DateTime.now()
    let query = {
      search:{      
        AND:[
          {
            originator:this.$store.state.userId
          },
          {
            createdAt:{
              gte:this.fromDate,
              lte:this.toDate
            }
          },
        ] 
      },
      populate:"assigns"
    }
    axios.post(`${ADDRESS}/assignments/paginate`,query).then((response) => {
      console.log(response)
      this.docs = response.data.docs;
      this.total = response.data.total;
      this.pages = Math.ceil(response.data.total / this.limit);
    });
  }
  deleteItem(item: any) {
    var c = confirm(`คุณแน่ใจหรือว่าต้องการลบรายการนี้`);
    if (c) {
      axios.delete(`${ADDRESS}/assignment/delete/${item.id}`).then((response) => {
        console.log(response)
        this.fetchData();
      });
    }
  }
}
