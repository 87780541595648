































































import { Component, Vue, Watch } from "vue-property-decorator";
import axios from "axios";
import { ADDRESS } from "@/setting";
import { DateTime } from "luxon";
import { isNull } from "lodash";
@Component({
  props:{
    budget:Object,
    item:Object,
    indicators:Array,
    level:Number,
    erps:Array,
    tudNgern:Boolean,
    hidePencil:Boolean,
    withActual:Boolean,
    withRemain:Boolean,
    activeClass:String,
    monthClass:String,
  },
  components: {
  },
})
export default class ERPTableRow extends Vue {
  budget!:any
  item!:any
  indicators!:Array<any>
  level!:number
  erps!:Array<any>
  tudNgern!:boolean
  hidePencil!:boolean
  withActual!:boolean
  withRemain!:Boolean
  monthClass!:string
  activeClass!:string

  id!:string
  get totalErpAmount(){
    try {
      return [1,2,3,4,5,6,7,8,9,10,11].map(mi=>this.getERP(this.item.id,mi+1)).reduce((a,b)=>a+b,0)
    } catch (error) {
      return 0
    }
  }
  getErpItemOnThisMonth(m:number){
    let results = this.erps.filter(erp=>{
      let month = 0
      if(typeof erp.fromDateTransaction === "string") month = DateTime.fromISO(erp.fromDateTransaction).month
      else month = DateTime.fromJSDate(erp.fromDateTransaction).month
      month = this.convertMonth(month)
      // console.log({month,m})
      return erp.budgetItem_id===this.item.id&&month === m
    })
    return results.map(r=>({code:r.code,id:r.id,value:r.value}))
  }
  getERPMonthsFlag():Array<boolean>{
    let results = new Array(12).fill(false)
    let erps = this.erps.filter(erp=>erp.budgetItem_id===this.item.id)
    erps.forEach(erp => {
      try {
        erp.months.forEach((em:number)=>{
          results[em] = true
        })
      } catch (error) {
        
      }
    });
    return results
  }
  getERP(id:string,chkMonth:number){
    try {
      let filtered = this.erps.filter(erp=>{
        let month = 0
        if(typeof erp.fromDateTransaction === "string") month = DateTime.fromISO(erp.fromDateTransaction).month
        else month = DateTime.fromJSDate(erp.fromDateTransaction).month
        month = this.convertMonth(month)
        return erp.budgetItem_id===id&&month===chkMonth})
      return filtered.map(f=>f.value).reduce((a,b)=>a+b,0)
    } catch (error) {
      return 0
    }
  }
  isWithdrawn(){
    try {
      let found = this.erps.findIndex(erp=>erp.budgetItem_id===this.item.id)
      return found > -1
    } catch (error) {
      return false
    }
  }
  emitChange(){
    this.$emit("change")
  }
  createModal(id:string){
    if(this.budget.status=="Open"||this.$store.getters.isAdmin || this.budget.status=="Approved")
      this.$emit("createModal", id)
    else
      alert("ปิดการจัดทำงบประมาณแล้ว")  
  }
  openModal(id:string,detail?:boolean){
    if(this.budget.status=="Open"||this.$store.getters.isAdmin || this.budget.status=="Approved")
      this.$emit("openModal", id,detail)
    else
      alert("ปิดการจัดทำงบประมาณแล้ว")  
  }
  resolveIndicator(id:string){
    try {
      let found = this.indicators.find(ind=>ind.id==id)
      if(found === undefined) return {code:"",name:""}
      return found
    } catch (error) {
      return {code:"",name:""}
    }
  }
  get showActual():number{
    return Number(this.withActual || (this.tudNgern && (this.$route.path.search("plan-adjusting/") != -1 || this.$route.path.search("track/edit") != -1 || this.$route.path.search("follow/edit") != -1)))
  }
  get showRemain():number{
    return Number(this.withRemain || (this.tudNgern && (this.$route.path.search("plan-adjusting/") != -1 || this.$route.path.search("track/edit") != -1 || this.$route.path.search("follow/edit") != -1)))
  }
  
  convertMonth(i:number):number {
    switch (i) {
      case 10 : return 1;
      case 11 : return 2;
      case 12 : return 3;
      case 1 :  return 4;
      case 2 :  return 5;
      case 3 :  return 6;
      case 4 :  return 7;
      case 5 :  return 8;
      case 6 :  return 9;
      case 7 :  return 10;
      case 8 :  return 11;
      case 9 :  return 12;
      default: return 1
    }
  }
}
