





































import { Component, Vue } from "vue-property-decorator";
import axios from "axios";
import { ADDRESS } from "@/setting";

@Component({
  components: {
  },
})
export default class B04 extends Vue {
  docs:Array<any> = []
  sumQty:Array<any> = []

  fetchData() {
    let query = {
      budgetYear : parseInt(this.$route.params.budgetYear),
      sector : this.$route.params.sector
    }
    axios.post(`${ADDRESS}/report/B04`,query).then((response) => {
      console.log('report/B04',response)
      this.docs = response.data.result;
      this.sumQty = response.data.sumQty;
    });
  }
  mounted() {
    this.fetchData()
  }
}
