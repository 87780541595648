import MyDocument from "../../index";
import { Document, 
  ImageRun, 
  Packer, 
  Paragraph,AlignmentType,UnderlineType,HorizontalPositionAlign,VerticalPositionAlign, 
  TextRun, 
  Alignment ,
  Table,
  TableCell,
  TableRow,
  FrameAnchorType, 
  WidthType,
  Column,
  BorderStyle} from "docx";
import { size } from "lodash";

export class DocumentDocx extends MyDocument {
  _doc!: Document;
  fontName = "TH Sarabun New"
  //reportB01
  async reportB01(doc: any): Promise<any> {
    this.init = true
    let data: Array<any> = []
    let data2: Array<any> = [];
    (doc.indicators||[]).forEach((el: any, i: number) => {
      data.push(
        new TableRow({
          children: [
            new TableCell({
              children: [new Paragraph(`${i+1||0}`)],
            }),
            new TableCell({
              children: [new Paragraph(`${el.describe||""}`)],
            }),
            new TableCell({
              children: [new Paragraph(`${el.score||0}`)],
            }),
          ]
        })
    )});
    (doc.responsible||[]).forEach((el: any, i: number) => {
      data2.push(
        new TableRow({
          children: [
            new TableCell({
              children: [new Paragraph(`${el.type||""}`)],
            }),
            new TableCell({
              children: [new Paragraph(`${el.staff||""}`)],
            }),
            new TableCell({
              children: [new Paragraph(`${el.telephone||""}`)],
            }),
          ]
        })
    )});
    const document = new Document({
      styles: {
        paragraphStyles: [
          {
            id: "Normal",
            name: "Normal",
            basedOn: "Normal",
            next: "Normal",
            quickFormat: true,
            run: {
              color: "000000",
              size: 15 * 2,
              font: {
                name: this.fontName,
              },
            },
            paragraph: {
              // spacing: {
              //   after: 120,
              // },
            },
          },
        ]
      },
      sections: [
        {
          children: [
            new Paragraph({
              children: [
                new TextRun({
                  text:`รายละเอียดตัวชี้วัดเพื่อประกอบคำรับรองการปฏิบัติงานกกท. ประจำปีงบประมาณ พ.ศ. ${doc.budgetYear||0}`,
                  size: 15*2,
                })
              ],
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text:`ตัวชี้วัดที่:	${doc.code||""} ${doc.name||""}`,
                  size: 15*2,
                })
              ],
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text:`หน่วยวัด:	${doc.unit||""}`,
                  size: 15*2,
                })
              ],
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text:`น้ำหนัก:  ร้อยละ ${doc.weight||0}`,
                  size: 15*2,
                })
              ],
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text:`คำอธิบาย`,
                  size: 15*2,
                })
              ],
            }),
            new Table({
              width: { size: 9000, type:WidthType.DXA, },
              rows: [
                  new TableRow({
                      children: [
                          new TableCell({
                              children: [new Paragraph(`${doc.description||""}`)],
                          }),
                      ],
                  }),
              ],
            }),            
            new Paragraph({
              children: [
                new TextRun({
                  text:`สูตรการคำนวณ`,
                  size: 15*2,
                })
              ],
            }),
            new Table({
              width: { size: 9000, type:WidthType.DXA, },
              rows: [
                  new TableRow({
                      children: [
                          new TableCell({
                              children: [new Paragraph(`${doc.calculation||""}`)],
                          }),
                      ],
                  }),
              ],
            }),  
            new Paragraph({
              children: [
                new TextRun({
                  text:`เกณฑ์การให้คะแนน`,
                  size: 15*2,
                })
              ],
            }), 
            new Table({
              width:{
                size:100,
                type:WidthType.PERCENTAGE
              },
              rows: [
                  new TableRow({
                      children: [
                          new TableCell({
                              children: [new Paragraph("ข้อที่")],
                          }),
                          new TableCell({
                            children: [new Paragraph("คำอธิบาย")],
                          }),
                          new TableCell({
                            children: [new Paragraph("คะแนน")],
                          }),
                      ],
                  }),
                  ...data
              ],
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text:`หมายเหตุ/เงื่อนไข`,
                  size: 15*2,
                })
              ],
            }),
            new Table({
              width: { size: 9000, type:WidthType.DXA, },
              rows: [
                  new TableRow({
                      children: [
                          new TableCell({
                              children: [new Paragraph(`${doc.notes||""}`)],
                          }),
                      ],
                  }),
              ],
            }),     
            new Paragraph({
              children: [
                new TextRun({
                  text:`รายละเอียดข้อมูลพื้นฐาน`,
                  size: 15*2,
                })
              ],
            }),
            new Table({
              rows: [
                  new TableRow({
                      children: [
                          new TableCell({
                              children: [new Paragraph("ข้อมูลพื้นฐานประกอบตัวชี้วัด")],
                          }),
                          new TableCell({
                            children: [new Paragraph("หน่วยวัด")],
                          }),
                          new TableCell({
                            children: [new Paragraph("ผลการดำเนินงานในอดีด ปีงบประมาณ พ.ศ.")],
                            columnSpan:3
                          }),
                      ],
                  }),
                  new TableRow({
                      children: [
                        new TableCell({
                          children: [new Paragraph(`${doc.basic.info||""}`)],
                          rowSpan:2
                        }),
                        new TableCell({
                          children: [new Paragraph(`${doc.basic.unit||""}`)],
                          rowSpan:1
                        }),
                        new TableCell({
                          children: [
                            new Paragraph(`${doc.basic.headerYearMinus3||""}`),
                          ],
                        }),
                        new TableCell({
                          children: [
                            new Paragraph(`${doc.basic.headerYearMinus2||""}`),
                          ],
                        }),
                        new TableCell({
                          children: [
                            new Paragraph(`${doc.basic.headerYearMinus1||""}`),
                          ],
                        }),
                    ],
                  }),
                  new TableRow({
                    children: [
                      new TableCell({
                        children: [new Paragraph(``)],
                      }),
                      new TableCell({
                        children: [
                          new Paragraph(`${doc.basic.valueMinus3||""}`),
                        ],
                      }),
                      new TableCell({
                        children: [
                          new Paragraph(`${doc.basic.valueMinus2||""}`),
                        ],
                      }),
                      new TableCell({
                        children: [
                          new Paragraph(`${doc.basic.valueMinus1||""}`),
                        ],
                      }),
                  ],
                  })
              ],
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text:`แหล่งที่มา/วิธีจัดเก็บข้อมูล`,
                  size: 15*2,
                })
              ],
            }),
            new Table({
              width: { size: 9000, type:WidthType.DXA, },
              rows: [
                  new TableRow({
                      children: [
                          new TableCell({
                              children: [new Paragraph(`${doc.source||""}`)],
                          }),
                      ],
                  }),
              ],
            }),     
            new Paragraph({
              children: [
                new TextRun({
                  text:`บุคลกร/ผู้รับผิดชอบ`,
                  size: 15*2,
                })
              ],
            }),
            new Table({
              width: { size: 9000, type:WidthType.DXA, },
              rows: [
                new TableRow({
                  children: [
                      new TableCell({
                          children: [new Paragraph("ประเภท")],
                      }),
                      new TableCell({
                        children: [new Paragraph("ผู้รับผิดชอบ")],
                      }),
                      new TableCell({
                        children: [new Paragraph("เบอร์โทร")],
                      }),
                  ],
                }),
                ...data2
              ],
            }),                   
          ],
        },
      ],
    });
    this._blob = await Packer.toBlob(document);
  }
  //reportB02
  async reportB02(doc: any): Promise<any> {
    this.init = true
    let missionTable
    let missionItems:Array<any> = []
    doc.mission.forEach((el:any,i:number) => {
      missionItems.push(new Paragraph(`${el.name}`))
    })
    missionTable = new TableRow({
      children:[
        new TableCell({rowSpan:doc.mission.length, children:[new Paragraph("พันธกิจ")]}),
        new TableCell({children:missionItems}),
      ]}
    )
    let objectiveTable
    let objectiveItems:Array<any> = []
    doc.objective.forEach((el:any,i:number) => {
      objectiveItems.push(new Paragraph(`${el.name}`))
    })
    objectiveTable = new TableRow({
      children:[
        new TableCell({rowSpan:doc.objective.length, children:[new Paragraph("วัตถุประสงค์")]}),
        new TableCell({children:objectiveItems}),
      ]}
    )
    let topicTable
    let topicItems:Array<any> = [];
    (doc.topic||[]).forEach((tp:any,i:number) => {
      topicItems.push(new Table({
        width:{ size:100, type: WidthType.PERCENTAGE },
        rows:[
          new TableRow({
            children:[
              new TableCell({
                columnSpan: 2,
                children:[
                  new Paragraph(`ประเด็นยุทธศาสตร์ : ${tp.name||""}`)
                ]
              }),
            ]
          }),
          ...tp.children.map((gl:any)=>new TableRow({
            children:[
              new TableCell({
                children:[
                  new Paragraph("เป้าประสงค์")
                ]
              }),
              new TableCell({
                children:[
                  new Paragraph(`${gl.name||""}`),
                  ...gl.indicators.map((indlv1:any)=> new Paragraph(`ตัวชี้วัดที่: ${indlv1.indicatorName||""}`)),
                  ...gl.indicators.map((indlv1:any)=> new Paragraph(`ค่าเป้าหมาย: ${indlv1.target||""}`)),
                  new Table({
                    width:{size:100, type:WidthType.PERCENTAGE},
                    rows:[
                      ...gl.children.map((tt:any)=>new TableRow({
                        children:[
                          new TableCell({
                            children:[
                              new Paragraph("ผลผลิต")
                            ]
                          }),
                          new TableCell({
                            children:[
                              new Paragraph(`${tt.name||""}`),
                              ...tt.indicators.map((indlv2:any)=> new Paragraph(`ตัวชี้วัดที่: ${indlv2.indicatorName||""}`)),
                              ...tt.indicators.map((indlv2:any)=> new Paragraph(`ค่าเป้าหมาย: ${indlv2.target||""}`)),
                              new Table({
                                width:{size:100, type:WidthType.PERCENTAGE},
                                rows:[
                                  ...tt.children.map((pn:any)=>new TableRow({
                                    children:[
                                      new TableCell({
                                        children:[
                                          new Paragraph("กลยุทธ์")
                                        ]
                                      }),
                                      new TableCell({
                                        children:[
                                          new Paragraph(`${pn.name||""}`),
                                          ...pn.indicators.map((indlv3:any)=> new Paragraph(`ตัวชี้วัดที่: ${indlv3.indicatorName||""}`)),
                                          ...pn.indicators.map((indlv3:any)=> new Paragraph(`ค่าเป้าหมาย: ${indlv3.target||""}`)),
                                          new Table({
                                            width:{size:100, type:WidthType.PERCENTAGE},
                                            rows:[
                                              ...pn.children.map((pl:any)=>new TableRow({
                                                children:[
                                                  new TableCell({
                                                    children:[
                                                      new Paragraph(`แผนงาน ${pl.name||""}`),
                                                    ]
                                                  })
                                                ]
                                              }))
                                            ]
                                          })
                                        ]
                                      })
                                    ]
                                  }))
                                ]
                              })
                            ]
                          })
                        ]
                      }))
                    ]
                  })
                ]
              })
            ]
          }))
        ]
      }))
      // tp.indicators.forEach((ind:any)=>{
      //   topicItems.push(new Paragraph(`${JSON.stringify(ind)}`))
      // })
    })
    topicTable = new TableRow({
      children:[
        new TableCell({children:topicItems}),
      ]}
    )
    // doc.objective.forEach((el:any,i:number) => {
    //   data2.push(
    //     new TableCell({
    //       children: [new Paragraph(`${el.name}`)],
    //     })
    // )});
    /*
    */
    const document = new Document({
      styles: {
        paragraphStyles: [
          {
            id: "Normal",
            name: "Normal",
            basedOn: "Normal",
            next: "Normal",
            quickFormat: true,
            run: {
              color: "000000",
              size: 15 * 2,
              font: {
                name: this.fontName,
              },
            },
            paragraph: {
              // spacing: {
              //   after: 120,
              // },
            },
          },
        ]
      },
      sections: [
        {
          children: [
            new Paragraph({
              children: [
                new TextRun({
                  text:`ผังยุทธศาสตร์ กกท. ประจำปี พ.ศ. ${doc.budgetYear||0}`,
                  size: 15*2,
                }),
                new Paragraph({
                  children: [
                    new TextRun({
                      text:`วิสัยทัศน์: ${doc.vision||""}`,
                      size: 15*2,
                    })
                  ],
                }),
                new Table({
                  width:{
                    size: 9000, type:WidthType.DXA, 
                  },
                  rows:[missionTable]
                }),
                new Table({
                  width:{
                    size: 9000, type:WidthType.DXA, 
                  },
                  rows:[objectiveTable]
                }),
                ...topicItems
                // new Table({
                //   width:{size:100, type:WidthType.PERCENTAGE},
                //   rows:[ topicTable ]
                // })
              ],
            }),
          ]
        }
      ]
    });
    this._blob = await Packer.toBlob(document);
  }
}
