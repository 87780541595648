





























import { Component, Vue, Watch } from "vue-property-decorator";
import axios from "axios";
import { ADDRESS } from "@/setting";
import { DateTime } from "luxon";
@Component({
  components: {

  },
  props:{
    // id: String,
    isModal:Boolean,
    projectId:String
  }
})
export default class historyDetail extends Vue {
  doc: any = {};
  ADDRESS = ADDRESS;
  projectId!:string
  // @Watch("projectId")
  fetchData() {
    return new Promise((resolve,reject)=>{
      axios
        .get(`${ADDRESS}/budgetRequest/get/${this.projectId}`)
        .then((response) => {
          console.log(response.data);
          if (response.data.status === "success") {
            let data = response.data.doc;
            this.doc = data
            resolve("done")
          } else {
            console.log(`พบปัญหาในการดึงข้อมูลจากแม่ข่าย , ${response.data.error}`);
            reject("error")
          }
        });
    })
  }
  mounted() {
    this.fetchData()
  }
}
