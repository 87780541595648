



















import { Component, Vue, Watch } from "vue-property-decorator";
import axios from "axios";
import { ADDRESS } from "@/setting";
import draggable from "vuedraggable";
import { ConfirmAction } from "@/decorators";
@Component({
  props: {
    value: Array,
    name: String,
    disabled: Boolean
  },
  components: {
    draggable,
  },
})
export default class BudgetComponentAttachment extends Vue {
  name!: string;
  value!: Array<string>;
  val: Array<string> = [];
  address = ADDRESS
  mounted() {
    this.val = this.value ?? [];
  }
  @Watch("val")
  emitUp() {
    this.$emit("input", this.val);
  }
  @ConfirmAction("ต้องการลบไฟล์แนบนี้ใช่หรือไม่?")
  deleteAttachments(index: number) {
    this.val.splice(index,1)
  }
}
