














































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import axios from "axios";
import { ADDRESS } from "@/setting";
import { active } from "d3-transition";
import ModalReview from "./components/ModalReview.vue"
import { DateTime } from "luxon";
@Component({
  components: {},
  props:{
    budget:Object,
    activeItem:Object,
    activeFollow:Object,
    activeFollowIndex:Number,
    follows:Array,
    budgetitems:Array,
    active_i:Number,
    active_j:Number,
    active_k:Number,
    levIndex:Number
  }
})
export default class Form extends Vue {
  budget!: any;
  activeItem!: any
  activeFollow!: any
  activeFollowIndex!: number
  follows!: Array<any>
  budgetitems!: Array<any>
  active_i!: number
  active_j!: number
  active_k!: number
  l1:any
  levIndex!: number
  today = new Date()

  budgetRequestTypes:Array<string> = []
  budgetRequestOptions:Array<string> = []
  budgetOperationTypes:Array<string> = []

  submitModal() {
    if(this.activeFollowIndex == -1){
      this.follows.push(this.activeFollow)
    } else {
      this.follows[this.activeFollowIndex] = this.activeFollow
    }
    let prep = {
      follows: this.follows
    }
    console.log('activeFollow',this.activeFollow)
    console.log("OOO", prep)
    axios.post(`${ADDRESS}/follow/upsert`, this.activeFollow)
      .then((response) => {
        console.log(response)
        // alert("ดำเนินการแก้ไขสำเร็จแล้ว")
        // this.$router.push(`/portal/budget/edit/${this.$route.params.id}`)
      })
  }

  getSumValue(){
    try {
      let sum = this.l1[this.active_i].months[this.levIndex]
      return sum.formatFull()
    } catch (error) {
      return 0
    }
  }
  getLevel1(){
    try {
      let l1 = this.budgetitems ?? {}
      this.l1 = l1
      return l1
    } catch(error) {
      console.log(error)
    }
  }
  getLevel2(i:number){
    try {
    let l1 = this.budgetitems ?? {}
    let l2 = l1[i].children ?? {}
    return l2
    } catch(error) {
      console.log(error)
    }
  }
  getLevel3(i:number,j:number){
    try {
    let l1 = this.budgetitems ?? {}
    let l2 = l1[i].children ?? {}
    let l3 = l2[j].children ?? {}
    return l3
    } catch(error) {
      console.log(error)
    }
  }
  get flatten(){
    let resuts:Array<any> = []
    this.budgetitems.forEach(l1 => {
      let clone = JSON.parse(JSON.stringify(l1))
      delete clone.children
      resuts.push(clone)
      l1.children.forEach((l2:any) => {
        let clone = JSON.parse(JSON.stringify(l2))
        delete clone.children
        resuts.push(clone)
        l2.children.forEach((l3:any) => {
          let clone = JSON.parse(JSON.stringify(l3))
          delete clone.children
          resuts.push(clone)
        })
      })
    })
    return resuts
  }

  getParentForLevel2(under:string){
    try {
      let parent2 = this.flatten.find(item => item.id == under)
      return {parent2:parent2.subject}
    } catch (error) {
      return {}
    }
  }

  getParentForLevel3(under:string){
    try {
      let parent3 = this.flatten.find(item => item.id == under)
      let parent2 = parent3.find((item:any) => item.id == parent3.under)
      return {
        parent2:parent2.subject,
        parent3:parent3.subject
      }
    } catch (error) {
      return {}
    }
  }

  getMonth(i:number) {
    switch (i) {
      case 0 : return  "ตุลาคม"; break;
      case 1 : return  "พฤศจิกายน"; break;
      case 2 : return  "ธันวาคม"; break;
      case 3 : return  "มกราคม"; break;
      case 4 : return  "กุมภาพันธ์"; break;
      case 5 : return  "มีนาคม"; break;
      case 6 : return  "เมษายน"; break;
      case 7 : return  "พฤษภาคม"; break;
      case 8 : return  "มิถุนายน"; break;
      case 9 : return  "กรกฎาคม"; break;
      case 10 : return  "สิงหาคม"; break;
      case 11 : return  "กันยายน"; break;
    }
  }

  sumBudget(){
    try {
      let sum = this.getLevel1()![this.active_i].months.reduce((a:number,b:number)=>a+b,0)
      return sum.formatFull()
      
    } catch (error) {
      return 0
    }
    return 0
  }

  sumERP(){
    try {
      let sum = this.getLevel1()![this.active_i].monthsERP.reduce((a:number,b:number)=>a+b,0)
      return sum.formatFull()
    } catch (error) {
      return 0
    }
    return 0
  }

  mounted() {
    this.fetchBudgetOperation()
    this.fetchBudgetOptions()
    this.fetchBudgetType()
  }

  fetchBudgetType(){
    axios.get(`${ADDRESS}/setting/get-by-name/ประเภทงบประมาณในคำขอ`).then((response) => {
      console.log(response)
      let data = response.data
      let member = []
      try {
        member = data.result.member
      } catch (error) { }
      this.budgetRequestTypes = member
    });
  }
  
  fetchBudgetOptions(){
    axios.get(`${ADDRESS}/setting/get-by-name/ตัวเลือกงบประมาณในคำขอ`).then((response) => {
      console.log(response)
      let data = response.data
      let member = []
      try {
        member = data.result.member
      } catch (error) { }
      this.budgetRequestOptions = member
    });
  }

  fetchBudgetOperation(){
    axios.get(`${ADDRESS}/setting/get-by-name/ประเภทการดำเนินงานงบประมาณในคำขอ	`).then((response) => {
      console.log(response)
      let data = response.data
      let member = []
      try {
        member = data.result.member
      } catch (error) { }
      this.budgetOperationTypes = member
    });
  }
}
