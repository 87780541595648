import { DateTime } from "luxon";
export {};

declare global {
  interface String {
    toThaiNumber(): string;
  }
  interface Date {
    toThaiShort(): string;
    toThaiTime(): string;
    toTime(): string;
  }
}

Date.prototype.toThaiShort = function () {
  let dt = DateTime.fromJSDate(this);
  return dt.reconfigure({ outputCalendar: "buddhist" }).setLocale("th").toFormat("dd LLLL yyyy");
};
Date.prototype.toThaiTime = function () {
  let dt = DateTime.fromJSDate(this);
  return dt.reconfigure({ outputCalendar: "buddhist" }).setLocale("th").toFormat("dd LLLL yyyy H:mm:ss");
};
Date.prototype.toTime = function () {
  let dt = DateTime.fromJSDate(this);
  let result = dt.reconfigure({ outputCalendar: "buddhist" }).setLocale("th").toFormat("H:mm:ss");
  if(result=="Invalid DateTime") return "-"
  else return result
};

String.prototype.toThaiNumber = function () {
  var array = {
    "1": "๑",
    "2": "๒",
    "3": "๓",
    "4": "๔",
    "5": "๕",
    "6": "๖",
    "7": "๗",
    "8": "๘",
    "9": "๙",
    "0": "๐",
  };
  var str = this;
  for (var val in array) {
    //@ts-ignore
    str = str.split(val).join(array[val]);
  }
  return str as string;
};
