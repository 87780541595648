
























































































import { Component, Vue } from "vue-property-decorator";
import axios from "axios";
import { ADDRESS } from "@/setting";
import { DateTime } from "luxon";

@Component({
  components: {},
})
export default class Form extends Vue {
  doc: any = {
    assigns:[],
    finishDate: new Date()
  };
  address = ADDRESS
  get isDetail (){
    if(this.$route.path.search(`/detail/`)!=-1) return true
    else return false
  }
  pushAssign(){
    if(!Array.isArray(this.doc.assigns)) this.doc.assigns = []
    this.doc.assigns.push("")
  }
  submitForm() {
    let prep = {
      status : this.doc.status,
      resolution : this.doc.resolution,
      replyAttachments : this.doc.replyAttachments,
      finishDate: this.doc.finishDate
    }
    axios.patch(`${ADDRESS}/assignment/update/${this.doc.id}`,prep)
    .then((response) => {
      console.log(response)
      alert("ดำเนินการแก้ไขสำเร็จแล้ว")
      this.$router.push("/portal/assignment-reply")
    })
  }
  fetchData() {
    axios
      .get(`${ADDRESS}/assignment/get/${this.$route.params.id}`)
      .then((response) => {
        console.log(response.data);
        if (response.data.status === "success") {
          let data = response.data.doc;
          this.doc = data;
          console.log("finishDate", this.doc.finishDate)
          if(this.doc.finishDate === undefined) {
            this.doc.finishDate = DateTime.now().toJSDate()
          }
        } else {
          console.log(`พบปัญหาในการดึงข้อมูลจากแม่ข่าย , ${response.data.error}`);
        }
      });
  }
  mounted() {
    if (this.$route.params.id != undefined) this.fetchData();
  }
}
