





import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class B18 extends Vue {
}
