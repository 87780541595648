






































































































import { Component, Vue, Watch } from "vue-property-decorator";
import axios from "axios";
import { ADDRESS } from "@/setting";
import TableRow from "./planComponents/TableRow.vue"
import { DocumentExcel } from "@/document/xlsx"
@Component({
  components: {
    TableRow,
  },
})
export default class PlanPreview extends Vue {
  revision?:number = undefined
  budget:any = {}
  sector:any = {}
  indicators:Array<any> = []
  adjustment: any = {}
  erps:Array<any> = []
  sectors:Array<any> = []
  budgetitems:Array<any> = []
  level1:Array<any> = []
  level2:Array<any> = []
  level3:Array<any> = []

  async saveAsExcel() {
    let docExcel = new DocumentExcel()
    docExcel.createA09(this.budget,this.budgetitems, this.indicators)
  }
  fetchBudget() {
    axios
      .get(`${ADDRESS}/budget/get/${this.$route.params.id}`)
      .then((response) => {
        console.log(response.data);
        if (response.data.status === "success") {
          let data = response.data.doc;
          this.budget = data
          this.fetchIndicatorsByBudget()
        } else {
          console.log(`พบปัญหาในการดึงข้อมูลจากแม่ข่าย , ${response.data.error}`);
        }
      });
  }
  fetchIndicatorsByBudget(){
    let query = {
      skip:0,
      limit: 5000
    }
    axios.post(`${ADDRESS}/indicators/paginate`,query).then(response => {
      this.indicators = response.data.docs;
    })
  }
  getSector(_id:string){
    axios
      .get(`${ADDRESS}/sector/get/${_id}`)
      .then((response) => {
        let data = response.data.doc;
        this.sector = data;
      })
  }
  async fetchData() {
    await this.fetchAdjustment()
    try {
      this.revision = this.adjustment.bindWithRevision
    } catch (error) {
      
    }
    let body = {
      // status:"",
      budget_id:this.$route.params.id,
      adjustment_id:this.adjustment.id,
      revision: this.revision
      // adjustment_id: '62612982dc7a397e8806e826'
    }
    console.log(`${ADDRESS}/budgetitem/get-items`, body)
    axios
      .post(`${ADDRESS}/budgetitem/get-items`, body)
      .then((response) => {
        if (response.data.status === "success") {
          let data = response.data.results;
          console.log('budget items', data)
          this.budgetitems = data.map((el:any)=>{
            return {
              ...el,
              edit:false
            }
          });
          console.log('this.budgetitems',this.budgetitems)
        } else {
          console.log(`พบปัญหาในการดึงข้อมูลจากแม่ข่าย , ${response.data.error}`);
        }
    });
  }
  fetchAdjustment():Promise<any>{
    return new Promise((resolve,reject)=>{

      axios.get(`${ADDRESS}/budgetAdjustment/get/${this.$route.params.adjustment_id}`).then(response => {
        this.adjustment = response.data.doc??{status:"Open"}
        resolve("done")
      }).catch(error=>{
        reject("error")
      })
    })
  }
  fetchERP(){
    this.erps = []
    let query = {
      skip: 0,
      limit: 9999,
      search:{
        project_id : this.$route.params.id,
        NOT:{
          status:"deleted"
        }
      }
    }
    axios.post(`${ADDRESS}/erps/paginate`,query).then((response) => {
      this.erps = response.data.docs;
    });
  }
  fetchSector(){
    return new Promise((resolve,reject)=>{
      axios.get(`${ADDRESS}/sectors/list`).then((response) => {
        this.sectors = response.data.docs;
        resolve('ok sector')
      });
    })
  }
  get totalBudet(){
    try {
      return this.flatted.reduce((a,b)=>a+b.sum,0)
    } catch (error) {
      return 0
    }
  }
  get indicatorsText(){
    try {
      let result = (((this.budget.data||{})).indicators||[]).map((el:any)=>{
        let found = this.indicators.find(ind=>ind.id==el)
        return found
      })
      return result
    } catch (error) {
      return "?"
    }
    return "-"
  }
  get flatted(){
    let prep:Array<any> = []
    this.budgetitems.forEach(e0=>{
      prep.push(e0)
      e0.children.forEach((e1:any)=>{
        prep.push(e1)
        e1.children.forEach((e2:any)=>{
          prep.push(e2)
          e2.children.forEach((e3:any)=>{
            prep.push(e3)
          })
        })
      })
    })
    return prep
  }
  getLevel1():Array<any>{
    try {
      let l1 = this.budgetitems ?? {}
      if(this.budget.showZero) l1 = l1.filter((e:any)=>(e.months??[0]).reduce((a:number,b:number)=>a+b,0)!==0)
      return l1
    } catch(error) {
      console.log(error)
      return this.budgetitems
    }
  }
  getLevel2(i:number){
    try {
    let l1 = this.budgetitems ?? {}
    let l2 = l1[i].children ?? {}
    if(this.budget.showZero) l2 = l2.filter((e:any)=>(e.months??[0]).reduce((a:number,b:number)=>a+b,0)!==0)
    return l2
    } catch(error) {
      console.log(error)
    }
  }
  getLevel3(i:number,j:number){
    try {
    let l1 = this.budgetitems ?? {}
    let l2 = l1[i].children ?? {}
    let l3 = l2[j].children ?? {}
    if(this.budget.showZero) l3 = l3.filter((e:any)=>(e.months??[0]).reduce((a:number,b:number)=>a+b,0)!==0)
    return l3
    } catch(error) {
      console.log(error)
    }
  }
  
  mounted() {
    this.fetchAll()
  }

  async fetchAll(){
    await this.fetchData()
    this.fetchSector()
    this.fetchBudget()
    this.fetchERP()
  }
}
