


























































import { Component, Vue } from "vue-property-decorator";
import axios from "axios";
import { ADDRESS } from "@/setting";
import { DateTime } from "luxon";
@Component({
  components: {
  },
})
export default class history extends Vue {
  docs:Array<any> = []
  budget:any = {}
  show: number = 20;
  page: number = 1;
  pages: number = 0;
  total: number = 0;
  ADDRESS = ADDRESS
  nofile(name:string) {
    alert(`ไม่มีการอัปโหลด${name}`)
  }
  fetchData() {
    this.docs = []
    let query = {
      limit: this.show,
      skip: (this.page-1)*this.show,
      search: {
        project_id : this.$route.params.project_id
      },
      sort: {
        createdAt: "desc"
      }
    }
    axios.post(`${ADDRESS}/budgetAdjustments/paginate`,query).then((response) => {
      console.log('requests',response)
      this.docs = response.data.docs;
      this.total = response.data.total;
      this.pages = Math.ceil(response.data.total / this.show);
    });
  }
  fetchBudget() {
    axios
      .get(`${ADDRESS}/budget/get/${this.$route.params.project_id}`)
      .then((response) => {
        console.log('budget',response.data);
        if (response.data.status === "success") {
          let data = response.data.doc;
          this.budget = data
        } else {
          console.log(`พบปัญหาในการดึงข้อมูลจากแม่ข่าย , ${response.data.error}`);
        }
      });
  }
  mounted() {
    this.fetchData()
    this.fetchBudget()
  }
}
