

























































































































import { Component, Vue, Watch } from "vue-property-decorator";
import axios from "axios";
import { ADDRESS } from "@/setting";
import draggable from "vuedraggable";
import { DateTime } from "luxon";
import ERPModal from "./components/ERPModal.vue";
import TableRow from "./components/TableRow.vue"

@Component({
  components: {
    draggable,
    ERPModal,
    TableRow,
  },
})
export default class ERPForm extends Vue {
  budget: any = {};
  budgetitems: Array<any> = [];
  indicators: Array<any> = []
  sectors:Array<any> = []
  erps: Array<any> = [];
  activeERP: any = {}
  activeBudgetItem:any = {}

  updateItem(item:any) {
    let erpIndex = this.erps.findIndex((d) => d.id == item.id);
    if (erpIndex == -1) {
      this.erps.push({
        ...item,
        project_id: this.$route.params.id,
      });
    } else {
      this.erps[erpIndex] = {
        ...item,
        project_id: this.$route.params.id,
      };
    }
    this.endJob();
  }
  endJob() {
    let prep = {
      project_id: this.$route.params.id,
      budgetYear: this.budget.budgetYear,
      sector: this.budget.sectorCode,
      username: this.$store.state.fullName,
      user_id: this.$store.state.userId,
      erps: this.erps,
    };
    console.log("prep", prep);
    axios
      .post(`${ADDRESS}/erp/endjob/${this.budget.id}`, prep)
      .then((response) => {
        console.log("endjob", response);
        alert("ดำเนินการบันทึกสำเร็จแล้ว");
        this.init();
      });
  }
  createERPModalFromTable(id:string){
      this.activeERP = {
        budgetItem_id:id,
        value:0,
        fromDateTransaction: new Date()
      }
      try {
        this.activeBudgetItem = this.flatted.find(item=>item.id === this.activeERP.budgetItem_id)
      } catch (error) {
        this.activeBudgetItem = {id:"notfound"}
      }
      let modal = this.$refs.openmodal as HTMLElement
      // let targetModal = this.$refs.erpModal as HTMLElement
      // targetModal.classList.add("show")
      modal.click();
  }
  openERPModalFromTable(id:string){
    let i = this.erps.findIndex(erp=>erp.id===id)
    this.openERPModal(i)
  }
  openERPModal(i:number){
    this.activeERP = Object.assign({},this.erps[i])
    try {
      this.activeBudgetItem = this.flatted.find(item=>item.id === this.activeERP.budgetItem_id)
    } catch (error) {
      this.activeBudgetItem = {}
    }
    let modal = this.$refs.openmodal as HTMLElement
    // let targetModal = this.$refs.erpModal as HTMLElement
    // targetModal.classList.add("show")
    modal.click();
  }

  resolveSector(_id:string):string{
    try {
      let foundName = this.sectors.find(item=>item.id==_id)
      return foundName.name
    } catch (error) {
      return "-"
    }
  }
  get totalBudet(){
    try {
      return this.getLevel1().reduce((a:number,b:any)=>a+b.months.reduce((a:number,b:number)=>a+b,0),0)
    } catch (error) {
      return 0
    }
  }
  get flatted(){
    let prep:Array<any> = []
    this.budgetitems.forEach(e0=>{
      prep.push(e0)
      e0.children.forEach((e1:any)=>{
        prep.push(e1)
        e1.children.forEach((e2:any)=>{
          prep.push(e2)
          e2.children.forEach((e3:any)=>{
            prep.push(e3)
          })
        })
      })
    })
    return prep
  }

  fetchSector(){
    return new Promise((resolve,reject)=>{
      axios.get(`${ADDRESS}/sectors/list`).then((response) => {
        this.sectors = response.data.docs;
        resolve('ok sector')
      });
    })
  }
  fetchErp() {
    return new Promise((resolve, reject) => {
      let query = {
        limit: 99999,
        search: {
          project_id: this.$route.params.id,
          NOT: {
            status: "deleted",
          },
        },
      };
      axios.post(`${ADDRESS}/erps/paginate`, query).then((response) => {
        if (response.data.status === "success") {
          let data = response.data.docs;
          this.erps = data;
          resolve(data);
        } else {
          reject(response.data.error);
          console.log(
            `พบปัญหาในการดึงข้อมูลจากแม่ข่าย , ${response.data.error}`
          );
        }
      });
    });
  }

  fetchBudget() {
    return new Promise((resolve, reject) => {
      axios
        .get(`${ADDRESS}/budget/get/${this.$route.params.id}`)
        .then((response) => {
          if (response.data.status === "success") {
            let data = response.data.doc;
            this.budget = data;
            resolve(data);
          } else {
            reject(response.data.error);
            console.log(
              `พบปัญหาในการดึงข้อมูลจากแม่ข่าย , ${response.data.error}`
            );
          }
        });
    });
  }

  fetchBudgetItem() {
    return new Promise((resolve, reject) => {
      let query = {
        budget_id: this.$route.params.id,
      };
      axios.post(`${ADDRESS}/budgetitem/get-items/`, query).then((response) => {
        console.log(response.data);
        if (response.data.status === "success") {
          let data = response.data.results;
          this.budgetitems = data;
          resolve(data);
        } else {
          reject(response.data.error);
          console.log(
            `พบปัญหาในการดึงข้อมูลจากแม่ข่าย , ${response.data.error}`
          );
        }
      });
    });
  }

  fetchIndicatorsByBudget(){
    return new Promise((resolve,reject)=>{
      let query = {
        skip:0,
        limit: 5000
      }
      axios.post(`${ADDRESS}/indicators/paginate`,query).then(response => {
        this.indicators = response.data.docs;
        resolve(this.indicators)
      })
    })
  }

  mounted() {
    this.init();
  }
  
  async init() {
    this.fetchIndicatorsByBudget()
    this.fetchSector()
    if (this.$route.params.id != undefined) {
      await this.fetchBudget();
      await this.fetchBudgetItem();
      await this.fetchErp();
    let modal = this.$refs.openmodal as HTMLElement
    let targetModal = this.$refs.erpModal as HTMLElement
    // targetModal.classList.add("show")
    }
  }
  resolveType(n: string) {
    switch (n) {
      case "PE":
        return "เบิกเงิน";
      case "AD":
        return "ยืมเงิน";
      case "PR":
        return "จัดซื้อจัดจ้าง";
      case "XX":
        return "ไม่ระบุ";
      default:
        return "ไม่ระบุ";
    }
  }

  
  getLevel1():Array<any>{
    try {
      let l1 = this.budgetitems ?? []
      if(this.budget.showZero) l1 = l1.filter((e:any)=>(e.months??[0]).reduce((a:number,b:number)=>a+b,0)!==0)
      return l1
    } catch(error) {
      console.log(error)
      return this.budgetitems
    }
  }
  getLevel2(i:number){
    try {
    let l1 = this.budgetitems ?? []
    let l2 = l1[i].children ?? []
    if(this.budget.showZero) l2 = l2.filter((e:any)=>(e.months??[0]).reduce((a:number,b:number)=>a+b,0)!==0)
    return l2
    } catch(error) {
      console.log(error)
    }
  }
  getLevel3(i:number,j:number){
    try {
    let l1 = this.budgetitems ?? []
    let l2 = l1[i].children ?? []
    let l3 = l2[j].children ?? []
    if(this.budget.showZero) l3 = l3.filter((e:any)=>(e.months??[0]).reduce((a:number,b:number)=>a+b,0)!==0)
    return l3
    } catch(error) {
      console.log(error)
    }
  }
  get indicatorsText(){
    try {
      let result = (((this.budget.data||{})).indicators||[]).map((el:any)=>{
        let found = this.indicators.find(ind=>ind.id==el)
        return found
      })
      return result
    } catch (error) {
      return "?"
    }
    return "-"
  }

  toThaiFormat(dt: string) {
    var dtThai = DateTime.fromISO(dt)
      .reconfigure({ outputCalendar: "buddhist" })
      .setLocale("th")
      .toFormat("dd LLL yy");
    return dtThai;
  }
}
