






import { Component, Vue } from "vue-property-decorator";
import axios from "axios";
import { ADDRESS } from "@/setting";
import draggable from "vuedraggable";
@Component({
  props:{
    value:String,
    name:String,
    disabled:Boolean
  },
  components: {
    draggable,
  },
})
export default class BudgetComponentTextArea extends Vue {
  name!:string
  value!:string
  val:string = ""
  updateVal(){
    this.val = this.value
  }
  updated(){
    this.updateVal()
  }
  mounted(){
    this.updateVal()
  }
  emitUp(){
    this.$emit("input", this.val)
  }
}
