import { RouteConfig } from 'vue-router'

import FollowMain from '../../views/budgetFollow/Main.vue'
import FollowMainList from '../../views/budgetFollow/List.vue'
import FollowMainForm from '../../views/budgetFollow/Form.vue'


const routes: Array<RouteConfig> = [
  {
    path: 'follow',
    name: 'FollowMain',
    component: FollowMain,
    meta:{
      "ac": "Follow,r"
    },
    children: [
      {
        path: '',
        name: 'FollowList',
        component: FollowMainList,
        meta:{
          "ac": "Follow,r"
        },
      },
      {
        path: 'form',
        name: 'FollowForm',
        component: FollowMainForm,
        meta:{
          "ac": "Follow,r"
        },
      },
      {
        path: 'edit/:id',
        name: 'FollowEdit',
        component: FollowMainForm,
        meta:{
          "ac": "Follow,r"
        },
      },
    ]
  }
]

export default routes