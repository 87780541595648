














































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import axios from "axios";
import { ADDRESS } from "@/setting";
import { DateTime } from "luxon";
@Component({
  components: {},
  props: {
    budget: Object,
    activeItem: Object,
    activeTrackIndex: Number,
    tracks: Array,
    erps: Array,
    budgetitems: Array,
    active_i: Number,
    active_j: Number,
    active_k: Number,
    levIndex: Number,
  },
})
export default class ModalInfo extends Vue {
  budget!: any;
  activeItem: any = {
    months: [],
  };
  activeTrackIndex!: number;
  tracks!: Array<any>;
  erps!: Array<any>;
  budgetitems!: Array<any>;
  active_i!: number;
  active_j!: number;
  active_k!: number;
  l1: any;
  levIndex!: number;
  today = new Date();

  budgetRequestTypes: Array<string> = [];
  budgetRequestOptions: Array<string> = [];
  budgetOperationTypes: Array<string> = [];
  projectInformations: any = {};
  get activeTrack() {
    try {
      return (
        this.tracks.find(
          (track) => track.budgetItem_id === this.activeItem.id
        ) ?? {}
      );
    } catch (error) {
      return {};
    }
  }
  get parentItem() {
    console.log({ budgetitems: this.budgetitems });
    try {
      return this.budgetitems.find((item) => item.id === this.activeItem.under);
    } catch (error) {
      return {
        number: "-",
        subject: "-",
      };
    }
    return {
      number: "-",
      subject: "-",
    };
  }
  getERP(id: string) {
    try {
      let filtered = this.erps.filter((erp) => {
        let month = 0;
        if (typeof erp.fromDateTransaction === "string")
          month = DateTime.fromISO(erp.fromDateTransaction).month;
        else month = DateTime.fromJSDate(erp.fromDateTransaction).month;
        month = this.convertMonth(month);
        return erp.budgetItem_id === id;
      });
      return filtered
        .map((f) => `${f.code} - ${this.convertType(f.budgetItemType)}`)
        .join(",");
    } catch (error) {
      return 0;
    }
  }
  getERPValue(id: string) {
    try {
      let filtered = this.erps.filter((erp) => {
        let month = 0;
        if (typeof erp.fromDateTransaction === "string")
          month = DateTime.fromISO(erp.fromDateTransaction).month;
        else month = DateTime.fromJSDate(erp.fromDateTransaction).month;
        month = this.convertMonth(month);
        return erp.budgetItem_id === id;
      });
      return filtered
        .map((f) => parseFloat(f.value))
        .reduce((a, b) => a + b, 0);
    } catch (error) {
      return 0;
    }
  }

  submitModal() {
    if (this.activeTrackIndex == -1) {
      this.tracks.push(this.activeTrack);
    } else {
      this.tracks[this.activeTrackIndex] = this.activeTrack;
    }
    let prep = {
      note: this.activeTrack.note,
      progress: this.activeTrack.progress,
      fromDateRange: this.activeTrack.fromDateRange,
      toDateRange: this.activeTrack.toDateRange,
      project_id: this.budget.id,
      budgetItem_id: this.activeItem.id,
    };
    axios.post(`${ADDRESS}/track/create`, prep).then((response) => {
      console.log(response);
      // alert("ดำเนินการแก้ไขสำเร็จแล้ว")
      // this.$router.push(`/portal/budget/edit/${this.$route.params.id}`)
    });
  }

  getSumValue() {
    try {
      let sum = this.l1[this.active_i].months[this.levIndex];
      return sum.formatFull();
    } catch (error) {
      return 0;
    }
  }
  getLevel1() {
    try {
      let l1 = this.budgetitems ?? {};
      this.l1 = l1;
      return l1;
    } catch (error) {
      console.log(error);
    }
  }
  getLevel2(i: number) {
    try {
      let l1 = this.budgetitems ?? {};
      let l2 = l1[i].children ?? {};
      return l2;
    } catch (error) {
      console.log(error);
    }
  }
  getLevel3(i: number, j: number) {
    try {
      let l1 = this.budgetitems ?? {};
      let l2 = l1[i].children ?? {};
      let l3 = l2[j].children ?? {};
      return l3;
    } catch (error) {
      console.log(error);
    }
  }
  get flatten() {
    let resuts: Array<any> = [];
    this.budgetitems.forEach((l1) => {
      let clone = JSON.parse(JSON.stringify(l1));
      delete clone.children;
      resuts.push(clone);
      l1.children.forEach((l2: any) => {
        let clone = JSON.parse(JSON.stringify(l2));
        delete clone.children;
        resuts.push(clone);
        l2.children.forEach((l3: any) => {
          let clone = JSON.parse(JSON.stringify(l3));
          delete clone.children;
          resuts.push(clone);
        });
      });
    });
    return resuts;
  }

  getParentForLevel2(under: string) {
    try {
      let parent2 = this.flatten.find((item) => item.id == under);
      return { parent2: parent2.subject };
    } catch (error) {
      return {};
    }
  }

  getParentForLevel3(under: string) {
    try {
      let parent3 = this.flatten.find((item) => item.id == under);
      let parent2 = parent3.find((item: any) => item.id == parent3.under);
      return {
        parent2: parent2.subject,
        parent3: parent3.subject,
      };
    } catch (error) {
      return {};
    }
  }

  getMonth(i: number) {
    switch (i) {
      case 0:
        return "ตุลาคม";
        break;
      case 1:
        return "พฤศจิกายน";
        break;
      case 2:
        return "ธันวาคม";
        break;
      case 3:
        return "มกราคม";
        break;
      case 4:
        return "กุมภาพันธ์";
        break;
      case 5:
        return "มีนาคม";
        break;
      case 6:
        return "เมษายน";
        break;
      case 7:
        return "พฤษภาคม";
        break;
      case 8:
        return "มิถุนายน";
        break;
      case 9:
        return "กรกฎาคม";
        break;
      case 10:
        return "สิงหาคม";
        break;
      case 11:
        return "กันยายน";
        break;
    }
  }

  sumBudget() {
    try {
      let sum = this.getLevel1()![this.active_i].months.reduce(
        (a: number, b: number) => a + b,
        0
      );
      return sum.formatFull();
    } catch (error) {
      return 0;
    }
    return 0;
  }

  sumERP() {
    try {
      let sum = this.getLevel1()![this.active_i].monthsERP.reduce(
        (a: number, b: number) => a + b,
        0
      );
      return sum.formatFull();
    } catch (error) {
      return 0;
    }
    return 0;
  }

  mounted() {
    this.fetchBudgetOperation();
    this.fetchBudgetOptions();
    this.fetchBudgetType();
    this.fetchProjectInformation();
    this.fetchTracks();
  }
  fetchProjectInformation() {
    axios
      .post(`${ADDRESS}/innoviz/k2-projectinformation`, {
        code: this.activeItem.code,
      })
      .then((response) => {
        console.log("k2", response.data);
        let data = response.data.doc;
        this.projectInformations = data;
        try {
          this.projectInformations.scheduleDetail = JSON.parse(
            this.projectInformations.scheduleDetail
          );
        } catch (error) {}
        try {
          this.projectInformations.scheduleResult = JSON.parse(
            this.projectInformations.scheduleResult
          );
        } catch (error) {}
      });
  }
  fetchBudgetType() {
    axios
      .get(`${ADDRESS}/setting/get-by-name/ประเภทงบประมาณในคำขอ`)
      .then((response) => {
        console.log(response);
        let data = response.data;
        let member = [];
        try {
          member = data.result.member;
        } catch (error) {}
        this.budgetRequestTypes = member;
      });
  }

  fetchBudgetOptions() {
    axios
      .get(`${ADDRESS}/setting/get-by-name/ตัวเลือกงบประมาณในคำขอ`)
      .then((response) => {
        console.log(response);
        let data = response.data;
        let member = [];
        try {
          member = data.result.member;
        } catch (error) {}
        this.budgetRequestOptions = member;
      });
  }

  fetchTracks() {
    let query = {
      limit: 500,
      skip: 0,
      search: {
        project_id: this.budget.id,
      },
      sort: {
        createdAt : "desc"
      },
    };
    axios.post(`${ADDRESS}/tracks/paginate`, query).then((response) => {
      this.tracks = response.data.docs;
    });
  }

  fetchBudgetOperation() {
    axios
      .get(`${ADDRESS}/setting/get-by-name/ประเภทการดำเนินงานงบประมาณในคำขอ	`)
      .then((response) => {
        let data = response.data;
        let member = [];
        try {
          member = data.result.member;
        } catch (error) {}
        this.budgetOperationTypes = member;
      });
  }

  convertMonth(i: number): number {
    switch (i) {
      case 10:
        return 1;
      case 11:
        return 2;
      case 12:
        return 3;
      case 1:
        return 4;
      case 2:
        return 5;
      case 3:
        return 6;
      case 4:
        return 7;
      case 5:
        return 8;
      case 6:
        return 9;
      case 7:
        return 10;
      case 8:
        return 11;
      case 9:
        return 12;
      default:
        return 1;
    }
  }
  convertType(n: string) {
    switch (n) {
      case "PE":
        return "เบิกเงิน";
      case "AD":
        return "ยืมเงิน";
      case "PR":
        return "จัดซื้อจัดจ้าง";
      case "XX":
        return "ไม่ระบุ";
      default:
        return "ไม่ระบุ";
    }
  }
}
