






































import { Component, Vue } from "vue-property-decorator";
// import { store } from "vuex";
import axios from "axios";
import { ADDRESS } from "@/setting";
import { DateTime } from "luxon";


@Component({
  components: {
  },
})
export default class Login extends Vue {
  today = new Date()
  docs:Array<any> = []
  address = ADDRESS;
  mounted(){
    this.fetchData()
  }
  fetchData() {
    this.docs = []
    let query = {
      skip:0,
      limit:1,
      sort: {
        createdAt : "desc"
      },
      search: {
        type : 'ข่าวด่วน'
      }
    }
    axios.post(`${ADDRESS}/publications/paginate`,query).then((response) => {
      console.log(response)
      this.docs = response.data.docs;
    });
  }
}
