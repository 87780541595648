





































































import { Component, Vue } from "vue-property-decorator";
import axios from "axios";
import { ADDRESS } from "@/setting";
import HistoryDetail from "./historyDetail.vue"
@Component({
  components: {
    HistoryDetail
  },
})
export default class Form extends Vue {
  types:Array<any> = []
  sectors:Array<any> = []
  doc: any = {};
  docs:Array<any> = []
  budget: any = {}
  forms:Array<any> = [];
  address = ADDRESS;
  show: number = 20;
  page: number = 1;
  pages: number = 0;
  total: number = 0;
  userId:string = ""
  projectId:string = ""
  his: Array<any> = [];
  resolveStatus(item:any){
    try {
      if(item.status==="เห็นชอบ")
        return `${item.status}โดย ${item.responsibles[item.step].name} แล้ว`
      else if(item.status==="ตีกลับ")
        return  `คำขอของคุณโดนตีกลับเพราะ<br/> ${item.comment||'"ไม่มีคำอธิบาย"'}`
      else if(item.status==="ส่งคืน")
        return  `คำขอของคุณถูกส่งคืนเพราะ<br/> ${item.comment||'"ไม่มีคำอธิบาย"'}`
    } catch (error) {
      return "-"
    }
  }
  getHis(id:string) {
    let query = {
      search: { id: id },
      limit: 9999999,
      skip: 0,
    };
    axios.post(`${ADDRESS}/budgetRequests`, query).then((response) => {
      this.his = response.data.docs;  
    });
  }
  fetchData() {
    this.docs = []
    let query = {
      limit: this.show,
      skip: (this.page-1)*this.show,
      search:{
        project_id : this.$route.params.project_id
      },
      sort: {
        createdAt: "desc"
      }
    }
    axios.post(`${ADDRESS}/budgetRequests/paginate`,query).then((response) => {
      console.log('budgetRequests',response)
      this.docs = response.data.docs;
      this.total = response.data.total;
      this.pages = Math.ceil(response.data.total / this.show);
    });
  }
  fetchBudget() {
    axios
      .get(`${ADDRESS}/budget/get/${this.$route.params.project_id}`)
      .then((response) => {
        console.log(response.data);
        if (response.data.status === "success") {
          let data = response.data.doc;
          this.budget = data
        } else {
          console.log(`พบปัญหาในการดึงข้อมูลจากแม่ข่าย , ${response.data.error}`);
        }
      });
  }
  deleteItem(item: any) {
    var c = confirm(`คุณแน่ใจหรือว่าต้องการลบรายการนี้`);
    if (c) {
      axios.delete(`${ADDRESS}/budgetRequest/delete/${item.id}`).then((response) => {
        console.log(response)
        this.fetchData();
      });
    }
  }

  resolveSector(_id:string):string{
    try {
      let foundName = this.sectors.find(item=>item.id==_id)
      return foundName.name
    } catch (error) {
      return "-"
    }
  }
  resolveSectorFhai(_id:string):string{
    try {
      let code = this.$store.state.sectors.find((item:any)=>item.id==_id).code
      let foundName = this.$store.state.sectors.filter((item:any)=>item.category=="ฝ่าย"||item.category=="สำนักงาน").find((item:any)=>item.code==code)
      return foundName.name
    } catch (error) {
      return ""
    }
  }
  fetchSector(){
    return new Promise((resolve,reject)=>{
      axios.get(`${ADDRESS}/sectors/list`).then((response) => {
        this.sectors = response.data.docs;
        resolve('ok sector')
      });
    })
  }
  mounted() {
    this.fetchData()
    this.fetchBudget()
    this.fetchSector()
  }
}
