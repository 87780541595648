

















import { Component, Vue, Watch } from "vue-property-decorator";

@Component({
  components: {
  },
  props: {
    value: Number,
  }
})
export default class JMProgress extends Vue {
  slider:number = 10
  value!:number
  @Watch("value")
  valueCheck() {
    try {
      if(this.value==undefined) this.slider = 0
      else this.slider = Math.round(this.value)
    } catch (error) {
      this.slider = 1
    }
  }
  mounted(){
    this.valueCheck()
  }
  updateValue(){
    this.$emit("input", this.slider);
  }
}
