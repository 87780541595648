
























import { Component, Vue, Watch } from "vue-property-decorator";
import axios from "axios";
import { ADDRESS } from "@/setting";
import { DateTime } from "luxon";
@Component({
  components: {

  },
  props:{
    // id: String,
    isModal:Boolean,
    projectId:String
  }
})
export default class historyDetail extends Vue {
  doc: any = {};
  address = ADDRESS;
  projectId!:string
  @Watch("projectId")
  fetchData() {
    this.doc = {
      histories:[]
    }
    axios.get(`${ADDRESS}/budgetAdjustment/get/${this.projectId}`).then((response) => {
      console.log('budgetAdjustmentHistory',response)
      this.doc = response.data.doc
    });
  }
  mounted() {
    this.fetchData()
  }
}
