import Vue from 'vue'
import VueRouter from 'vue-router';
import {  RouteConfig } from 'vue-router'
import Home from '../views/Home.vue'
import FlashNews from '../views/FlashNews.vue'
import Portal from '../views/Portal.vue'
import Setting from './setting'
import Account from './account'
import Assignment from './assignment'
import Budget from './budget'
import BudgetAdjust from './budgetAdjust'
import BudgetFollow from './budgetFollow'
import BudgetTrack from './budgetTrack'
import GuestPublicrelation from './guestpublicrelation'
import Publicrelation from './publicrelation'
import Position from './position'
import Debug from './debug'
import UAC from './uac'
import Sector from './sector'
import Login from '../views/Login.vue'
import LandingBudget from '../views/LandingBudget.vue'
import LandingPlan from '../views/LandingPlan.vue'
import LandingSector from '../views/LandingSector.vue'
import Report from './report'
import Indicator from './indicators'
import Strategy from './strategy'
import ERP from './ERP'
import Dashboard from './dashboard'
import WorkInProgress from '../views/WorkInProgress.vue'
import NoAccess from '../views/NoAccess.vue'
import RequestA4 from '../views/budgetAdjust/components/requestA4.vue'

Vue.use(VueRouter);
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Login
  },
  {
    path: '/request-a4/:id',
    name: 'Request A4',
    component: RequestA4
  },
  {
    path: '/',
    name: 'NoAccess',
    component: NoAccess
  },
  {
    path: '/portal',
    name: 'Portal',
    component: Portal,
    children:[
      ...Account,
      ...Budget,
      ...BudgetAdjust,
      ...Setting,
      ...UAC,
      ...Assignment,
      ...Sector,
      ...Publicrelation,
      ...Position,
      ...Report,
      ...Indicator,
      ...Strategy,
      ...ERP,
      ...Budget,
      ...BudgetFollow,
      ...BudgetTrack,
      ...Dashboard,
      {
        path: 'flashnews',
        name: 'Flash News',
        component: FlashNews
      },
      {
        path: 'landing/budget',
        name: 'LandingBudget',
        component: LandingBudget
      },
      {
        path: 'landing/plan',
        name: 'LandingPlan',
        component: LandingPlan
      },
      {
        path: 'landing/sector',
        name: 'LandingSector',
        component: LandingSector
      },
      {
        path: 'work-in-progress',
        name: 'WorkInProgress',
        component: WorkInProgress
      },  
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  // ...GuestPublicrelation,
  ...Debug,
]
const router = new VueRouter({
  routes,
});

export default router;
