









































































import { ADDRESS } from "@/setting";
import axios from "axios";
import { Component, Vue } from "vue-property-decorator";
import { DocumentDocx } from "@/document/docx/B";

@Component({
  components: {},
})
export default class List extends Vue {
  docs: Array<any> = [];
  show: number = 20;
  limit: number = 15;
  page: number = 1;
  pages: number = 0;
  total: number = 0;
  filter1: any = {}
  filter2: any = {}
  sectors: Array<any> = [];
  types: Array<any> = [];
  strategy:Array<any> = [];

  clearSearch() {
    this.filter1 = 0;
    this.filter2 = "";
    this.fetchData();
  }
  fetchData() {
    this.docs = [];
    let query = {
      limit: this.show,
      skip: (this.page-1)*this.show,
      search: {
        ...this.filter1,
        ...this.filter2
      },
      sort: {
        budgetYear: "desc",
      },
    };
    console.log(query);
    axios.post(`${ADDRESS}/strategies/paginate`, query).then((response) => {
      // console.log(response)
      this.docs = response.data.docs;
      this.total = response.data.total;
      this.pages = Math.ceil(response.data.total / this.show);
    });
  }
  deleteItem(item: any) {
    var c = confirm(`คุณแน่ใจหรือว่าต้องการลบรายการนี้`);
    if (c) {
      axios.delete(`${ADDRESS}/strategy/delete/${item.id}`).then((response) => {
        console.log(response);
        this.fetchData();
      });
    }
  }
  fetchKPIType() {
    axios
      .get(`${ADDRESS}/setting/get-by-name/ประเภทตัวชี้วัด`)
      .then((response) => {
        let data = response.data;
        let member = [];
        try {
          member = data.result.member;
        } catch (error) {}
        this.types = member;
      });
  }
  fetchSector() {
    axios.get(`${ADDRESS}/sectors/list`).then((response) => {
      this.sectors = response.data.docs;
      if (this.$route.params.id != undefined) this.fetchData();
    });
  }
  async createDocx(item:any) {
    const doc = new DocumentDocx();
    await doc.reportB02(item);
    doc.save("Report_B02.docx");
  }
  fetchStrategy(){
    let promise = new Promise((resolve,reject)=>{
    axios
      .get(`${ADDRESS}/setting/get-by-name/ระดับผังยุทธศาสตร์`)
      .then((response) => {
        console.log(response.data);
        if (response.data.status === "success") {
          let data = response.data.doc;
          this.strategy = data.member;
        } else {
          reject(response.data.error)
          console.log(`พบปัญหาในการดึงข้อมูลจากแม่ข่าย , ${response.data.error}`);
        }
      });
    })
    return promise
  }
  mounted() {
    this.fetchKPIType();
    this.fetchStrategy();
    this.fetchData();
    this.fetchSector();
  }
}
