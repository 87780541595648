






























import { Component, Vue } from 'vue-property-decorator';
import axios from "axios"
import { ADDRESS } from "@/setting";
import { DateTime } from 'luxon';
@Component({
  components: {
  },
})
export default class List extends Vue {
  docs:Array<any> = []
  show: number = 20;
  page: number = 1;
  pages: number = 0;
  total: number = 0;
  address = ADDRESS
  endDate:Date = DateTime.now().toJSDate()
  fromDate:Date = DateTime.now().minus({days:30}).toJSDate()
  mounted(){
    this.fetchData()
  }
  showAllDate(){
    this.endDate = DateTime.now().plus({ years:5 }).toJSDate()
    this.fromDate = DateTime.now().minus({ years:5 }).toJSDate()
  }
  fetchData(){
    this.docs = []
    let search = `WHERE assigns LIKE '%${this.$store.state.userId}%' AND status = 'ดำเนินการแล้ว'`
    // search += ` AND createdAt >= DATEADD(day,-30,GETDATE())`
    search += ` AND targetDate BETWEEN 
    CONVERT(datetime,'${this.fromDate.toISOString().split('T')[0]}') 
    AND CONVERT(datetime,'${this.endDate.toISOString().split('T')[0]}')`
    console.log(search)
    let query = {
      search
    }
    axios.post(`${ADDRESS}/assignments/paginate`,query).then((response) => {
      console.log(response)
      this.docs = response.data.docs;
      this.total = response.data.total;
      this.pages = Math.ceil(response.data.total / this.show);
    });
  }
  deleteItem(item: any) {
    var c = confirm(`คุณแน่ใจหรือว่าต้องการลบรายการนี้`);
    if (c) {
      axios.delete(`${ADDRESS}/assignment/delete/${item.id}`).then((response) => {
        console.log(response)
        this.fetchData();
      });
    }
  }
}
