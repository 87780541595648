



































import { Component, Vue,Watch} from "vue-property-decorator";
import axios from "axios";
import { ADDRESS } from "@/setting";
import draggable from "vuedraggable";
import { ConfirmAction } from "@/decorators"
@Component({
  props:{
    value:Array,
    name:String,
    link:String,
    disabled:Boolean,
    budgetYear: [Number,String],
    id:String
  },
  components: {
    draggable,
  },
})
export default class BudgetComponentLink extends Vue {
  name!:string
  link!:string
  budgetYear!: number | string
  value!:Array<string>
  val:Array<string> = []
  values: Array<any> = []
  indicators: Array<any> = []
  loaded:boolean = false
  budget:any = {}
  id!:string
  mounted(){
    this.val = this.value??[]
    this.fetchStrategy()
    this.fetchIndicators()
  }

  @ConfirmAction("ยืนยันการลบข้อมูลหรือไม่?")
  deleteItem(i:number){
    this.val.splice(i,1)
    this.emitUp()
  }
  pushItem(){
    this.val.push("")
    this.emitUp()
  }
  emitUp(){
    this.$emit("input", this.val)
  }
  get items() {
    try {
    let prep:Array<string> = []
    let parent = this.values[0].topic
    if(this.link == 's'){
       parent.forEach((item:any)=>{
          item.children.forEach((el1:any) => {
              el1.children.forEach((el2:any) => {
                  prep.push(el2.name)
              });
          });
      })
    } else if(this.link == 't'){
       parent.forEach((item:any)=>{
          item.children.forEach((el1:any) => {
              el1.children.forEach((el2:any) => {
                el2.children.forEach((el3:any) => {
                    prep.push(el3.name)
                });
              });
          });
      })
    } else if(this.link == 'p'){
       parent.forEach((item:any)=>{
          item.children.forEach((el1:any) => {
              el1.children.forEach((el2:any) => {
                el2.children.forEach((el3:any) => {
                  el3.children.forEach((el4:any) => {
                      prep.push(el4.name)
                  });
                });
              });
          });
      })
    } else {
      prep = this.indicators
    }
      return prep
    } catch (error) {
      // console.log("error",error)
      return []
    }
  }
  @Watch('budgetYear')
  fetchStrategy() {
    let promise = new Promise((resolve,reject)=>{
    let query = {
      search: {
        budgetYear : this.budgetYear,
      },
      sort:{
        name : "asc"
      },
      skip: 0,
      limit: 999
    }
    console.log('strategies query',query)
    axios
      .post(`${ADDRESS}/strategies/paginate`, query)
      .then((response) => {
        console.log(response.data);
        if (response.data.status === "success") {
          let data = response.data.docs;
          console.log('strategies',response.data.docs)
          this.$set(this, "values", data)
          this.loaded = true
          resolve('ok indicator')
        } else {
          reject(response.data.error)
          console.log(`พบปัญหาในการดึงข้อมูลจากแม่ข่าย , ${response.data.error}`);
        }
      });
    })
    return promise
  }
  @Watch('budgetYear')
  fetchIndicators() {
    let promise = new Promise((resolve,reject)=>{
    let query :any =  []
    if(this.link==='kpi') {
      query = {
        search: {
          budgetYear : this.budgetYear,
        },
        skip: 0,
        limit: 99999,
        sort:{
          code : "desc"
        }
      }
    } else {
      query = {
        search: {
          budgetYear : this.budgetYear,
        },
        skip: 0,
        limit: 99999,
        sort:{
          name : "desc"
        }
      }
    }
    axios
      .post(`${ADDRESS}/indicators/paginate`, query)
      .then((response) => {
        console.log(response.data);
        if (response.data.status === "success") {
          let data = response.data.docs;
          this.$set(this, "indicators", data.map((d:any)=>{
            return {
              id: d.id,
              name: d.name,
              intro: d.intro,
              code: d.code,
            }
          }))
          this.loaded = true
          resolve('ok indicator')
        } else {
          reject(response.data.error)
          console.log(`พบปัญหาในการดึงข้อมูลจากแม่ข่าย , ${response.data.error}`);
        }
      });
    })
    return promise
  }
}
