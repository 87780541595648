
















import { Component, Vue } from "vue-property-decorator";
import { Watch } from "vue-property-decorator";
import { Store } from "vuex";
import axios, { AxiosResponse } from "axios";
import { ADDRESS } from "@/setting";
import _ from "lodash";

@Component({
  components: {},
  props: {
    value: Array,
  },
})
export default class JMToast extends Vue {
  value!: Array<any>;
  docs: Array<any> = [
    {
      title: "Hello, world!",
      content: "Hello, world! This is a toast message.",
      createdAt: new Date().toString()
    }
  ];
  mounted(){
    this.docs = this.value
  }
  deleteNotification(index: number) {
    this.docs.splice(index, 1);
    // this.$emit("update:value", this.value);
  }
  addNotification(){
    this.docs.push({})
    this.$emit("input", this.docs);
  }
}
