







import { Component, Vue } from "vue-property-decorator";
import { Watch } from "vue-property-decorator";
import Quill from "quill";
import { v4 as uuidv4 } from 'uuid';
@Component({
  components: {},
  props:{
    value:String,
    label: String,
    disabled: Boolean
  }
})
export default class Richtext extends Vue {
  uuid:string = ""
  id:string = ""
  value!:string

  get isDetail (){
    if(this.$route.path.search(`/detail/`)!=-1) return true
    else return false
  }
  mounted() {
    this.uuid = uuidv4()
    var toolbarOptions = [
      ["bold", "italic", "underline", "strike"],
      ["link", "image", "video"],
      ["blockquote", "code-block",],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ script: "sub" }, { script: "super" }], // superscript/subscript
      [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
      [{ size: ["small", false, "large", "huge"] }], // custom dropdown
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ color: [] }, { background: [] }], // dropdown with defaults from theme
      [{ align: [] }]
    ];
    var options = {
      modules: {
        toolbar: toolbarOptions
      },
      imageCompress: {
        quality: 0.45, // default
        maxWidth: 250, // default
        imageType: "image/jpeg", // default
        debug: true // default
      },
      theme: "snow"
    };
    setTimeout( () =>
    {
      var container = document.getElementById(`editor-${this.uuid}-${this.id}`);
      var editor = new Quill(container!, options);
      if(this.isDetail) editor.enable(false);
      editor.on("text-change", () => {
        this.$emit("input", editor.root.innerHTML);
      });
    }, 100 ); 
    this.updateData()
  }

  updateData() {
    //@ts-ignore
    try {
      document.getElementById(`#editor-${this.uuid}-${this.id}`)!.innerHTML = this.value??"";
    } catch (error) {
      
    }
  }
}
