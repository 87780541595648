import {  RouteConfig } from 'vue-router'

import AccountSchemaMain from '../../views/accountSchema/Main.vue'
import AccountSchemaList from '../../views/accountSchema/List.vue'
import AccountSchemaForm from '../../views/accountSchema/Form.vue'
import AccountMasterMain from '../../views/accountMaster/Main.vue'
import AccountMasterList from '../../views/accountMaster/List.vue'
import AccountMasterForm from '../../views/accountMaster/Form.vue'
import AccountZPMain from '../../views/accountZP/Main.vue'
import AccountZPList from '../../views/accountZP/List.vue'
import AccountZPForm from '../../views/accountZP/Form.vue'
const routes: Array<RouteConfig> = [
  {
    path: 'account-schema',
    component: AccountSchemaMain,
    meta:{
      "ac": "Setting,r"
    },
    children: [
      {
        path: '',
        name: 'Account Schema List',
        component: AccountSchemaList,
        meta:{
          "ac": "Setting,r"
        },
      },
      {
        path: 'form',
        name: 'Account Schema Form',
        component: AccountSchemaForm,
        meta:{
          "ac": "Setting,r"
        },
      },
      {
        path: 'edit/:id',
        name: 'Account Schema Edit',
        component: AccountSchemaForm,
        meta:{
          "ac": "Setting,r"
        },
      },
      {
        path: 'detail/:id',
        name: 'Account Schema Detail',
        component: AccountSchemaForm,
        meta:{
          "ac": "Setting,r"
        },
      },
    ]
  },
  {
    path: 'account-master',
    component: AccountMasterMain,
    meta:{
      "ac": "Setting,r"
    },
    children: [
      {
        path: '',
        name: 'Account Master List',
        component: AccountMasterList,
        meta:{
          "ac": "Setting,r"
        },
      },
      {
        path: 'form',
        name: 'Account Master Form',
        component: AccountMasterForm,
        meta:{
          "ac": "Setting,r"
        },
      },
      {
        path: 'edit/:id',
        name: 'Account Master Edit',
        component: AccountMasterForm,
        meta:{
          "ac": "Setting,r"
        },
      },
      {
        path: 'detail/:id',
        name: 'Account Master Detail',
        component: AccountMasterForm,
        meta:{
          "ac": "Setting,r"
        },
      },
    ]
  },
  {
    path: 'account-zp',
    component: AccountZPMain,
    meta:{
      "ac": "Setting,r"
    },
    children: [
      {
        path: '',
        name: 'Account ZP List',
        component: AccountZPList,
        meta:{
          "ac": "Setting,r"
        },
      },
      {
        path: 'form',
        name: 'Account ZP Form',
        component: AccountZPForm,
        meta:{
          "ac": "Setting,r"
        },
      },
      {
        path: 'edit/:id',
        name: 'Account ZP Edit',
        component: AccountZPForm,
        meta:{
          "ac": "Setting,r"
        },
      },
      {
        path: 'detail/:id',
        name: 'Account ZP Detail',
        component: AccountZPForm,
        meta:{
          "ac": "Setting,r"
        },
      },
    ]
  },
]
export default routes