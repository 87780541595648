






import { Component, Vue } from "vue-property-decorator";
// import { store } from "vuex";
import axios from "axios";
import { ADDRESS } from "@/setting";
import { DateTime } from "luxon";

@Component({
  components: {},
})
export default class NoAccess extends Vue {
  from:string = "/"
  mounted(){
    this.from = this.$route.query.redirect as string
  }
}
