





































































import { ADDRESS } from "@/setting";
import axios from "axios";
import { Component, Vue } from "vue-property-decorator";
import { DateTime } from "luxon";


@Component({
  components: {
  },
})
export default class List extends Vue {
  docs:Array<any> = []
  limit: number = 15;
  page: number = 1;
  show: number = 5;
  pages: number = 0;
  total: number = 0;
  filter1: any = {}
  filter2: any = {}
  types:Array<any> = []
  search:string = ""
  sectors:Array<any> = []
  clearSearch(){
    this.filter1 = {}
    this.filter2 = {}
    this.search =  ""
    this.fetchData()
  }
  resolveSector(_id:string):string{
    try {
      let foundName = this.sectors.find(item=>item.id==_id)
      return foundName.name
    } catch (error) {
      return "-"
    }
  }
  resolveSectorFhai(_id:string):string{
    try {
      let code = this.$store.state.sectors.find((item:any)=>item.id==_id).code
      let foundName = this.$store.state.sectors.filter((item:any)=>item.category=="ฝ่าย"||item.category=="สำนักงาน").find((item:any)=>item.code==code)
      return foundName.name
    } catch (error) {
      return ""
    }
  }
  erpReoprt(){
    this.$router.push('/portal/erp/report')
  }
  fetchData(){
    this.docs = []
    let query = {
      skip: this.limit*(this.page-1),
      limit: this.limit,
      sort:{
        createdAt : "desc"
      },
      search:{
        ...this.filter1,
        ...this.filter2
      }
    }
    if(this.search!=""){
      query.search.OR = [
        {code: {contains: this.search}},
        {name: {contains: this.search}}
      ]
    }
    console.log(query)
    axios.post(`${ADDRESS}/budgets/paginate`,query).then((response) => {
      console.log('budgets',response)
      this.docs = response.data.docs;
      this.total = response.data.total;
      this.pages = Math.ceil(response.data.total / this.limit);
    });
  }
  fetchSector(){
    return new Promise((resolve,reject)=>{
      axios.get(`${ADDRESS}/sectors/list`).then((response) => {
        this.sectors = response.data.docs;
        resolve('ok sector')
      });
    })
  }
  deleteItem(item: any) {
    var c = confirm(`คุณแน่ใจหรือว่าต้องการลบรายการนี้`);
    if (c) {
      axios.delete(`${ADDRESS}/erp/delete/${item.id}`).then((response) => {
        console.log(response)
        this.fetchData();
      });
    }
  }
  fetchKPIType(){
    axios.get(`${ADDRESS}/setting/get-by-name/ประเภทตัวชี้วัด`).then(response => {
      let data = response.data
      let member = []
      try {
        member = data.result.member
      } catch (error) { }
      this.types = member
    })
  }
  mounted() {
    this.filter1.budgetYear = (new Date().getFullYear() + 543) + ((new Date().getMonth()+1)>10?1:0)
    this.fetchKPIType()
    this.fetchData()
    this.fetchSector()
  }
}
