
























































































import { Component, Vue } from 'vue-property-decorator';
import axios from "axios";
import { ADDRESS } from "@/setting";
@Component({
  components: {
  },
})
export default class Form extends Vue {
  doc: any = {};
  executiveTypes: Array<any> = [];
  committeeTypes: Array<any> = [];
  managerTypes: Array<any> = [];
  sectorTypes: Array<any> = [];
  address = ADDRESS;
  pushSector() {
    let sector: Array<any> = this.doc.sector;
    if (!Array.isArray(sector)) sector = [];
    sector.push({
      name: "",
    });
    this.$set(this.doc, "sector", sector);
  }
  pushCommittee() {
    let committee: Array<any> = this.doc.committee;
    if (!Array.isArray(committee)) committee = [];
    committee.push({
      name: "",
    });
    this.$set(this.doc, "committee", committee);
  }
  submitForm() {
    if (this.$route.params.id == undefined) {
      axios.post(`${ADDRESS}/person/create`,this.doc)
      .then((response) => {
        console.log(response)
        alert("ดำเนินการบันทึกสำเร็จแล้ว")
        this.$router.push("/portal/committee")
      })
    } else {
      axios.patch(`${ADDRESS}/person/${this.doc.id}`,this.doc)
      .then((response) => {
        console.log(response)
        alert("ดำเนินการแก้ไขสำเร็จแล้ว")
        this.$router.push("/portal/committee")
      })
    }
  }
  fetchData() {
    axios.get(`${ADDRESS}/person/${this.$route.params.id}`).then((response) => {
      console.log(response)
      if(response.data.status === "success") {
      let data = response.data.doc;
      this.doc = data;
      } else {
        console.log(`พบปัญหาในการดึงข้อมูลจากแม่ข่าย , ${response.data.error}`)
      }
    });
  }
  getData() {
    axios.get(`${ADDRESS}/setting-by-name/คณะกรรมการหรือผู้ว่าการ`).then((response) => {
      // console.log(response)
      this.executiveTypes = response.data.result.member;
    });
    axios.get(`${ADDRESS}/setting-by-name/คณะกรรมการ`).then((response) => {
      // console.log(response)
      this.committeeTypes = response.data.result.member;
    });
    axios.get(`${ADDRESS}/setting-by-name/ผู้จัดการหรือรองผู้ว่าการ`).then((response) => {
      // console.log(response)
      this.managerTypes = response.data.result.member;
    });
    axios.get(`${ADDRESS}/sector/search-by-level/2`).then((response) => {
      // console.log(response)
      this.sectorTypes = response.data;
    });
  }
  mounted() {
    if (this.$route.params.id != undefined) this.fetchData()
    this.getData()
  }
}
