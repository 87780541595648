




























































































import { Component, Vue } from "vue-property-decorator";
import axios from "axios";
import { ADDRESS } from "@/setting";
import draggable from "vuedraggable";
import { DateTime } from "luxon";
import NoAccess from "@/components/NoAccess.vue";
import { ConfirmAction } from "@/decorators"
@Component({
  components: {
    draggable,
    NoAccess
  },
})
export default class Form extends Vue {
  types:Array<any> = []
  sectors:Array<any> = []
  doc: any = {
      _id:""
  };
  docs:Array<any> = []
  forms:Array<any> = [];
  address = ADDRESS;
  erpCode = ""
  telephone:boolean = false  
  show: number = 20;
  page: number = 1;
  pages: number = 0;
  total: number = 0;
  budgetItem:Array<any> = []

  reset(item:any){
    item = {
      id:""
    }
  }
  reset2(){
    this.doc = {
      id:""
    }
  }
  @ConfirmAction("ยืนยันการลบข้อมูลหรือไม่?")
  approveItem(item:any) {
    let prep = JSON.parse(JSON.stringify(item))
    prep.value = -1 * prep.value
    prep.status = "compensate"
    console.log(prep)
    console.log("approve")
    axios.patch(`${ADDRESS}/erp/update/${item.id}`, { status: "deleted" ,deleteBy:this.$store.state.userId })
    .then((response1) => {
      console.log('1', prep)
      console.log('2', response1)
      axios.post(`${ADDRESS}/erp/create`, prep)
        .then((response2) => {
          console.log(response2)
            alert("ดำเนินการลบสำเร็จแล้ว")
            this.reset(item)
        })
    })
  }
  rejectItem(item:any) {
    console.log("reject",item.id)
    axios.patch(`${ADDRESS}/erp/update/${item.id}`, { status: "normal" })
    .then((response) => {
      console.log('1', response)
      this.fetchData()
    })
  }
  checkTelephone() {
    if(this.$store.state.telephone == "" || this.$store.state.telephone == undefined ) this.telephone = true
  }
  openModalItem() {
    (this.$refs.openmodal as HTMLElement).click()
  }
  get isPass24Hours(){
    let hours = DateTime.fromISO(this.doc.createdAt).diffNow('hours').toObject().hours
    return (hours??0) < -24
  }
  submitForm() {
    let prep = JSON.parse(JSON.stringify(this.doc))
    prep.value = -1 * prep.value
    prep.status = "compensate"
    axios.patch(`${ADDRESS}/erp/update/${this.doc.id}`, { status: "deleted" })
    .then((response1) => {
      console.log('1', prep)
      console.log('2', response1)
      axios.post(`${ADDRESS}/erp/create/`, prep)
        .then((response2) => {
          console.log(response2)
            alert("ดำเนินการลบสำเร็จแล้ว")
            this.reset2()
            this.fetchData()
        })
    })
  }
  fetchData() {
    axios
      .get(`${ADDRESS}/erp/get-by-code/${this.erpCode}`)
      .then((response) => {
        console.log(response.data);
        if (response.data.status === "success") {
          let data = response.data.doc;
          this.doc = data;
        } else {
          console.log(`พบปัญหาในการดึงข้อมูลจากแม่ข่าย , ${response.data.error}`);
        }
      });
  }
  
  getItem(id:string){
    let result = ""
    return (this.budgetItem.find(bi=>bi.id==id)??{}).subject
  }
  fetchBudgetItem(){
    return new Promise((resolve,reject)=>{
    let prep:Array<string> = []
    this.docs.forEach(doc=>{
      prep.push(`${doc.budgetItem_id}`)
    })
    console.log("docs",prep)
    axios.post(`${ADDRESS}/budget-items/paginate`,{
      limit:999,
      skip:0,
      search: {
        id: { in : prep }
      }
    }).then((response) => {
        if (response.data.status === "success") {
          this.budgetItem = response.data.result;
          resolve('ok BudgetItem')
        } else {
          reject()
          console.log(`พบปัญหาในการดึงข้อมูลจากแม่ข่าย , ${response.data.error}`);
        }
      });
    })
  }
  fetchERP(){
    let promise = new Promise((resolve,reject)=>{
      this.docs = []
      let query = {
        search:{
          status:'pending',
        },
        skip: this.show*(this.page-1),
        limit: this.show,
        sort: {
          createdAt: "desc"
        }
      }
      console.log('query', query)
      axios.post(`${ADDRESS}/erps/paginate`,query).then((response) => {
        // console.log(response)
        if (response.data.status === "success") {
          this.docs = response.data.docs;
          this.total = response.data.total;
          this.pages = Math.ceil(response.data.total / this.show);
          resolve('ok data')
        } else {
          reject()
          console.log(`พบปัญหาในการดึงข้อมูลจากแม่ข่าย , ${response.data.error}`);
        }
      });
    })
    return promise
  }
  mounted() {
    this.checkTelephone()
    this.fetchERP()
    if(this.$route.params.id != undefined) this.fetchData()
  }
}
