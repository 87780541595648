






























































































































































import { Component, Vue } from 'vue-property-decorator';
import axios from "axios"
import { ADDRESS } from "@/setting";
import { ConfirmAction } from "@/decorators";
@Component({
  components: {
  },
})
export default class List extends Vue {
  doc:any = {}
  indicator:any = {}
  docs:Array<any> = []
  show: number = 20;
  page: number = 1;
  pages: number = 0;
  total: number = 0;
  today = new Date();
  address = ADDRESS
  data: Array<any> = []

  mounted() {
    this.fetchIndicator()
    if(this.$route.params.id != undefined) this.fetchData()
    this.doc.indicator_id = this.indicator.id
  }
  // dataCollector() {
  //   let result= this.indicator.responsible.find((el:any)=>el.type=="ผู้จัดเก็บข้อมูล")
  //   return result
  // }
  fetchIndicator() {
    let id = ""
    if(this.$route.path == `/portal/indicator-review/form/${this.$route.params.id}`) id = `${this.$route.params.id}`
    else id = `${this.$route.params.indicatorid}`
    axios
      .get(`${ADDRESS}/indicator/get/${id}`)
      .then((response) => {
        console.log(id);
        if (response.data.status === "success") {
          let data = response.data.doc;
          this.indicator = data;
          // this.doc.indicator_id = this.indicator.id
        } else {
          console.log(`พบปัญหาในการดึงข้อมูลจากแม่ข่าย , ${response.data.error}`);
        }
      });
  }
  fetchData() {
    axios
      .get(`${ADDRESS}/indicatorReview/get/${this.$route.params.id}`)
      .then((response) => {
        console.log(response.data);
        if (response.data.status === "success") {
          let data = response.data.doc;
          this.doc = data;
        } else {
          console.log(`พบปัญหาในการดึงข้อมูลจากแม่ข่าย , ${response.data.error}`);
        }
      });
  }
  pushEvidences() {
    let evidences: Array<any> = this.doc.evidences;
    if (!Array.isArray(evidences)) evidences = [];
    evidences.push({
      name: ""
    });
    this.$set(this.doc, "evidences", evidences);
  }
  submitForm() {
    let prep = {
      ...this.doc,
      indicator_id : this.indicator.id
    }
    if (this.doc.id == undefined) {
      axios.post(`${ADDRESS}/indicatorReview/create`, prep)
      .then((response) => {
        console.log(response)
        alert("ดำเนินการบันทึกสำเร็จแล้ว")
        // this.$router.push(`/portal/indicator-review/list/${this.review.id}`)
      })
    } else {
      axios.patch(`${ADDRESS}/indicatorReview/update/${this.$route.params.id}`,prep)
      .then((response) => {
        console.log(response)
        alert("ดำเนินการแก้ไขสำเร็จแล้ว")
        // this.$router.push(`/portal/indicator-review/list/${this.indicator.id}`)
      })
    }
  }
  @ConfirmAction("ยืนยันลบหลักฐานอ้างอิง?")
  deleteEvidences(f:number){
    this.doc.evidences.splice(f, 1)
  }
  @ConfirmAction("ยืนยันลบเอกสารแนบ?")
  deleteAttachments(i:number){
    this.doc.attachments.splice(i,1)
  }
}
