




































































import { Component, Vue } from "vue-property-decorator";
import axios from "axios";
import { ADDRESS } from "@/setting";
@Component({
  components: {
  },
})
export default class approve extends Vue {
  doc: any = {}
  budget: any = {}
  fetchData() {
    axios
      .get(`${ADDRESS}/adjustment/get/${this.$route.params.adjustid}`)
      .then((response) => {
        console.log(response.data);
        if (response.data.status === "success") {
          let data = response.data.doc;
          this.doc = data
        } else {
          console.log(`พบปัญหาในการดึงข้อมูลจากแม่ข่าย , ${response.data.error}`);
        }
      });
  }
  fetchBudget() {
    axios
      .get(`${ADDRESS}/budget/get/${this.$route.params.id}`)
      .then((response) => {
        console.log(response.data);
        if (response.data.status === "success") {
          let data = response.data.doc;
          this.budget = data
        } else {
          console.log(`พบปัญหาในการดึงข้อมูลจากแม่ข่าย , ${response.data.error}`);
        }
      });
  }
  submitForm() {

  }
  mounted() {
    this.fetchData()
    this.fetchBudget()
  }
}
