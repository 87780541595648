









































































import { ADDRESS } from "@/setting";
import axios from "axios";
import { Component, Vue } from "vue-property-decorator";
import { ConfirmAction } from "@/decorators";
import { DocumentExcel } from "@/document/xlsx"
@Component({
  components: {
  },
})
export default class List extends Vue {
  docs:Array<any> = []
  show: number = 20;
  page: number = 1;
  pages: number = 0;
  total: number = 0;
  filter1:any = {}
  filter2:any = {}
  types:Array<any> = []
  sector:any = {
    name: "-"
  }
  async saveAsExcel() {
    let docExcel = new DocumentExcel()
    docExcel.createB17(this.docs, 2564)
  }
  clearSearch(){
    this.filter1 = 0
    this.filter2 = ""
    this.fetchData()
  }
  resolveSectorFhai(_id:string):string{
    try {
      let code = this.$store.state.sectors.find((item:any)=>item.id==_id).code
      let foundName = this.$store.state.sectors.filter((item:any)=>item.level==3).find((item:any)=>item.code==code)
      return foundName.name
    } catch (error) {
      return ""
    }
  }
  editForm(id:any){
    this.$router.push(`/portal/follow/edit/${id}`)
  }
  fetchData(){
    this.docs = []
    let search:Array<string> = []
    let searchQuery = ""
    let query = {
      skip: this.show*(this.page-1),
      limit: this.show,
      search:{
        ...this.filter1,
        ...this.filter2
      },
      isFollow: true
    }
    // console.log(query)
    axios.post(`${ADDRESS}/budgets/paginate`,query).then((response) => {
      console.log(response)
      this.docs = response.data.docs;
      this.total = response.data.total;
      this.pages = Math.ceil(response.data.total / this.show);
    });
  }
  @ConfirmAction('คุณแน่ใจหรือว่าต้องการลบรายการนี้')
  deleteItem(item: any) {
    alert("คุณไม่สามารถลบรายการจากหน้าติดตามผลได้!")
    // var c = confirm(`คุณแน่ใจหรือว่าต้องการลบรายการนี้`);
    // if (c) {
      // axios.delete(`${ADDRESS}/budget/follow/delete/${item.id}`).then((response) => {
      //   // console.log(response)
      //   this.fetchData();
      // });
    // }
  }
  getSector(id:string){
    axios
      .get(`${ADDRESS}/sector/get/${id}`)
      .then((response) => {
        let data = response.data.doc;
        this.sector = data.name;
      })
  }
  mounted() {
    this.fetchData()
  }
}
