import {  RouteConfig } from 'vue-router'

import FollowPerformanceMain from '../../views/budgetTrack/Main.vue'
import FollowPerformanceList from '../../views/budgetTrack/List.vue'
import FollowPerformanceForm from '../../views/budgetTrack/Form.vue'
const routes: Array<RouteConfig> = [
  {
    path: 'track',
    component: FollowPerformanceMain,
    meta:{
      "ac": "Follow,r"
    },
    children: [
      {
        path: '',
        name: 'Follow Performance List',
        component: FollowPerformanceList,
        meta:{
          "ac": "Follow,r"
        },
      },
      {
        path: 'form',
        name: 'Follow Performance Form',
        component: FollowPerformanceForm,
        meta:{
          "ac": "Follow,r"
        },
      },
      {
        path: 'edit/:id',
        name: 'Follow Performance Edit',
        component: FollowPerformanceForm,
        meta:{
          "ac": "Follow,r"
        },
      },
    ]
  },
]
export default routes