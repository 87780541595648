




















































































































































































import { ConfirmAction } from "@/decorators";
import { ADDRESS } from "@/setting";
import axios from "axios";
import { Component, Vue } from "vue-property-decorator";
import draggable from "vuedraggable";

@Component({
  components: {
    draggable
  },
})
export default class Form extends Vue {
  doc:any = {
    indicators: [
      {
        describe : "",
        score : 0,
      },
      {
        describe : "",
        score : 0,
      },
      {
        describe : "",
        score : 0,
      },
      {
        describe : "",
        score : 0,
      },
      {
        describe : "",
        score : 0,
      }
    ],
    basic:{
      info:"",
      unit:"",
      headerYearMinus3:"",
      headerYearMinus2:"",
      headerYearMinus1:"",
      valueMinus3:"",
      valueMinus2:"",
      valueMinus1:""
    },
    responsible:[
      {
      type:"",
      staff:"",
      telephone:""
    }
    ]
  }
  types:Array<any> = []
  sectors:Array<any> = []
  options:Array<any> = []

  fetchOptions(search:string, loading:boolean) {
    axios.post(`${ADDRESS}/activedirectories/paginate`, {
      search:{
        displayName:{
          contains:search,
        },
        NOT:{
          OR:[
            { displayName:{ startsWith:"งาน" } },
            { displayName:{ startsWith:"กอง" } },
            { displayName:{ startsWith:"ฝ่าย" } },
            { displayName:{ startsWith:"สำนัก" } },
            { displayName:{ startsWith:"FN_" } },
          ]
        }
      },
      limit: 10,
      skip: 0,
    })
    .then((response) => {
      this.options = response.data.docs.map((el:any)=>el.displayName)
    })
    .catch((error) => {
      console.log(error)
    })
  }
  addIndicator(){
    let arr = this.doc.indicators
    if(!Array.isArray(arr)){
      arr = []
    }
    arr.push({
      describe : "",
      score : 0,
    })
    this.$set(this.doc,"indicators",arr)
  }

  @ConfirmAction("ยืนยันลบค่าเกณฑ์วัดในแต่ละระดับ?")
  deleteIndicators(index:number){
    this.doc.indicators.splice(index, 1)
  }

  @ConfirmAction("ยืนยันลบบุคลากรผู้กำกับดูแล/จัดเก็บตัวชี้วัด?")
  deleteResponsible(index:number){
    this.doc.responsible.splice(index, 1)
  }
  submitForm() {
    let prep = this.doc
    if (this.$route.params.id == undefined) {
      axios.post(`${ADDRESS}/indicator/create`, prep)
      .then((response) => {
        console.log(response)
        alert("ดำเนินการบันทึกสำเร็จแล้ว")
        // this.$router.push("/portal/indicator")
      })
    } else {
      axios.patch(`${ADDRESS}/indicator/update/${this.$route.params.id}`,prep)
      .then((response) => {
        console.log('response',response)
        alert("ดำเนินการแก้ไขสำเร็จแล้ว")
        // this.$router.push("/portal/indicator")
      })
    }
  }
  fetchData() {
    axios
      .get(`${ADDRESS}/indicator/get/${this.$route.params.id}`)
      .then((response) => {
        console.log(response.data);
        if (response.data.status === "success") {
          let data = response.data.doc;
          this.doc = data;
          if(this.doc.basic == undefined) this.doc.basic = {}
        } else {
          console.log(`พบปัญหาในการดึงข้อมูลจากแม่ข่าย , ${response.data.error}`);
        }
      });
  }
  fetchKPIType(){
    axios.get(`${ADDRESS}/setting/get-by-name/ประเภทตัวชี้วัด`).then(response => {
      let data = response.data
      let member = []
      try {
        member = data.doc.member
      } catch (error) { }
      this.types = member
    });
  }
  get totalRoyla(){
    return this.doc.indicators.map((a:any) => a.percent||0).reduce((a:number,b:number)=>a+b,0)
  }
  fetchSector(){
    axios.get(`${ADDRESS}/sectors/list`).then((response) => {
      this.sectors = response.data.docs;
      if (this.$route.params.id != undefined) this.fetchData()
    });
  }
  pushResponsible() {
    let responsible: Array<any> = this.doc.responsible;
    if (!Array.isArray(responsible)) responsible = [];
    responsible.push({
      type: "",
      staff: "",
      telephone: ""
    });
    this.$set(this.doc, "responsible", responsible);
  }
  updateValue(i:number) {
    console.log(this.totalRoyla)
    if(this.totalRoyla > 100) {
      this.doc.indicators[i].percent = 0
      alert("กรุณากรอกร้อยละการดำเนินงานไม่เกิน 100 เปอร์เซ็นต์")
    }
  }
  mounted() {
    this.fetchKPIType()
    this.fetchSector()
    if (this.$route.params.id != undefined) this.fetchData();
  }
}
